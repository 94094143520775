<template>
    <v-col class="pa-0">
        <v-row no-gutters>
            <v-col cols="5" class="mt-3">
                <div class="text-h5 font-weight-bold mb-3">Документы</div>
            </v-col>
        </v-row>
        <div v-if="!$document.list.length" class="my-2">Документы отсутствуют</div>
        <v-col @click="editItem(el)" class="cursor-pointer px-0 py-2" v-for="(el, index) in $document.list"
               :key="index">
            {{ el.sorting }}. {{ el.name }}
        </v-col>
        <v-btn class="rounded-lg text-none body-2 mt-3" color="primary" depressed @click="editItem()">Добавить</v-btn>
        <v-dialog v-model="dialog" max-width="500">
            <v-card v-if="$document.item" class="pa-5">
                <div class="text-h5 font-weight-bold">Редактирование</div>
                <v-text-field class="mt-3" v-model="$document.item.name" outlined dense hide-details
                              label="Наименование"></v-text-field>
                <v-text-field class="mt-3" v-model="$document.item.link" outlined dense hide-details
                              label="Ссылка"></v-text-field>
                <v-text-field class="mt-3" v-model="$document.item.sorting" outlined dense hide-details
                              label="Порядок"></v-text-field>
                <v-row no-gutters align="center" class="mt-4">
                    <v-btn :loading="loading" @click="save"
                           :disabled="!$document.item.name || !$document.item.link || !$document.item.sorting"
                           class="rounded-lg text-none body-2" color="primary" depressed>Сохранить
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn v-if="$document.item.id" @click="deleteItem" depressed class="rounded-lg text-none body-2"
                           color="error">Удалить
                    </v-btn>
                </v-row>
            </v-card>
        </v-dialog>
    </v-col>
</template>

<script>

export default {
    name: 'Settings',
    data: () => ({
        dialog: false,
        loading: false
    }),
    methods: {
        editItem(item = null) {
            if (item) {
                this.$document.item = this.$document.copy(item)
            } else {
                this.$document.setItemFromDefault()
            }
            this.dialog = true
        },
        async save() {
            this.loading = true
            const {promise} = this.$document.updateOrCreate()
            promise.then(() => {
                this.loadList()
                this.dialog = false
                this.$store.commit('setSnackbar', {color: 'success', text: 'Сохранено'})
                this.loading = false
            }).catch(() => {
                this.$store.commit('setSnackbar', {color: 'red', text: 'Ошибка сохранения'})
                this.loading = false
            })
        },
        async deleteItem() {
            this.dialog = false
            await this.$document.delete()
            this.loadList()
        },
        loadList() {
            this.$document.loadList({page_size: 'all'})
        }
    },
    mounted() {
        this.loadList()
    }
}
</script>

<style scoped>

</style>