const m = require('air-vue-model')()
m.url = 'after_order_review_dialogs'
m.name = 'afterOrderReview'

m.filters = {outlet: null}
m.default = {
    title: null,
    text: null,
    buttons_data: [],
    sorting: 1,
    outlet: null,
    image: null,
    active: true
}

module.exports = m