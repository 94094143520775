<template>
    <v-col class="pa-0" v-if="nutritional">
        <v-row no-gutters class="my-3" align="center">
            <div class="text-h6 mr-3">Пищевая ценность</div>
            <v-divider/>
            <v-btn :loading="$airIngredient.loadings.action" @click="saveNomenclature" depressed color="primary"
                   v-if="$auth.hasRight('products__edit_information')"
                   class="ml-3 rounded-lg text-none body-2">
                Сохранить
            </v-btn>
        </v-row>
        <v-select
            :items="$store.state.units"
            item-text="title"
            item-value="name"
            v-model="nutritional.unit"
            label="Единица измерения"
            hide-details
            class="mb-3"
            dense
            outlined
        ></v-select>
        <v-text-field type="number" v-model.number="nutritional.weight" outlined hide-details dense label="Вес"
                      class="mb-3"/>
        <v-text-field type="number" v-model.number="nutritional.calories" outlined hide-details dense label="Калории"
                      class="mb-3"/>
        <v-text-field type="number" v-model.number="nutritional.protein" outlined hide-details dense label="Белки"
                      class="mb-3"/>
        <v-text-field type="number" v-model.number="nutritional.fat" outlined hide-details dense label="Жиры"
                      class="mb-3"/>
        <v-text-field type="number" v-model.number="nutritional.carbohydrates" outlined hide-details dense
                      label="Углеводы"
                      class="mb-3"/>
    </v-col>
</template>

<script>
import {cloneDeep, isEqual} from "lodash";
import {useEventListener} from "@/plugins/useEventListener";

export default {
    name: 'ProductItemNomenclature',
    data: () => ({
      initialItem: null,
      eventsToClear: [],
    }),
    computed: {
        item() {
            return this.$airIngredient.item
        },
        nutritional() {
            return this.item.nutritional_value
        }
    },
  mounted() {
    this.initialItem = cloneDeep(this.item);
    this.eventsToClear.push(this.$router.beforeEach((to, from, next) => {
      if (!isEqual(this.initialItem, this.item)) {
        const answer = window.confirm(
            "Если вы уйдете со страницы, то потеряете все несохраненные данные"
        );
        if (answer) {
          if (this.eventsToClear.length) this.eventsToClear.map(v => v());
          next();
        } else {
          this.$store.commit('setProductDrawer', true)
        }
      } else next()
    }))
    this.eventsToClear.push(useEventListener(window, 'beforeunload', () => {
      if (!isEqual(this.initialItem, this.item)) {
        return confirm()
      }
    }))
  },
  beforeDestroy() {
    if (this.eventsToClear.length) this.eventsToClear.map(v => v())
  },
    methods: {
        saveNomenclature() {
            this.item.nutritional_value.dont_forget = this.$airIngredient.item.dont_forget
            this.$airIngredient.sendPutSingle('set_nutritional_value', this.$airIngredient.item.id, this.nutritional).then(res => {
              this.initialItem = cloneDeep(this.item)
                this.$store.commit('setSnackbar', {color: 'success', text: 'Пищевая ценность сохранена'})
            })
        }
    },
    created() {
        if (!this.nutritional) {
            this.$airIngredient.item.nutritional_value = {
                unit: 'g',
                weight: 0,
                calories: 0,
                protein: 0,
                fat: 0,
                carbohydrates: 0
            }
        }
    }
}
</script>

<style scoped>

</style>