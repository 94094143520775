import BaseModel from "../vendor/baseModel";

export class UpSale extends BaseModel {
    constructor() {
        super({
            url: 'products/up_sales',
            name: 'upSale',
            page_size: 'all',
        });
    }

    loadList(params) {
        return super.loadList({page_size: 'all'});
    }

    getProductUpSales(product) {
        const upSale = this.list.find(e => e.id === product.id)
        if (upSale) return upSale.up_sales
        else return null
    }
}
