<template>
    <span style="padding: 1px 4px; border-radius: 4px"
          :class="currentStatus.color"
          class="font-weight-regular text-caption">{{ currentStatus.name }}</span>
</template>

<script>

export default {
    name: 'OrderStatus',
    props: {
        status: Number
    },
    data: () => ({
        statuses: [
            {status: -1, name: 'Не удалось определить', color: 'blue-grey lighten-4', text: 'white'},
            {status: 0, name: 'Отменен', color: 'pink accent-1', text: 'white'},
            {status: 1, name: 'Создан', color: 'blue lighten-3', text: 'white'},
            {status: 2, name: 'Принят', color: 'yellow lighten-3', text: 'white'},
            {status: 3, name: 'В работе', color: 'cyan accent-3', text: 'white'},
            {status: 4, name: 'Готов к выдаче', color: 'deep-orange lighten-2', text: 'white'},
            {status: 5, name: 'Доставка', color: 'deep-purple lighten-3', text: 'white'},
            {status: 6, name: 'Завершен', color: 'green accent-2', text: 'white'},
        ]
    }),
    computed: {
        currentStatus() {
            // const value = Math.floor(Math.random() * (6 - -1 + 1)) + -1;
            return this.statuses.find(v => v.status === this.status) || this.statuses[0]
        }
    }
}
</script>

<style>

</style>