<template>
  <div style="width: auto">
    <template-list :auto-load-list="true" :model="$ingredientPriceListEdict">
      <template v-slot="{ object, to }">
        <router-link :to="to" style="width: 100%">
          <v-col class="pa-0 link">
            <v-row no-gutters>
              <div class="ml-2">
                <div class="font-weight-bold">
                  Приказ на {{ parseDate(object.starts_from) }}
                </div>
                <div class="caption">
                  Создан {{ parseDate(object.created_at) }}
                </div>
              </div>
            </v-row>
          </v-col>
        </router-link>
      </template>
    </template-list>
  </div>
</template>

<script>
export default {
  name: "OrderReviewTagGroups",
  components: {},
  data: () => ({}),
  methods: {
    parseDate(date) {
      return window.moment.localDateTime(date);
    },
  },
};
</script>

<style scoped></style>
