<template>
    <v-col class="pa-0 pa-5">
        <v-row dense align="center">
            <v-col cols="12" md="3">
                <date-picker class="rounded-lg" label="Дата начала"
                             clearable
                             v-model="date"></date-picker>
            </v-col>
            <v-col cols="12" md="3">
                <v-select clearable hide-details class="rounded-lg"
                          :items="$outlet.list" item-value="id" :item-text="v => v.number + '. ' + v.address"
                          v-model="outlet" label="Торговая точка" outlined
                          dense></v-select>
            </v-col>
            <v-col cols="12" md="3">
                <v-btn :disabled="!date || !outlet" color="primary" depressed class="rounded-lg text-none body-2"
                       height="40" :loading="loading"
                       @click="loadData">Обновить
                </v-btn>
            </v-col>
        </v-row>
        <v-slider hide-details class="mt-3" v-model="width"
                  color="black" track-color="black"
                  hint="Размер блока"
                  max="20"
                  min="8"
        ></v-slider>
        <v-row no-gutters class="flex-nowrap mt-5">
            <div class="pr-2" style="padding-top: 64px">
                <div style="height: 20px" v-for="(el, index) in resultView" :key="index" class="mt-2 caption">
                    {{ el.equipment }} ({{ el.slotAmount }})
                </div>
            </div>
            <v-divider vertical></v-divider>
            <v-col class="pa-0" style="overflow-y: auto;">
                <v-row no-gutters align="center" class="flex-nowrap">
                    <div v-for="el in 24" :key="el"
                         :style="`min-width: ${width * 2 * 60}px; box-shadow:0px 0px 0px 1px black inset;`">
                        <div style="height: 40px; width: 100%" class="text-center title font-weight-bold pt-2">{{
                                el - 1
                            }}
                        </div>
                        <v-row no-gutters align="center" class="flex-nowrap mt-1">
                            <div class="text-center caption"
                                 :style="`height: 20px; min-width: ${width * 2}px; box-shadow:0px 0px 0px 1px black inset;`"
                                 v-for="(min, minIndex) in 60" :key="minIndex">
                                {{ min - 1 }}
                            </div>
                        </v-row>
                    </div>
                </v-row>
                <v-row no-gutters v-for="(el, index) in resultView" :key="index" class="mt-2 flex-nowrap">
                    <div style="display: flex" v-for="(value, valueIndex) in el.values" :key="valueIndex">
                        <div class="grey lighten-3" v-if="value.white_blocks"
                             :style="`height: 20px; width: ${width * value.white_blocks}px`"></div>

                        <v-menu offset-y auto>
                            <template v-slot:activator="{ on, attrs }">
                                <v-row v-bind="attrs"
                                       :class="value.color" v-on="on" @mouseenter="currentOrder = value.order.id"
                                       @mouseleave="currentOrder = null"
                                       no-gutters class="pointer" v-if="value.blocks"
                                       :style="`height: 20px; width: ${value.blocks * width}px; ${value.order.id === currentOrder ? 'box-shadow:0px 0px 0px 3px black inset;': ''}`"></v-row>
                            </template>
                            <v-card class="pa-3 caption">
                                <div>Цвет:
                                    <v-icon size="15" :color="value.color">mdi-circle</v-icon>
                                </div>
                                <div>Оборудование: <span class="font-weight-medium">{{ value.equipment.name }}</span>
                                </div>
                                <div>Продолжительность: <span class="font-weight-medium">{{ value.seconds / 60 }}</span>
                                </div>
                                <!--                                <div>Блоки: <span class="font-weight-medium">{{ value.blocks }}</span></div>-->
                                <div>Продолжительность до: <span class="font-weight-medium">{{
                                        value.white_seconds / 60
                                    }}</span></div>
                                <!--                                <div>Блоки до: <span class="font-weight-medium">{{ value.white_blocks }}</span></div>-->
                                <div>Начало: <span class="font-weight-medium">{{
                                        value.start_date.format('HH:mm:ss')
                                    }}</span></div>
                                <div>Окончание: <span class="font-weight-medium">{{
                                        value.end_date.format('HH:mm:ss')
                                    }}</span></div>
                                <v-row no-gutters align="center">
                                    <span>{{ value._start_date.format('HH:mm:ss') }}</span>
                                    <span class="mx-1">-</span>
                                    <span>{{ value._end_date.format('HH:mm:ss') }}</span>
                                </v-row>
                                <v-btn block @click="loadOrder(value.order.id)" :loading="orderLoading" color="primary"
                                       class="mt-1 rounded-lg text-none body" outlined>Показать заказ
                                </v-btn>
                            </v-card>
                        </v-menu>
                        <div class="grey lighten-3" v-if="value.end_white_blocks"
                             :style="`height: 20px; width: ${width * value.end_white_blocks}px`"></div>
                    </div>
                </v-row>
            </v-col>
        </v-row>
        <order-view-dialog v-model="dialog"></order-view-dialog>
    </v-col>
</template>

<script>
import OrderViewDialog from "@/admin/views/equipmentLockedPeriod/OrderViewDialog";

export default {
    name: 'GantView',
    components: {
        OrderViewDialog
    },
    data: () => ({
        results: [],
        date: null,
        outlet: null,
        loading: false,
        width: 10,
        currentOrder: null,
        equipments: [],
        resultView: [],
        dialog: false,
        demoData: [
            {
                equipment: {id: 1},
                start_date: '2021-06-10 00:05:30',
                end_date: '2021-06-10 00:08:30',
                slot_number: 1,
                order: {id: 1}
            },
            {
                equipment: {id: 1},
                start_date: '2021-06-10 00:01:30',
                end_date: '2021-06-10 00:03:20',
                slot_number: 1,
                order: {id: 1}
            },
            {
                equipment: {id: 1},
                start_date: '2021-06-10 00:03:00',
                end_date: '2021-06-10 00:05:30',
                slot_number: 1,
                order: {id: 1}
            },
            {
                equipment: {id: 1},
                start_date: '2021-06-10 00:58:30',
                end_date: '2021-06-10 01:01:31',
                slot_number: 1,
                order: {id: 2}
            },
        ],
        orderLoading: false
    }),
    methods: {
        loadData() {
            const start_date = window.moment(this.date + ' 00:00:00').local().utc().format('YYYY-MM-DD HH:mm:ss')
            const end_date = window.moment(this.date + ' 23:59:59').local().utc().format('YYYY-MM-DD HH:mm:ss')
            this.loading = true
            this.$equipmentConfig.loadList({
                page_size: 'all',
                outlet: this.outlet
            }).then(res => {
                this.equipments = res
                this.$equipmentLockedPeriod.loadList({
                        page_size: 'all',
                        start_date,
                        end_date
                    }, {setToModel: false}
                ).then(res => {
                    this.loading = false
                    this.results = res.results
                    this.editResults()
                })
            })
        },
        loadOrder(id) {
            this.orderLoading = true
            this.$order.loadItem(id).then(() => {
                this.dialog = true
                this.orderLoading = false
            })
        },
        editResults() {
            let result = []
            let orders = []
            for (const el of this.equipments) {
                for (const value of Array(el.slot_amount).keys()) {
                    result.push({
                        equipmentId: el.equipment.id,
                        equipment: el.equipment.name,
                        slotAmount: value + 1,
                        values: [],
                    })
                }
            }
            for (const el of this.results) {
                const res = result.find(val => val.equipmentId === el.equipment.id && el.slot_number === val.slotAmount)
                if (res) {
                    el._start_date = window.moment.utc(el.start_date).local()
                    el._end_date = window.moment.utc(el.end_date).local()
                    el.start_date = this.getMomentDate(el.start_date)
                    el.end_date = this.getMomentDate(el.end_date)
                    res.values.push(el)
                }
            }
            for (const el of result) {
                el.values = el.values.sort((a, b) => {
                    return a.start_date - b.start_date
                })
                for (const val of el.values)
                    orders.push(val.order.id)
            }
            orders = this.unique(orders)
            const colors = this.getOrderColors(orders)


            const start_date = window.moment(this.date + ' 00:00:00').local()
            const end_date = window.moment(this.date + ' 23:59:59').add(1, 'seconds').local()
            for (const el of result) {
                el.values = el.values.sort((a, b) => {
                    return a.start_date - b.start_date
                })
                for (const [index, val] of el.values.entries()) {
                    if (!index) {
                        val.white_seconds = val.start_date.diff(start_date, 'seconds')
                    } else {
                        const last_end_date = el.values[index - 1].end_date
                        val.white_seconds = val.start_date.diff(last_end_date, 'seconds')
                        if (val.white_seconds < 0) {
                            val.white_seconds = 0
                            val.start_date = last_end_date
                        }
                    }
                    if (index === el.values.length - 1) {
                        val.end_white_seconds = end_date.diff(val.end_date, 'seconds')
                    }
                    val.seconds = val.end_date.diff(val.start_date, 'seconds')
                    val.blocks = Math.floor(val.seconds / 30)
                    val.white_blocks = Math.floor(val.white_seconds / 30)
                    val.end_white_blocks = Math.floor(val.end_white_seconds / 30)
                    val.color = colors[val.order.id]
                }
            }
            this.resultView = result
        },
        getMomentDate(date) {
            let newDate = window.moment.utc(date).local()
            let seconds = newDate.seconds()
            if (seconds > 0 && seconds < 30) {
                newDate.set({s: 30})
            } else if (seconds > 30 && seconds <= 59) {
                newDate.add(1, 'minutes')
                newDate.set({s: 0})
            }
            return newDate
        },
        getOrderColors(orders) {
            const colors = [
                'purple accent-2',
                'red accent-3',
                'amber',
                'blue lighten-1',
                'light-green',
                'teal lighten-3',
                'deep-purple lighten-3',
                'cyan',
                'light-green accent-3',

            ]
            const results = {}
            let _index = 0
            for (const order of orders) {
                if (_index === colors.length) {
                    _index = 0
                }
                results[order] = colors[_index]
                _index++
            }
            return results

        },
        unique(arr) {
            let result = [];

            for (let str of arr) {
                if (!result.includes(str)) {
                    result.push(str);
                }
            }

            return result;
        }
    },
    computed: {
        list() {

        }
    },
    created() {
        this.$outlet.loadList()
        this.date = window.moment().format('YYYY-MM-DD')
    }
}
</script>

<style scoped>

</style>