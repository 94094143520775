<template>
  <v-col
    v-if="$auth.user && ($auth.user.is_staff || $auth.user.is_superuser)"
    class="pa-0"
  >
    <drawer v-if="ready"></drawer>
    <v-app-bar app clipped-left color="primary" elevation="2">
      <v-btn
        color="white"
        icon
        @click="$store.state.drawer = !$store.state.drawer"
      >
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <router-link :to="{ name: 'admin' }">
        <v-row
          no-gutters
          align="center"
          class="mx-3 white--text font-weight-medium row"
        >
          <div>Панель администратора</div>
          <div
            class="ml-3 primary darken-3 px-4 py-1 rounded-lg"
            v-if="getStageName()"
          >
            {{ getStageName() }}
          </div>
        </v-row>
      </router-link>
      <v-spacer></v-spacer>

      <v-row justify="end">
        <v-row class="ma-0" justify="end">
          <div
            class="white--text mr-3 text-right justify-center"
            style="display: flex; flex-direction: column"
          >
            <div class="">
              {{ $auth.user.first_name }}/{{
                $user.userTypes.find((v) => v.value === $auth.user.user_type)
                  ?.title
              }}
            </div>
            <div class="text-body-2">{{ $auth.user.phone }}</div>
          </div>
          <v-avatar color="#f0ad00">
            <v-icon dark> mdi-account-circle</v-icon>
          </v-avatar>
        </v-row>
        <v-divider class="mx-3" style="border-color: #e9a905" vertical />
        <v-btn color="white" icon @click="logoutModal = true">
          <v-icon size="20">mdi-exit-to-app</v-icon>
        </v-btn>
      </v-row>
    </v-app-bar>
    <router-view v-if="ready"></router-view>
    <v-dialog v-model="authenticatorDialog" max-width="500" persistent>
      <v-card class="pa-5">
        <v-row align="center" class="flex-column" justify="center" no-gutters>
          <div class="title font-weight-bold mb-5">
            Ввод Google Authenticator
          </div>
          <vue-qrcode
            v-if="qrCodeValue"
            :options="{ width: 200 }"
            :value="qrCodeValue"
            errorCorrectionLevel="H"
          />
          <AuthenticatorInput
            style="width: 400px"
            @complete="enableAuthenticator"
          ></AuthenticatorInput>
          <div class="caption mt-3">
            Введите 6-значный код из приложения Google Authenticator
          </div>
          <v-row justify="center" no-gutters>
            <div class="mt-5 body-2 link" @click="logout">Выйти</div>
          </v-row>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog v-model="logoutModal" max-width="500" persistent>
      <ConfirmDialogData
        text="Вы действительно хотите выйти?"
        v-on:closeDialog="logoutModal = false"
        v-on:confirm="logout()"
      />
    </v-dialog>
  </v-col>
</template>

<script>
import Drawer from "../components/Drawer";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import AuthenticatorInput from "@/admin/components/AuthenticatorInput.vue";
import moment from "moment";
import ConfirmDialogData from "@/admin/components/ConfirmDialogData.vue";

export default {
  name: "Home",
  components: {
    ConfirmDialogData,
    AuthenticatorInput,
    Drawer,
    VueQrcode,
  },
  data: () => ({
    authenticatorDialog: false,
    qrCodeValue: "",
    ready: false,
    logoutModal: false,
  }),
  methods: {
    logout() {
      this.$auth.logout();
      localStorage.removeItem("authenticator_enabled_at");
      window.location = "/login?path=/admin";
    },
    enableAuthenticator(code) {
      const action = !this.$auth.user.authenticator_enabled
        ? "enable_authenticator"
        : "validate_authenticator";
      this.$user
        .sendPost(action, {
          authenticator: code,
        })
        .then(() => {
          this.authenticatorDialog = false;
          this.ready = true;
          this.$store.commit("setSnackbar", {
            color: "success",
            text: "Успешно",
          });
          localStorage.setItem(
            "authenticator_enabled_at",
            moment.utc().format("YYYY-MM-DD HH:mm:ss")
          );
        })
        .catch(() => {
          this.$store.commit("setSnackbar", {
            color: "red",
            text: "Ошибка подключения",
          });
        });
    },
    getStageName() {
      const _host = window.location.hostname;
      if (_host.includes("localhost")) return "localhost";
      if (_host.includes('dev.rosterhit')) return 'Демо-контур'
    },
  },
  created() {
    this.$store.state.outletQueueLoading = true;
    this.$company.loadList({ page_size: "all" });
    this.$outlet.loadList({ page_size: "all", queue_delta: true });
    this.$nomenclature.loadList({ page_size: "all" });
    this.qrCodeValue = "";
    this.$productTag.loadList();
    if (this.$auth.user.authenticator_enabled) {
      const value = localStorage.getItem("authenticator_enabled_at");
      const checkDate = moment.utc(value).add(6, "hour");
      if (!value || checkDate <= moment.utc()) {
        this.authenticatorDialog = true;
      } else this.ready = true;
    } else {
      this.$user.sendPost("set_authenticator").then((res) => {
        this.qrCodeValue = res.authenticator_url;
        this.authenticatorDialog = true;
      });
    }
    this.$discountSystem.sendGet("current_system").then((res) => {
      this.$discountSystem.currentSystem = res.discount_system;
    });
    this.$outlet.sendGet("get_queue_deltas").then((res) => {
      this.$store.state.outletQueue = res.results;
      this.$store.state.outletQueueLoading = false;
    });
    this.$settings.sendGet("current").then((res) => {
      this.$store.state.settings = res;
    });
    this.$productGroup.loadList()
  },
};
</script>
