<template>
  <v-col class="pa-0">
    <v-row no-gutters>
      <v-col v-if="settings" class="mt-3" cols="5">
        <div class="text-h5 font-weight-bold">Настройки терминалов</div>
        <v-text-field
            v-model="settings.terminal_settings.user_session_timeout"
            class="rounded-lg mt-3"
            dense
            hide-details
            label="Возращаться в режим ожидания через N сек"
            type="number"
            outlined
        ></v-text-field>
        <v-text-field
            v-model="settings.terminal_settings.are_you_here_timeout"
            class="rounded-lg mt-3"
            dense
            hide-details
            label="Длительность экрана 'Вы еще здесь?' N сек"
            type="number"
            outlined
        ></v-text-field>
        <v-text-field
            v-model="settings.terminal_settings.success_order_page_timeout"
            class="rounded-lg mt-3"
            dense
            hide-details
            label="Возвращаться в режим ожидания после заказа через N сек"
            type="number"
            outlined
        ></v-text-field>
        <v-btn
            :loading="loading"
            class="rounded-lg mt-5"
            color="primary"
            outlined
            @click="save"
        >Сохранить
        </v-btn>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>

export default {
  name: 'Settings',
  components: {},
  data: () => ({
    settings: null,
    loading: false
  }),
  methods: {
    async save() {
      this.loading = true
      try {
        await this.$settings.sendPut('set_settings', this.settings)
        this.$store.commit('setSnackbar', {
          color: 'success',
          text: 'Сохранено'
        })
      } catch {
        this.$store.commit('setSnackbar', {
          color: 'error',
          text: 'Не удалось сохранить'
        })
      }
      this.loading = false
    }
  },
  mounted() {
    this.$settings.sendGet('current').then((res) => {
      this.settings = res
    })
  }
}
</script>

<style scoped></style>