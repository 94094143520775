const m = require("air-vue-model")();
m.url = "notifications";
m.name = "userNotification";
m.text = {
  itemTitle: "Уведомление",
  listTitle: "Уведомления",
};


module.exports = m;
