import Vue from "vue"
import App from "./App.vue"
import vuetify from "../plugins/vuetify"
import './models'
import VueRouter from 'vue-router'
import OutletQueue from "@/outlet_queue/OutletQueue";
import '@/../public/css/materialdesignicons.min.css'

Vue.use(VueRouter)

require('../bootstrap')

const router = new VueRouter({
    mode: 'history',
    routes: [
        {path: '/outlet_queue', name: 'orderQueue', component: OutletQueue}
    ]
})

Vue.config.productionTip = false

new Vue({
    vuetify,
    router,
    render: (h) => h(App),
}).$mount("#app")
