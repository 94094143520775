<template>
  <div style="width: auto">
    <template-list
      :auto-load-list="true"
      :model="$orderReviewTag"
      :show-create-button="false"
      :key="templateListKey"
    >
      <template v-slot:actions>
        <v-btn
          class="rounded-lg"
          color="primary"
          depressed
          height="40"
          @click="openModal()"
        >
          <div class="text-none body-2 px-2">Создать</div>
        </v-btn>
      </template>
      <template v-slot:subactions> <Tabs /> </template>
      <template v-slot="{ object, index }">
        <v-col class="pa-0 link" @click="openModal(object)">
          <v-row no-gutters>
            <div class="ml-2 font-weight-medium text-truncate">
              {{ object.title }}
            </div>
            <div
              style="
                width: 22px;
                height: 22px;
                border: 1px solid #dedede;
                cursor: pointer;
                border-radius: 4px;
              "
              :style="{ background: object.color || '#fab400' }"
              class="ml-2"
            ></div>
            <v-col cols="12" class="pa-0 ml-2">
              <div class="text-body-2">Группа: {{ object.group?.title || 'Не выбрано' }}</div>
            </v-col>
          </v-row>
        </v-col>
      </template>
    </template-list>
    <v-dialog v-model="dialog" max-width="450">
      <v-card
        v-if="dialogItem"
        class="pa-5 rounded-lg"
        style="overflow-x: auto"
      >
        <div class="font-weight-bold mb-5">
          {{ dialogItem.id ? "Обновление категории" : "Создание категории" }}
        </div>
        <v-row class="ma-0">
          <v-text-field
            outlined
            dense
            v-model="dialogItem.title"
            label="Текст"
          />
          <v-menu bottom offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on }">
              <div
                style="
                  width: 40px;
                  height: 40px;
                  border: 1px solid #dedede;
                  cursor: pointer;
                  border-radius: 4px;
                "
                :style="{ background: dialogItem.color || '#fab400' }"
                v-on="on"
                class="ml-2"
              ></div>
            </template>
            <v-color-picker
              dot-size="13"
              hide-inputs
              hide-mode-switch
              mode="hexa"
              swatches-max-height="200"
              :value="dialogItem.color"
              @input="setColor($event, dialogItem)"
            ></v-color-picker>
          </v-menu>
          <LazySelect
            :model="$orderReviewTagGroup"
            :item-text="(v) => v.title"
            item-value="id"
            outlined
            :multiple="false"
            dense
            v-model="dialogItem.group"
            label="Группа"
            set-to="list"
            :search-enabled="false"
            :preload="true"
            class="mt-n4"
          />
        </v-row>
        <v-row class="px-2 mt-4">
          <v-btn
            :disabled="!dialogItem.title"
            :loading="loading"
            color="primary"
            @click="saveDialogItem()"
            >Сохранить
          </v-btn>
          <v-spacer />
          <v-btn
            v-if="dialogItem.id"
            :loading="loading"
            color="error"
            outlined
            @click="deleteDialogItem()"
            >Удалить
          </v-btn>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import Tabs from "@/admin/views/orderReviewTags/Tabs.vue";
import LazySelect from "@/components/helpers/LazySelect.vue";

export default {
  name: "Reasons",
  components: { LazySelect, Tabs },
  data: () => ({
    dialog: false,
    dialogItem: null,
    loading: false,
    templateListKey: 0,
  }),
  methods: {
    setColor(e, item) {
      item.color = typeof e === "object" ? e.hexa : e;
    },
    openModal(_item) {
      let item = cloneDeep(_item);
      if (!item) item = cloneDeep(this.$orderReviewTag.default);
      this.dialogItem = item;
      this.dialog = true;
    },
    saveDialogItem() {
      const create = !this.dialogItem.id;
      const item = this.dialogItem;
      this.loading = true;
      if (create) {
        this.$orderReviewTag
          .create(item)
          .then((v) => {
            this.$orderReviewTag.list.push(v);
            this.dialog = false;
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.$orderReviewTag
          .update(item)
          .then((v) => {
            this.dialog = false;
            const index = this.$orderReviewTag.list.findIndex(
              (el) => el.id === v.id
            );
            if (index > -1) this.$orderReviewTag.list[index] = v;
            this.templateListKey++;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    deleteDialogItem() {
      this.loading = true;
      this.$orderReviewTag
        .delete(this.dialogItem.id)
        .then(() => {
          this.dialog = false;
          const index = this.$orderReviewTag.list.findIndex(
            (v) => v.id === this.dialogItem.id
          );
          if (index > -1) this.$orderReviewTag.list.splice(index, 1);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) this.dialogItem = null;
    },
  },
};
</script>

<style scoped></style>
