<template>
    <v-col class="pa-0">
        <v-row align="center" class="px-4 mb-6 my-0">
            <div class="text-h6 mr-3">Торговые точки</div>
            <v-divider/>
            <v-btn
                :loading="$product.loadings.action"
                @click="openModalForApplyToOther()"
                depressed
                height="40"
                color="primary"
                class="ml-3 rounded-lg text-none body-2"
            >
                Применить к остальным
            </v-btn>
             <v-btn
                :loading="$product.loadings.action"
                @click="saveOutlets"
                height="40"
                depressed
                class="rounded-lg ml-3"
                color="primary"
            >
                <div class="text-none body-2 px-2">Сохранить</div>
            </v-btn>
        </v-row>
        <v-row no-gutters align="center" v-if="results.length">
            <v-btn @click="setValues('pickup')" depressed small>
                <v-icon size="17">
                    {{
                        !results[0].pickup
                            ? "mdi-checkbox-blank-circle-outline"
                            : "mdi-check-circle-outline"
                    }}
                </v-icon>
                <div class="pl-2">Самовывоз</div>
            </v-btn>
            <v-btn @click="setValues('delivery')" depressed small class="ml-4">
                <v-icon size="17">
                    {{
                        !results[0].delivery
                            ? "mdi-checkbox-blank-circle-outline"
                            : "mdi-check-circle-outline"
                    }}
                </v-icon>
                <div class="pl-2">Доставка</div>
            </v-btn>
          <v-btn @click="setValues('yandex_eda')" depressed small class="ml-4">
            <v-icon size="17">
              {{
                !results[0].yandex_eda
                    ? "mdi-checkbox-blank-circle-outline"
                    : "mdi-check-circle-outline"
              }}
            </v-icon>
            <div class="pl-2">Яндекс еда</div>
          </v-btn>
        </v-row>
        <v-data-table
            no-data-text="Нет данных"
            hide-default-footer
            :headers="headers"
            :items="results"
            :items-per-page="999999"
        >
            <template v-slot:item.pickup="{ item }">
                <v-checkbox
                    class="mt-0 pa-0"
                    hide-details
                    v-model="item.pickup"
                ></v-checkbox>
            </template>
            <template v-slot:item.delivery="{ item }">
                <v-checkbox
                    class="ma-0 pa-0"
                    hide-details
                    v-model="item.delivery"
                ></v-checkbox>
            </template>
          <template v-slot:item.yandex_eda="{ item }">
            <v-checkbox
                class="ma-0 pa-0"
                hide-details
                v-model="item.yandex_eda"
            ></v-checkbox>
          </template>
        </v-data-table>
        <v-dialog v-model="modalForApplyToOther" max-width="460px">
            <v-card class="pa-4">
                <v-row justify="space-between" class="ma-0 mb-2">
                    <div class="text-body-1 font-weight-bold">Выберите товары</div>
                    <v-btn text small @click="selectAllPigs">Выбрать все</v-btn>
                </v-row>
                <v-col class="px-0" style="max-height: 500px; overflow-y: auto">
                    <template v-for="(el, index) in modalForApplyToOtherData">
                        <v-divider style="width: 100%" v-if="index" class="my-2"/>
                        <v-row class="ma-0" justify="space-between" align="center">
                            <div>{{ el.product.name }}</div>
                            <v-checkbox hide-details class="ma-0" v-model="applyToPigs"
                                        :value="el.ingredient_group_id"></v-checkbox>
                        </v-row>
                    </template>
                </v-col>
                <v-btn class="mt-4" color="primary" @click="applyToSelectedPigs()" :disabled="!applyToPigs.length">
                    Применить
                </v-btn>
            </v-card>
        </v-dialog>
    </v-col>
</template>

<script>
import ProductIngredientGroupTabs from "@/admin/views/productIngredientGroupIngredient/ProductIngredientGroupTabs.vue";
import {cloneDeep, isEqual} from "lodash";

export default {
    name: "ProductGroupItem",
    components: {ProductIngredientGroupTabs},
    data: () => {
        return {
            headers: [
                {text: "Адрес", value: "address"},
                {text: "Самовывоз", value: "pickup"},
                {text: "Доставка", value: "delivery"},
              {text: "Яндекс еда", value: "yandex_eda"},

            ],
            results: [],
            modalForApplyToOther: false,
            modalForApplyToOtherData: [],
            applyToPigs: [],

          initialItem: null,
          eventsToClear: [],
        };
    },
    mounted() {
      this.eventsToClear.push(this.$router.beforeEach((to, from, next) => {
        if (!isEqual(this.initialItem, this.results)) {
          const answer = window.confirm(
              "Если вы уйдете со страницы, то потеряете все несохраненные данные"
          );
          if (answer) {
            if (this.eventsToClear.length) this.eventsToClear.map(v => v());
            next();
          } else {
            this.$store.commit('setProductDrawer', true)
          }
        } else next()
      }))
      window.onbeforeunload = () => {
        if (!isEqual(this.initialItem, this.results)) {
          return confirm()
        }
      }
    },
  beforeDestroy() {
    if (this.eventsToClear.length) this.eventsToClear.map(v => v())
  },
    computed: {},
    watch: {
        "$outlet.list"() {
            this.rebuildTable();
        },
    },
    methods: {
        selectAllPigs() {
            if (this.applyToPigs.length !== this.modalForApplyToOtherData.length) this.applyToPigs = this.modalForApplyToOtherData.map(v => v.ingredient_group_id)
            else this.applyToPigs = []
        },
        openModalForApplyToOther() {
            this.applyToPigs = []
            this.modalForApplyToOtherData = []
            this.$productIngredientGroup.sendGetSingle('products_by_item', this.$productIngredientGroup.item.id,).then(v => {
                this.modalForApplyToOtherData = v
            })
            this.modalForApplyToOther = true
        },
        applyToSelectedPigs() {
            const data = []
            for (const pig of this.applyToPigs) {
                const item = {
                    product_ingredient_group: pig,
                    data: this.getDataForSave()
                }
                data.push(item)
            }
            this.$productIngredientGroup.sendPostSingle('set_available_outlets_many', undefined, data).then(() => {
                this.modalForApplyToOther = false
                this.$store.commit('setSnackbar', {color: 'success', text: 'Обновлено'})
            }).catch(() => {
                this.$store.commit('setSnackbar', {color: 'error', text: 'Ошибка обновления'})
            })
        },
        rebuildTable() {
            const results = [];
            this.$outlet.list.forEach((outlet) => {
                const currentItem = this.$productIngredientGroupOutlet.list.find(
                    (v) => outlet.id === v.outlet
                );
                results.push({
                    outlet: outlet.id,
                    address: outlet.number + ". " + outlet.address,
                    delivery: currentItem?.delivery ?? true,
                    pickup: currentItem?.pickup ?? true,
                    yandex_eda: currentItem?.yandex_eda ?? false,
                });
            });
            this.results = results;
            this.initialItem = cloneDeep(this.results)
        },
        getDataForSave() {
            const items = cloneDeep(this.results)
            items.forEach(v => v.address = undefined)
            return items
        },
        saveOutlets() {
            this.$productIngredientGroup
                .sendPutSingle(
                    "set_available_outlets",
                    this.$productIngredientGroup.item.id,
                    this.getDataForSave()
                )
                .then((res) => {
                  this.initialItem = cloneDeep(this.results)
                    this.$store.commit("setSnackbar", {
                        color: "success",
                        text: "Торговые точки сохранены",
                    });
                });
        },
        setValues(key) {
            const setValue = !this.results[0][key];
            this.results.forEach((v) => (v[key] = setValue));
        },
    },
    created() {
        this.$productIngredientGroupOutlet.list = [];
        this.$productIngredientGroupOutlet
            .loadList({
                page_size: "all",
                product_ingredient_group: this.$productIngredientGroup.item.id,
            })
            .then((res) => {
                this.rebuildTable();
            });
    },
};
</script>
