<template>
    <order-list/>
</template>

<script>
import OrderList from "@/main/components/order/OrderList";

export default {
    name: "Orders",

    components: {
        OrderList
    },
}
</script>
