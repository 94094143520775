<template>
    <div class="pa-2 outline-box rounded-lg">
        <v-row no-gutters align="center" style="flex-wrap: nowrap">
            <div class="text-body-1 font-weight-bold">+7</div>
            <v-divider vertical class="mx-2 primary"/>
            <input
                v-mask="'(999) 999-99-99'" id="phone-input" @input="input" style="width: 100%"
                v-model="value" class="text-body-1 font-weight-bold" type="text"
            >
        </v-row>
    </div>
</template>

<script>
export default {
    name: "PhoneInput",

    data: () => ({
        value: null,
    }),

    methods: {
        input() {
            const el = document.getElementById('phone-input')
            const phone = el.inputmask.unmaskedvalue()
            this.$emit('input', `7${phone}`)
        },
    }
}
</script>

<style scoped>
.outline-box {
    border: 1px #FAB400 solid;
}
</style>