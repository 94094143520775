export const secondsToTime = (v) => {
    const secs = Number(Number(v).toFixed(0));
    let hours = Math.floor(secs / 3600);
    hours = hours < 10 ? `0${hours}` : hours;
    let minutes = Math.floor((secs % 3600) / 60);
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    let seconds = secs - hours * 3600 - minutes * 60;
    seconds = seconds < 10 ? `0${seconds}` : seconds;
    return `${hours}:${minutes}:${seconds}`;
}

export const beautifyNumber = (x, toFixed = false) => {
    if (toFixed) x = Number(x.toFixed(2))
    const parts = x.toString().split('.')
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    return parts.join('.')
}