import BaseModel from "../vendor/baseModel";

export class Ingredient extends BaseModel {
    constructor() {
        super({
            url: 'ingredients',
            name: 'ingredient',
            page_size: 'all',
        });
    }
}