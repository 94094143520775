<template>
    <v-col class="pa-0">
        <v-row no-gutters class="my-3" align="center">
            <div class="text-h6 mr-3">Общая информация</div>
            <v-divider/>
        </v-row>
        <v-row>
            <v-col cols="4">
                <div class="font-weight-medium">Оборудование</div>
                <json-viewer
                    :expand-depth="2"
                    copyable
                    :value="$equipmentConfig.list"
                ></json-viewer>
            </v-col>
            <v-col cols="4">
                <div class="font-weight-medium">Информация об остатках</div>
                <json-viewer
                    :expand-depth="2"
                    copyable
                    :value="$ingredientOutletDetail.list"
                ></json-viewer>
            </v-col>
        </v-row>


    </v-col>
</template>

<script>
export default {
    name: 'OutletItemDatabase',
    data: () => ({}),
    methods: {}
}
</script>

<style scoped>

</style>