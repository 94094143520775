<template>
    <v-menu v-if="result.length || disabled"
            open-on-hover
            top
            offset-y
    >
        <template v-slot:activator="{ on, attrs }">
            <v-icon :size="size" v-on="on"
                    :class="{'red--text': disabled, 'green--text': !disabled}">mdi-percent-box

            </v-icon>
        </template>

        <v-card class="pa-3 rounded-lg" style="max-width: 250px">

            <div class="caption" v-if="disabled">Не начисляются баллы если есть в элементе заказа</div>
            <v-col class="pa-0" v-else>
                <div class="caption">Действующие начисления</div>
                <div v-for="(el, index) in result" :key="index" class="caption mt-2">
                    <div class="font-weight-bold">{{
                            el.category ? 'Группа' : el.ingredient ? 'Модификатор' : 'Товар'
                        }}
                    </div>
                    <div>Приоритет: {{ el.priority }}</div>
                    <div>Процент: {{ el.percent }}</div>
                </div>
            </v-col>
        </v-card>
    </v-menu>
</template>

<script>
export default {
    name: 'DiscountSystemInformation.vue',
    props: {
        product: {
            type: Object,
            required: false
        },
        group: {
            type: Object,
            required: false
        },
        pigi: {
            type: Object,
            required: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        size: {
            type: String,
            default: '25'
        }
    },
    data: () => ({}),
    methods: {
        byProduct(product) {
            const system = this.$discountSystem.currentSystem
            if (!system) return []
            return system.product_discounts.filter(v => v.product.id === product.id && v.percent)
        },
        byPigi(pigi) {
            const system = this.$discountSystem.currentSystem
            if (!system) return []
            const pigies = []

            for (const el of system.product_discounts) {
                el.ingredient_discounts.forEach(v => pigies.push(v))
            }
            return pigies.filter(v => v.ingredient.id === pigi.id && v.percent)
        },
        byGroup(category) {
            const system = this.$discountSystem.currentSystem
            if (!system) return []
            return system.category_discounts.filter(v => v.category.id === category.id && v.percent)

        }
    },
    computed: {
        result() {
            let results = []
            if (this.product) {
                results = results.concat(this.byProduct(this.product))
                results = results.concat(this.byGroup(this.product.group))
            }
            if (this.group) {
                results = results.concat(this.byGroup(this.group))
            }
            if (this.pigi) {
                results = results.concat(this.byPigi(this.pigi))
            }
            return results
        }

    }
}
</script>

<style scoped>

</style>