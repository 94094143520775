<template>
  <v-card>
    <v-row align="center" justify="center" no-gutters class="pa-4">
      <div
        class="text-center font-weight-bold subtitle-1"
        v-text="text"
      />
      <slot></slot>
    </v-row>
    <v-card-actions>
      <v-row align="center" class="mb-3" justify="center" no-gutters>
        <v-btn
          :color="confirmButtonColor || 'primary'"
          depressed
          @click="$emit('confirm')"
          class="mr-3"
          :disabled="confirmDisabled"
          >Подтвердить</v-btn
        >
        <v-btn depressed color="" @click="$emit('closeDialog')" class="ml-3">
          Отменить
        </v-btn>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "ConfirmDialogData",
  emits: ["closeDialog", "confirm"],
  props: {
    text: String,
    confirmButtonColor: String,
    confirmDisabled: Boolean,
  },
  data: () => ({}),
};
</script>
