
export const authByPhone = async (store, number) => {
  const result = await store.dispatch("executeAction", {
    url: "/users/",
    action: "server_time",
    method: "get",
  });
  const time = result.server_time
    .replaceAll("-", "")
    .replaceAll(" ", "")
    .replaceAll(":", "");
  const token =
    "HIio48cvfayezE4dH6Yu0!nG=-3CpY0p?No6pLaCfEmxmmWkNcoSbaZ3bEOXhOU2tuCWAm!/iFOZG17gmcMXo?O5QiSEasbeKQ-h!zcDiW!u?34dze9L?X?NUj=gvlTWoatklgPi0u3!JbUly2ljZ7X3834pPFhUeygUmXflHU=aG";
  const msgBuffer = new TextEncoder().encode(time + token);
  const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
  await store
    .dispatch("executeAction", {
      url: "/users/",
      action: "request_registration",
      body: {
        key: hashHex,
        phone: number,
      },
    })
    // .then(() => {
    //   this.$store.commit("setSnackbar", {
    //     color: "success",
    //     text: "СМС отправлено",
    //   });
    //   this.loading = false;
    //   this.smsSent = true;
    // })
    // .catch(() => {
    //     this.loading = false;
    // });
};
