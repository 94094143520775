import Vue from "vue"
import App from "./App.vue"
import './models'
import router from "./router/router"
import store from "./store/store"
import vuetify from "../plugins/vuetify"
import { OverlayScrollbarsComponent } from 'overlayscrollbars-vue'
import 'air-vue-leaflet/dist/air-vue-leaflet.css'
import 'swiper/swiper-bundle.css'
import '@/../public/css/materialdesignicons.min.css'

import {initDefaultComponents, initModels} from '@/vendor/initApp'
import {handle} from "@/vendor/errorHandler";

require('../bootstrap')

const VueInputMask = require('@/vendor/inputMask').default
Vue.use(VueInputMask)

initDefaultComponents(Vue)
Vue.component('overlay-scrollbars', OverlayScrollbarsComponent)
initModels(Vue, store, require('./models'))

Vue.config.productionTip = false

Vue.prototype.$L = require('air-vue-leaflet')()
Vue.prototype.$eventBus = new Vue()
// Vue.use(VueLazyLoad)

axios.interceptors.response.use(response => {
    return response
}, error => {
    handle(error, store)
    return Promise.reject(error)
})

new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
}).$mount("#app")
