<template>
  <v-dialog
    :value="value"
    @input="$emit('input', $event)"
    @click:outside="$emit('input', false)"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    max-width="700"
    scrollable
  >
    <v-card
      v-if="product"
      color="background"
      max-height="600"
      style="overflow-y: auto"
    >
      <v-row v-if="$vuetify.breakpoint.smAndDown" no-gutters>
        <div class="pt-2 pl-1">
          <v-btn icon @click="close">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </div>
      </v-row>
      <v-card class="rounded-lg elevation-1 ma-3">
        <v-row align="center" no-gutters>
          <v-col class="pa-3" cols="3">
            <v-img :src="image" aspect-ratio="1" contain />
          </v-col>
          <v-col class="pa-3" cols="9">
            <div v-if="$store.state.showAllInfo" class="font-weight-medium">
              id: {{ product.id }}
            </div>
            <div
              class="text-subtitle-1 text-md-h5 font-weight-medium"
              v-text="product.name"
            />
            <div class="mt-3" style="min-height: 72px">
            <b>Статус: </b>{{ statusText }}
            <div v-if="currentSelectedIngredientIds.length">
              <b>Задействовано модификаторов: </b
              >{{ currentSelectedIngredientIds.length }}
            </div>
            <div v-if="currentExcludedIngredientIds.length">
              <b>Исключено модификаторов: </b
              >{{ currentExcludedIngredientIds.length }}
            </div>
            </div>
          </v-col>
        </v-row>
      </v-card>
      <ingredient-group
        v-for="(ingredientGroup, index) in productIngredientGroups"
        :key="index"
        :product-ingredient-group="ingredientGroup"
        :selected-ingredient-ids="currentSelectedIngredientIds"
        :excluded-ingredient-ids="currentExcludedIngredientIds"
        :is-product-selected="isProductSelected"
        :disabled="disabled"
      />
      <div style="min-height: 65px" />
    </v-card>
  </v-dialog>
</template>

<script>
import IngredientGroup from "./IngredientGroup";
import { cloneDeep } from "lodash";

export default {
  name: "ProductDialog",

  components: {
    IngredientGroup,
  },

  props: {
    value: Boolean,
    product: Object,
    isProductSelected: Boolean,
    selectedIngredientIds: Array,
    excludedIngredientIds: Array,
    disabled: Boolean
  },

  emits: ["input", "updateIngredients"],

  data: () => ({
    showDescription: false,

    currentSelectedIngredientIds: [],
    currentExcludedIngredientIds: [],
  }),

  computed: {
    productIngredientGroups() {
      return this.product.product_ingredient_groups;
    },

    image() {
      const notFoundImage = "/static/not-found.png";
      if (!this.product) return notFoundImage;
      return this.product.image || notFoundImage;
    },

    selectedProductIngredients() {
      return this.$store.state.selectedProductIngredients;
    },
    statusText() {
      const selectedCount = this.currentSelectedIngredientIds.length;
      const excludedCount = this.currentExcludedIngredientIds.length;
      if (!this.isProductSelected && !selectedCount && !excludedCount)
        return "Продукт не выбран";
      let text = "Продукт выбран";

      if (!selectedCount && !excludedCount)
        text += ", включая все модификаторы";
      if (selectedCount || excludedCount) text += " частично";
      // if (selectedCount)
      //   text += `, задействовано модификаторов: ${selectedCount}`;
      // if (excludedCount) text += `, исключено модификаторов: ${excludedCount}`;
      return text;
    },
  },

  methods: {},

  created() {},

  watch: {
    value(v) {
      if (v) {
        this.currentSelectedIngredientIds = cloneDeep(
          this.selectedIngredientIds
        );
        this.currentExcludedIngredientIds = cloneDeep(
          this.excludedIngredientIds
        );
      } else {
        this.$emit("updateIngredients", {
          selected: this.currentSelectedIngredientIds,
          excluded: this.currentExcludedIngredientIds,
        });
      }
    },
  },
};
</script>
