const m = require('air-vue-model')()
m.url = 'ingredient_nomenclature'
m.name = 'ingredientNomenclature'


m.setPagination({
    page_size: 'all'
})

m.default = {
    ingredient: null,
    nomenclature: null,
    amount: 0,
    removable: false,
    control_amount: false
}

module.exports = m