const m = require('air-vue-model')()
m.url = 'companies'
m.name = 'company'

m.text = {
    listTitle: 'Компании', itemTitle: 'Компания',
}

m.routes = [{
    name: 'list', component: require('@/admin/views/company/Companies'),
}, {
    name: 'item',
    component: require('@/admin/views/company/Company'),
    redirect: {
        name: 'companyBase'
    },
    single: true,
    children: [
        {name: 'base', path: 'base', component: require('@/admin/views/company/CompanyItemBase.vue')},
        {name: 'documents', path: 'documents', component: require('@/admin/views/company/CompanyItemDocuments')},
        {name: 'banks', path: 'banks', component: require('@/admin/views/company/CompanyItemBanks')},
    ]
},]

module.exports = m