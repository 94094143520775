const m = require('air-vue-model')()
m.url = 'order_review_tag_groups'
m.name = 'orderReviewTagGroup'

m.text = {
    listTitle: 'Группа категорий отзывов'
}

m.default = {
    title: '',
    is_worker_error: false,
}

m.filters = {
}



m.routes = [
    {
        name: 'list',
        component: require('@/admin/views/orderReviewTags/OrderReviewTagGroups.vue'),
    },
]

module.exports = m