<template>
  <v-col class="pa-3 pb-10">
    <v-row align="center" class="my-0" dense>
      <date-picker
        v-model="startDate"
        class="rounded-lg mr-2"
        label="Дата начала"
        style="max-width: 200px"
      ></date-picker>
      <date-picker
        v-model="endDate"
        class="rounded-lg mr-2"
        label="Дата окончания"
        style="max-width: 200px"
      ></date-picker>
      <v-select
        clearable
        hide-details
        class="rounded-lg mr-2"
        :items="$outlet.list"
        item-value="id"
        :item-text="(v) => v.number + '. ' + v.address"
        v-model="outlet"
        label="Торговая точка"
        outlined
        style="max-width: 250px"
        dense
      ></v-select>
      <lazy-select
        style="max-width: 250px"
        v-model="userFilter"
        :model="$user"
        :extra-filter="{ user_type: 4 }"
        preload
        class="rounded-lg"
        clearable
        :item-text="(v) => v.first_name"
        label="Курьер"
      ></lazy-select>
      <!--      <v-select-->
      <!--        clearable-->
      <!--        hide-details-->
      <!--        class="rounded-lg mr-2"-->
      <!--        :items="$user.list"-->
      <!--        item-value="id"-->
      <!--        :item-text="(v) => v.first_name"-->
      <!--        v-model="userFilter"-->
      <!--        label="Сотрудник"-->
      <!--        outlined-->
      <!--        style="max-width: 250px"-->
      <!--        dense-->
      <!--      ></v-select>-->
      <!--      <v-text-field-->
      <!--        v-model="search_"-->
      <!--        class="rounded-lg"-->
      <!--        dense-->
      <!--        hide-details-->
      <!--        label="Поиск"-->
      <!--        outlined-->
      <!--        style="max-width: 180px"-->
      <!--      />-->
      <v-col cols="auto">
        <v-row align="center" class="pa-0 ml-1">
          <v-btn
            :loading="loading"
            class="rounded-lg"
            color="primary"
            depressed
            height="40"
            @click="loadAnalytics()"
            >Обновить
          </v-btn>
          <!--          <v-btn-->
          <!--            v-if="results"-->
          <!--            @click="exportToExcel"-->
          <!--            :loading="exportLoading"-->
          <!--            class="rounded-lg ml-3"-->
          <!--            color="primary"-->
          <!--            height="40"-->
          <!--            outlined-->
          <!--            rounded-->
          <!--            >Выгрузить-->
          <!--          </v-btn>-->
        </v-row>
      </v-col>
      <v-spacer />
    </v-row>
    <!--    <template v-for="item in Object.values(currentData)">-->
    <!--      <div class="body-1 font-weight-bold mb-2 mt-5">{{ item.outletName }}</div>-->
    <v-data-table
      v-if="results?.length"
      :headers="headers"
      :items="results"
      class="mt-2"
      disable-pagination
      hide-default-footer
      @click:row="(_, evt) => openShiftAnalytics(evt.item.user)"
    />
    <ShiftAnalyticsModal
      :start-date="getTimeData().start_date"
      :end-date="getTimeData().end_date"
      :model-value="dialog"
      @update:modelValue="dialog = $event"
      :data="result"
      :user="user"
    />
    <!--    </template>-->
  </v-col>
</template>
<script>
import moment from "moment";
import ShiftAnalyticsModal from "@/admin/views/deliveryAdmin/ShiftAnalyticsModal.vue";
import { secondsToTime } from "@/services/numberFunctions";
import LazySelect from "@/components/helpers/LazySelect.vue";

export default {
  name: "CouriersShiftsAnalytics",
  components: { LazySelect, ShiftAnalyticsModal },
  data: () => ({
    dialog: false,
    result: null,
    user: null,
    userFilter: null,
    startDate: moment().subtract(1, "w").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    outlet: null,
    loading: false,
    results: null,
    exportLoading: false,
    headers: [
      {
        text: "Курьер",
        align: "start",
        sortable: true,
        value: "user.first_name",
      },
      {
        text: "Время на смене",
        align: "start",
        sortable: true,
        value: "report.total_shifts_time",
      },
      {
        text: "Доставлено заказов",
        align: "start",
        sortable: true,
        value: "report.delivered_orders",
      },
      {
        text: "Средний рейтинг заказов",
        align: "start",
        sortable: true,
        value: "report.avg_orders_rating",
      },
      {
        text: "Общее время в пути",
        align: "start",
        sortable: true,
        value: "report.total_going_time",
      },
      {
        text: "Общее время в пути к клиенту",
        align: "start",
        sortable: true,
        value: "report.delivering_time",
      },
      {
        text: "Общее время возвращения",
        align: "start",
        sortable: true,
        value: "report.go_back_time",
      },
      {
        text: "Время перерывов",
        align: "start",
        sortable: true,
        value: "report.breaking_time",
      },
      {
        text: "Оплачиваемое время смены",
        align: "start",
        sortable: true,
        value: "report.paying_time",
      },
      {
        text: "Доход за время на смене",
        align: "start",
        sortable: true,
        value: "report.reward_by_hours",
      },
      {
        text: "Доход за доставленные заказы",
        align: "start",
        sortable: true,
        value: "report.reward_by_orders",
      },
      {
        text: "Общий доход",
        align: "start",
        sortable: true,
        value: "report.total_reward",
      },
    ],
  }),
  async mounted() {
    this.loadAnalytics();
  },
  methods: {
    getTimeData() {
      const res = {
        start_date: this.startDate ? this.startDate + " 00:00:00" : "",
        end_date: this.endDate ? this.endDate + " 23:59:59" : "",
      };
      if (res.start_date)
        res.start_date = moment(res.start_date)
          .utc()
          .format("YYYY-MM-DD HH:mm:ss");
      if (res.end_date)
        res.end_date = moment(res.end_date).utc().format("YYYY-MM-DD HH:mm:ss");
      return res;
    },
    loadAnalytics() {
      if (!this.startDate || !this.endDate) return;
      if (this.startDate > this.endDate) {
        const tmp = String(this.startDate);
        this.startDate = String(this.endDate);
        this.endDate = tmp;
      }
      this.loading = true;
      this.$analytics
        .sendPost("couriers_report_by_dates", {
          ...this.getTimeData(),
          outlet: this.outlet,
          user: this.userFilter?.id,
        })
        .then((res) => {
          const timeKeys = [
            "total_shifts_time",
            "total_going_time",
            "delivering_time",
            "go_back_time",
            "breaking_time",
            "paying_time",
          ];
          const data = res.result;
          for (const item of data) {
            const report = item.report;
            for (const key of timeKeys)
              report[key] = secondsToTime(report[key]);
          }
          this.results = data;
          this.loading = false;
        });
    },
    async openShiftAnalytics(user) {
      this.user = user;
      this.$analytics
        .sendPostSingle("courier_report_by_date", undefined, {
          ...this.getTimeData(),
          user: user.id,
        })
        .then((res) => {
          // this.shift = shift;
          this.result = res.result;
          this.dialog = true;
        })
        .catch(() => void 0)
        .finally(() => {});
    },
    // exportToExcel() {
    //   this.exportLoading = true;
    //   const data = [];
    //   console.log(this.currentData)
    //   for (const item of Object.values(this.currentData)) {
    //     const excelItem = {
    //       ТТ: item.outletName,
    //     };
    //     for (const el of item.data) {
    //       excelItem["Номенклутара"] = el.nomenclatureName;
    //       excelItem["На начало периода"] = el.onStart;
    //       excelItem["На конец периода"] = el.onEnd;
    //       data.push(cloneDeep(excelItem));
    //     }
    //   }
    //   const XLSX = require("xlsx");
    //   const worksheet = XLSX.utils.json_to_sheet(data);
    //   const workbook = XLSX.utils.book_new();
    //   XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //   XLSX.writeFile(workbook, "data.xlsx");
    //   this.exportLoading = false;
    // },
  },
  computed: {
    // outletsByIds() {
    //   let data = {};
    //   for (const item of this.$outlet.list) {
    //     data[item.id] = item;
    //   }
    //   return data;
    // },
    // nomenclaturesByIds() {
    //   let data = {};
    //   for (const item of this.$nomenclature.list) {
    //     data[item.id] = item;
    //   }
    //   return data;
    // },
    // currentData() {
    //   let data = cloneDeep(this.results);
    //   const resByOutlets = {};
    //   if (
    //     !data ||
    //     !Object.keys(this.outletsByIds).length ||
    //     !Object.keys(this.nomenclaturesByIds).length
    //   )
    //     return [];
    //   for (const item of data) {
    //     if (!resByOutlets[item.outlet]) {
    //       const outletData = this.outletsByIds[item.outlet];
    //       resByOutlets[item.outlet] = {
    //         outletName: `${outletData.number}. ${outletData.address}`,
    //         data: [],
    //       };
    //     }
    //     console.log(item.outlet);
    //     const currentItem = resByOutlets[item.outlet];
    //     currentItem.data.push({
    //       onStart: item.on_start,
    //       onEnd: item.on_end,
    //       nomenclatureName: this.nomenclaturesByIds[item.nomenclature].name,
    //     });
    //   }
    //   return resByOutlets;
    // },
  },
};
</script>
<style scoped>
#parent {
  position: absolute;
  width: 100%;
}

table {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  overflow: hidden;
  /* Use this to create a "dead" area color if table is too wide for cells */
}

thead {
  /*
    Grow thead automatically to fit content, don't shrink it
    proportionately to the body.
    */
  flex: 1 0 auto;
  display: block;
  /* x-scrolling will be managed via JS */
  overflow-x: hidden;
  /*
    Keep header columns aligned with useless scrollbar.
    For IE11, use "dead area" color to hide scrollbar functions
    */
  overflow-y: scroll;
  scrollbar-base-color: #ccc;
  scrollbar-face-color: #ccc;
  scrollbar-highlight-color: #ccc;
  scrollbar-track-color: #ccc;
  scrollbar-arrow-color: #ccc;
  scrollbar-shadow-color: #ccc;
  scrollbar-dark-shadow-color: #ccc;
}

thead::-webkit-scrollbar {
  display: block;
  background-color: #ccc;
}

thead::-webkit-scrollbar-track {
  background-color: #ccc;
}

/* Scroll the actual tbody (second child on all browsers) */
tbody {
  display: block;
  overflow: scroll;
}

/* IE11 adds an extra tbody, have to hide it */
tbody:nth-child(3) {
  display: none;
}

/* The one caveat, a hard-set width is required. */
td,
th {
  width: 10em;
  min-width: 10em;
  padding: 0.3em;
  border: 1px solid #ddd;
  background-color: white;
}

th {
  background-color: #f7f7f7;
}

td:first-child,
th:first-child {
  position: sticky;
  position: -webkit-sticky;
  left: 0;
}

.list-item {
  padding: 5px 5px;
  border-radius: 7px;
  cursor: pointer;
}

.list-item:hover {
  background: #e3e3e3;
}
</style>
