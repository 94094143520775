const m = require('air-vue-model')()
m.url = 'display_devices'
m.name = 'displayDevice'

m.text = {
    listTitle: 'Экраны',
    itemTitle: 'Экран',
}
m.pk = 'uuid'

m.default = {
    name: null,
    outlet: null
}

m.filters = {
    outlet: null
}

m.setPagination({
    page_size: 100
})

m.routes = [
    {
        name: 'list',
        component: require('@/admin/views/display/devices/DisplayDevices'),
    },
    {
        name: 'item',
        component: require('@/admin/views/display/devices/DisplayDevice'),
        single: true
    },
]

module.exports = m