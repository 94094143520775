const m = require('air-vue-model')()
m.url = 'answer_templates'
m.name = 'answerTemplate'

m.setPagination({
    page_size: 'all'
})

m.text = {
    listTitle: 'Список шаблонов для ответа', itemTitle: 'Шаблон для ответа',
}

m.routes = [
    {name: 'list', component: require('../admin/views/answerTemplate/answerTemplates')},
    {name: 'item', component: require('../admin/views/answerTemplate/answerTemplate'), single: true},
]

m.filters = {
    search: null,
}

m.default = {
    text: '',
}
module.exports = m