<template>
    <div style="border: 1px #c9c9c9 solid; border-radius: 10px" class="pa-2">
        <v-row no-gutters align="center">
            <div class="body-2 font-weight-medium mb-1">{{ title }}</div>
            <v-spacer></v-spacer>
            <v-menu
                open-on-hover
                top
                offset-y
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-icon v-on="on" size="20"
                    >mdi-information-outline
                    </v-icon>
                </template>
                <v-card class="pa-3 rounded-lg">
                    <div class="caption mb-2">Нарушения</div>
                    <v-row no-gutters align="center" v-for="(el, index) in data.items" :key="index">
                        <div class="caption text-center" style="width: 40px">{{ computeItem(el) }}</div>
                        <div class="ml-5 body-2 font-weight-medium">{{el.count}}</div>
                    </v-row>
                </v-card>
            </v-menu>
        </v-row>
        <div class="body-2">Всего нарушений: <span class="font-weight-medium">{{ data.total }}</span></div>
        <div class="body-2">Просрочено минут в среднем: <span class="font-weight-medium">{{ data.avg_minutes }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OverdueBlock',
    props: {
        data: Object,
        title: String
    },
    data: () => ({}),
    methods: {
        computeItem(item) {
            if (item.end > 1000) {
                return `${item.start} +`
            }
            return `${item.start} - ${item.end}`
        }
    }
}
</script>

<style scoped>

</style>