<template>
    <v-banner class="white pa-0 ma-0" sticky app>
        <v-container class="pa-0">
            <overlay-scrollbars ref="scrollbar" :options="scrollOptions">
                <v-row align="center" class="ma-0" ref="productsGroup" style="overflow-x: scroll; flex-wrap: nowrap">
                    <div v-for="(item, index) in productGroups" :key="index"
                         @click="$vuetify.goTo('#products-group-' + item.id, options)"
                         class="pa-2 text-body-2 unselectable" style="min-width: fit-content; cursor: pointer;"
                         :style="currentActive === index ? 'border-bottom: orange 2px solid; color: orange' : ''">
                        {{ item.name }}
                    </div>
                </v-row>
            </overlay-scrollbars>
        </v-container>
    </v-banner>
</template>

<script>
export default {
    name: "ProductGroups",
    data: () => ({
        active: [],
        scrollOptions: {
            overflowBehavior: {
                x: "scroll",
                y: "hidden"
            },
            scrollbars: {visibility: 'hidden'},
        },
    }),
    computed: {
        groupNames() {
            return this.productGroups.map(value => 'products-group-' + value.id)
        },
        currentActive() {
            const index = this.groupNames.indexOf(this.active[this.active.length - 1])
            return (index > -1) ? index : 0
        },
        options() {
            return {
                duration: 300,
                offset: 40,
            }
        },
        productGroups() {
            if (this.$store.state.selectedPromo) {
                return this.$product.filterByPromo(this.$store.state.selectedPromo)
            } else return this.$product.menu
        },
    },
    created() {
        this.$eventBus.$on('onIntersect', ({id, isIntersecting}) => {
            if (isIntersecting) {
                if (this.active.indexOf(id) === -1) {
                    if (this.active.length === 0)
                        this.active.push(id)
                    else
                        this.active.splice(0, 1, id)
                    if (this.$refs.scrollbar)
                        this.$refs.scrollbar.osInstance().scroll({
                            el: this.$refs.productsGroup.childNodes[this.productGroups.indexOf(id)],
                            block: "center"
                        }, 200);
                }
            } else {
                const index = this.active.indexOf(id)
                if (index > -1 && this.active.length !== 1)
                    this.active.splice(index, 1)
            }
        })
    }
}
</script>

<style>
.v-banner__wrapper {

}

.os-content-glue {
    height: 0 !important;
}

</style>