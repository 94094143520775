<template>
 <v-combobox
    dense
    :autofocus="autofocus"
    :small-chips="chips"
    :disabled="disabled"
    :readonly="readonly"
    outlined
    hide-details
    :return-object="returnObject"
    :clearable="clearable"
    :rules="rules"
    :item-text="itemText"
    color="primary"
    :loading="loading"
    :item-value="itemValue"
    :items="computedItems"
    :value="value"
    :label="label"
    :multiple="multiple"
    @click="emitClick"
    v-bind="searchEnabled ? { 'search-input.sync': search } : {}"
    @change="changeValue"
    @keyup="lazyLoadList"
    :filter="customFilter"
  >
    <template v-slot:no-data>
      <v-col class="pa-0">
        <v-row
          class="d-flex flex-column"
          justify="center"
          align="center"
          no-gutters
          v-if="!loaded"
        >
          <v-icon class="mt-2">mdi-fountain-pen-tip</v-icon>
          <div class="body-2 pa-2 grey--text text--darken-2">
            Укажите данные для поиска
          </div>
        </v-row>
        <v-row
          class="d-flex flex-column"
          justify="center"
          align="center"
          no-gutters
          v-if="loaded && !model[setTo].length"
        >
          <v-icon class="mt-2">mdi-comment-search-outline</v-icon>
          <div class="body-2 pa-2 grey--text text--darken-2">
            Отсутствуют результаты поиска
          </div>
        </v-row>
      </v-col>
    </template>
  </v-combobox>
</template>

<script>
import { cloneDeep } from "lodash";

export default {
  name: "LazySelect",
  props: {
    value: [Object, String, Array],
    items: Array,
    multiple: Boolean,
    label: String,
    autofocus: Boolean,
    clearable: Boolean,
    returnObject: {
      default: true,
      type: Boolean,
    },
    itemText: {
      type: Function,
      default: (el) => el.name,
    },
    itemValue: {
      type: String,
      default: "id",
    },
    setTo: {
      type: String,
      default: "searchList",
    },
    afterItemsLoad: {
      type: Function,
      required: false,
    },
    model: Object,
    delay: {
      type: Number,
      default: 300,
    },
    extraFilter: Object,
    readonly: Boolean,
    disabled: Boolean,
    preload: Boolean,
    rules: Array,
    lazy: {
      type: Boolean,
      default: true,
    },
    chips: {
      type: Boolean,
      default: true,
    },
    searchEnabled: {
      type: Boolean,
      default: true,
    },
  },
  components: {},
  data: () => ({
    search: null,
    loading: false,
    timeout: null,
    loaded: false,
  }),
  methods: {
    customFilter(item, queryText, itemText) {
      const words = queryText.toLowerCase().split(" ");
      const text = itemText.toLowerCase();
      for (const index in words) {
        if (words[index] !== " " && text.indexOf(words[index]) === -1) {
          return false;
        }
      }
      return true;
    },
    emitClick() {
      this.$emit("click");
    },
    changeValue(val) {
      if (this.multiple) {
        this.$emit(
          "input",
          val.filter((el) => typeof el === "object")
        );
        this.$emit(
          "change",
          val.filter((el) => typeof el === "object")
        );
      } else {
        this.$emit("input", typeof val === "object" ? val : null);
        this.$emit("change", typeof val === "object" ? val : null);
      }
    },
    loadList() {
      return new Promise((resolve, reject) => {
        let filter = {
          search: this.search,
        };
        if (this.extraFilter) filter = Object.assign(filter, this.extraFilter);
        this.model
          .loadList(filter, this.setTo)
          .then((res) => {
            if (this.afterItemsLoad) this.afterItemsLoad(res)
            resolve(res);
          })
          .catch(() => {
            reject();
          });
      });
    },
    lazyLoadList() {
      if (!this.model || !this.lazy) return;
      if (!this.search && !this.extraFilter) {
        this.loaded = false;
        this.model[this.setTo] = [];
        return;
      }
      this.loading = true;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.loadList()
          .then(() => {
            this.loaded = true;
            this.loading = false;
          })
          .catch(() => {
            this.loaded = false;
            this.loading = false;
            this.model[this.setTo] = [];
            this.$store.commit("setSnackbar", {
              color: "red",
              text: "Не удалось загрузить данные",
            });
          });
      }, this.delay);
    },
  },
  watch: {},
  created() {
    if (this.model && (this.preload || !this.lazy)) {
      this.loadList().then((res) => {
          if (this.model && this.setTo) this.model[this.setTo] = res
      });
    }
  },
  computed: {
    computedItems() {
      const res = cloneDeep(this.model ? this.model[this.setTo] : this.items);
      // Костыль, vuetify блочит элементы, у которых поле disable true
      if (this.model === this.$outlet)
        res.forEach((v) => (v.disabled = undefined));
      return res;
    },
  },
};
</script>

<style>
.v-list-item__mask {
  color: black !important;
  background: transparent !important;
}
</style>
