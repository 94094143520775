<template>
    <v-row no-gutters>
        <v-col cols="6">
            <v-row v-for="(el, index) in firstColumn" style="padding: 15px 0 0 15px" :key="index" no-gutters
                   align="center" justify="start" class="flex-nowrap text-truncate ">
                <div class="primary headline py-3 text-center font-weight-bold"
                     style="border-radius: 15px; width: 100px; min-width: 100px; color: rgb(38, 41, 42)">
                    {{ el.daily_number }}
                </div>
                <div class="text-h5 white--text font-weight-medium body-2 pl-3">
                    {{ el.first_name || 'Не указано' }}
                </div>
            </v-row>
        </v-col>
        <v-col cols="6">
            <v-row v-for="(el, index) in secondColumn" style="padding: 15px 0 0 15px" :key="index" no-gutters
                   align="center" justify="start" class="flex-nowrap text-truncate ">
                <div class="primary headline py-3 text-center font-weight-bold"
                     style="border-radius: 15px; width: 100px; min-width: 100px; color: rgb(38, 41, 42)">
                    {{ el.daily_number }}
                </div>
                <div class="text-h5 white--text font-weight-medium body-2 px-3">
                    {{ el.first_name || 'Не указано' }}
                </div>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'OutletQueueColumn',
    props: {
        items: Array,
    },
    data: () => ({
        totalHeight: 0,
        // firstColumn: [],
        itemsCount: 0
    }),
    methods: {},
    computed: {
        firstColumn() {
            return this.items.slice(0, this.itemsCount)
        },
        secondColumn() {
            return this.items.slice(this.itemsCount)
        }
    },
    watch: {
        items() {
            this.itemsCount = Math.floor((this.totalHeight - 70) / 71)
        }
    },
    created() {
        this.totalHeight = window.innerHeight
    }
}
</script>

<style scoped>

</style>