const m = require('air-vue-model')()
m.url = 'groups'
m.name = 'productGroup'
m.setPagination({
    page_size: 'all'
})
m.text = {
    itemTitle: 'Группа блюд',
    listTitle: 'Группы блюд'
}
m.routes = [
    {
        name: 'list',
        component: require('@/admin/views/productGroups/ProductGroupList'),
    },
    {
        name: 'item',
        component: require('@/admin/views/productGroups/ProductGroupItem'),
        single: true,
    },
]

module.exports = m