<template>
    <v-col class="pa-0">
        <v-row no-gutters>
            <v-col v-if="settings" class="mt-3" cols="5">
                <div class="text-h5 font-weight-bold">Начисление баллов</div>
                <v-select
                    v-model="settings.accrual_settings.for_first_order_type"
                    :items="firstOrderTypes"
                    class="mt-5"
                    dense
                    hide-details
                    item-text="title"
                    item-value="value"
                    label="Тип начисления за первый заказ"
                    outlined
                ></v-select>
                <v-text-field
                    v-if="settings.accrual_settings.for_first_order_type !== 3"
                    v-model="settings.accrual_settings.for_first_order"
                    class="rounded-lg mt-3"
                    dense
                    hide-details
                    label="Бонусы за первый заказ"
                    outlined
                ></v-text-field>
                <v-text-field
                    v-model="settings.accrual_settings.for_birthday"
                    class="rounded-lg mt-3"
                    dense
                    hide-details
                    label="Бонусы за день рождения"
                    outlined
                ></v-text-field>
                <v-text-field
                    v-model="settings.accrual_settings.for_complete_user_information"
                    class="rounded-lg mt-3"
                    dense
                    hide-details
                    label="Бонусы за заполнение данных о пользователе"
                    outlined
                ></v-text-field>
                <v-text-field
                    v-model="settings.accrual_settings.for_referral"
                    class="rounded-lg mt-3"
                    dense
                    hide-details
                    label="Бонусы за активацию реферального кода"
                    outlined
                ></v-text-field>
                <v-divider class="my-5"></v-divider>
                <v-text-field
                    v-model="settings.system_settings.notify_if_refiled_balance_more"
                    class="rounded-lg"
                    dense
                    hide-details
                    label="Уведомлять если начислено баллов в заказе больше чем"
                    outlined
                ></v-text-field>
                <v-text-field
                    v-model="settings.system_settings.max_amount_for_refiled_balance"
                    class="rounded-lg mt-3"
                    dense
                    hide-details
                    label="Максимальное количество баллов для ручного начисления"
                    outlined
                ></v-text-field>
                <v-divider class="my-5"></v-divider>
                <v-text-field
                    v-model="settings.accrual_settings.reset_balance_after_days_without_registration"
                    class="rounded-lg"
                    dense
                    hide-details
                    label="Очищать баланс если пользователь не зарегистрировался через n-дней"
                    outlined
                ></v-text-field>
                <v-text-field
                    v-model="settings.accrual_settings.reset_balance_after_days_without_orders"
                    class="rounded-lg mt-3"
                    dense
                    hide-details
                    label="Очищать баланс если пользователь не формировал заказы n-дней"
                    outlined
                ></v-text-field>
                <v-btn
                    :loading="loading"
                    class="rounded-lg mt-8"
                    color="primary"
                    outlined
                    @click="save"
                >Сохранить
                </v-btn>
            </v-col>
            <v-col v-if="settings" class="pl-5" cols="5">
                <div class="text-h5 font-weight-bold">Дополнительно</div>
                <ImageUploader
                    :disable-edit="false"
                    :image="settings.loyalty_system_settings.head_image"
                    class="mt-3"
                    height="200"
                    title="Изображение на странице с баллами"
                    width="355"
                    @input="uploadImage($event, 'loyalty_system_head_image')"
                ></ImageUploader>
            </v-col>
        </v-row>
    </v-col>
</template>

<script>
import ImageUploader from "@/admin/components/ImageUploader.vue";

export default {
    name: "Settings",
    components: {ImageUploader},
    data: () => ({
        settings: null,
        loading: false,
        firstOrderTypes: [
            {title: "Процент", value: 1},
            {title: "Фиксированное количество", value: 2},
            {title: "Сумма заказ", value: 3},
        ],
    }),
    methods: {
        async uploadImage(file, field) {
            const formData = new FormData();
            formData.append("image", file);
            formData.append("type", field);
            const res = await this.$settings.sendPut("upload_image", formData);
            this.settings.loyalty_system_settings.head_image = res.url;
            this.$store.commit("setSnackbar", {
                color: "success",
                text: "Изображение сохранено",
            });
        },
        async save() {
            this.loading = true;
            const accrualSettings = this.settings.accrual_settings || {};
            const accrualSettingsKeys = Object.keys(accrualSettings);
            for (const key of accrualSettingsKeys) {
                const invalidValues = ["", null];
                const value = accrualSettings[key];
                if (invalidValues.includes(value)) accrualSettings[key] = 0;
            }
            if (this.settings.system_settings.notify_if_refiled_balance_more === "")
                this.settings.system_settings.notify_if_refiled_balance_more = null;
            if (this.settings.system_settings.max_amount_for_refiled_balance === "")
                this.settings.system_settings.max_amount_for_refiled_balance = null;
            try {
                await this.$settings.sendPut("set_settings", this.settings);
                this.$store.commit("setSnackbar", {
                    color: "success",
                    text: "Сохранено",
                });
            } catch {
                this.$store.commit("setSnackbar", {
                    color: "error",
                    text: "Не удалось сохранить",
                });
            }
            this.loading = false;
        },
    },
    mounted() {
        this.$settings.sendGet("current").then((res) => {
            this.settings = res;
        });
    },
};
</script>

<style scoped></style>