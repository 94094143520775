<template>
    <v-row align="center" class="py-1 px-3 link" no-gutters @click="$emit('click')">
        <div class="col-6">
            <slot></slot>
        </div>
        <div class="col-3 text-center">{{ item.percent }} %</div>
        <div class="col-2 text-center">{{ item.priority }}</div>
        <div class="col-1 text-center">
            <slot name="actions"></slot>
        </div>
    </v-row>
</template>

<script>


export default {
    name: 'DiscountSystemElement',
    props: {
        item: Object
    },
    components: {},
}
</script>

<style scoped>

</style>