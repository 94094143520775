<template>
    <v-col>
        <v-row no-gutters align="center">
            <div class="text-h4 font-weight-bold">Система скидок</div>
            <v-btn class="mx-5" depressed @click="currentType = 'refill'" :outlined="currentType !== 'refill'"
                   color="primary ">
                Начисление
            </v-btn>
            <v-btn depressed @click="currentType = 'withdraw'" :outlined="currentType === 'refill'" color="primary ">
                Списание
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                v-if="$auth.hasRight('discount_systems__edit_information')"
                height="40" class="text-none rounded-lg body-2" depressed color="primary" @click="save"
                :loading="saveLoading">
                Сохранить
            </v-btn>
        </v-row>
        <DiscountSystem :item="currentSystem"></DiscountSystem>
    </v-col>
</template>

<script>
import DiscountSystem from "@/admin/views/discountSystems/DiscountSystem.vue";
import {cloneDeep} from "lodash";

export default {
    name: 'DiscountSystems.vue',
    components: {
        DiscountSystem
    },
    data: () => ({
        currentSystem: null,
        saveLoading: false,
        currentType: 'refill'
    }),
    methods: {
        async save() {
            this.saveLoading = true
            const data = cloneDeep(this.currentSystem)
            data.percent = data.percent || 0
            for (const el of data['product_discounts']) {
                el['product'] = el['product'].id
                for (const ingredient of el['ingredient_discounts']) {
                    ingredient['ingredient'] = ingredient['ingredient'].id
                }
            }
            for (const el of data['category_discounts']) {
                el['category'] = el['category'].id
            }
            try {
                const res = await this.$discountSystem.sendPost('set_discount_system', data)
                this.currentSystem = res.discount_system
                this.$store.commit('setSnackbar', {color: 'success', text: 'Сохранено'})
            } catch (e) {
                console.log(e)
                this.$store.commit('setSnackbar', {color: 'red', text: 'Ошибка сохранения'})
            }
            this.saveLoading = false
        },
        loadSystem() {
            this.currentSystem = null
            this.$discountSystem.sendGet('current_system', {
                discount_type: this.currentType
            }).then(res => {
                this.currentSystem = res.discount_system
            })
        }
    },
    created() {
        this.loadSystem()
        this.$productGroup.loadList()
    },
    watch: {
        currentType() {
            this.loadSystem()
        }
    }
}
</script>

<style scoped>

</style>