<template>
    <v-col cols="6" md="3">
        <v-card class="rounded-lg pa-3 elevation-1" height="100%">
            <div v-if="$store.state.showAllInfo" class="mb-1 caption">
                {{ currentSelectedInGroupCount }}/{{ minProductIngredientAmount }}/{{ maxProductIngredientAmount }} -
                id:
                {{ productIngredient.id }}
            </div>
            <div v-if="isChanging">
                <div class="ma-0 fill-height d-flex flex-column justify-space-between">
                    <v-row no-gutters justify="space-between">
                        <div class="text-caption text--secondary px-1 pt-1">
                            {{ price }} ₽
                        </div>
                        <v-btn icon small @click="isChanging = false">
                            <v-icon small>mdi-close</v-icon>
                        </v-btn>
                    </v-row>
                    <v-row no-gutters align="center" justify="center" style="height: 100%">
                        <v-btn
                            :disabled="!canDecrement"
                            icon :x-small="!withImage" :small="withImage"
                            :class="canDecrement ? 'primary' : 'grey lighten-4'" @click="decrement">
                            <v-icon color="white" size="20">mdi-minus</v-icon>
                        </v-btn>
                        <div class="text-body-2 px-4 noselect font-weight-medium" v-text="currentSelectedInGroupCount"/>
                        <v-btn :disabled="!canAppend" icon :x-small="!withImage" :small="withImage"
                               :class="canAppend ? 'primary' : 'grey lighten-4'"
                               @click="appendToGroup">
                            <v-icon color="white" size="20">mdi-plus</v-icon>
                        </v-btn>
                    </v-row>
                </div>
            </div>
            <div v-else>
                <div @click="addIngredient"
                     class="cursor-pointer fill-height d-flex flex-column justify-space-between">
                    <v-row no-gutters align="start" justify="space-between">
                        <v-icon :size="20" color="primary">
                            {{ isSelected ? 'mdi-check-circle-outline' : 'mdi-checkbox-blank-circle-outline' }}
                        </v-icon>
                        <v-img v-if="withImage" height="40" width="40" :src="image" aspect-ratio="1" contain/>
                        <div class="text-caption text--secondary noselect">
                            {{ price }} ₽
                        </div>
                    </v-row>
                    <div class="d-flex align-center justify-center mt-2">
                        <div class="text-body-2 noselect text-center">{{ ingredient.name }}
                        </div>
                    </div>
                </div>
            </div>
        </v-card>
    </v-col>
</template>

<script>
export default {
    name: "IngredientTile",

    props: {
        productIngredient: Object,
        productIngredientGroup: Object,
        withImage: Boolean,
    },

    data: () => ({
        isChanging: false,
    }),

    computed: {
        ingredient() {
            return this.productIngredient.ingredient
        },
        image() {
            return this.ingredient.image || '/media/not-found.png'
        },
        price() {
            const defaultPrices = this.ingredient.prices.filter(e => !e.start_at && !e.end_at)
            if (defaultPrices.length === 0)
                if (this.ingredient.prices.length) return this.ingredient.prices[0].price
                else return 0
            const price = defaultPrices[0].price
            return this.selectedAmount > 1 ? price * this.selectedAmount : price
        },

        minGroupAmount() {
            return this.productIngredientGroup.min_amount
        },
        maxGroupAmount() {
            return this.productIngredientGroup.max_amount
        },
        minProductIngredientAmount() {
            return this.productIngredient.min_amount
        },
        maxProductIngredientAmount() {
            return this.productIngredient.max_amount
        },
        currentSelectedInGroup() {
            return this.selectedInGroup.filter(val => val.id === this.productIngredient.id)
        },
        currentSelectedInGroupCount() {
            return this.currentSelectedInGroup.length
        },
        selectedInGroup() {
            return this.$store.state.selectedProductIngredients.filter(selected => {
                return this.productIngredientGroup.ingredients.findIndex(e => e.id === selected.id) > -1
            })
        },
        selectedInGroupCount() {
            return this.selectedInGroup.length
        },
        isSelected() {
            return this.currentSelectedInGroupCount > 0
        },
        canAppend() {
            return this.maxGroupAmount > this.selectedInGroupCount && this.maxProductIngredientAmount > this.currentSelectedInGroupCount
        },
        canDecrement() {
            return this.minGroupAmount < this.selectedInGroupCount && this.minProductIngredientAmount < this.currentSelectedInGroupCount
        }
    },

    methods: {
        addIngredient() {
            if (this.maxGroupAmount === 1) {
                if (this.isSelected && this.minGroupAmount === 0 && this.minProductIngredientAmount === 0) {
                    this.clearGroup()
                } else if (!this.isSelected) {
                    this.clearGroup()
                    this.appendToGroup()
                }
            } else {
                if (this.isSelected && this.currentSelectedInGroupCount === 1 && this.maxProductIngredientAmount === 1
                    && this.minProductIngredientAmount === 0 && this.selectedInGroupCount - 1 >= this.minGroupAmount) {
                    this.clearProductIngredientInGroup()
                } else if (this.isSelected) {
                    this.toggle()
                } else if (!this.isSelected) {
                    this.appendToGroup()
                }
            }
        },

        decrement() {
            if (!this.canDecrement)
                return
            const index = this.$store.state.selectedProductIngredients.findIndex(e => e.id === this.productIngredient.id)
            if (index > -1)
                this.$store.state.selectedProductIngredients.splice(index, 1)
            this.isChanging = !!this.currentSelectedInGroupCount
        },

        toggle() {
            if (this.isSelected && this.productIngredient.min_amount !== 1) {
                this.isChanging = true
            } else {
                this.increment()
            }
        },
        appendToGroup() {
            if (this.canAppend)
                this.$store.state.selectedProductIngredients.push(this.productIngredient)
        },
        clearGroup() {
            this.selectedInGroup.forEach(e => {
                const index = this.$store.state.selectedProductIngredients.findIndex(selected => {
                    return selected.id === e.id
                })
                if (index > -1) this.$store.state.selectedProductIngredients.splice(index, 1)
            })
        },
        clearProductIngredientInGroup() {
            this.$store.state.selectedProductIngredients = this.$store.state.selectedProductIngredients.filter(val => val.id !== this.productIngredient.id)
        }
    },
    watch: {
        '$store.state.productDialog'(val) {
            if (val)
                this.isChanging = false
        }
    }
}
</script>
