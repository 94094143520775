// import BaseModel from "../vendor/baseModel";

export class Cart {
    constructor() {
        this.list = []
    }

    getCartItemIds(cartItems) {
        const cart_items = []
        for (const cartItem of cartItems) {
            cart_items.push({
                product: cartItem.product.id,
                ingredients: cartItem.productIngredients.map(e => e.ingredient.id),
                amount: cartItem.amount,
            })
        }
        return cart_items
    }
}