import Vue from "vue";
import store from "./store/store";

import { User } from "@/models/user";
import { ProductGroup } from "@/models/productGroup";
import { Product } from "@/models/product";
import { Outlet } from "@/models/outlet";
import { Order } from "@/models/order";
import { Cart } from "@/models/cart";
import { Promo } from "@/models/promo";
import { UpSale } from "@/models/upSale";

Vue.prototype.$user = Vue.set(store.state.items, "user", new User());
Vue.prototype.$productGroup = Vue.set(
  store.state.items,
  "productGroup",
  new ProductGroup()
);
Vue.prototype.$product = Vue.set(store.state.items, "product", new Product());
Vue.prototype.$outlet = Vue.set(store.state.items, "outlet", new Outlet());
// Vue.prototype.$order = Vue.set(store.state.items, 'order', new Order())
Vue.prototype.$cart = Vue.set(store.state.items, "cart", new Cart());
Vue.prototype.$promo = Vue.set(store.state.items, "promo", new Promo());
Vue.prototype.$upSale = Vue.set(store.state.items, "upSale", new UpSale());
Vue.prototype.$upSale = Vue.set(store.state.items, "upSale", new UpSale());
Vue.prototype.$order = Vue.observable(require("../airModels/order"));
Vue.prototype.$company = Vue.observable(require("../airModels/company"));
Vue.prototype.$document = Vue.observable(require("../airModels/document"));
