var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',{staticClass:"pa-0"},[_c('v-row',{staticClass:"my-3",attrs:{"no-gutters":"","align":"center"}},[_c('div',{staticClass:"text-h6 mr-3"},[_vm._v("Модификаторы")]),_c('v-divider')],1),_vm._l((_vm.item.product_ingredient_groups),function(ingredientGroup,index){return _c('div',{key:ingredientGroup.id},[(index)?_c('v-divider',{staticClass:"mb-1"}):_vm._e(),_c('ingredient-group-card',{attrs:{"ingredient-group":ingredientGroup},on:{"ingredientGroup":function($event){return _vm.openRoute({
          name: 'productProductIngredientGroup',
          params: { productIngredientGroupId: $event.id },
        })},"ingredient":function($event){return _vm.openRoute({
          name: 'productProductIngredient',
          params: { productIngredientGroupIngredientId: $event.id },
        })},"resort":function($event){_vm.item.product_ingredient_groups = _vm.item.product_ingredient_groups.sort((a,b) => (a.sorting - b.sorting))},"click":function($event){$event.stopPropagation();return _vm.$store.commit('setProductDrawer', true)}}})],1)}),_c('v-navigation-drawer',{staticStyle:{"z-index":"1000"},attrs:{"disable-route-watcher":"","value":_vm.$store.getters.productDrawer,"overlay-opacity":"0","width":"80%","app":"","right":"","temporary":""},on:{"input":function($event){return _vm.$store.commit('setProductDrawer', $event)}}},[_c('router-view')],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }