<template>
  <template-list
    :show-create-button="$auth.hasRight('displays__edit_information')"
    :model="$displayDeviceGroup"
    title-prepend="Настройки экранов / "
  >
    <template v-slot:actions> </template>
    <template v-slot="{ object, to, index }">
      <router-link
        style="width: 100%"
        :to="{
          name: 'displayDeviceGroupItem',
          params: { displayDeviceGroupUuid: object.uuid },
        }"
      >
        <v-row no-gutters class="pa-0 body-2 black--text">
          <div class="link font-weight-bold">{{ object.name }}</div>
          <v-spacer></v-spacer>
          <div class="font-weight-bold">
            {{ object.line ? object.line.name : "Очередь отсутствует" }}
          </div>
        </v-row>
      </router-link>
    </template>
  </template-list>
</template>

<script>
export default {
  name: "DisplayDeviceGroups",
  data: () => ({}),
  methods: {
    parseDate(date) {
      return window.moment.localDateTime(date);
    },
  },
  created() {},
};
</script>

<style scoped></style>
