<template>
    <v-row no-gutters class="flex-column mt-10" align="center" justify="center">
        <v-icon size="100">mdi-cart-outline</v-icon>
        <div class="mt-5">Корзина пуста</div>
    </v-row>
</template>

<script>
export default {
name: "EmptyCart"
}
</script>

<style scoped>

</style>