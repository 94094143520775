import BaseModel from "../vendor/baseModel";
// import OrderView from "../main/views/Order"

export class Order extends BaseModel {

    creationLoading = false

    constructor() {
        super({
            url: 'orders',
            name: 'order',
            page_size: 'all',
            routes: [
                // {
                //     name: 'item',
                //     component: OrderView,
                //     single: true
                // },
            ],
        });
    }

    parseStatus(status = null) {
        if (status == null) status = this.item?.status
        switch (status) {
            case 0:
                return 'Отклонен'
            case 1:
                return 'Создан'
            case 2:
                return 'Оплачен'
            case 3:
                return 'Принят в работу'
            case 4:
                return 'Готовится'
            case 5:
                return 'Завершен'
        }
    }

    async setReview(rating, comment) {
        try {
            const response = await axios.post(`${this.url}/${this.item.id}/review/`, {rating, comment})
            this.item = response.data
        } catch (e) {
            console.log(e)
        }
    }
}
