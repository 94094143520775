<template>
  <div>
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          depressed
          height="40px"
          color="primary"
          @click="openDialog"
        >
          <v-icon>mdi-progress-check</v-icon>
        </v-btn>
      </template>
      <span>Применить к остальным</span>
    </v-tooltip>
    <v-dialog v-model="dialog" max-width="460px">
      <v-card class="pa-4">
        <v-row justify="space-between" class="ma-0 mb-2">
          <div class="text-body-1 font-weight-bold">Выберите товары</div>
        </v-row>
        <v-row align="center" no-gutters>
          <v-btn depressed color="primary" @click="approve">Подтвердить</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="selectAll">Выбрать все</v-btn>
        </v-row>
        <search
          class="my-3"
          max-width="300px"
          :rounded="false"
          always
          v-model="search"
        ></search>
        <v-col class="px-0" style="max-height: 500px; overflow-y: auto">
          <v-col v-for="(el, index) in list" class="pa-0" :key="el.id">
            <v-divider style="width: 100%" v-if="index" class="my-2" />
            <v-row class="ma-0" align="center">
              <v-img
                class="mr-3"
                max-width="30"
                height="30"
                aspect-ratio="1"
                contain
                :src="el.image || '/images/not-found.png'"
              />
              <div>{{ el.vendor_name || el.name }}</div>
              <v-spacer></v-spacer>
              <v-checkbox
                multiple
                :value="el.id"
                hide-details
                class="ma-0"
                v-model="selected"
              ></v-checkbox>
            </v-row>
          </v-col>
        </v-col>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Search from "@/components/template/Search.vue";
import search from "air-vue-model/search";

export default {
  name: "AcceptToOtherProducts",
  components: { Search },
  props: {
    filters: Object,
    setData: Object,
  },
  emits: ["approved"],
  data: () => {
    return {
      search: "",
      dialog: false,
      selected: [],
      products: [],
    };
  },
  methods: {
    approve() {
      const data = Object.assign(this.setData, {
        selected_products: this.selected,
      });
      this.$product
        .sendPost("related_update", data)
        .then(() => {
          this.dialog = false;
          this.$emit("approved", data);
          this.$store.commit("setSnackbar", {
            color: "success",
            text: "Обновлено",
          });
        })
        .catch(() => {
          this.$store.commit("setSnackbar", {
            color: "error",
            text: "Ошибка обновления",
          });
        });
    },
    openDialog() {
      this.selected = [];
      this.products = [];
      this.search = "";
      const _filters = Object.assign(
        { hidden_product_ingredient_groups: true },
        this.filters || {}
      );
      this.$product
        .loadList(_filters, { page_size: "all", setToModel: false })
        .then((res) => {
          this.products = res.results;
        });
      this.dialog = true;
    },
    selectAll() {
      this.selected = this.list.map((v) => v.id);
    },
  },
  computed: {
    list() {
      return search(this.search, this.products, (val) => val.vendor_name);
    },
  },
};
</script>
