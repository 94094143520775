<template>
    <v-snackbar
        v-model="$store.getters.snackbar.value" top :right="$vuetify.breakpoint.mdAndUp"
        :color="$store.getters.snackbar.color" multi-line :timeout="2000"
    >
        {{ $store.getters.snackbar.text }}
        <template v-slot:action="{ attrs }">
            <v-btn small icon dark v-bind="attrs" @click="$store.getters.snackbar.value = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
export default {
    name: "Snackbar"
}
</script>
