<template>
    <template-list :model="$outletServiceLog" :show-create-button="false">
        <template v-slot:actions>
            <v-btn height="40" color="primary" depressed class="rounded-lg text-none body-2"
                   @click="$outletServiceLog.loadList()">Обновить
            </v-btn>
        </template>
        <template v-slot:subactions>
            <v-row dense align="center" class="my-0">
                <v-col cols="12" md="3">
                    <v-select clearable hide-details class="rounded-lg"
                              :items="$outletServiceLog.types" item-value="value" item-text="title"
                              v-model="$outletServiceLog.filters.type" label="Тип" outlined dense></v-select>
                </v-col>
                <v-col cols="12" md="3">
                    <v-select clearable hide-details class="rounded-lg"
                              :items="$user.list" item-value="id" :item-text="val => val.phone + ' ' + val.first_name"
                              v-model="$outletServiceLog.filters.user" label="Пользователь" outlined dense></v-select>
                </v-col>
                <v-col cols="12" md="3">
                    <v-select clearable hide-details class="rounded-lg"
                              :items="$outlet.list" item-value="id" :item-text="v => v.number + '. ' + v.address"
                              v-model="$outletServiceLog.filters.outlet" label="Торговая точка" outlined
                              dense></v-select>
                </v-col>
                <v-col cols="12" md="3">
                    <v-text-field outlined hide-details dense v-model="$outletServiceLog.filters.phone"
                                  class="rounded-lg"
                                  label="Телефон"></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                    <v-text-field outlined hide-details dense v-model="$outletServiceLog.filters.mac" class="rounded-lg"
                                  label="Устройство"></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                    <v-text-field outlined hide-details dense v-model="$outletServiceLog.filters.text"
                                  class="rounded-lg"
                                  label="Текст"></v-text-field>
                </v-col>
            </v-row>
        </template>
        <template v-slot="{object, to, index}">
            <v-col class="pa-0">
                <v-row no-gutters align="center">
                    <div class="font-weight-bold">{{
                            $outletServiceLog.getTypeByValue(object.type).title
                        }}
                    </div>
                    <v-spacer></v-spacer>
                    <div v-if="object.user">+{{ object.user.phone }}</div>
                    <div class="ml-1 font-weight-bold">{{ object.outlet.address }}</div>
                </v-row>
                <v-row no-gutters align="center" class="mt-0 caption">
                    <div>{{ parseDate(object.created_at) }}</div>
                    <v-spacer></v-spacer>
                    <div class="mr-1">Устройство:</div>
                    <div>{{ object.mac }}</div>
                </v-row>
                <v-row no-gutters align="center" class="mt-0 caption">
                    <div class="mr-1">Текст:</div>
                    <div>{{ object.text }}</div>
                </v-row>
            </v-col>
        </template>
    </template-list>
</template>

<script>
export default {
    name: 'OutletServiceLogs',
    data: () => ({}),
    methods: {
        parseDate(date) {
            return window.moment.localDateTime(date)
        },
    },
    created() {
        this.$user.loadList({
            user_type: 3
        })
        this.$outlet.loadList({})
    }
}
</script>

<style scoped>

</style>