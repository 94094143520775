<template>
    <template-list :model="$lineProductGroup" :show-create-button="$auth.hasRight('tracker__edit_information')">
        <template v-slot="{object, to, index}">
            <router-link :to="to">
                <v-col class="pa-0 link">
                    <v-row no-gutters align="center">
                        <v-icon :style="`color: ${object.color}`" size="20">mdi-checkbox-blank-circle</v-icon>
                        <div class="ml-2 font-weight-medium">{{ object.name }}</div>
                        <v-chip v-if="object.print_by_cook" class="ml-2" x-small>Печать на посту готовки</v-chip>
                        <v-chip v-if="object.print_by_assembler" class="ml-2" x-small>Печать на посту сборки</v-chip>
                    </v-row>
                    <div class="caption">Блюда: {{ object.products.map(v => v.name).join(', ') }} <span
                        v-if="!object.products.length">Отсутствуют</span>
                    </div>
                </v-col>
            </router-link>
        </template>
    </template-list>
</template>

<script>

export default {
    name: 'LineProductGroups',
    components: {},
    data: () => ({}),
    methods: {
        setColor(v) {
            this.$line.update(v)
        }
    },
}
</script>

<style scoped>

</style>