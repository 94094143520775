<template>
    <v-col class="pa-3 pb-10">
        <v-row dense align="center" class="my-0">
            <v-col cols="12" md="3">
                <date-picker class="rounded-lg" label="Дата начала"
                             clearable
                             v-model="startDate"></date-picker>
            </v-col>
            <v-col cols="12" md="3">
                <date-picker label="Дата окончания" class="rounded-lg"
                             clearable
                             v-model="endDate"></date-picker>
            </v-col>
            <v-col cols="12" md="3">
                <v-text-field type="number" v-model.number="minutesOffset" outlined hide-details dense
                              label="Считать нарушением от N минут"/>
            </v-col>
            <v-col cols="12" md="3">
                <v-btn :loading="loading" @click="loadAnalytics" class="rounded-lg" depressed color="primary"
                       height="40">Обновить
                </v-btn>
                <v-btn :loading="exportLoading" class="rounded-lg ml-3" outlined height="40" rounded color="primary"
                       v-if="result" @click="exportToExcel">Выгрузить
                </v-btn>
            </v-col>
        </v-row>
        <v-row no-gutters align="center" class="mt-3">
            <v-checkbox hide-details dense class="pa-0 ma-0" label="Заказы из 1С"
                        v-model="fromAccountingSystem"></v-checkbox>
            <v-checkbox hide-details dense class="pa-0 ma-0 ml-4" label="Заказы из приложения"
                        v-model="fromApp"></v-checkbox>
        </v-row>
        <v-col class="pa-0 mt-5" v-if="result">
            <div class="font-weight-medium mb-1">Общая информация</div>
            <div class="body-2">Всего заказов: <span class="font-weight-medium">{{ result.orders }}</span></div>
            <div class="body-2">Всего нарушений: <span class="font-weight-medium">{{ result.total }}</span>
            </div>
            <div class="body-2">Просрочено минут в среднем: <span class="font-weight-medium">{{
                    result.avg_minutes
                }}</span></div>
        </v-col>
        <v-divider class="mt-5"></v-divider>
        <v-col class="pa-0 mt-3" v-if="result">
            <v-col class="pa-0 py-2" v-for="(el, index) in result.result" :key="index">
                <div class="font-weight-medium mb-1">
                    <span class="mr-2" v-if="el.outlet.vendor_code">{{ el.outlet.number }}</span>
                    <span>{{ el.outlet.address }}</span>
                    <span class="ml-2 text-caption font-weight-regular">({{ el.outlet.company.name }})</span>
                </div>
                <div class="body-2">Всего заказов: <span class="font-weight-medium">{{ el.orders }}</span></div>
                <div class="body-2">Всего нарушений: <span class="font-weight-medium">{{ el.total }}</span></div>
                <div class="body-2">Просрочено минут в среднем: <span class="font-weight-medium">{{
                        el.avg_minutes
                    }}</span>
                </div>
                <v-row no-gutters class="mt-2">
                    <overdue-block title="Начало готовки" :data="el.start_cooking_time"></overdue-block>
                    <overdue-block class="ml-5" title="Конец готовки" :data="el.end_cooking_time"></overdue-block>
                </v-row>
            </v-col>
        </v-col>
    </v-col>
</template>

<script>

import OverdueBlock from "@/admin/views/analyitcs/OverdueBlock.vue";

export default {
    name: "Analytics",
    components: {
        OverdueBlock
    },
    data: () => ({
        result: null,
        loading: false,
        startDate: null,
        endDate: null,
        exportLoading: false,
        fromAccountingSystem: true,
        fromApp: true,
        minutesOffset: 1
    }),
    methods: {
        loadAnalytics() {
            this.loading = true
            this.$analytics.sendPost('overdue_orders', {
                start_date: this.startDate,
                end_date: this.endDate,
                from_accounting_system: this.fromAccountingSystem,
                from_app: this.fromApp,
                minutes_offset: this.minutesOffset
            }).then(res => {
                this.result = res
                this.loading = false
            })
        },
        exportToExcel() {
            this.exportLoading = true
            const data = []
            for (const el of this.result.result) {
                data.push({
                    'Номер': el.outlet.number,
                    'Адрес': el.outlet.address,
                    'Компания': el.outlet.company.name,
                    'Всего заказов': el.orders,
                    'Всего нарушений': el.total,
                    'Просрочено минут в среднем': el.avg_minutes,
                    'Начало: Всего нарушений': el.start_cooking_time.total,
                    'Начало: Просрочено минут в среднем': el.start_cooking_time.avg_minutes,
                    'Конец: Всего нарушений': el.end_cooking_time.total,
                    'Конец: Просрочено минут в среднем': el.end_cooking_time.avg_minutes,
                })
            }
            const XLSX = require("xlsx");

            const worksheet = XLSX.utils.json_to_sheet(data);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
            XLSX.writeFile(workbook, 'data.xlsx');
            this.exportLoading = false
        }
    },
    watch: {},
    created() {
        this.endDate = window.moment().local().format('YYYY-MM-DD')
        this.startDate = window.moment().subtract(1, 'month').local().format('YYYY-MM-DD')
        this.loadAnalytics()
    }
}
</script>

<style scoped>

</style>
