const m = require('air-vue-model')()
m.url = 'websocket_events'
m.name = 'websocketEvent'

m.text = {
    itemTitle: 'Событие',
    listTitle: 'События'
}
m.routes = [
    {
        name: 'list',
        component: require('@/admin/views/websocketEvent/WebsocketEventList'),
    }
]

module.exports = m