const { cloneDeep } = require("lodash");
const m = require("air-vue-model")();
m.url = "promocode_conditions";
m.name = "promocodeCondition";
m.text = {
  itemTitle: "Условие промокода",
  listTitle: "Условия промокодов",
};

// m.pagination.page_size = 'all'

m.filters = {}

m.typeEnum = {
  DEFAULT: 1,
  TEXT: 2,
};

m.typeName = {
  [m.typeEnum.DEFAULT]: "Обычный",
  [m.typeEnum.TEXT]: "Текстовый",
};

m.typesList = Object.keys(m.typeName).map((key) => {
  const _key = Number(key);
  return {
    label: m.typeName[_key],
    value: _key,
  };
});

m.statusEnum = {
  DRAFT: 1,
  ACTIVE: 2,
  DISABLED: 3,
};

m.statusName = {
  [m.statusEnum.DRAFT]: "Черновик",
  [m.statusEnum.ACTIVE]: "Активен",
  [m.statusEnum.DISABLED]: "Не активен",
};

m.statusColor = {
  [m.statusEnum.DRAFT]: "black",
  [m.statusEnum.ACTIVE]: "green",
  [m.statusEnum.DISABLED]: "grey",
}

m.statusList = Object.keys(m.statusName).map((key) => {
  const _key = Number(key);
  return {
    label: m.statusName[_key],
    value: _key,
  };
});

m.rewardTypes = {
  DELIVERY_DISCOUNT: 1,
  FREE_DELIVERY: 2,
  INCREASE_BONUS: 3,
  INCREASE_BONUS_PERCENT: 4,
  FREE_PRODUCTS: 5,
  PRODUCTS_DISCOUNT: 6,
};

m.rewardTypeName = {
  [m.rewardTypes.DELIVERY_DISCOUNT]: "Скидка на доставку",
  [m.rewardTypes.FREE_DELIVERY]: "Бесплатная доставка",
  [m.rewardTypes.INCREASE_BONUS]: "Сумма бонусов",
  [m.rewardTypes.INCREASE_BONUS_PERCENT]: "Процент от заказа бонусами",
  [m.rewardTypes.FREE_PRODUCTS]: "Блюдо на выбор за 1 рубль",
  [m.rewardTypes.PRODUCTS_DISCOUNT]: "Блюдо на выбор со скидкой",
};

m.rewardTypeList = Object.keys(m.rewardTypeName).map((key) => {
  const _key = Number(key);
  return {
    label: m.rewardTypeName[_key],
    value: _key,
  };
});

m.getRewardType = (item) => {
  const reward = item.reward;
  if (!item.reward) return null;
  if (reward.delivery_discount_percent != null) {
    if (reward.delivery_discount_percent === 100)
      return m.rewardTypes.FREE_DELIVERY;
    return m.rewardTypes.DELIVERY_DISCOUNT;
  }
  if (reward.increase_bonus_percent != null)
    return m.rewardTypes.INCREASE_BONUS_PERCENT;
  if (reward.increase_bonus != null) return m.rewardTypes.INCREASE_BONUS;
  if (reward.products_data) {
    for (const el of reward.products_data) {
      if (el.discount_percent !== 100) return m.rewardTypes.PRODUCTS_DISCOUNT;
    }
    return m.rewardTypes.FREE_PRODUCTS;
  }
  return null;
};

m.default = {
  name: null,
  type: m.typeEnum.DEFAULT,
  status: m.statusEnum.DRAFT,
  for_apply: {
    min_order_sum: 0,
    apply_limit: 1,
    for_first_order: false,
    applicable_order_types: [],
    products_data: [],
  },
  for_earn: {
    earn_limit: 1,
    user_order_number: 0,
    required_progress: 0,
    applicable_order_types: [],
    for_first_order: false,
    min_order_sum: 0,
    products_data: [],
  },
  reward: {},
};
module.exports = m;
