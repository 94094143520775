const m = require('air-vue-model')()
m.url = 'outlet_lines'
m.name = 'outletLine'

m.default = {
    outlet: null,
    line: null,
    line_product_groups: []
}

module.exports = m