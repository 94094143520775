<template>
    <v-col class="pa-3 pb-10">
        <v-row dense align="center" class="my-0">
            <v-col cols="12" md="3">
                <date-picker class="rounded-lg" label="Дата начала"
                             clearable
                             v-model="startDate"></date-picker>
            </v-col>
            <v-col cols="12" md="3">
                <date-picker label="Дата окончания" class="rounded-lg"
                             clearable
                             v-model="endDate"></date-picker>
            </v-col>
            <v-col cols="12" md="3">
                <v-btn :loading="loading" @click="loadAnalytics" class="rounded-lg" depressed color="primary"
                       height="40">Обновить
                </v-btn>
            </v-col>
        </v-row>
        <v-row no-gutters align="center" class="mt-3">
            <v-checkbox hide-details dense class="pa-0 ma-0" label="Только оплаченные заказы"
                        v-model="onlyPaid"></v-checkbox>
        </v-row>
        <v-row no-gutters align="start" class="mt-10">
            <div class="pa-0" v-if="result">
                <div class="font-weight-medium mb-3">Общая информация</div>
                <div class="body-2 mt-2">Всего заказов: <span class="font-weight-medium">{{
                        result.result.total
                    }}</span>
                </div>
                <div class="body-2 mt-2">Как можно скорее: <span
                    class="font-weight-medium">{{ result.result.as_soon_as_possible }}</span>
                </div>
                <div class="body-2 mt-2">К выбранному времени: <span class="font-weight-medium">{{
                        result.result.selected_time
                    }}</span>
                    <v-menu
                        open-on-hover
                        top
                        offset-y
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-on="on" size="20"
                                    class="ml-1 mb-1"
                            >mdi-information-outline
                            </v-icon>
                        </template>
                        <v-card class="pa-3 rounded-lg">
                            <div class="caption mb-2">Список</div>
                            <v-row no-gutters align="center" :class="{'mt-1': index}"
                                   v-for="(el, index) in result.result.selected_time_ranges"
                                   :key="index">
                                <div class="caption text-center" style="width: 60px">{{ computeItem(el) }}</div>
                                <div class="ml-5 body-2 font-weight-medium">{{ el.count }}</div>
                            </v-row>
                        </v-card>
                    </v-menu>
                </div>
            </div>
            <v-divider vertical class="ml-10"></v-divider>
            <Donat width="340" :labels="['Как можно скорее', 'На выбранное время']"
                   :values="[result.result.as_soon_as_possible, result.result.selected_time]"
                   v-if="result"></Donat>
        </v-row>
    </v-col>
</template>

<script>

import OverdueBlock from "@/admin/views/analyitcs/OverdueBlock.vue";
import Donat from "@/admin/views/analyitcs/Donat.vue";

export default {
    name: "Analytics",
    components: {
        Donat,
        OverdueBlock
    },
    data: () => ({
        result: null,
        loading: false,
        startDate: null,
        endDate: null,
        onlyPaid: false,
    }),
    methods: {
        loadAnalytics() {
            this.loading = true
            this.$analytics.sendPost('as_soon_as_possible', {
                start_date: this.startDate,
                end_date: this.endDate,
                only_paid: this.onlyPaid,
            }).then(res => {
                this.result = res
                this.loading = false
            })
        },
        computeItem(item) {
            if (item.end > 1000) {
                return `${this.formatMinutes(item.start)} +`
            }
            return `${this.formatMinutes(item.start)} - ${this.formatMinutes(item.end)}`
        },
        formatMinutes(minutes) {
            const hours = Math.floor(minutes / 60); // вычисляем количество часов
            const mins = minutes % 60; // вычисляем количество минут
            return `${hours}:${mins < 10 ? '0' : ''}${mins}`; // форматируем время
        }
    },
    watch: {},
    created() {
        this.endDate = window.moment().local().format('YYYY-MM-DD')
        this.startDate = window.moment().subtract(1, 'month').local().format('YYYY-MM-DD')
        this.loadAnalytics()
    }
}
</script>

<style scoped>

</style>
