<template>
    <template-list :model="$productGroup" :items="list" :show-create-button="false">
        <template v-slot:actions>
            <v-checkbox class="mr-5" v-model="$store.state.onlyInApp"
                        label="Только отображаемые в приложении"></v-checkbox>
            <search :rounded="false" always v-model="search"></search>
        </template>
        <template v-slot="{object, to}">
            <v-col>
                <v-row no-gutters align="center">
                    <router-link :to="to">
                        <v-hover v-slot="{hover}">
                            <v-row no-gutters align="center"
                                   :class="object.show_in_app || hover ? 'link' : 'secondary--text'"
                                   class="text-h6 font-weight-bold link">
                                <div class="mr-2">{{ object.vendor_name || 'Отсутствует' }}</div>
                                <div class="text-caption mr-2" v-if="object.name">({{ object.name }})</div>
                                <div class="mr-2 text-caption">Количество блюд: {{ object.products.length }}</div>
                                <discount-system-information :group="object"></discount-system-information>
                            </v-row>
                        </v-hover>
                    </router-link>
                </v-row>
                <v-col class="pa-0" v-for="(product, index) in filterItems(object.products.filter(val => val.active))"
                       :key="index">
                    <router-link :to="{name: 'productItem', params: {productId: product.id}}">
                        <v-hover v-slot="{hover}">
                            <v-row no-gutters align="center"
                                   :class="product.show_in_app || hover ? 'link' : 'secondary--text'">
                                <v-img max-width="40" height="40" aspect-ratio="1" contain
                                       :src="product.image || '/images/not-found.png'"/>
                                <div class="text-body-2 ml-4" v-text="product.vendor_name || 'Отсутствует'"/>
                                <div class="text-caption ml-2 mr-2" v-if="product.name">({{ product.name }})</div>
                                <discount-system-information :product="product"></discount-system-information>
                            </v-row>
                        </v-hover>
                    </router-link>
                </v-col>
            </v-col>
        </template>
    </template-list>
</template>

<script>
import search from "air-vue-model/search";
import Search from "@/components/template/Search";
import DiscountSystemInformation from "@/admin/views/discountSystems/DiscountSystemInformation.vue";

export default {
    name: "ProductGroupList",
    components: {
        DiscountSystemInformation,
        Search
    },
    data: () => ({
        search: null,

    }),
    methods: {
        filterItems(items) {
            if (this.$store.state.onlyInApp) items = items.filter(v => v.show_in_app)
            return search(this.search, items, val => val.vendor_name)
        }
    },
    computed: {
        list() {
            return this.$productGroup.list.filter(v => {
                if (this.$store.state.onlyInApp && !v.show_in_app) return false
                return this.filterItems(v.products.filter(e => e.active)).length > 0
            })
        }
    },
    created() {

    }
}
</script>
