<template>
  <template-item
    v-slot="{ object, rules }"
    :model="$displayLine"
    :show-actions="$auth.hasRight('displays__edit_information')"
    title-prepend="Настройки экранов / Очереди /"
    object-key-for-title="name"
  >
    <v-text-field
      v-model="object.name"
      class="rounded-lg"
      dense
      hide-details
      label="Наименование"
      outlined
    ></v-text-field>
    <v-checkbox
      v-model="object.active"
      class="ma-0 pa-0 mt-4"
      hide-details
      label="Активно"
    ></v-checkbox>
    <v-row align="center" class="my-3" no-gutters>
      <div class="font-weight-bold">Элементы:</div>
      <v-spacer></v-spacer>
      <v-btn
        v-if="$auth.hasRight('displays__edit_information')"
        class="text-none body-2 rounded-lg"
        color="primary"
        depressed
        @click="openDialog"
      >
        Добавить
      </v-btn>
    </v-row>
    <div v-if="!object.items.length" class="caption">Отсутствуют</div>
    <v-col v-else class="pa-0">
      <v-col
        v-for="(el, index) in object.items"
        :key="index"
        class="pa-0 body-2"
      >
        <v-divider v-if="index" class="my-2"></v-divider>
        <v-row align="center" no-gutters>
          <v-col class="pa-0">
            <div class="font-weight-bold">{{ el.content.name }}</div>
            <div class="caption">Продолжительность: {{ el.duration }}</div>
          </v-col>
          <v-spacer></v-spacer>
          <v-btn
            v-if="$auth.hasRight('displays__edit_information')"
            icon
            @click="openDialog(index)"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </v-row>
      </v-col>
    </v-col>
    <v-dialog v-model="dialog" max-width="500">
      <v-card v-if="item" class="pa-5 rounded-lg">
        <div class="font-weight-bold body-2 mb-5">Настройка элемента</div>
        <v-text-field
          v-model="item.duration"
          class="rounded-lg"
          dense
          hide-details
          label="Продолжительность"
          outlined
        ></v-text-field>
        <v-text-field
          v-model="item.sorting"
          class="rounded-lg mt-3"
          dense
          hide-details
          label="Сортировка"
          outlined
        ></v-text-field>
        <v-select
          v-model="item.content"
          :items="$displayContent.list"
          class="mt-3 rounded-lg"
          dense
          hide-details
          item-text="name"
          item-value="uuid"
          label="Контент"
          outlined
          return-object
        ></v-select>

        <v-row align="center" class="mt-5" no-gutters>
          <v-btn
            :disabled="!item.content"
            class="text-none body-2 rounded-lg"
            color="primary"
            depressed
            @click="saveItem"
          >
            Сохранить
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-if="index > -1"
            class="text-none body-2 rounded-lg"
            color="red"
            outlined
            @click="deleteItem"
          >
            Удалить
          </v-btn>
        </v-row>
      </v-card>
    </v-dialog>
  </template-item>
</template>

<script>
export default {
  name: "DisplayLine",
  data: () => ({
    dialog: false,
    defaultItem: {
      content: null,
      duration: 30,
      sorting: 1,
    },
    item: null,
    index: -1,
  }),
  methods: {
    openDialog(index = -1) {
      this.index = index;
      if (this.index > -1) {
        this.item = this.$displayLine.copy(this.$displayLine.item.items[index]);
      } else this.item = this.$displayLine.copy(this.defaultItem);
      this.dialog = true;
    },
    saveItem() {
      this.dialog = false;
      if (this.index > -1) {
        this.$displayLine.item.items.splice(this.index, 1, this.item);
      } else this.$displayLine.item.items.push(this.item);
      this.item = null;
    },
    deleteItem() {
      this.dialog = false;
      this.$displayLine.item.items.splice(this.index, 1);
    },
  },
  computed: {},
  created() {
    this.$displayContent.loadList();
  },
};
</script>

<style scoped></style>
