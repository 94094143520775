const m = require('air-vue-model')()
m.url = 'device_activities'
m.name = 'deviceActivity'

m.text = {
    listTitle: 'Активность устройств'
}

m.types = [
    {'value': 1, title: 'Сервисное приложение'},
    {'value': 2, title: 'Клиентское приложение'},
]

m.filters = {
    user: null,
    type: null,
    phone: null,
    mac: null,
}

m.routes = [
    {
        name: 'list',
        component: require('@/admin/views/deviceActivity/DeviceActivities'),
    },
]

module.exports = m