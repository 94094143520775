<template>
  <template-item
    v-slot="{ object, rules }"
    :model="$userRole"
    :show-actions="$auth.hasRight('user_roles__edit_information')"
    title-prepend="Роли / "
    object-key-for-title="name"
    :after-save="emitItemSaved"
  >
    <v-row class="ma-0 mb-7">
      <v-btn
        @click="tab = 'rules'"
        :color="tab === 'rules' ? 'primary' : ''"
        class="mr-3"
        depressed
        >Настройки прав</v-btn
      >
      <v-btn
        @click="tab = 'pushes'"
        :color="tab === 'pushes' ? 'primary' : ''"
        depressed
        >Настройки уведомлений</v-btn
      >
    </v-row>
    <UserRoleItemRules
      v-if="tab === 'rules'"
      :object="object"
      :values="values"
    />
    <UserRoleItemPushes v-if="tab === 'pushes'" :object="object" />
  </template-item>
</template>

<script>
import imageUpload from "../../../vendor/imageUpload";
import ImageUploader from "@/admin/components/ImageUploader";
import UserRoleItemRules from "@/admin/views/userRoles/UserRoleItemRules.vue";
import UserRoleItemPushes from "@/admin/views/userRoles/UserRoleItemPushes.vue";

export default {
  name: "PromoItem",

  mixins: [imageUpload],
  components: {
    UserRoleItemPushes,
    UserRoleItemRules,
    ImageUploader,
  },
  data: () => ({
    values: [],
    tab: "rules",
  }),
  computed: {},

  created() {
    this.$userRole.sendGet("rights").then((res) => {
      this.values = res.right_groups;
      const totalRights = [];
      this.values.forEach((v) =>
        v.rights.forEach((e) => totalRights.push(e.right))
      );
      this.$userRole.item.rights = this.$userRole.item.rights.filter((v) =>
        totalRights.includes(v)
      );
    });
  },
  methods: {
    emitItemSaved() {
      this.$eventBus.$emit('roleSaved')
    }
  }
};
</script>
