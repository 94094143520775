<template>
    <v-card v-if="$vuetify.breakpoint.mdAndUp" class="pa-2" @click="showProduct" elevation="1">
        <v-img aspect-ratio="1" contain :src="image"/>
        <div class="text-body-2 mt-3" v-text="product.name"/>
        <div class="text-body-1 font-weight-bold">{{ product.price }} ₽</div>
    </v-card>
    <v-card v-else flat tile @click="showProduct" color="transparent" class="py-3">
        <v-row no-gutters align="center">
            <div>
                <v-img aspect-ratio="1" contain :src="image" height="60" width="60"/>
            </div>
            <div class="px-2">
                <div class="text-body-1" v-text="product.name"/>
                <div class="text-body-1 font-weight-bold">от {{ product.price }} ₽</div>
            </div>
            <v-spacer/>
            <div class="ma-0 rounded-circle primary elevation-3" style="padding: 2px">
                <v-icon color="white">
                    mdi-plus
                </v-icon>
            </div>
        </v-row>
    </v-card>
</template>

<script>
export default {
    name: "ProductMenuTile",

    props: {
        product: Object,
    },

    computed: {
        image() {
            return this.product.image || '/media/not-found.png'
        },
        price() {
            const productIngredients = this.$product.getDefaultIngredients(this.product)
            return this.$product.countTotalPrice(productIngredients, 1)
        },
    },
    created() {

    },

    methods: {
        async showProduct() {
            this.$eventBus.$emit('openProduct', {
                product: this.product,
            })
        },
    }
}
</script>
