<template>
    <template-list :auto-load-list="false" :model="$balanceOperation" :show-create-button="false" :hide-header="inUser">
        <template v-slot:actions>
            <v-btn :loading="$order.loadings.list" color="primary" height="40" depressed
                   class="rounded-lg text-none body-2"
                   @click="loadList">Обновить
            </v-btn>
        </template>
        <template v-slot:subactions>
            <v-row dense align="center" class="my-0">
                <v-col>
                    <date-picker class="rounded-lg" label="Дата начала"
                                 with-time clearable
                                 v-model="$balanceOperation.filters.start_date"></date-picker>
                </v-col>
                <v-col>
                    <date-picker label="Дата окончания" with-time class="rounded-lg"
                                 clearable
                                 v-model="$balanceOperation.filters.end_date"></date-picker>
                </v-col>
                <v-col>
                    <v-text-field class="rounded-lg" outlined label="Мин. сумма" dense hide-details
                                  v-model="$balanceOperation.filters.min_sum"></v-text-field>
                </v-col>
                <v-col>
                    <v-text-field class="rounded-lg" dense hide-details outlined label="Макс. сумма"
                                  v-model="$balanceOperation.filters.max_sum"></v-text-field>
                </v-col>
                <v-col>
                    <v-select clearable hide-details class="rounded-lg"
                              :items="[
                                  {name: 'Автоматическое', id: 'auto'},
                                  {name: 'Ручное', id: 'manual'},
                              ]" item-value="id"
                              item-text="name"
                              v-model="$balanceOperation.filters.type" label="Тип" outlined
                              dense></v-select>
                </v-col>
              <v-col>
                <v-select clearable hide-details class="rounded-lg"
                          :items="[
                                  {name: 'Начисление', id: 'positive'},
                                  {name: 'Списание', id: 'negative'},
                              ]" item-value="id"
                          item-text="name"
                          v-model="$balanceOperation.filters.sum_type" label="Начисление/списание" outlined
                          dense></v-select>
              </v-col>
                <v-col>
                    <v-text-field clearable class="rounded-lg" v-model="$balanceOperation.filters.search" dense
                                  hide-details
                                  outlined
                                  label="Поиск"></v-text-field>
                </v-col>
            </v-row>
            <order-view-dialog v-model="dialog"></order-view-dialog>
        </template>
        <template v-slot="{object, to, index}">
            <v-col class="pa-0">
                <v-row no-gutters align="center" class="font-weight-bold">
                    <div>Пользователь: {{ object.user.first_name || 'Не указано' }} {{ object.user.phone }}</div>
                    <v-spacer></v-spacer>
                    <div>Тип: {{ object.type === 'auto' ? 'Автоматическое' : 'Ручное' }}</div>

                </v-row>
                <v-row no-gutters align="center">
                    <div>Сумма: <span class="font-weight-bold"
                                      :class="object.sum > 0 ? 'green--text' : 'red--text'">{{ object.sum }}</span>
                    </div>
                    <v-spacer></v-spacer>
                    <div>{{ parseDate(object.created_at) }}</div>
                </v-row>
                <v-row no-gutters align="center">
                    <div>Заголовок: {{ object.title || 'Не указано' }}</div>
                </v-row>
                <v-row no-gutters align="center">
                    <div>Сообщение пользователю: {{ object.message || 'Не указано' }}</div>
                </v-row>
                <v-row no-gutters align="center">
                    <div>Комментарий: {{ object.description || 'Не указано' }}</div>
                </v-row>
                <v-row no-gutters align="center">
                    <div v-if="object.created_by">Сформировал: {{ object.created_by.first_name || 'Не указано' }} {{
                            object.created_by.phone
                        }}
                    </div>
                </v-row>
            </v-col>
        </template>
    </template-list>
</template>

<script>

export default {
    name: 'BalanceOperations',
    components: {},
    data: () => ({
        dialog: false
    }),
    props: {
        inUser: Boolean
    },
    methods: {
        parseDate(date) {
            return window.moment.localDateTime(date)
        },
        loadList() {
            let params = undefined
            if (this.inUser) {
                params = {user: this.$user.item.id}
            }
            this.$balanceOperation.loadList(params)
        }
    },
    created() {
        this.loadList()
    }
}
</script>

<style scoped>

</style>