<template>
  <v-col class="pa-3 pb-10">
    <v-row align="center" class="my-0" dense style="gap: 10px 0px">
      <v-row class="ma-0" style="flex-wrap: nowrap; flex: unset">
        <date-picker
          v-model="startDate"
          class="rounded-lg mr-2"
          label="Дата начала"
          style="max-width: 300px"
        ></date-picker>
        <date-picker
          v-model="endDate"
          class="rounded-lg mr-2"
          label="Дата конца"
          style="max-width: 300px"
        ></date-picker>
      </v-row>
      <v-col cols="auto">
        <v-row align="center" class="pa-0 ml-1">
          <v-btn
            :loading="loading"
            class="rounded-lg"
            color="primary"
            depressed
            height="40"
            @click="loadAnalytics()"
            >Выгрузить
          </v-btn>
        </v-row>
      </v-col>
      <v-spacer />
    </v-row>
  </v-col>
</template>
<script>
import moment from "moment";

export default {
  name: "OrderReviewTagsAnalytics",
  components: {},
  data: () => ({
    startDate: moment().subtract(1, "w").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    loading: false,
    result: null,
    exportLoading: false,
  }),
  methods: {
    loadAnalytics() {
      if (this.startDate > this.endDate) {
        const tmp = String(this.startDate);
        this.startDate = String(this.endDate);
        this.endDate = tmp;
      }
      this.loading = true;
      this.startDate = this.startDate.split(' ')[0] + ' 00:00:00'
      this.endDate = this.endDate.split(' ')[0] + ' 23:59:59'
      const filters = {
        start_date: moment(this.startDate).utc().format('YYYY-MM-DD HH:mm:ss') || undefined,
        end_date: moment(this.endDate).utc().format('YYYY-MM-DD HH:mm:ss') || undefined,
      }
      this.$analytics
        .sendPost("order_review_excel_report", {
          ...filters
        })
        .then((res) => {
          window.open(res.url, '_blank')
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
#parent {
  position: absolute;
  width: 100%;
}

table {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  overflow: hidden;
}

thead {
  /*
  Grow thead automatically to fit content, don't shrink it
  proportionately to the body.
  */
  flex: 1 0 auto;
  display: block;
  /* x-scrolling will be managed via JS */
  overflow-x: hidden;
  /*
  Keep header columns aligned with useless scrollbar.
  For IE11, use "dead area" color to hide scrollbar functions
  */
  overflow-y: scroll;
  scrollbar-base-color: #ccc;
  scrollbar-face-color: #ccc;
  scrollbar-highlight-color: #ccc;
  scrollbar-track-color: #ccc;
  scrollbar-arrow-color: #ccc;
  scrollbar-shadow-color: #ccc;
  scrollbar-dark-shadow-color: #ccc;
}

thead::-webkit-scrollbar {
  display: block;
  background-color: #ccc;
}

thead::-webkit-scrollbar-track {
  background-color: #ccc;
}

/* Scroll the actual tbody (second child on all browsers) */
tbody {
  display: block;
  overflow: scroll;
}

/* IE11 adds an extra tbody, have to hide it */
tbody:nth-child(3) {
  display: none;
}

/* The one caveat, a hard-set width is required. */
td,
th {
  width: 10em;
  min-width: 10em;
  padding: 0.3em;
  border: 1px solid #ddd;
  background-color: white;
}

th {
  background-color: #f7f7f7;
}

td:first-child,
th:first-child {
  position: sticky;
  position: -webkit-sticky;
  left: 0;
}

.list-item {
  padding: 5px 5px;
  border-radius: 7px;
  cursor: pointer;
}

.list-item:hover {
  background: #e3e3e3;
}

.reviews-table :deep(thead th:nth-child(6)) {
  width: 300px;
}
</style>
