<template>
  <v-col class="pa-0">
    <v-row no-gutters>
      <v-col v-if="settings" class="mt-3" cols="5">
        <div class="text-h5 font-weight-bold">Настройки терминалов</div>
        <v-text-field
          v-model="
            settings.service_app_settings.period_of_sales_points_rating_in_days
          "
          class="rounded-lg mt-3"
          dense
          hide-details
          label="За какой период в днях отображать рейтинг в сервисном"
          type="number"
          outlined
        ></v-text-field>
        <v-btn
          :loading="loading"
          class="rounded-lg mt-5"
          color="primary"
          outlined
          @click="save"
          >Сохранить
        </v-btn>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: "Settings",
  components: {},
  data: () => ({
    settings: null,
    loading: false,
  }),
  methods: {
    async save() {
      this.loading = true;
      if (
        !this.settings.service_app_settings
          .period_of_sales_points_rating_in_days
      )
        this.settings.service_app_settings.period_of_sales_points_rating_in_days =
          null;
      try {
        await this.$settings.sendPut("set_settings", this.settings);
        this.$store.commit("setSnackbar", {
          color: "success",
          text: "Сохранено",
        });
      } catch {
        this.$store.commit("setSnackbar", {
          color: "error",
          text: "Не удалось сохранить",
        });
      }
      this.loading = false;
    },
  },
  mounted() {
    this.$settings.sendGet("current").then((res) => {
      this.settings = res;
    });
  },
};
</script>

<style scoped></style>
