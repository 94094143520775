<template>
  <div>
    <v-dialog
      :value="value"
      max-width="600"
      @close="$emit('input', false)"
      @click:outside="$emit('input', false)"
    >
      <v-card v-if="item" class="pa-3 rounded-lg">
        <div class="c-row">
          <div class="c-row c-items-center">
            <div class="body-1 font-weight-bold mr-2">Детали</div>
            <v-icon v-if="!dataLoading" size="19px" @click="openData">
              mdi-information-outline
            </v-icon>
            <v-progress-circular
              v-else
              color="grey"
              indeterminate
              size="20"
              width="2"
            />
          </div>
          <v-spacer />
          <div
            @click="trackDialog = true"
            v-if="item.scheduled_delivery_time_by_courier"
            class="py-1 mr-2 px-2 body-2 font-weight-medium c-row c-items-center cursor-pointer"
            style="
              background: rgba(250, 62, 62, 0.12);
              border-radius: 5px;
              color: #f52828;
            "
          >
            <v-icon class="mr-1" size="19px" color="#f52828"
              >mdi-map-marker-outline</v-icon
            >
            <div>Трек</div>
            <order-track-dialog
              v-model="trackDialog"
              :order="item"
            ></order-track-dialog>
          </div>
          <div
            class="py-1 px-2 body-2 font-weight-medium c-row c-items-center"
            style="background: #faab3e1f; border-radius: 5px; color: #f59a28"
          >
            <v-icon class="mr-1" size="19px" color="#F59A28"
              >{{
                [true, null].includes(item.as_soon_as_possible)
                  ? "mdi-clock-fast"
                  : "mdi-calendar-clock-outline"
              }}
            </v-icon>
            <div>
              {{
                [true, null].includes(item.as_soon_as_possible)
                  ? "Как можно скорее"
                  : "Ко времени"
              }}
            </div>
          </div>
          <div
            class="py-1 px-2 body-2 ml-2 font-weight-medium"
            :style="{ ...$order.userStatusColors[item.user_status] }"
          >
            {{ $order.getUserStatusName(item) }}
          </div>
        </div>
        <v-divider class="my-3" />
        <div class="c-column body-2">
          <div class="mb-1">
            <span class="grey--text text--darken-2">Время создания:</span>
            {{ parseDate(item.created_at) }}
          </div>
          <div class="mb-1" v-if="item.scheduled_delivery_time_by_courier">
            <span class="grey--text text--darken-2"
              >Плановое время выдачи курьером:</span
            >
            {{ parseDate(item.scheduled_delivery_time_by_courier) }}
          </div>
          <div class="mb-1">
            <span class="grey--text text--darken-2">Время выдачи:</span>
            {{ parseDate(item.released_at) }}
          </div>
          <div class="mb-1">
            <span class="grey--text text--darken-2">Точка:</span>
            {{ item.outlet.number }}. {{ item.outlet.address }}
          </div>
          <div class="mb-1">
            <span class="grey--text text--darken-2">Источник:</span>
            {{ $order.sourceName[item.source] }}
          </div>
        </div>
        <v-divider class="my-3" />
        <div class="c-row">
          <div
            class="py-2 px-2 mr-3"
            style="
              cursor: pointer;
              background: rgb(246, 246, 246);
              border-radius: 8px;
            "
            @click="
              $router.push({
                name: 'userItem',
                params: { userId: item.user.id },
              })
            "
          >
            <v-icon color="black" size="23">mdi-account-outline</v-icon>
          </div>
          <div class="c-column body-2">
            <div
              style="cursor: pointer"
              @click="
                $router.push({
                  name: 'userItem',
                  params: { userId: item.user.id },
                })
              "
            >
              {{ item.user.first_name || item.user.phone }}
            </div>
            <div class="c-row c-justify-center">
              <template v-if="item.review">
                Оценка: {{ item.review.rating + 1 }}
                <v-icon class="ml-1 mr-2" color="primary" size="17px"
                  >mdi-star
                </v-icon>
                {{ parseDate(item.review.created_at) }}
              </template>
              <template v-else>Без оценки и комментария</template>
            </div>
          </div>
        </div>
        <div v-if="item.review?.comment" class="body-2 mt-1">
          <span class="grey--text text--darken-2">Комментарий:</span>
          {{ item.review.comment }}
        </div>
        <div class="body-2"></div>
        <v-divider class="my-3" />
        <div class="c-row c-justify-center">
          <div class="body-1 font-weight-bold">Состав заказа</div>
          <v-spacer />
          <div class="c-primary--text body-1 font-weight-bold">
            ИТОГО: {{ item.sum }} ₽
          </div>
        </div>
        <v-divider class="my-3" />
        <v-col
          v-for="(orderItem, index) in item.items"
          :key="index"
          class="pa-0"
        >
          <v-divider v-if="index" style="margin: 2px 0" />
          <v-row align="center" class="my-1" no-gutters>
            <div class="body-2 font-weight-medium">
              <template>
                {{ orderItem.product.vendor_name || orderItem.product.name }}
              </template>
              <span class="grey--text text--darken-1"
                >(x{{ orderItem.amount }})</span
              >
            </div>
            <v-spacer></v-spacer>
            <div class="body-2 font-weight-bold mr-2">
              {{ orderItem.price }} ₽
            </div>
          </v-row>
          <v-col class="pa-0">
            <div
              v-if="orderItem.disabled_nomenclature?.length"
              class="c-column"
            >
              <div
                class="c-row c-items-center red--text mb-1 mt-1 body-2"
                v-for="(
                  disabledNomenclature, disabledNomenclatureIndex
                ) in orderItem.disabled_nomenclature"
                :key="disabledNomenclatureIndex"
              >
                <v-icon class="mr-1" color="red" size="20"
                  >mdi-information-outline
                </v-icon>
                Убрать
                {{
                  disabledNomenclature.vendor_name || disabledNomenclature.name
                }}
              </div>
            </div>
            <div
              v-for="(el, ingIndex) in orderItem.ingredients"
              :key="ingIndex"
              class="caption py-1"
            >
              <v-row align="center" class="pl-1" no-gutters>
                 <span class="body-2">{{
                  el.ingredient.vendor_name || el.ingredient.name
                }}</span>
                <span class="grey--text text--darken-1"
                  > (x{{ orderItem.amount }})</span
                >
                <v-spacer></v-spacer>
                <OrderBalanceView
                  full-info
                  without-colors
                  :object="{ refiled_balance: el.refiled_balance }"
                  class="text-caption"
                ></OrderBalanceView>
              </v-row>
            </div>
          </v-col>
        </v-col>
        <v-divider class="my-2"></v-divider>
        <div class="c-column body-2">
          <div class="c-row c-justify-between">
            <div>Позиций {{ item.items.length }}</div>
            <div>{{ item.sum }} ₽</div>
          </div>
          <div
            class="c-row c-justify-between mt-2"
            v-if="item.withdraw_balance"
          >
            <div>Списано</div>
            <OrderBalanceView
              full-info
              :object="{ withdraw_balance: item.withdraw_balance }"
              class="text-caption"
            ></OrderBalanceView>
          </div>
          <div class="c-row c-justify-between mt-2" v-if="item.refiled_balance">
            <div>Будет начислено</div>
            <OrderBalanceView
              full-info
              :object="{ refiled_balance: item.refiled_balance }"
              class="text-caption"
            ></OrderBalanceView>
          </div>
          <div class="c-row c-justify-between mt-2 font-weight-bold">
            <div>Сумма заказа</div>
            <div>{{ item.sum - item.withdraw_balance || 0 }} ₽</div>
          </div>
        </div>
        <v-divider class="my-3" />
        <template v-if="item.user_promocodes.length">
          <div class="font-weight-medium c-row c-justify-between body-1">
            <div>
              Примененные промокоды
              <span class="grey--text text--darken-1"
                >(x{{ item.user_promocodes.length }})</span
              >
            </div>
          </div>
          <v-divider class="my-3" />
          <v-col class="pa-0" style="max-height: 300px; overflow-y: auto">
            <v-col
              v-for="(el, index) in item.user_promocodes"
              :key="index"
              class="pa-0 caption"
            >
              <v-row
                align="center"
                no-gutters
                :style="{ 'margin-top': index ? '5px' : '' }"
              >
                <v-row no-gutters align="center" class="font-weight-medium">
                  <v-icon class="mr-1">mdi-ticket-percent</v-icon>
                  {{ el.promocode_condition.name }}
                </v-row>
              </v-row>
            </v-col>
          </v-col>
          <v-divider class="my-3" />
        </template>
        <div class="font-weight-medium c-row c-justify-between body-1">
          <div>
            Заблокированное оборудование
            <span class="grey--text text--darken-1"
              >(x{{ periods.length }})</span
            >
          </div>
        </div>
        <v-divider class="my-3" />
        <v-col class="pa-0" style="max-height: 300px; overflow-y: auto">
          <v-col
            v-for="(el, index) in periods"
            :key="index"
            class="pa-0 caption"
          >
            <v-divider v-if="index" class="my-1"></v-divider>
            <v-row align="center" no-gutters>
              <div class="font-weight-medium">
                {{ el.equipment.name }} ({{ el.slot_number }})
              </div>
              <v-icon
                :color="el.active ? 'green' : 'grey'"
                class="ml-2"
                size="15"
              >
                mdi-checkbox-blank-circle
              </v-icon>
            </v-row>
            <v-row align="center" class="mt-0" no-gutters>
              <div>
                {{ parseDate(el.start_date) }} <span class="mx-1">-</span>
                {{ parseDate(el.end_date) }}
              </div>
            </v-row>
          </v-col>
        </v-col>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dataDialog" max-width="800">
      <v-card v-if="orderData" class="pa-3 rounded-lg" style="overflow-x: auto">
        <pre>{{ orderData }}</pre>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import OrderBalanceView from "@/admin/views/orders/OrderBalanceView.vue";
import OrderTrackDialog from "@/admin/views/equipmentLockedPeriod/OrderTrackDialog.vue";

export default {
  name: "OrderViewDialog",
  props: {
    value: Boolean,
  },
  components: {
    OrderTrackDialog,
    OrderBalanceView,
  },
  data: () => ({
    periods: [],
    trackDialog: false,
    dataDialog: false,
    orderData: null,
    dataLoading: false,
  }),
  methods: {
    parseDate(date) {
      return window.moment.localDateTime(date);
    },
    openData() {
      if (this.item.vendor_code) {
        this.orderData = this.item;
        this.dataDialog = true;
        return;
      }
      this.dataLoading = true;
      this.$order.sendGetSingle("payment_status", this.item.id).then((res) => {
        this.orderData = res;
        this.dataLoading = false;
        this.dataDialog = true;
      });
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.periods = [];
        this.$equipmentLockedPeriod
          .loadList(
            {
              order: this.item.id,
            },
            { setToModel: false }
          )
          .then((res) => {
            this.periods = res.results.reverse();
          });
      }
    },
  },
  computed: {
    item() {
      return this.$order.item;
    },
  },
};
</script>

<style scoped>
.c-row {
  display: flex;
  flex-wrap: wrap;
}

.c-column {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.c-no-wrap {
  flex-wrap: nowrap !important;
}

.c-justify-center {
  justify-content: center;
}

.c-justify-between {
  justify-content: space-between;
}

.c-items-center {
  align-items: center;
}

.c-primary--text {
  color: #f59a28;
}
</style>
