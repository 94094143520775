<template>
  <div @mouseenter="hover = true" @mouseleave="hover = false">
    <slot :hover="hover"></slot>
  </div>
</template>

<script>
export default {
  name: "CHover",
  data: () => ({
    hover: false,
  }),
};
</script>
