const m = require('air-vue-model')()
m.url = 'documents'
m.name = 'documents'
m.text = {
    listTitle: 'Настройки'
}
m.default = {
    name: null,
    link: null,
    sorting: null
}

m.routes = []
module.exports = m