<template>
  <div>
    <form method="post" :action="actionUrl" v-if="allFields">
      <input type="hidden" name="PaReq" :value="pareq">
      <input type="hidden" name="TermUrl" :value="termUrl">
      <input type="hidden" name="MD" :value="md">
    </form>
  </div>
</template>

<script>
export default {
  name: "AlfaRedirect",
  data: () => {
    return {
      actionUrl: null,
      pareq: null,
      termUrl: null,
      md: null
    };
  },

  computed: {
    allFields() {
      return !!(this.actionUrl && this.pareq && this.termUrl && this.md)
    }
  },
  watch: {
    allFields(v) {
      if (v) {
        this.execute()
      }
    }
  },
  methods: {
    execute() {
      setTimeout(() => {
        document.forms[0].submit()
      })
    }
  },

  mounted() {
    const params = this.$route.query
    this.actionUrl = params.action_url
    this.pareq = params.pareq
    this.termUrl = params.term_url
    this.md = params.md
  },
};
</script>
