const m = require('air-vue-model')()
m.url = 'cook_stations'
m.name = 'cookStation'
m.setPagination({
    page_size: 'all'
})
m.text = {
    itemTitle: 'Пост', listTitle: 'Посты'
}
m.default = {
    name: '',
    outlet: null
}


m.routes = []

module.exports = m