<template>
    <template-item :title="title" :show-actions="false" :model="$company" :show-delete="false" v-slot="{object, rules}">
        <v-row no-gutters align="center">
            <v-btn @click="$router.replace({name: 'companyBase'})"
                   :color="$route.name === 'companyBase' ? 'primary' : ''" depressed
                   class="mr-3 rounded-lg text-none body-2">Общая информация
            </v-btn>
            <v-btn @click="$router.replace({name: 'companyDocuments'})"
                   :color="$route.name === 'companyDocuments' ? 'primary' : ''"
                   depressed class="mr-3 rounded-lg text-none body-2">Документы
            </v-btn>
            <v-btn @click="$router.replace({name: 'companyBanks'})"
                   v-if="$auth.hasRight('company_banks__show_information')"
                   :color="$route.name === 'companyBanks' ? 'primary' : ''"
                   depressed class="mr-3 rounded-lg text-none body-2">Банки
            </v-btn>
        </v-row>
        <router-view></router-view>
    </template-item>
</template>

<script>
import CompanyPaymentServices from "@/admin/views/company/CompanyPaymentServices";

export default {
    name: 'Company',
    components: {
        CompanyPaymentServices
    },
    data: () => ({}),
    methods: {},
    computed: {
        title() {
            const values = ['Компании']
            if (this.$company.item) {
                values.push(this.$company.item.name)
            }
            return values.join(' / ')
        }
    }
}
</script>

<style scoped>

</style>