<template>
    <v-col class="pa-0 pt-1"
           v-if="!productIngredientGroup.show_when_ingredient_selected || selectedIngredientIds.indexOf(productIngredientGroup.show_when_ingredient_selected) > -1">
        <v-row no-gutters class="px-3 px-md-4" align="center">
            <div class="text-body-2 text-md-subtitle-1 mr-2">{{ ingredientGroup.name }}</div>
            <div class="text-caption secondary--text">
                ({{ groupInfo }})
            </div>
        </v-row>
        <v-row dense class="px-3 py-2">
            <ingredient-tile
                v-for="(productIngredient, index) in productIngredients" :key="index"
                :with-image="withImage"
                :product-ingredient="productIngredient"
                :product-ingredient-group="productIngredientGroup"
            />
        </v-row>
    </v-col>
</template>

<script>
import IngredientTile from './IngredientTile'

export default {
    name: "IngredientGroup",

    components: {
        IngredientTile,
    },

    props: {
        productIngredientGroup: Object,
    },

    computed: {
        selectedIngredientIds() {
            return this.$store.state.selectedProductIngredients.map(val => val.ingredient.id)
        },
        groupInfo() {
            if (this.$store.state.showAllInfo)
                return `${this.selectedAmount}/${this.minAmount}/${this.maxAmount} - id: ${this.productIngredientGroup.id}`
            return `${this.selectedAmount}/${this.maxAmount}`
        },
        ingredientGroup() {
            return this.productIngredientGroup.ingredient_group
        },

        maxAmount() {
            return this.productIngredientGroup.max_amount
        },
        minAmount() {
            return this.productIngredientGroup.min_amount
        },

        selectedInGroup() {
            return this.$store.state.selectedProductIngredients.filter(selected => {
                return this.productIngredients.findIndex(e => e.id === selected.id) > -1
            })
        },

        selectedAmount() {
            return this.selectedInGroup.length
        },

        productIngredients() {
            return this.productIngredientGroup.ingredients
        },

        withImage() {
            for (let productIngredient of this.productIngredients) {
                if (productIngredient.ingredient.image) return true
            }
            return false
        },
    },
    methods: {
        recomputeSelected() {
            const showIfIngredient = this.productIngredientGroup.show_when_ingredient_selected
            if (!showIfIngredient) return
            const currentIngredientIds = this.productIngredientGroup.ingredients.map(val => val.ingredient.id)
            if (this.selectedIngredientIds.indexOf(showIfIngredient) > -1) {
                if (this.productIngredientGroup.min_amount >= 1) {
                    if (!this.productIngredientGroup.ingredients.length) return
                    const pig = this.productIngredientGroup.ingredients[0]
                    if (currentIngredientIds.findIndex(val => this.selectedIngredientIds.indexOf(val) > -1) === -1) {
                        this.$store.state.selectedProductIngredients.push(pig)
                    }
                }
                return
            }
            currentIngredientIds.forEach(val => {
                const index = this.$store.state.selectedProductIngredients.findIndex(v => v.ingredient.id === val)
                if (index > -1) {
                    this.$store.state.selectedProductIngredients.splice(index, 1)
                }
            })
        }
    },
    watch: {
        selectedIngredientIds() {
            this.recomputeSelected()
        }
    }
}
</script>
