import Vue from 'vue'
import Vuex from 'vuex'
import auth from '../../vendor/auth'
import crud from "../../vendor/crud";
import items from "./items";
import helpers from "../../vendor/helpers";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        ready: false,
        loading: true,
        selectedProductGroupId: null,
        productDialog: false,
        selectedProduct: null,
        selectedProductIngredients: [],
        cartItems: [],
        selectedOutletId: null,
        showAllInfo: false,
        authModal: false,
        selectedPromo: null,
        selectedTime: null,
    },
    getters: {},
    mutations: {
        setLoading(loading) {
            this.loading = loading
        },

        setOutlet(state, outlet) {
            if (!outlet) {
                state.selectedOutletId = null
            } else {
                state.selectedOutletId = outlet.id
                if (outlet.closest_time) {
                    state.selectedTime = {
                        pt: outlet.closest_time.pt,
                        ts: [outlet.closest_time.t]
                    }
                }
            }
        },

        setTime(state, time) {
            if (time)
                state.selectedTime = {
                    pt: time.pt,
                    ts: time.ts ? time.ts : [time.t]
                }
            else
                state.selectedTime = null
        },
    },
    actions: {
        saveCart({state}) {
            localStorage.setItem('cart', JSON.stringify(state.cartItems))
        },

        loadCart({state}) {
            const cart = localStorage.getItem('cart')
            if (cart) state.cartItems = JSON.parse(cart)
        },
    },
    modules: {
        auth, crud, helpers, items
    },
})
