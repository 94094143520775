const m = require('air-vue-model')()
m.url = 'order_reviews'
m.name = 'orderReview'

m.text = {
    listTitle: 'Отзывы'
}

m.types = [
    {'value': 1, title: 'Сервисное приложение'},
    {'value': 2, title: 'Клиентское приложение'},
]

m.filters = {
    start_date: null,
    end_date: null,
    outlet: null,
    status: null,
    has_unread_messages: null,
    with_comment: null,
    ordering: '-created_at',
    tags: []
}

m.getFilters = () => {
    const res = m._getFilters()
    const arrays = {}
    for (const [k,v] of Object.entries(res)) {
        if (Array.isArray(v)) {
            arrays[k] = v
        }
    }
    for (const [k,v] of Object.entries(arrays)) {
        delete res[k]
        if (v.length) {
            const vals = []
            for (const item of v) {
                if (item && typeof item === 'object') {
                    const keys = Object.keys(item)
                    if (keys.includes('id')) vals.push(item.id)
                    else if (keys.includes('pk')) vals.push(item.pk)
                }
            }
            res[k.replace('[]', '')] = vals.join(',')
        }

    }
    return res
}

m.statuses = [
    {
        label: 'Требует разбора',
        value: 1,
        color: 'red',
        textColor: 'white'
    },
    {
        label: 'Закрыт',
        color: 'success',
        value: 4,
    },
    {
        label: 'Открыт менеджером',
        color: 'primary',
        value: 5
    },
    {
        label: 'Не требует разбора',
        color: 'gray',
        value: 6
    },
    // {
    //     label: 'Требует разбора',
    //     value: 1
    // },
]

m.color = {
    1: '#ff0000',
    2: '#ff8000',
    3: '#daac00',
    4: '#6dab00',
    5: '#1fa400',

}

m.statusDataByStatus = {}

m.statusEnum = {
    PROCEEDING_REQUIRED: 1,
    CLOSED: 4,
    REOPENED_BY_MANAGER: 5,
}

for (const el of m.statuses) {
    m.statusDataByStatus[el.value] = el
}

m.routes = [
    {
        name: 'list',
        component: require('@/admin/views/orderReviews/OrderReviews'),
    },
]

module.exports = m