const m = require('air-vue-model')()
m.url = 'product_ingredient_group_ingredient_outlets'
m.name = 'productIngredientGroupIngredientOutlet'

m.filters = {
    product_ingredient_group_ingredient: null
}

m.setPagination({
    page_size: 'all'
})

module.exports = m