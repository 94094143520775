<template>
  <template-item
    v-slot="{ object }"
    :model="$product"
    :show-actions="false"
    :show-delete="false"
    :title="title"
    title-prepend="Группы блюд / "
  >
    <v-row align="center" no-gutters>
      <v-btn
        :color="$route.name === 'productBase' ? 'primary' : ''"
        class="mr-3 rounded-lg text-none body-2"
        depressed
        @click="$router.replace({ name: 'productBase' })"
        >Общая информация
      </v-btn>
      <!--            <v-btn @click="tab = 'nomenclature'" :color="tab === 'nomenclature' ? 'primary' : ''" depressed-->
      <!--                   class="mr-3 rounded-lg text-none body-2">Технологическая карточка-->
      <!--            </v-btn>-->
      <v-btn
        :color="$route.name === 'productIngredients' ? 'primary' : ''"
        class="mr-3 rounded-lg text-none body-2"
        depressed
        @click="$router.replace({ name: 'productIngredients' })"
        >Модификаторы
      </v-btn>
      <!--            <v-btn @click="tab = 'nutritional'" :color="tab === 'nutritional' ? 'primary' : ''" depressed-->
      <!--                   class="mr-3 rounded-lg text-none body-2">Пищевая ценность-->
      <!--            </v-btn>-->
      <v-btn
        v-if="$auth.hasRight('products__show_product_outlets')"
        :color="$route.name === 'productOutlets' ? 'primary' : ''"
        class="mr-3 rounded-lg text-none body-2"
        depressed
        @click="$router.replace({ name: 'productOutlets' })"
        >Торговые точки
      </v-btn>
    </v-row>
    <router-view></router-view>
  </template-item>
</template>

<script>
export default {
  name: "ProductItem",

  components: {},
  data: () => ({}),
  methods: {},
  computed: {
    title() {
      const values = [];
      if (this.$product.item.group) {
        values.push(this.$product.item.group.vendor_name || "Отсутствует");
      }
      values.push(this.$product.item.vendor_name || "Отсутствует");
      return values.join(" / ");
    },
  },
};
</script>
