<template>
    <v-col class="pa-0">
        <v-row no-gutters class="my-3" align="center">
            <div class="text-h6 mr-3">Технологическая карточка</div>
            <v-divider/>
            <v-btn :loading="$airIngredient.loadings.action"
                   v-if="$auth.hasRight('products__edit_information')"
                   @click="saveNomenclature" depressed color="primary"
                   class="ml-3 rounded-lg text-none body-2">
                Сохранить
            </v-btn>
        </v-row>
        <v-row no-gutters class="pt-3">
            <v-spacer></v-spacer>
            <v-btn @click="dialog=true" depressed class="text-none rounded-lg body-2 px-5">Добавить</v-btn>
        </v-row>
        <v-data-table
            no-data-text="Нет данных"
            hide-default-footer
            :headers="headers"
            :items="$ingredientNomenclature.list"
        >
            <template v-slot:item.sorting="{ item }">
                <v-text-field
                    outlined
                    style="width: 100px"
                    dense
                    hide-details
                    type="number"
                    v-model.number="item.sorting"
                ></v-text-field>
            </template>
            <template v-slot:item.amount="{ item }">
                <v-text-field
                    outlined
                    style="width: 100px"
                    dense
                    hide-details
                    type="number"
                    v-model.number="item.amount"
                ></v-text-field>
            </template>

            <template v-slot:item.removable="{ item }">
                <v-checkbox
                    v-model="item.removable"
                ></v-checkbox>
            </template>
            <template v-slot:item.control_amount="{ item }">
                <v-checkbox
                    v-model="item.control_amount"
                ></v-checkbox>
            </template>
            <template v-slot:item.actions="{ item, index }">
                <v-icon
                    small
                    @click="$ingredientNomenclature.list.splice(index, 1)"
                >
                    mdi-delete
                </v-icon>
            </template>
        </v-data-table>
        <v-dialog v-model="dialog" max-width="600">
            <v-card class="pa-3">
                <v-row no-gutters align="center" class="mb-3">
                    <div class="font-weight-bold">Добавить позицию</div>
                    <v-spacer></v-spacer>
                    <v-text-field v-model="search" outlined dense hide-details label="Поиск" class="ml-3"
                                  style="max-width: 200px"
                    ></v-text-field>
                </v-row>
                <div class="body-2" v-if="!availableNomenclature.length">Доступная номенклатура отсутствует</div>
                <v-col class="pa-0">
                    <v-col class="pa-0" v-for="(el, index) in availableNomenclature" :key="index">
                        <v-divider v-if="index"></v-divider>
                        <v-row no-gutters class="py-2 link" @click="addNomenclature(el)">
                            {{ el.name }}
                        </v-row>
                    </v-col>
                </v-col>
            </v-card>
        </v-dialog>
    </v-col>
</template>

<script>
import search from "air-vue-model/search";
import {cloneDeep, isEqual} from "lodash";
import {useEventListener} from "@/plugins/useEventListener";

export default {
    name: 'IngredientItemNomenclature',
    data: () => ({
        dialog: false,
        search: '',
        headers: [
            {text: 'Номенклатура', value: 'nomenclature.name'},
            {text: 'Сортировка', value: 'sorting'},
            {text: 'Количество', value: 'amount'},
            {text: 'Можно убрать', value: 'removable'},
            {text: 'Контроль остатков', value: 'control_amount'},
            {text: 'Действия', value: 'actions', sortable: false},
        ],

      initialItem: null,
      eventsToClear: [],
    }),
    computed: {
        availableNomenclature() {
            return search(this.search, this.$nomenclature.list.filter(val => {
                return !this.$ingredientNomenclature.list.find(v => v.nomenclature.id === val.id)
            }))
        }
    },
  mounted() {
    this.initialItem = cloneDeep(this.$ingredientNomenclature.list);
    this.eventsToClear.push(this.$router.beforeEach((to, from, next) => {
      if (!isEqual(this.initialItem, this.$ingredientNomenclature.list)) {
        const answer = window.confirm(
            "Если вы уйдете со страницы, то потеряете все несохраненные данные"
        );
        if (answer) {
          if (this.eventsToClear.length) this.eventsToClear.map(v => v());
          next();
        } else {
          this.$store.commit('setProductDrawer', true)
        }
      } else next()
    }))
    this.eventsToClear.push(useEventListener(window, 'beforeunload', () => {
      if (!isEqual(this.initialItem, this.$ingredientNomenclature.list)) {
        return confirm()
      }
    }))
  },
  beforeDestroy() {
    if (this.eventsToClear.length) this.eventsToClear.map(v => v())
  },
    methods: {
        saveNomenclature() {
            this.$airIngredient.sendPutSingle('set_nomenclature', this.$airIngredient.item.id, this.$ingredientNomenclature.list).then(res => {
                this.$ingredientNomenclature.list = res
                this.$store.commit('setSnackbar', {color: 'success', text: 'Технологическая карточка сохранена'})

                this.$ingredientNomenclature.loadList({
                    ingredient: this.$airIngredient.item.id
                }).then(() => {
                  this.initialItem = cloneDeep(this.$ingredientNomenclature.list)
                })
            }).catch(() => {
                this.$store.commit('setSnackbar', {color: 'error', text: 'Не удалось сохранить'})
            })
        },
        addNomenclature(item) {
            let maxSorting = 0
            if (this.$ingredientNomenclature.list.length)
                maxSorting = this.$ingredientNomenclature.list[this.$ingredientNomenclature.list.length - 1].sorting
            this.$ingredientNomenclature.list.push({
                nomenclature: item,
                amount: 0,
                removable: false,
                control_amount: false,
                product: this.$airIngredient.item.id,
                sorting: maxSorting + 1
            })
            this.dialog = false
        }
    }
}
</script>

<style scoped>

</style>