<template>
  <div class="px-7 py-3" style="max-width: 900px; margin: 0 auto">
    <h1 class="mb-5">Список компаний</h1>
    <div class="pb-3">
      Для просмотра условий предзаказа товара выберите компанию из списка:
    </div>
    <div v-for="(item, index) in $company.list.filter((v) => v.domain)">
      <v-divider v-if="index" />
      <a
        :href="'https://' + item.domain + '/payment_info'"
        class="py-3 company-item"
      >
        {{ item.name }}
        <v-icon>mdi-chevron-right</v-icon>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "PublicCompaniesList",
  created() {
    this.$company.loadList({page_size: 'all'});
  },
};
</script>

<style scoped>
.company-item {
  background: transparent;
  cursor: pointer;
  transition: 0.3s;
  display: flex;
  justify-content: space-between;
  color: inherit;
}

.company-item:hover {
  background: #eeeeee;
}
</style>
