const m = require("air-vue-model")();
m.url = "ingredient_price_list_edicts";
m.name = "ingredientPriceListEdict";

m.text = {
  listTitle: "Приказы на изменение цен",
  itemTitle: "Приказ на изменение цен",
};

m.default = {
  // outlets: [],
  starts_from: null,
  type: 2,
  items: [],
};

m.filters = {};

m.typeEnum = {
  APP: 1,
  YANDEX_EDA: 2
}

m.typeName = {
  [m.typeEnum.APP]: 'Внутренний',
  [m.typeEnum.YANDEX_EDA]: 'Яндекс еда',
}

m.types = Object.keys(m.typeName).map(key => {
  return {
    label: m.typeName[key],
    value: Number(key)
  }
})

m.routes = [
  {
    name: "list",
    component: require("@/admin/views/ingredientPriceListEdicts/IngredientPriceListEdictList.vue"),
  },
  {
    name: "item",
    component: require("@/admin/views/ingredientPriceListEdicts/IngredientPriceListEdictItem.vue"),
    single: true
  },
];

module.exports = m;
