const m = require('air-vue-model')()
m.url = 'ingredient_groups'
m.name = 'ingredientGroup'

m.text = {
    itemTitle: 'Группа модификаторов',
    listTitle: 'Группы модификаторов'
}
m.pagination.page_size = 'all'

m.routes = [
    {name: 'list', component: require('../admin/views/ingredientGroups/IngredientGroupList.vue')},
    {name: 'item', component: require('@/admin/views/ingredientGroups/IngredientGroupItem'), single: true}
]
// m.routes = [
//     {name: 'list', component: require('../admin/views/ingredient/IngredientList.vue')},
//     {name: 'item', component: require('../admin/views/ingredient/IngredientItem.vue'), single: true},
// ]


module.exports = m