<template>
  <v-dialog :value="value" @input="closeModal()" max-width="500px">
    <v-card class="pa-3">
      <v-text-field
        dense
        hide-details
        class="my-3"
        outlined
        v-model="selectedCodeStart"
        label="Первая часть промокода"
      />
      <v-text-field
        dense
        hide-details
        class="my-3"
        outlined
        v-model.number="selectedCodeEnd"
        label="Вторая часть промокода"
      />
      <v-text-field
        dense
        hide-details
        class="my-3"
        outlined
        type="number"
        v-model.number="selectedCount"
        label="Сколько промокодов выпустить"
        :rules="[rules.required]"
      />
      <v-select
        :items="$userPromocode.types"
        item-text="label"
        item-value="value"
        multiple
        :return-object="false"
        hide-details
        outlined
        dense
        label="Дополнительные символы для генерации"
        v-model="selectedTypes"
        :rules="[rules.required]"
      />
      <v-text-field
        v-if="selectedTypes.length"
        dense
        hide-details
        class="my-3"
        outlined
        type="number"
        v-model.number="selectedLength"
        label="Сколько символов сгенерировать"
        :rules="[rules.required]"
      />
      <div v-if="selectedTypes.length">
        Возможных комбинаций: <b>{{ totalCombinations }}</b>
      </div>
      <v-btn color="primary" class="mt-3" @click="create()">
        <div class="text-none">Выпустить</div>
      </v-btn>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from "air-vue-model/rules";

export default {
  name: "CreateTextCodesModal",
  computed: {
    rules() {
      return rules;
    },
    totalCombinations() {
      if (!this.selectedTypes) return null;
      if (!this.selectedLength) return 0;
      return this.$userPromocode.getAvailableCombinationsCount(
        this.selectedTypes,
        this.selectedLength
      );
    },
  },
  components: {},
  data: () => {
    return {
      selectedTypes: [],
      selectedLength: 0,
      selectedCount: 1,
      selectedCodeStart: "",
      selectedCodeEnd: "",
    };
  },
  props: {
    value: Boolean,
  },
  emits: ["input"],
  mounted() {},
  methods: {
    clearData() {
      this.selectedTypes = [];
      this.selectedLength = 0;
      this.selectedCount = 0;
      this.selectedCodeStart = "";
      this.selectedCodeEnd = "";
    },
    closeModal() {
      this.$emit("input", false);
      this.$emit("updateProductsData", [this.currentData]);
    },
    create() {
      const data = {
        types: this.selectedTypes,
        length: this.selectedLength,
        count: this.selectedCount,
        start: this.selectedCodeStart,
        end: this.selectedCodeEnd,
      };
      this.$promocodeCondition
        .sendPostSingle(
          "generate_promocodes",
          this.$promocodeCondition.item.id,
          data
        )
        .then(() => {
          this.$store.commit("setSnackbar", "Промокоды выпущены");
          this.$userPromocode.loadList();
          this.closeModal();
          this.clearData();
        });
    },
  },
};
</script>

<style scoped></style>
