<template>
    <v-col class="pa-0">
        <div id="parent">
            <table>
                <thead id="myhead" style="overflow-y: hidden">
                <tr>
                    <th :style="!index ? 'min-width: 250px': ''" v-for="(el, index) in headers" :key="index">
                        <div class="body-2">{{ el }}</div>
                    </th>
                </tr>
                </thead>
                <tbody id="mybody" v-scroll.self="keknut">
                <tr v-for="(tableEl, tableIndex) in table" :key="tableIndex">
                    <td style="min-width: 250px">{{ tableEl.product.name }}</td>
                    <td style="cursor: pointer" :class="{'red lighten-3': value.locked}" @click="openElement(value)"
                        v-for="(value, valueIndex) in tableEl.values" :key="valueIndex"
                    >
                        <v-row no-gutters align="center" justify="center" class="body-2">

                            <div v-if="value.lockedIngredients.length" class="mr-2">М -
                                {{ value.lockedIngredients.length }}
                            </div>
                            <div v-if="value.lockedNomenclature.length">Н - {{ value.lockedNomenclature.length }}</div>
                        </v-row>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <v-dialog v-model="dialog" max-width="500px">
            <v-card class="pa-5" v-if="currentElement">
                <div class="font-weight-medium my-3">Модификаторы</div>
                <div v-for="(el) in currentElement.lockedIngredients" :key="el.id">
                    {{ el.name }}
                </div>
                <div class="font-weight-medium my-3">Номенклатура</div>
                <div v-for="(el) in currentElement.lockedNomenclature" :key="el.id">
                    {{ el.name }}
                </div>
            </v-card>
        </v-dialog>
    </v-col>
</template>

<script>
export default {
    name: 'BlockedProductsTable',
    data: () => ({
        groups: [],
        outlets: [],
        currentElement: null,
        dialog: false
    }),
    methods: {
        keknut() {
            const thead = document.getElementById("myhead");
            const tbodyScroll = document.getElementById("mybody").scrollLeft;
            thead.scrollLeft = tbodyScroll;
        },
        openElement(v) {
            this.currentElement = v
            this.dialog = true
        }
    },
    computed: {
        headers() {
            const headers = [null]
            this.outlets.forEach(v => headers.push(v.address))
            return headers
        },
        table() {
            const values = []
            this.groups.forEach(group => {
                group.products.forEach(product => {
                    const outletValues = []
                    this.outlets.forEach(outlet => {
                        const lockedIngredients = []
                        const pushedIngredients = []
                        const lockedNomenclature = []
                        const pushedNomenclature = []
                        product.ingredient_groups.forEach(ingredientGroup => {
                            ingredientGroup.ingredients.forEach(ingredient => {
                                const currentIngredient = ingredient.ingredient
                                if (outlet.disabled_ingredients.includes(currentIngredient.id)) {
                                    if (!pushedIngredients.includes(currentIngredient.id)) {
                                        lockedIngredients.push(currentIngredient)
                                        pushedIngredients.push(currentIngredient.id)
                                    }
                                }
                                currentIngredient.ingredient_nomenclature.forEach(nomenclature => {
                                    const currentNomenclature = nomenclature.nomenclature
                                    if (outlet.disabled_nomenclature.includes(currentNomenclature.id)) {
                                        if (!pushedNomenclature.includes(currentNomenclature.id)) {
                                            lockedNomenclature.push(currentNomenclature)
                                            pushedNomenclature.push(currentNomenclature.id)
                                        }
                                    }
                                })
                            })
                        })
                        outletValues.push({
                            outlet: outlet,
                            locked: outlet.disabled_products.includes(product.id),
                            lockedIngredients,
                            lockedNomenclature
                        })
                    })
                    values.push({
                        product: product,
                        values: outletValues
                    })
                })
            })
            return values
        }
    },
    async created() {
        this.groups = await this.$productGroup.sendGet('full')
        this.outlets = await this.$outlet.sendPost('get_list_by_location_and_cart', {
            location: [], cart_items: []
        })
    }
}
</script>

<style scoped>
#parent {
    position: absolute;
    width: 100%;
    height: calc(100vh - 64px);
    overflow: hidden;
}

table {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    width: 100%;
    height: 100%;
    border-collapse: collapse;
    overflow: hidden;
    /* Use this to create a "dead" area color if table is too wide for cells */
}

thead {
    /*
    Grow thead automatically to fit content, don't shrink it
    proportionately to the body.
    */
    flex: 1 0 auto;
    display: block;
    /* x-scrolling will be managed via JS */
    overflow-x: hidden;
    /*
    Keep header columns aligned with useless scrollbar.
    For IE11, use "dead area" color to hide scrollbar functions
    */
    overflow-y: scroll;
    scrollbar-base-color: #ccc;
    scrollbar-face-color: #ccc;
    scrollbar-highlight-color: #ccc;
    scrollbar-track-color: #ccc;
    scrollbar-arrow-color: #ccc;
    scrollbar-shadow-color: #ccc;
    scrollbar-dark-shadow-color: #ccc;
}


thead::-webkit-scrollbar {
    display: block;
    background-color: #ccc;
}

thead::-webkit-scrollbar-track {
    background-color: #ccc;
}

/* Scroll the actual tbody (second child on all browsers) */
tbody {
    display: block;
    overflow: scroll;
}

/* IE11 adds an extra tbody, have to hide it */
tbody:nth-child(3) {
    display: none;
}

/* The one caveat, a hard-set width is required. */
td, th {
    width: 10em;
    min-width: 10em;
    padding: 0.3em;
    border: 1px solid #ddd;
    background-color: white;
}

th {
    background-color: #f7f7f7;
}

td:first-child,
th:first-child {
    position: sticky;
    position: -webkit-sticky;
    left: 0;
}


</style>