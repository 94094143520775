<template>
    <v-col class="pa-0">
        <v-row no-gutters class="my-3" align="center">
            <div class="text-h6 mr-3">Зоны доставки</div>
            <v-divider/>
            <v-btn
                @click="saveAreas"
                :loading="$outlet.loadings.action"
                depressed
                color="primary"
                class="ml-3 rounded-lg text-none body-2"
                :disabled="hasError"
            >Сохранить
            </v-btn>
        </v-row>
        <v-row dense>
            <v-col cols="4" style="max-height: calc(100vh - 250px); overflow-y: auto">
                <v-col class="pa-0 body-2" v-for="(el, index) in items" :key="index">
                    <v-divider v-if="index" class="my-5"></v-divider>
                    <v-row no-gutters align="center" class="mb-5">
                        <div class="font-weight-medium">
                            Зона {{ index + 1 }}
                            <span
                                :class="{
                  'green--text': el.active && el.id,
                  'error--text': !el.active && el.id,
                }"
                            >{{
                                    el.active ? " - Активна" : el.id ? " - Выключена" : ""
                                }}</span
                            >
                        </div>
                        <v-spacer></v-spacer>
                        <v-btn
                            v-if="el.id"
                            small
                            class="body-2 text-none mr-4"
                            depressed
                            @click="initDeactivateZone(el)"
                        >{{ el.active ? "Выключить зону" : "Включить зону" }}
                        </v-btn>
                        <v-btn
                            small
                            class="body-2 text-none white--text"
                            depressed
                            color="red"
                            @click="deleteZone(el)"
                        >Удалить зону доставки
                        </v-btn>
                    </v-row>
                    <v-row class="ma-0">
                        <v-text-field
                            v-model="el.name"
                            dense
                            hide-details
                            outlined
                            label="Наименование"
                            class="mr-4"
                        ></v-text-field>
                        <v-menu bottom offset-y :close-on-content-click="false">
                            <template v-slot:activator="{ on }">
                                <div
                                    style="
                    width: 40px;
                    height: 40px;
                    border: 1px solid #dedede;
                    cursor: pointer;
                    border-radius: 4px;
                  "
                                    :style="{ background: el.color }"
                                    v-on="on"
                                ></div>
                            </template>
                            <v-color-picker
                                dot-size="13"
                                hide-inputs
                                hide-mode-switch
                                mode="hexa"
                                swatches-max-height="200"
                                :value="el.color"
                                @input="setColor($event, el)"
                            ></v-color-picker>
                        </v-menu>
                    </v-row>
                    <v-row dense class="mt-4">
                        <v-col cols="6">
                            <v-text-field
                                v-model="el.delivery_minutes"
                                @input="hasError = Number($event) < 1"
                                dense
                                hide-details
                                outlined
                                label="Время доставки"
                                type="number"
                                :rules="[(v) => v > 0]"
                            >
                                <template v-slot:append>
                                    <span class="caption">минут</span>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                v-model="el.courier_reward_per_order"
                                dense
                                hide-details
                                outlined
                                label="Оплата курьеру за заказ"
                                type="number"
                            >
                                <template v-slot:append>
                                    <span class="caption">₽</span>
                                </template>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters align="center" class="my-5">
                        <div class="font-weight-medium">Настройка цен доставки</div>
                        <v-spacer></v-spacer>
                        <v-btn
                            small
                            class="body-2 text-none"
                            depressed
                            @click="
                el.delivery_price_settings.cart_prices.push({
                  delivery_price: 0,
                  start_cart_price: 0,
                })
              "
                        >Добавить
                        </v-btn>
                    </v-row>
                    <v-col
                        class="pa-0 mb-3"
                        v-for="(setting, settingIndex) in el.delivery_price_settings
              .cart_prices"
                        :key="settingIndex"
                    >
                        <v-row no-gutters align="center" class="flex-nowrap">
                            <v-text-field
                                v-model="setting.delivery_price"
                                dense
                                hide-details
                                outlined
                                label="Стоимость доставки"
                                class="mr-3"
                                type="number"
                            ></v-text-field>
                            <v-text-field
                                v-model="setting.start_cart_price"
                                dense
                                hide-details
                                outlined
                                class="mr-1"
                                label="От какой цены"
                                type="number"
                            ></v-text-field>
                            <v-btn
                                icon
                                @click="
                  el.delivery_price_settings.cart_prices.splice(settingIndex, 1)
                "
                                class="body-2 text-none"
                                depressed
                            >
                                <v-icon size="20px">mdi-close</v-icon>
                            </v-btn>
                        </v-row>
                    </v-col>
                </v-col>
            </v-col>
            <v-col cols="8">
                <div
                    style="width: 100%; height: calc(100vh - 250px); z-index: 0"
                    id="map"
                ></div>
            </v-col>
        </v-row>
        <v-dialog v-model="zoneDeactivateModal" width="370">
            <ConfirmDialogData
                @closeDialog="zoneDeactivateModal = false"
                @confirm="deactivateZone()"
                text="Вы подтверждаете действие?"
            >
                <date-picker
                    v-if="zoneToDeactivate?.active"
                    :value="notifyAboutDeactivated"
                    class="mt-4"
                    clearable
                    label="Когда напомнить о выключенной зоне?"
                    with-time
                    @change="notifyAboutDeactivated = $event"
                />
            </ConfirmDialogData>
        </v-dialog>
    </v-col>
</template>

<script>
import ConfirmDialogData from "@/admin/components/ConfirmDialogData.vue";

export default {
    name: "OutletItemDatabase",
    components: {ConfirmDialogData},
    data: () => ({
        drawnItems: [],
        items: [],
        zoneToDeactivate: null,
        zoneDeactivateModal: false,
        notifyAboutDeactivated: null,
        hasError: false,
    }),
    methods: {
        setColor(e, item) {
            item.color = typeof e === "object" ? e.hexa : e;
            this.drawPolygons();
        },
        drawPolygons() {
            this.drawnItems.clearLayers();
            for (const el of this.$deliveryArea.list) {
                const color =
                    el.outlet === this.item.id ? el.color || "#fab400" : "#676767";
                const layer = L.polygon(L.GeoJSON.coordsToLatLngs(el.coords), {
                    color,
                    _area: el,
                }).addTo(this.drawnItems);

                const area = layer.options._area;
                const outletId = area?.outlet;
                const outlet = this.$outlet.list.find((v) => v.id === outletId);
                if (outlet) {
                    layer.bindTooltip(
                        `<div>ТТ ${outlet.id} - ${outlet.address}</div>
        <div>Активна: ${area.active ? "Да" : "Нет"}</div>
        <div>Наименование: ${area.name || "Н/Д"}</div>
        <div>Время доставки: ${area.delivery_minutes + " мин" || "Н/Д"}</div>
        <div>Вознагражения курьеру: ${
                            area.courier_reward_per_order + " ₽" || "Н/Д"
                        }</div>`,

                        {
                            direction: "top",
                        }
                    );
                }

                // layer.on("click", (_) => this.onPolygonClick(layer));
                el.leafletId = this.drawnItems.getLayerId(layer);
            }
        },
        saveAreas() {
            this.$outlet
                .sendPutSingle("set_delivery_areas", this.$outlet.item.id, this.items)
                .then((res) => {
                    this.$store.commit("setSnackbar", {
                        color: "success",
                        text: "Зоны доставки успешно сохранены",
                    });
                });
        },
        addOutletToMap() {
            const layer = this.$outlet.generateMapLayer([this.$outlet.item]);
            this.$L.drawLayer(this, "outlet", layer, false);
        },
        initDeactivateZone(zone) {
            this.zoneToDeactivate = zone;
            this.zoneDeactivateModal = true;
        },
        deactivateZone() {
            if (!this.zoneToDeactivate) return;
            const data = {
                active: this.zoneToDeactivate,
                send_notify_at: undefined,
            };
            if (this.zoneToDeactivate.active) {
                data.active = false;
                data.send_notify_at = this.notifyAboutDeactivated || undefined;
            } else {
                data.active = true;
            }
            this.$deliveryArea
                .sendPutSingle("set_active", this.zoneToDeactivate.id, data)
                .then(() => {
                    this.zoneToDeactivate.active = data.active;
                    this.$store.commit("setSnackbar", {
                        color: "success",
                        text: "Успешно",
                    });
                    this.zoneToDeactivate = null;
                    this.zoneDeactivateModal = false;
                    this.notifyAboutDeactivated = null;
                })
                .catch(() => {
                    this.$store.commit("setSnackbar", {color: "red", text: "Ошибка"});
                });
        },
        deleteZone(zone) {
            let index = this.items.findIndex((v) => v.id === zone?.id);
            if (index > -1) this.items.splice(index, 1);
            index = this.$deliveryArea.list.findIndex((v) => v.id === zone?.id);
            if (index > -1) this.$deliveryArea.list.splice(index, 1);
            this.drawPolygons();
        },
        setItems(items = this.$deliveryArea.list) {
            this.items = items.filter((v) => v.outlet === this.item.id);
        },
    },
    computed: {
        // items() {
        //     return this.$deliveryArea.list.filter(v => v.outlet === this.item.id)
        // },
        item() {
            return this.$outlet.item
        }
    },
    async mounted() {
        await this.$deliveryArea.loadList({});
        this.$deliveryArea.list.forEach((v) => (v.color = v.color || "#fab400"));
        this.setItems();
        this.$L.initMap("map", [54.5223, 20.7039]);

        this.drawnItems = new L.FeatureGroup();
        this.$L.map.addLayer(this.drawnItems);

        this.$L.map.addControl(
            new L.Control.Draw({
                edit: {
                    featureGroup: this.drawnItems,
                },
                draw: {
                    marker: false,
                    circlemarker: false,
                    polyline: false,
                    circle: false,
                    rectangle: false,
                },
            })
        );

        this.$L.map.on(L.Draw.Event.CREATED, (e) => {
            if (e.layer.options) e.layer.options.color = "#fab400";
            this.drawnItems.addLayer(e.layer);
            const data = e.layer.toGeoJSON();
            const item = this.$deliveryArea.copy(this.$deliveryArea.default);
            item.outlet = this.$outlet.item?.id;
            item.color = "#fab400";
            item.coords = data.geometry.coordinates[0];
            item.leafletId = e.layer._leaflet_id;
            this.$deliveryArea.list.push(item);
            this.setItems();
        });
        this.$L.map.on(L.Draw.Event.EDITED, () => {
            for (const el of this.drawnItems.getLayers()) {
                const data = el.toGeoJSON();
                const currentItem = this.items.find(
                    (v) => v.leafletId === el._leaflet_id
                );
                if (currentItem) currentItem.coords = data.geometry.coordinates[0];
            }
        });
        this.$L.map.on(L.Draw.Event.DELETED, () => {
            const currentResults = [];
            for (const el of this.drawnItems.getLayers()) {
                currentResults.push(el._leaflet_id);
            }
            this.$deliveryArea.list = this.items.list.filter(
                (val) => val.leafletId && currentResults.indexOf(val.leafletId) > -1
            );
        });
        this.addOutletToMap();
        this.drawPolygons();
    },
};
</script>

<style scoped></style>
