<template>
    <v-row align="center">
        <v-col>
            <v-row no-gutters align="center" justify="center">
                <div class="font-weight-bold mb-5">Платежные сервисы</div>
            </v-row>
            <v-row no-gutters align="center" justify="center">
                <donat :values="paymentServices.values" :labels="paymentServices.labels"></donat>
            </v-row>
        </v-col>
        <v-col>
            <v-row no-gutters align="center" justify="center">
                <div class="font-weight-bold mb-5">Системы</div>
            </v-row>
            <v-row no-gutters align="center" justify="center">
                <donat :values="systems.values" :labels="systems.labels"></donat>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import Donat from "@/admin/views/analyitcs/Donat";

export default {
    name: 'DeviceVersions',
    components: {
        Donat
    },
    props: {
        ordersCreatedFrom: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        systems: {
            values: [],
            labels: [],
        },
        paymentServices: {
            values: [],
            labels: [],
        }
    }),
    watch: {
        ordersCreatedFrom() {
            this.systems.values = []
            this.systems.labels = []
            this.paymentServices.values = []
            this.paymentServices.labels = []
            if (!this.ordersCreatedFrom.systems) return
            for (const [key, value] of Object.entries(this.ordersCreatedFrom.systems)) {
                this.systems.values.push(value)
                this.systems.labels.push(key)
            }
            for (const [key, value] of Object.entries(this.ordersCreatedFrom.payment_services)) {
                this.paymentServices.values.push(value)
                this.paymentServices.labels.push(key)
            }
        },
    },
    methods: {},
}
</script>

<style scoped>

</style>