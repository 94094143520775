<template>
    <template-list
        :model="$productBlank"
        :show-create-button="$auth.hasRight('products__edit_information')"
    >
        <template v-slot="{ object, to }">
            <v-col class="pa-0">
                <router-link :to="to">
                    <div class="link font-weight-bold">{{ object.name }}</div>
                </router-link>
                <div class="text-caption">Срок хранения: {{ object.shelf_life }} ч</div>
            </v-col>
        </template>
    </template-list>
</template>

<script>
export default {
    name: "ProductBlankList",
    data: () => {
        return {};
    },
    methods: {}
};
</script>

<style scoped>
.text--success {
    color: #56c224;
}

.text--error {
    color: #bf533f;
}
</style>
