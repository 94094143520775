<template>
    <v-col class="pa-0 px-3">
        <v-col class="pa-0 py-3" slot="header">
            <div class="text-h4 font-weight-bold">Настройки экранов</div>
        </v-col>
        <v-col class="pa-0">
            <v-col class="pa-0" v-for="(el, index) in items" :key="index">
                <router-link :to="{name: el.name}">
                    <v-row no-gutters align="center" class="pa-2 black--text">
                        <v-icon class="mr-3">{{ el.icon }}</v-icon>
                        <div class="link body-2 font-weight-bold">{{ el.title }}</div>
                    </v-row>
                </router-link>
            </v-col>
        </v-col>
    </v-col>
</template>

<script>
export default {
    name: 'DisplayHome',
    data: () => ({
        items: [
            {name: 'displayDeviceList', icon: 'mdi-monitor', title: 'Экраны'},
            {name: 'displayLineList', icon: 'mdi-tray-full', title: 'Очереди'},
            {name: 'displayDeviceGroupList', icon: 'mdi-group', title: 'Группы устройств'},
            {name: 'displayContentList', icon: 'mdi-content-copy', title: 'Контент'},
        ]
    }),
    methods: {}
}
</script>

<style scoped>

</style>