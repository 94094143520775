import BaseModel from "../vendor/baseModel";

export class ProductGroup extends BaseModel {
    constructor() {
        super({
            url: 'groups',
            page_size: 'all',
        });
    }

    loadList(params) {
        return new Promise((resolve, reject) => {
            axios.get(`/${this.url}/full/`, {}).then(response => {
                const results = response.data
                this.list = results
                resolve(results)
            }).catch(() => {
                reject()
            })
        })
    }

    findProduct(id) {
        const group = this.list.find(group => {
            return group.products.findIndex(pr => pr.id === id) > -1
        })
        return group.products.find(pr => pr.id === id)
    }

    filterByPromo(promo) {
        const productIds = promo.products.map(e => e.id)
        return this.list.filter(g => {
            return g.products.findIndex(p => productIds.indexOf(p.id) > -1) > -1
        })
    }
}