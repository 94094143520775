<template>
  <v-col class="pa-3 pb-10">
    <v-row align="center" class="my-0" dense>
      <v-col cols="12" md="3">
        <date-picker
          v-model="startDate"
          class="rounded-lg"
          clearable
          label="Дата начала"
        ></date-picker>
      </v-col>
      <v-col cols="12" md="3">
        <date-picker
          v-model="endDate"
          class="rounded-lg"
          clearable
          label="Дата окончания"
        ></date-picker>
      </v-col>
      <v-col cols="12" md="3">
        <v-btn
          :loading="loading"
          class="rounded-lg"
          color="primary"
          depressed
          height="40"
          @click="loadAnalytics"
          >Обновить
        </v-btn>
      </v-col>
    </v-row>
    <v-col v-if="performanceData.totalCurrent" class="pa-0 mt-3">
      <v-checkbox
        v-model="buildPrevious"
        class="ma-0 pa-0"
        dense
        label="Сравнить с предыдущим периодом"
      ></v-checkbox>
      <div class="mb-1">
        Количество зарегистрированных пользователей:
        <PercentDifference
          :current="performanceData.totalCurrent.joined_users"
          :previous="
            performanceData.totalPrevious
              ? performanceData.totalPrevious.joined_users
              : undefined
          "
        ></PercentDifference>
      </div>
      <div class="mb-1">
        Количество созданных заказов:
        <PercentDifference
          :current="performanceData.totalCurrent.created_orders"
          :previous="
            performanceData.totalPrevious
              ? performanceData.totalPrevious.created_orders
              : undefined
          "
        ></PercentDifference>
      </div>
    </v-col>
    <performance :data="performanceData"></performance>
    <v-col class="pa-0">
      <v-col>
        <div class="body-2 font-weight-bold mb-5">Заказы из приложения</div>
        <performance-by-outlets
          :data="resultsMobile"
          :previous="resultsMobilePrevious"
        ></performance-by-outlets>
      </v-col>
      <v-col>
        <div class="body-2 font-weight-bold mb-5">Заказы из 1С</div>
        <performance-by-outlets
          :data="resultsSystem"
          :previous="resultsSystemPrevious"
        ></performance-by-outlets>
      </v-col>
    </v-col>
    <orders-created-from
      :orders-created-from="ordersCreatedFrom"
    ></orders-created-from>
    <v-row no-gutters>
      <v-col class="pa-0 mt-5" cols="5">
        <v-row align="center" class="ma-0">
          <div class="body-2 font-weight-bold mb-3 mr-4">
            Рейтинг торговых точек
          </div>
          <v-checkbox
            v-model="useDatesForOutletRating"
            dense
            label="Учитывать даты"
          />
        </v-row>
        <outlet-ratings></outlet-ratings>
      </v-col>
      <v-col class="pa-0" cols="7">
        <div class="body-2 font-weight-bold mb-5">Обновление устройств</div>
        <device-versions :results="versions"></device-versions>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import Performance from "@/admin/views/analyitcs/Performance";
import PerformanceByOutlets from "@/admin/views/analyitcs/PerformanceByOutlets";
import DeviceVersions from "@/admin/views/analyitcs/DeviceVersions";
import OutletRatings from "@/admin/views/analyitcs/OutletRatings";
import OrdersCreatedFrom from "@/admin/views/analyitcs/OrdersCreatedFrom";
import PercentDifference from "@/admin/views/analyitcs/PercentDifference.vue";

export default {
  name: "Analytics",
  components: {
    Performance,
    PerformanceByOutlets,
    DeviceVersions,
    OutletRatings,
    OrdersCreatedFrom,
    PercentDifference,
  },
  data: () => ({
    useDatesForOutletRating: true,
    startDate: null,
    endDate: null,
    buildPrevious: false,
    table: [],
    performanceData: {
      totalCurrent: null,
      totalPrevious: null,
      current: [],
      previous: [],
    },
    resultsMobile: [],
    resultsMobilePrevious: [],
    resultsSystem: [],
    resultsSystemPrevious: [],
    loading: false,
    ordersCreatedFrom: {
      payment_services: {},
      systems: {},
    },
    versions: {
      total: [],
      versions: [],
    },
  }),
  methods: {
    loadAnalytics() {
      this.loading = true;
      this.$analytics
        .sendPost("performance", {
          start_date: this.startDate,
          end_date: this.endDate,
          build_previous: this.buildPrevious,
        })
        .then((res) => {
          this.performanceData.totalCurrent = res.current.total;
          this.performanceData.totalPrevious = res.previous?.total || null;
          this.performanceData.current = res.current.results;
          this.performanceData.previous = res.previous?.results || [];
        });

      this.$analytics
        .sendPost("performance_by_outlets", {
          start_date: this.startDate,
          end_date: this.endDate,
          build_previous: this.buildPrevious,
        })
        .then((res) => {
          this.loading = false;
          const current = res.current;
          const previous = res.previous;
          this.resultsMobile = current.mobile_app;
          this.resultsSystem = current.accounting_system;
          this.resultsMobilePrevious = previous?.mobile_app || [];
          this.resultsSystemPrevious = previous?.accounting_system || [];
        });

      this.$analytics
        .sendPost("orders_created_from", {
          start_date: this.startDate,
          end_date: this.endDate,
        })
        .then((res) => {
          this.ordersCreatedFrom = res;
        });

      this.$analytics
        .sendPost("device_versions", {
          start_date: this.startDate,
          end_date: this.endDate,
        })
        .then((res) => {
          this.versions = res;
        });
      this.loadOutletsRates()

    },
    loadOutletsRates() {
      const outletRatingsFilters = this.useDatesForOutletRating ? {
        start_date: this.startDate,
        end_date: this.endDate,
      } : {};

      this.$analytics
          .sendPost("outlet_ratings", outletRatingsFilters)
          .then((res) => {
            this.$eventBus.$emit("loadedOutletsDate", res.outlet_ratings);
          });
    }
  },
  watch: {
    useDatesForOutletRating() {
      this.loadOutletsRates()
    }
  },
  created() {
    this.endDate = window.moment().local().format("YYYY-MM-DD");
    this.startDate = window
      .moment()
      .subtract(1, "month")
      .local()
      .format("YYYY-MM-DD");
    this.loadAnalytics();
  },
};
</script>

<style scoped></style>
