<template>
    <v-col class="pa-0">
        <div class="text-h4 font-weight-bold pa-3">Настройки</div>
        <v-divider></v-divider>
        <v-col class="pa-3">
            <v-row no-gutters align="center" class="my-1">
                <v-btn @click="$router.replace({name: 'settingsBase'})"
                       :color="$route.name === 'settingsBase' ? 'primary' : ''" depressed
                       class="mr-3 rounded-lg text-none body-2">Общая информация
                </v-btn>
                <v-btn @click="$router.replace({name: 'settingsLoyaltySystem'})"
                       :color="$route.name === 'settingsLoyaltySystem' ? 'primary' : ''" depressed
                       class="mr-3 rounded-lg text-none body-2">Система лояльности
                </v-btn>
                <v-btn @click="$router.replace({name: 'settingsDocuments'})"
                       :color="$route.name === 'settingsDocuments' ? 'primary' : ''"
                       depressed class="mr-3 rounded-lg text-none body-2">Документы
                </v-btn>
              <v-btn @click="$router.replace({name: 'settingsDelivery'})"
                     :color="$route.name === 'settingsDelivery' ? 'primary' : ''"
                     depressed class="mr-3 rounded-lg text-none body-2">Доставка
              </v-btn>
              <v-btn @click="$router.replace({name: 'settingsTerminal'})"
                     :color="$route.name === 'settingsTerminal' ? 'primary' : ''"
                     depressed class="mr-3 rounded-lg text-none body-2">Терминал
              </v-btn>
              <v-btn @click="$router.replace({name: 'settingsServiceApp'})"
                     :color="$route.name === 'settingsServiceApp' ? 'primary' : ''"
                     depressed class="mr-3 rounded-lg text-none body-2">Сервисное приложение
              </v-btn>
            </v-row>
            <router-view></router-view>
        </v-col>
    </v-col>
</template>

<script>
import CompanyPaymentServices from "@/admin/views/company/CompanyPaymentServices";

export default {
    name: 'Company',
    components: {
        CompanyPaymentServices
    },
    data: () => ({
        settings: null
    }),
    methods: {},
    computed: {
        title() {
            const values = ['Компании']
            if (this.$company.item) {
                values.push(this.$company.item.name)
            }
            return values.join(' / ')
        }
    },
    mounted() {
    }
}
</script>

<style scoped>

</style>