<template>
  <v-dialog
    v-model="modelValue"
    @click:outside="$emit('update:modelValue', false)"
    @close="$emit('update:modelValue', false)"
    max-width="450"
  >
    <v-card v-if="item" class="pa-5 rounded-lg" style="overflow-x: auto">
      <div class="font-weight-bold mb-5">Смена</div>
      <v-select
        v-model="item.user"
        :items="workers"
        :item-text="(v) => v.first_name"
        item-value="id"
        label="Работник"
        :disabled="item.id"
      />
      <v-select
        v-model="item.outlet"
        :items="$outlet.list"
        :item-text="(v) => `${v.number || 0}. ${v.address}`"
        item-value="id"
        label="ТТ"
        :disabled="item.id"
      />
      <v-row class="px-2 mt-0">
        <v-btn
          :disabled="!item.outlet || !item.user"
          :loading="loading"
          color="primary"
          @click="initShift()"
          >{{ item.id ? "Завершить смену" : "Сохранить" }}
        </v-btn>
        <v-spacer />
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: "InitShiftModal",
  data: () => {
    return {
      item: {},
      workers: [],
      loading: false
    }
  },
  props: {
    modelValue: Boolean,
  },
  emits: ["update:modelValue", 'shiftInited'],
  methods: {
    initShift() {
      this.loading = true
      this.$shift.sendPostSingle('start', undefined, {
        user: this.item.user,
        outlet: this.item.outlet
      }).then(v => {
        this.$emit('shiftInited', v.result)
        this.$emit('update:modelValue', false)
      }).catch(err => {}).finally(() => {
        this.loading = false
      })
    }
  },
  mounted() {

  },
  watch: {
    'modelValue'(v) {
      if (!v) return
      this.item = {outlet: this.$auth?.user?.outlets?.length === 1 ? this.$auth.user.outlets[0].id : undefined}
      this.$user.loadList({user_type: 4, page_size: 'all', without_active_shift: true}).then(v => {
        this.workers = v
      })
    }
  }
};
</script>

<style scoped></style>
