<template>
  <template-item
    content-classes="pa-0"
    :show-header="isItemPage"
    :title="title"
    :show-actions="false"
    :model="$airIngredient"
    :show-delete="false"
    v-slot="{ object }"
  >
    <v-row align="center" class="px-4 mb-6 mt-4" v-if="!isItemPage">
      <div class="text-h6 mr-3">Модификатор</div>
      <v-divider />
    </v-row>
    <v-col class="pa-0" :class="{ 'px-3 mt-3': isItemPage }">
      <v-row no-gutters align="center">
        <v-btn
          v-for="(route, index) in routes"
          :key="index"
          @click="$router.replace({ name: route.name })"
          :color="$route.name === route.name ? 'primary' : ''"
          depressed
          class="mr-3 rounded-lg text-none body-2"
          >{{ route.title }}
        </v-btn>
      </v-row>
      <router-view></router-view>
    </v-col>
  </template-item>
</template>

<script>
export default {
  name: "ProductItem",

  components: {},
  data: () => ({}),
  computed: {
    isItemPage() {
      return this.$route.matched.map((v) => v.name).includes("ingredientItem");
    },
    routes() {
      const items = [
        {
          name: "base",
          title: "Общая информация",
        },
        {
          name: "nutritional",
          title: "Пищевая ценность",
        },
        {
          name: "nomenclature",
          title: "Технологическая карточка",
        },
        {
          name: "prices",
          title: "Цены",
        },
      ];
      const append = this.isItemPage ? "ingredient" : "productIngredient";
      for (const el of items) {
        el.name = append + el.name[0].toUpperCase() + el.name.slice(1);
      }
      return items;
    },
    title() {
      if (this.isItemPage)
        return "Модификаторы / " + this.$airIngredient.item.vendor_name;
      const values = [];
      if (this.$product.item) {
        if (this.$product.item.group) {
          values.push(this.$product.item.group.vendor_name || "Отсутствует");
        }
        values.push(this.$product.item.vendor_name || "Отсутствует");
      }
      values.push(this.$airIngredient.item.vendor_name || "Отсутствует");
      return values.join(" / ");
    },
  },
  methods: {},
  created() {
    this.$ingredientNomenclature.loadList({
      ingredient: this.$airIngredient.item.id,
    });
  },
};
</script>
