import BaseModel from "../vendor/baseModel";

export class Promo extends BaseModel {

    creationLoading = false

    constructor() {
        super({
            url: 'promos',
            name: 'promo',
            page_size: 'all',
        });
    }
}
