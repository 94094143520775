const m = require('air-vue-model')()
m.url = 'display_device_groups'
m.name = 'displayDeviceGroup'

m.text = {
    listTitle: 'Группы устройств',
    itemTitle: 'Группа устройств',
}
m.pk = 'uuid'

m.default = {
    name: null,
    line: null
}

m.setPagination({
    page_size: 100
})

m.routes = [
    {
        name: 'list',
        component: require('@/admin/views/display/deviceGroups/DisplayDeviceGroups'),
    },
    {
        name: 'item',
        component: require('@/admin/views/display/deviceGroups/DisplayDeviceGroup'),
        single: true
    },
]

module.exports = m