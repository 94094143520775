const m = require('air-vue-model/auth')
const {userTypes} = require('@/airModels/user.js')
m.urls.login = '/jwt/create/'
m.urls.refresh = '/jwt/refresh/'

m.hasRight = (v) => {
    if (!v) return true
    if (m.user.is_superuser) return true
    const roles = m.user.user_roles
    for (const el of roles || []) {
        if (el.rights.includes(v)) return true
    }
    return false
}

m.getAvailableRoles = () => {
    return [...new Set(m.user.user_roles.flatMap(role => role.available_user_roles))]
}

m.getAvailableTypes = () => {
    const availableTypes = m.user.user_roles.flatMap(role => role.available_user_types.map(v => v.value))
    return userTypes.filter(v => availableTypes.includes(v.value))
}

module.exports = m