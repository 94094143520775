<template>
  <v-col v-if="model.text" slot="header">
    <div v-if="model.text.listTitle || title" class="text-h4 font-weight-bold">
      <template v-if="titlePrepend">{{titlePrepend}}</template>
      {{ title || model.text.listTitle }}
    </div>
  </v-col>
</template>

<script>
export default {
  name: "TemplateListHeader",
  props: {
    model: Object,
    title: String,
    titlePrepend: String,
  },
};
</script>
