<template>
  <template-list
    :model="$user"
    :show-create-button="$auth.hasRight('users__creating')"
    hide-items
    :auto-load-list="false"
  >
    <template v-slot:actions>
      <search
        v-model="$user.filters.search"
        :rounded="false"
        always
        class="mr-3"
      ></search>
      <UsersFilter @filter="loadList($event)" />
      <v-btn
        :loading="$user.loadings.list"
        class="rounded-lg text-none body-2 mr-3"
        color="primary"
        depressed
        height="40"
        @click="loadList"
        >Обновить
      </v-btn>
    </template>
    <template v-slot:items>
      <v-data-table
        :headers="headers"
        :items="$user.list"
        :items-per-page="-1"
        disable-sort
        hide-default-footer
        @click:row="
          (_, evt) =>
            $router.push({ name: 'userItem', params: { userId: evt.item.id } })
        "
      >
        <template
          v-for="header in headers"
          v-slot:[getHeaderSlotName(header.name)]
        >
          <CHover #default="{ hover }">
            <v-row
              class="ma-0"
              style="cursor: pointer; flex-wrap: nowrap"
              @click="setSortingByField(header.name)"
            >
              {{ header.text }}

              <v-icon
                :style="{
                  opacity: sortingData.some((v) => v.includes(header.name))
                    ? 1
                    : hover
                    ? 0.5
                    : 0,
                  transform: getTransformValue(header.name),
                }"
                class="ml-1"
                size="16px"
                >mdi-arrow-up
              </v-icon>
            </v-row>
          </CHover>
        </template>
        <template
          v-for="header in headers"
          v-slot:[getItemSlotName(header.name)]="{ value, item }"
        >
          <template v-if="['outlets', 'user_roles'].includes(header.name)">
            <v-tooltip :disabled="value.length < 2" bottom transition="none">
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="row text-truncate"
                  style="flex-wrap: nowrap; align-items: center"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{
                    value.length
                      ? value[0][header.name === "outlets" ? "address" : "name"]
                      : null
                  }}
                  <div
                    v-if="value.length > 1"
                    class="primary ml-2 text-caption white--text"
                    style="
                      border-radius: 10px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      padding: 4px 5px;
                      line-height: 100%;
                    "
                  >
                    +{{ value.length - 1 }}
                  </div>
                </div>
              </template>
              <div
                v-for="v in value
                  .slice(1)
                  .map(
                    (v) => v[header.name === 'outlets' ? 'address' : 'name']
                  )"
                class="caption"
              >
                {{ v }}
              </div>
            </v-tooltip>
          </template>
          <div v-else class="text-truncate">
            {{ getValue(header, value, item) }}
          </div>
        </template>
      </v-data-table>
    </template>
  </template-list>
</template>

<script>
import Search from "@/components/template/Search";
import CHover from "@/admin/components/CHover.vue";
import moment from "moment";
import LazySelect from "@/components/helpers/LazySelect.vue";
import UsersFilter from "@/admin/views/users/UsersFilter.vue";

export default {
  name: "Users",
  components: {
    UsersFilter,
    LazySelect,
    CHover,
    Search,
  },
  data: () => ({
    timeout: null,
    headers: [
      { text: "Телефон", name: "phone", value: "phone" },
      { text: "Имя", name: "first_name", value: "first_name" },
      {
        text: "Тип",
        name: "user_type",
        value: "user_type",
      },
      // {
      //   text: "Торговые точки",
      //   name: "outlets",
      //   value: "outlets",
      // },
      // { text: "Роли", name: "user_roles", value: "user_roles" },
      {
        text: "Доступ к ПА",
        name: "is_staff",
        value: "is_staff",
        format: (v) => (v ? "Да" : null),
      },
      {
        text: "Суперпользователь",
        name: "is_superuser",
        value: "is_superuser",
        format: (v) => (v ? "Да" : null),
      },
      {
        text: "Тестирование",
        name: "developer",
        value: "developer",
        format: (v) => (v ? "Да" : null),
      },
      {
        text: "Заблокирован",
        name: "is_disabled",
        value: "is_disabled",
        formatByItem: (v) => {
          if (!v.is_active) return "Да";
          if (moment.utc(v.disabled_until).isAfter(moment.utc()))
            return (
              "до " +
              moment.utc(v.disabled_until).local().format("DD.MM.YYYY HH:mm:ss")
            );
          return null;
        },
      },
      {
        text: "Дата регистрации",
        name: "date_joined",
        value: "date_joined",
        format: (v) =>
          v ? moment.utc(v).local().format("DD.MM.YYYY HH:mm:ss") : null,
      },
      {
        text: "Последний вход",
        name: "last_login",
        value: "last_login",
        format: (v) =>
          v ? moment.utc(v).local().format("DD.MM.YYYY HH:mm:ss") : null,
      },
      {
        text: "День рождения",
        name: "birthday",
        value: "birthday",
        format: (v) => (v ? moment(v).format("DD.MM.YYYY") : null),
      },
      {
        text: "Пол",
        name: "sex",
        value: "sex",
        format: (v) => {
          if (!v) return null;
          return v === "male" ? "Мужской" : "Женский";
        },
      },
      {
        text: "Баланс",
        name: "balance",
        value: "balance",
      },
      {
        text: "Количество заказов",
        name: "orders_amount",
        value: "orders_amount",
        formatByItem: (v) => v.order_details.amount,
      },
      {
        text: "Сумма заказов",
        name: "orders_sum",
        value: "orders_sum",
        formatByItem: (v) => v.order_details.total_sum,
      },
      {
        text: "Средний чек",
        name: "orders_avg",
        value: "orders_avg",
        formatByItem: (v) => v.order_details.avg_sum,
      },
    ],
    sortingData: [],
  }),
  methods: {
    loadList(params = {}) {
      this.$user.loadList(params);
    },
    getValue(header, value, item) {
      if (header.formatByItem) {
        return header.formatByItem(item);
      }
      if (header.format) {
        return header.format(value);
      }
      if (header.name === "user_type" && value) {
        return this.$user.userTypes.find((el) => el.value === value).title;
      }
      return value;
    },
    parseDate(date) {
      return window.moment.localDateTime(date);
    },
    getHeaderSlotName(v) {
      return `header.${v}`;
    },
    getItemSlotName(v) {
      return `item.${v}`;
    },
    getTransformValue(v) {
      const item = this.sortingData.find((el) => el.includes(v));
      if (!item) return "";
      if (item.startsWith("-")) return "rotate(0deg)";
      return "rotate(180deg)";
    },
    setSortingByField(v) {
      const index = this.sortingData.findIndex((el) => el.includes(v));
      if (index > -1) {
        const item = this.sortingData[index];
        if (item.startsWith("-")) {
          this.sortingData[index] = item.replace("-", "");
        } else {
          this.sortingData.splice(index, 1);
        }
      } else {
        this.sortingData.push(`-${v}`);
      }
      this.$user.filters.ordering = this.sortingData.length
        ? this.sortingData.join(",")
        : undefined;
      this.loadList();
    },
    formatDate(v, withDate = true) {
      const value = moment(v);
      if (withDate) return value.local().format("DD.MM.YYYY HH:mm:ss");
      else return value.format("DD.MM.YYYY");
    },
  },
  watch: {
    "$user.filter.search"(val) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.loadList();
      }, 300);
    },
  },
};
</script>

<style scoped>
:deep(tr) {
  cursor: pointer;
}
</style>
