const m = require('air-vue-model')()
m.url = 'lines'
m.name = 'line'

m.text = {
    listTitle: 'Линии', itemTitle: 'Компания',
}

m.setPagination({
    page_size: 'all'
})

m.routes = [
    {
        name: 'list',
        component: require('@/admin/views/lines/Lines'),
    }
]

module.exports = m