<template>
  <v-col
    class="pa-0 pt-1"
    v-if="
      !productIngredientGroup.show_when_ingredient_selected ||
      selectedIngredientIds.indexOf(
        productIngredientGroup.show_when_ingredient_selected
      ) > -1
    "
  >
    <!--    {{selectedIngredientIds}} | {{excludedIngredientIds}}-->
    <v-row no-gutters class="px-3 px-md-4" align="center">
      <div class="text-body-1 font-weight-bold text-md-subtitle-1 mr-2">
        {{ ingredientGroup.name || ingredientGroup.vendor_name }}
        <span class="text-caption">
          {{ selectedIngredientsByGroup.length }}/{{
            productIngredientGroup.max_amount
          }}
        </span>
      </div>
    </v-row>
    <v-row dense class="px-3 py-2">
      <ingredient-tile
        v-for="(productIngredient, index) in productIngredients"
        :key="index"
        :with-image="withImage"
        :product-ingredient="productIngredient"
        :product-ingredient-group="productIngredientGroup"
        :selected-ingredient-ids="selectedIngredientIds"
        :excluded-ingredient-ids="excludedIngredientIds"
        :disabled="isTileDisabled(productIngredient)"
      />
    </v-row>
  </v-col>
</template>

<script>
import IngredientTile from "./IngredientTile";

export default {
  name: "IngredientGroup",

  components: {
    IngredientTile,
  },

  props: {
    productIngredientGroup: Object,
    selectedIngredientIds: Array,
    excludedIngredientIds: Array,
    isProductSelected: Boolean,
    disabled: Boolean,
  },

  computed: {
    ingredientGroup() {
      return this.productIngredientGroup.ingredient_group;
    },

    productIngredients() {
      return this.productIngredientGroup.ingredients;
    },

    withImage() {
      for (let productIngredient of this.productIngredients) {
        if (productIngredient.ingredient.image) return true;
      }
      return false;
    },
    selectedIngredientsByGroup() {
      const ingredientsByGroup =
        this.productIngredientGroup.ingredients.flatMap((v) => v.ingredient);
      return ingredientsByGroup.filter((v) =>
        this.selectedIngredientIds.includes(v.id)
      );
    },
  },
  methods: {
    isTileDisabled(ingredient) {
      const selectedByGroup = this.selectedIngredientsByGroup;
      const maxAmount = this.productIngredientGroup.max_amount;
      if (selectedByGroup.length >= maxAmount) {
        return !this.selectedIngredientIds.find((v) => v === ingredient.ingredient.id);

      }
      return this.disabled;
    },
  },
};
</script>
