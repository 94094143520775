<template>
    <v-row
        align="center"
        justify="space-between"
        class="ma-0 px-3 pb-3"
    >
        <v-btn
            :loading="saveLoading"
            height="40"
            depressed
            class="rounded-lg"
            color="primary"
            @click="$emit('save')"
            :disabled="!form || deleteLoading"
        >
            <div class="text-none body-2 px-2">
                Сохранить
            </div>
        </v-btn>
        <v-btn
            depressed
            class="rounded-lg"
            outlined
            v-if="pk && showDelete"
            :disabled="saveLoading"
            :loading="deleteLoading"
            color="error"
            @click="$emit('delete')"
        >
            <div class="body-2 text-none">Удалить</div>
        </v-btn>
    </v-row>
</template>

<script>
import {mapState} from 'vuex'

export default {
    name: 'CrudOperations',

    props: {
        pk: null,
        saveLoading: Boolean,
        deleteLoading: Boolean,
        showDelete: {
            type: Boolean,
            default: true,
        }
    },

    computed: {
        ...mapState({
            form: state => state.form,
        })
    },
}
</script>
