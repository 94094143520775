<template>
  <template-list
    :model="$displayLine"
    :show-create-button="$auth.hasRight('displays__edit_information')"
    title-prepend="Настройки экранов / "
  >
    <template v-slot:actions></template>
    <template v-slot="{ object, to, index }">
      <router-link
        :to="{
          name: 'displayLineItem',
          params: { displayLineUuid: object.uuid },
        }"
      >
        <v-col class="pa-0 body-2 black--text font-weight-bold">
          <div class="link">{{ object.name }}</div>
          <div
            :class="object.active ? 'green--text' : 'red--text'"
            class="caption"
          >
            {{ object.active ? "Активно" : "Не активно" }}
          </div>
        </v-col>
      </router-link>
    </template>
  </template-list>
</template>

<script>
export default {
  name: "DisplayLines",
  data: () => ({}),
  methods: {
    parseDate(date) {
      return window.moment.localDateTime(date);
    },
  },
  created() {},
};
</script>

<style scoped></style>
