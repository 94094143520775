<template>
    <cool-light-box
            :items="items"
            :index="index"
            useZoomBar
            loop
            @close="index = null">
    </cool-light-box>
</template>

<script>
    import CoolLightBox from 'vue-cool-lightbox'
    import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

    export default {
        name: "LightBox",
        components: {
            CoolLightBox
        },
        data: () => ({
            images: [],
            index: null
        }),
        created() {
            this.$eventBus.$on('setImages', images => {
                this.images = images
            })
            this.$eventBus.$on('setIndex', (index) => {
                this.index = index
            })
        },
        computed: {
            items() {
                return this.images.map(value => value.image)
            }
        }
    }
</script>
