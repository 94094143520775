<template>
    <div>
        <div v-if="$store.state.outletQueueLoading">
            <v-progress-circular
                indeterminate size="10" width="1"
                color="primary"
            ></v-progress-circular>
        </div>
        <div v-else-if="currentData && currentData.queue_delta">
            <span class="text-caption">Очередь до: {{ currentData.queue_delta.full_format_end_date }}</span>
            <span style="border-radius: 6px"
                  :class="color"
                  class="white--text text-caption px-1 ml-2">{{ currentData.queue_delta.format_delta }}</span>

        </div>
    </div>
</template>

<script>
import Search from "@/components/template/Search";
import search from "air-vue-model/search";

export default {
    name: "OutletQueueData",
    props: {
        outletId: Number
    },
    computed: {
        currentData() {
            return this.$store.state.outletQueue.find(v => v.outlet === this.outletId)
        },
        color() {
            if (!this.currentData || !this.currentData.queue_delta || !this.$store.state.settings) return 'grey'
            const minutes = this.currentData.queue_delta.total_minutes
            if (minutes < 0) return 'grey'
            if (this.$store.state.settings.system_settings.big_queue_delay < minutes) return 'red'
            return 'success'
        }
    }
}
</script>

<style scoped>

</style>
