<template>
  <div>
    <v-btn
      :loading="exportLoading"
      class="rounded-lg text-none body-2 mr-3"
      color="primary"
      depressed
      height="40"
      outlined
      @click="exportData()"
    >
      Выгрузить
    </v-btn>
    <v-menu
      :close-on-content-click="false"
      max-height="80vh"
      max-width="400"
      min-width="400"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="rounded-lg text-none body-2 mr-3"
          color="primary"
          depressed
          height="40"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-filter</v-icon>
        </v-btn>
      </template>
      <v-list class="pt-4 px-3">
        <v-row align="center" class="ma-0" justify="space-between">
          <div class="subtitle-2">Фильтрация</div>
          <v-btn text x-small @click="clearFilter()">Сбросить фильтр</v-btn>
        </v-row>

        <v-text-field
          v-model="userFilters.phone"
          label="Номер телефона"
          v-bind="inputProps"
        />
        <v-text-field
          v-model="userFilters.first_name"
          label="Имя"
          v-bind="inputProps"
        />
        <v-select
          v-model="userFilters.user_types"
          :items="$user.userTypes"
          item-text="title"
          item-value="value"
          label="Тип"
          multiple
          v-bind="inputProps"
        />
        <lazy-select
          v-model="userFilters.outlets"
          :item-text="(el) => el.address"
          :items="$outlet.list"
          :lazy="false"
          :model="$outlet"
          item-value="id"
          label="Торговые точки"
          multiple
          preload
          set-to="list"
          v-bind="inputProps"
        />
        <lazy-select
          v-model="userFilters.user_roles"
          :item-text="(el) => el.name"
          :items="$userRole.list"
          :lazy="false"
          :model="$userRole"
          item-value="id"
          label="Роли"
          multiple
          preload
          set-to="list"
          v-bind="inputProps"
        />
        <v-select
          v-model="userFilters.is_staff"
          :items="booleanItems"
          item-text="title"
          item-value="value"
          label="Доступ к ПА"
          v-bind="inputProps"
        />
        <v-select
          v-model="userFilters.is_superuser"
          :items="booleanItems"
          item-text="title"
          item-value="value"
          label="Суперпользователь"
          v-bind="inputProps"
        />
        <v-select
          v-model="userFilters.developer"
          :items="booleanItems"
          item-text="title"
          item-value="value"
          label="Тестирование"
          v-bind="inputProps"
        />
        <v-select
          v-model="userFilters.is_blocked"
          :items="booleanItems"
          item-text="title"
          item-value="value"
          label="Пользователь заблокирован"
          v-bind="inputProps"
        />
        <v-select
          v-model="userFilters.sexes"
          :items="sexItems"
          item-text="title"
          item-value="value"
          label="Пол"
          multiple
          v-bind="inputProps"
        />
        <div class="mt-3 text--secondary">Дата регистрации</div>
        <v-row class="ma-0">
          <v-col class="pa-0" cols="6">
            <date-picker
              v-model="userFilters.start_date_joined"
              class="mr-1 body-2"
              label="С"
              v-bind="{ ...inputProps, class: undefined }"
            />
          </v-col>
          <v-col class="pa-0" cols="6">
            <date-picker
              v-model="userFilters.end_date_joined"
              class="ml-1 body-2"
              label="По"
              v-bind="{ ...inputProps, class: undefined }"
            />
          </v-col>
        </v-row>
        <div class="mt-3 text--secondary">Дата рождения</div>
        <v-row class="ma-0">
          <v-col class="pa-0" cols="6">
            <date-picker
              v-model="userFilters.from_birthday"
              class="mr-1 body-2"
              label="С"
              v-bind="{ ...inputProps, class: undefined }"
            />
          </v-col>
          <v-col class="pa-0" cols="6">
            <date-picker
              v-model="userFilters.to_birthday"
              class="ml-1 body-2"
              label="По"
              v-bind="{ ...inputProps, class: undefined }"
            />
          </v-col>
        </v-row>
        <div class="mt-3 text--secondary">Последний вход</div>
        <v-row class="ma-0">
          <v-col class="pa-0" cols="6">
            <date-picker
              v-model="userFilters.start_last_login"
              class="mr-1"
              label="С"
              v-bind="{ ...inputProps, class: undefined }"
            />
          </v-col>
          <v-col class="pa-0" cols="6">
            <date-picker
              v-model="userFilters.end_last_login"
              class="ml-1"
              label="По"
              v-bind="{ ...inputProps, class: undefined }"
            />
          </v-col>
        </v-row>
        <div class="mt-3 text--secondary">Количество заказов</div>
        <v-row class="ma-0">
          <v-col class="pa-0" cols="6">
            <v-text-field
              v-model="userFilters.from_orders_amount"
              class="mr-1"
              label="С"
              style="margin-top: 0 !important"
              v-bind="{ ...inputProps }"
            />
          </v-col>
          <v-col class="pa-0" cols="6">
            <v-text-field
              v-model="userFilters.to_orders_amount"
              class="ml-1"
              label="По"
              style="margin-top: 0 !important"
              v-bind="{ ...inputProps }"
            />
          </v-col>
        </v-row>
        <div class="mt-3 text--secondary">Сумма заказов</div>
        <v-row class="ma-0">
          <v-col class="pa-0" cols="6">
            <v-text-field
              v-model="userFilters.from_orders_sum"
              class="mr-1"
              label="С"
              style="margin-top: 0 !important"
              v-bind="{ ...inputProps }"
            />
          </v-col>
          <v-col class="pa-0" cols="6">
            <v-text-field
              v-model="userFilters.to_orders_sum"
              class="ml-1"
              label="По"
              style="margin-top: 0 !important"
              v-bind="{ ...inputProps }"
            />
          </v-col>
        </v-row>
        <div class="mt-3 text--secondary">Средний чек</div>
        <v-row class="ma-0">
          <v-col class="pa-0" cols="6">
            <v-text-field
              v-model="userFilters.from_orders_avg"
              class="mr-1"
              label="С"
              style="margin-top: 0 !important"
              v-bind="{ ...inputProps }"
            />
          </v-col>
          <v-col class="pa-0" cols="6">
            <v-text-field
              v-model="userFilters.to_orders_avg"
              class="ml-1"
              label="По"
              style="margin-top: 0 !important"
              v-bind="{ ...inputProps }"
            />
          </v-col>
        </v-row>
        <v-row
          align="center"
          class="mx-0 mb-0 mt-5 pb-2 pt-2"
          style="position: sticky; bottom: 0; background: white"
        >
          <v-btn color="primary" small @click="emitFilter()">Применить</v-btn>
        </v-row>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import LazySelect from "@/components/helpers/LazySelect.vue";

export default {
  name: "UsersFilter",
  components: {
    LazySelect,
  },
  emits: ["filter"],
  data: () => ({
    exportLoading: false,
    inputProps: {
      class: "project-search-input pa-0 rounded-lg mt-3 ",
      dense: true,
      hideDetails: true,
      outlined: true,
      rounded: true,
      style: "transition: 0.3s",
      clearable: true,
    },
    userFilters: {},
    booleanItems: [
      {
        title: "Да",
        value: true,
      },
      {
        title: "Нет",
        value: false,
      },
    ],
    sexItems: [
      {
        title: "Мужской",
        value: "male",
      },
      {
        title: "Женский",
        value: "female",
      },
    ],
    sortingData: [],
  }),
  mounted() {
    this.$emit("filter", this.getUserFilters());
  },
  methods: {
    emitFilter() {
      this.$store.state.userPageSavedFilters = this.userFilters;
      this.$emit("filter", this.getUserFilters());
    },
    clearFilter() {
      this.userFilters = {};
      this.$store.state.userPageSavedFilters = {};
      this.$user.filters = {detail_data: true}
      this.emitFilter();
    },
    getUserFilters() {
      const savedData = this.$store.state.userPageSavedFilters;
      if (Object.keys(savedData).length) this.userFilters = savedData;
      const keys = Object.keys(this.userFilters);
      const data = {};
      for (const key of keys) {
        const item = this.userFilters[key];
        if (Array.isArray(item)) {
          data[key] = item
            .map((v) =>
              this.isObject(v) ? this.getUserFilterObjectValue(v) : v
            )
            .join(",");
        } else if (this.isObject(item)) {
          data[key] = this.getUserFilterObjectValue(item);
        } else {
          data[key] = item;
        }
        const dataKeyValue = data[key];
        if (
          (Array.isArray(dataKeyValue) && !dataKeyValue.length) ||
          dataKeyValue === null
        )
          data[key] = undefined;
      }
      this.$user.filters = { ...this.$user.filters, ...data };
      return this.$user.filters;
    },
    getUserFilterObjectValue(item) {
      const keys = ["id", "value", "uuid"];
      let value = null;
      for (const key of keys) {
        const v = item[key];
        if (v) {
          value = v;
          break;
        }
      }
      return value || undefined;
    },
    isObject(item) {
      return item && typeof item === "object";
    },
    exportData() {
      this.exportLoading = true;
      this.$user
        .sendGetSingle("export", undefined, this.getUserFilters())
        .then((v) => {
          window.open(v.url, "_blank");
        })
        .finally(() => (this.exportLoading = false));
    },
  },
};
</script>

<style scoped></style>