const m = require('air-vue-model')()
m.url = 'outlet_service_logs'
m.name = 'outletServiceLog'
m.setPagination({
    page_size: 50
})

m.types = [
    {value: 1, title: 'Другое'},
    {value: 2, title: 'Вебсокет'},
    {value: 3, title: 'Загрузка списка заказов'},
]

m.filters = {
    type: null,
    user: null,
    outlet: null,
    text: null,
    mac: null,
    phone: null,
}

m.getTypeByValue = value => {
    return m.types.find(val => val.value === value)
}

m.text = {
    itemTitle: 'Сервисный лог торговой точки',
    listTitle: 'Сервисные логи торговых точек'
}
m.routes = [
    {
        name: 'list',
        component: require('@/admin/views/outletServiceLog/OutletServiceLogs'),
    },
]

module.exports = m