<template>
    <template-list :model="$nomenclature" :items="list" :showCreateButton="false">
        <template v-slot:actions>
            <search :rounded="false" always v-model="search"></search>
        </template>
        <template v-slot="{object, to, index}">
            <router-link :to="to" style="width: 100%" class="black--text">
                <v-row no-gutters align="center">
                    <v-col cols="4">
                        <div class="font-weight-bold mr-2 link">{{ object.name }}</div>
                        <span class="mr-2">Артикул: {{ object.vendor_code }}</span>
                    </v-col>
                    <v-col cols="4">
                        <div class="mr-2">{{ object.client_name }}</div>
                    </v-col>
                    <v-col cols="2">
                        <div class="mr-2">{{ getUnitName(object.unit) }}</div>
                    </v-col>
                    <v-col cols="2">
                        <v-row no-gutters align="center">
                            <v-icon size="15">mdi-bell</v-icon>
                            <div class="ml-2">{{ object.min_amount_for_notification || '-' }}</div>
                            <v-icon size="15" class="ml-3">mdi-lock-open</v-icon>
                            <div class="ml-2">{{ object.min_amount_for_order || '-' }}</div>
                        </v-row>
                    </v-col>
                </v-row>
            </router-link>
        </template>
    </template-list>
</template>

<script>

import Search from "@/components/template/Search";
import search from 'air-vue-model/search'

export default {
    name: 'NomenclatureList',
    components: {
        Search
    },
    data: () => ({
        search: null,
    }),
    methods: {
        getUnitName(v) {
            if (!v) return null
            return this.$store.state.units.find(u => u.name === v).title
        }
    },
    computed: {
        list() {
            return search(this.search, this.$nomenclature.list, v => v.name + v.client_name)
        }
    },
    created() {

    }
}
</script>

<style scoped>

</style>