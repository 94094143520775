const m = require('air-vue-model')()
m.url = 'line_product_groups'
m.name = 'lineProductGroup'

m.text = {
    listTitle: 'Группы товаров для линий', itemTitle: 'Группа товаров для линий',
}

m.setPagination({
    page_size: 'all'
})

m.default = {
    name: '',
    products: [],
    color: '#000000'
}

m.routes = [
    {
        name: 'list',
        component: require('@/admin/views/lineProductGroups/LineProductGroups'),
    },
    {
        name: 'item',
        single: true,
        component: require('@/admin/views/lineProductGroups/LineProductGroup'),
    }
]

module.exports = m