const m = require('air-vue-model')()
m.url = 'equipments'
m.name = 'equipment'
m.setPagination({
    page_size: 'all'
})

m.routes = [{
    name: 'list', component: require('@/admin/views/equipment/EquipmentList'),
}]

m.text = {
    listTitle: 'Список оборудования', itemTitle: 'Оборудование',
}

m.types = [
    {
        label: 'Гриль',
        value: 'grill'
    },
    {
        label: 'Кофе машина',
        value: 'coffee_machine'
    },
    {
        label: 'Печь',
        value: 'bake'
    },
    {
        label: 'Повар',
        value: 'cook'
    },
    {
        label: 'Фритюр',
        value: 'fryer'
    },
    {
        label: 'Продавец',
        value: 'cashier'
    },
    {
        label: 'Термопот',
        value: 'thermopot'
    },
]
module.exports = m