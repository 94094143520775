const m = require('air-vue-model')()
m.url = 'promos'
m.name = 'promo'
m.setPagination({
    page_size: 'all'
})
m.text = {
    itemTitle: 'Акция',
    listTitle: 'Акции'
}
m.default = {
    id: null,
    name: '',
    description: '',
    image: null,
    square_image: null,
    wide_image: null,
    sorting: 1,
    active: true,
    products: [],
}
m.routes = [
    {
        name: 'list',
        component: require('@/admin/views/promos/PromoList'),
    },
    {
        name: 'item',
        component: require('@/admin/views/promos/PromoItem'),
        single: true
    }
]

module.exports = m