<template>
    <v-dialog v-model="dialog" max-width="400">
        <v-card class="pa-3">
            <v-row no-gutters align="center" justify="center">
                <div class="text-subtitle-1" v-text="title"/>
            </v-row>
            <v-row no-gutters class="mt-3" align="center" justify="center">
                <v-btn outlined @click="dialog = false">
                    Отменить
                </v-btn>
                <div class="mx-2"/>
                <v-btn color="primary" @click="confirm">
                    Ок
                </v-btn>
            </v-row>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: "ConfirmationDialog",

        data: () => ({

            title: null,
        }),

        methods: {
            confirm() {
                this.$eventBus.$emit('confirm')
                this.dialog = false
            },
        },

        created() {
            this.$eventBus.$on('showConfirmationDialog', data => {
                this.title = data.title
                this.dialog = true
            })
        },
    }
</script>
