<template>
  <template-list
    :model="$promo"
    :show-create-button="$auth.hasRight('promo__edit_information')"
    :filter-list="(item) => showHidden ? true : item.active"
  >
    <template v-slot:actions>
      <v-checkbox
        label="Показать скрытые"
        v-model="showHidden"
        hide-details
        class="mr-4 mt-0"
      />
    </template>
    <template v-slot="{ object, to, index }">
      <v-col class="pa-0">
        <router-link :to="to">
          <v-row no-gutters align="center">
            <v-img
              max-width="30"
              height="30"
              class="rounded"
              aspect-ratio="1"
              cover
              :src="object.image || '/images/not-found.png'"
            />
            <div class="text-subtitle-1 link font-weight-bold ml-3">
              {{ object.name }}
            </div>
            <v-spacer />
            <div
              class="mr-1 text-caption"
              :class="{
                'text--error': !object.active,
                'text--success': object.active,
              }"
            >
              {{ object.active ? "Активна" : "Закрыта" }}
            </div>
          </v-row>
        </router-link>
      </v-col>
    </template>
  </template-list>
</template>

<script>
export default {
  name: "PromoList",
  data: () => {
    return {
      showHidden: false,
    };
  },
};
</script>

<style scoped>
.text--success {
  color: #56c224;
}

.text--error {
  color: #bf533f;
}
</style>
