<template>
  <div style="position: relative">
    <v-row style="width: 100%" class="ma-0 px-2" justify="space-between">
      <div class="text-h5">Отзывы</div>
      <v-menu offset-y min-width="450px" max-width="450px" :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" width="40" min-width="40" depressed v-on="on">
            <v-icon>mdi-filter</v-icon>
          </v-btn>
        </template>
        <v-list class="pt-4 px-3">
          <v-row align="center" class="ma-0 mb-2" justify="space-between">
            <div class="subtitle-2">Фильтрация</div>
            <v-btn text x-small @click="clearFilter()">Сбросить фильтр</v-btn>
          </v-row>
          <v-text-field
            class="rounded-lg"
            v-model="$orderReview.filters.user_phone"
            label="Номер телефона"
            outlined
            hide-details
            dense
          ></v-text-field>
          <date-picker
            class="rounded-lg"
            label="Дата начала"
            clearable
            v-model="$orderReview.filters.start_date"
            v-bind="inputProps"
          ></date-picker>
          <date-picker
            class="rounded-lg"
            label="Дата конца"
            clearable
            v-model="$orderReview.filters.end_date"
            v-bind="inputProps"
          ></date-picker>
          <v-select
            class="rounded-lg"
            :items="outletItems"
            item-value="id"
            :item-text="(v) => v.number + '. ' + v.address"
            v-model="$orderReview.filters.outlet"
            label="Торговая точка"
            v-bind="inputProps"
          ></v-select>
          <v-select
            label="Статус"
            :items="$orderReview.statuses"
            item-text="label"
            item-value="value"
            v-bind="inputProps"
            v-model="$orderReview.filters.status"
          />
          <v-row class="ma-0 mt-3" style="gap: 10px">
            <v-text-field
              class="rounded-lg"
              v-model="$orderReview.filters.rating_gte"
              label="Рейтинг от"
              outlined
              hide-details
              dense
              clearable
              style="width: 208px"
            ></v-text-field>
            <v-text-field
              class="rounded-lg"
              v-model="$orderReview.filters.rating_lte"
              label="Рейтинг до"
              outlined
              hide-details
              dense
              clearable
              style="width: 208px"
            ></v-text-field>
          </v-row>
          <lazy-select
              v-model="$orderReview.filters.tags"
              :item-text="(val) => val.title + ` (${val.group?.title || 'Без группы'})`"
              :lazy="false"
              :model="$orderReviewTag"
              class="mt-3 rounded-lg"
              clearable
              label="Группы"
              multiple
              preload
              :after-items-load="(res) => {
                $orderReviewTag.list = res.sort((a,b) => (b.group?.id || 0 - a.group?.id || 0))
              }"
              set-to="list"
          ></lazy-select>

          <v-select
            label="Сортировать по"
            v-bind="inputProps"
            v-model="$orderReview.filters.ordering"
            :items="[
              { label: 'Дата', value: '-created_at' },
              { label: 'Рейтинг', value: '-rating' },
            ]"
            item-text="label"
            item-value="value"
          />
          <v-checkbox
            v-if="$auth.hasRight('service_app__read_messages')"
            v-model="$orderReview.filters.with_comment"
            label="С отзывом"
            hide-details
          />
          <v-checkbox
            v-if="$auth.hasRight('service_app__read_messages')"
            v-model="$orderReview.filters.has_unread_messages"
            label="С непрочитанными сообщениями"
            hide-details
          />
          <v-btn color="primary" small @click="loadList()" class="mt-5"
            >Применить
          </v-btn>
        </v-list>
      </v-menu>
    </v-row>
    <v-divider class="mt-2" />
    <v-col
      class="pa-0"
      style="height: calc(100vh - 150px); overflow: scroll"
      id="reviews-scroller"
      ref="scroller"
    >
      <v-col
        v-for="(item, index) in items"
        :key="item.id"
        class="px-3 py-0"
        style="cursor: pointer"
        :style="{
          background: item.unread_messages_count
            ? 'rgb(255, 247, 227)'
            : 'white',
        }"
        @click="
          $auth.hasRight('service_app__read_messages')
            ? $emit('clicked', item)
            : void 0
        "
      >
        <v-divider v-if="index" class="m-1" />
        <v-col class="px-0 py-2">
          <v-row class="ma-0">
            <div
              :class="{ 'primary--text': activeItem?.id === item.id }"
              class="body-2 font-weight-bold"
            >
              {{ item.order.daily_number }}
            </div>
            <v-spacer />
            <v-chip
              :color="$orderReview.statusDataByStatus[item.status].color"
              :text-color="
                $orderReview.statusDataByStatus[item.status].textColor
              "
              class="px-2 mr-3"
              x-small
            >
              {{ $orderReview.statusDataByStatus[item.status].label }}
            </v-chip>
            <div class="caption">{{ parseDate(item.created_at) }}</div>
          </v-row>
          <v-row class="ma-0">
            <v-col class="pa-0" cols="9">
              <v-row class="pa-0 ma-0 body-2">
                ТТ: {{ item.order.outlet.number }}.
                {{ item.order.outlet.address }}
              </v-row>
              <v-row class="pa-0 ma-0 body-2">
                Оценка:
                <span
                  :style="{ color: $orderReview.color[item.rating] }"
                  class="ml-1 font-weight-bold"
                  >{{ item.rating }}</span
                >
                <v-icon
                  class="ml-1"
                  style="margin-top: -1px"
                  :color="$orderReview.color[item.rating]"
                  size="17px"
                  >mdi-star
                </v-icon>
              </v-row>
              <v-row
                class="pa-0 ma-0 body-2 mt-1"
                v-if="
                  item.last_message_data?.text &&
                  $auth.hasRight('service_app__read_messages') &&
                  item.status !== $orderReview.statusEnum.CLOSED
                "
              >
                <div>
                  <span class="font-weight-bold">{{
                    item.last_message_data.author
                      ? item.last_message_data.user_data?.first_name
                      : "Сервисное сообщение"
                  }}</span
                  >: {{ item.last_message_data.text }}
                </div>
              </v-row>
              <v-row
                class="pa-0 ma-0 body-2 mt-1"
                v-if="
                  item.status === $orderReview.statusEnum.CLOSED &&
                  item.decision
                "
              >
                <div>
                  <span class="font-weight-bold">Решение:</span>
                  {{ item.decision }}
                </div>
              </v-row>
              <v-row
                class="pa-0 ma-0 mt-1"
                style="gap: 5px"
                v-if="item.tags.length"
              >
                <v-chip
                  v-for="tag in item.tags"
                  :key="tag.id"
                  :color="tag.color || 'primary'"
                  small
                  >{{ tag.title }}</v-chip
                >
              </v-row>
            </v-col>
            <v-col
              style="position: relative"
              v-if="$auth.hasRight('service_app__read_messages')"
            >
              <div
                v-if="item.unread_messages_count"
                style="
                  position: absolute;
                  right: 10px;
                  background: #f0ad00;
                  border-radius: 8px;
                  height: 25px;
                  min-width: 25px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
                class="white--text px-1"
              >
                {{ item.unread_messages_count }}
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-col>
      <div
        v-if="$orderReview.loadings.list"
        :style="{
          bottom: $orderReview.list.length ? '10px' : '50%',
        }"
        style="
          position: absolute;
          background: white;
          padding: 10px;
          left: 50%;
          transform: translateX(-50%);
        "
      >
        <v-progress-circular indeterminate color="primary" />
      </div>
    </v-col>
  </div>
</template>

<script>
import LazySelect from "@/components/helpers/LazySelect.vue";
import moment from "moment";
import { cloneDeep } from "lodash";

export default {
  name: "OrderReviews",
  components: { LazySelect },
  props: {
    items: Array,
    activeItem: Object,
  },
  emits: ["clicked", "loadNextPage"],
  data: () => {
    return {
      scrollListenerHandler: null,
      inputProps: {
        class: "project-search-input pa-0 rounded-lg mt-3 ",
        dense: true,
        hideDetails: true,
        outlined: true,
        rounded: true,
        style: "transition: 0.3s",
        clearable: true,
      },
    };
  },
  methods: {
    parseDate(date) {
      return window.moment.localDateTime(date);
    },
    clearFilter() {
      this.$orderReview.filters = { ordering: "-created_at" };
      this.loadList();
    },
    loadList() {
      const startDate = this.$orderReview.filters.start_date;
      const endDate = this.$orderReview.filters.end_date;
      if (startDate && startDate.split(" ").length < 2) {
        this.$orderReview.filters.start_date = moment(startDate + " 00:00:00")
          .utc()
          .format("YYYY-MM-DD HH:mm:ss");
      }
      if (endDate && endDate.split(" ").length < 2) {
        this.$orderReview.filters.end_date = moment(endDate + " 23:59:59")
          .utc()
          .format("YYYY-MM-DD HH:mm:ss");
      }
      this.$eventBus.$emit("loadOrderReviews");
    },
  },
  mounted() {
    this.scrollListenerHandler = (e) => {
      if (!e.srcElement) return;
      const el = e.srcElement;
      const currentPos = Number((el.scrollTop + el.clientHeight).toFixed(0));
      const height = el.scrollHeight;
      if (currentPos + 50 > height) {
        this.$emit("loadNextPage");
      }
    };
    this.$refs.scroller.addEventListener("scroll", this.scrollListenerHandler);
    this.$outlet.loadList();
  },

  beforeDestroy() {
    this.$refs.scroller.removeEventListener(
      "scroll",
      this.scrollListenerHandler
    );
  },
  computed: {
    outletItems() {
      const res = cloneDeep(this.$outlet.list);
      res.forEach((v) => (v.disabled = undefined));
      return res;
    },
  },
};
</script>

<style scoped>
.active {
  background: #000;
}
</style>
