<template>
    <div class="fill-height">
        <product-dialog/>
        <router-view v-if="$store.state.ready"/>
        <v-bottom-navigation app shift color="primary" class="elevation-2">
            <v-btn value="menu" :to="{name: 'products'}">
                <span>Меню</span>

                <v-icon>mdi-silverware-fork-knife</v-icon>
            </v-btn>

            <v-btn value="map" :to="{name: 'outlets'}">
                <span>Карта</span>

                <v-icon>mdi-map-marker</v-icon>
            </v-btn>

            <div class="fill-height" style="position:relative" @click="$router.push({name: 'cart'})">
                <div v-if="cartSum > 0"
                     :style="$route.name === 'cart' ? 'top: -8px; left: 50px': 'top: 0; left: 50px'"
                     style="position:absolute; background: orange; z-index: 10;"
                     class="text-body-2 font-weight-medium rounded-lg white--text px-2 text-no-wrap transition-fast-in-fast-out">
                    {{ cartSum }} ₽
                </div>
                <v-btn value="cart">
                    <span>Корзина</span>
                    <v-icon>mdi-cart</v-icon>
                </v-btn>
            </div>

            <v-btn value="user" :to="{name: 'account'}">
                <span>Кабинет</span>

                <v-icon>mdi-account</v-icon>
            </v-btn>
        </v-bottom-navigation>
    </div>
</template>

<script>

import ProductDialog from "../components/productCard/ProductDialog";

export default {
    name: 'Home',
    components: {
        ProductDialog,
    },
    computed: {
        cartSum() {
            return this.$store.state.cartItems.reduce((acc, item) => acc + this.countPrice(item), 0)
        },
    },
    methods: {
        countPrice(cartItem) {
            return this.$product.countTotalPrice(cartItem.productIngredients, cartItem.amount)
        },

        account() {
            const user = this.$store.getters.currentUser
            if (user) {
                this.$router.push({name: 'account'})
            } else {
                this.$store.state.authModal = true
            }
        },
    },
}
</script>
