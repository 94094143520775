const m = require('air-vue-model')()
m.url = 'outlet_delivery_areas'
m.name = 'deliveryArea'

m.default = {
    outlet: null,
    name: null,
    color: null,
    coords: [],
    delivery_minutes: 15,
    courier_reward_per_order: 0,
    delivery_price_settings: {
        cart_prices: []
    },
    leafletId: null
}

module.exports = m