<template>
    <template-item
        :back-route="{name: 'outletList'}"
        :title="title"
        :model="$outlet"
        :show-actions="false"
        :show-delete="false"
    >

        <v-row no-gutters align="center" style="gap: 8px">
            <v-btn v-for="(el, index) in routes" :key="index"
                   @click="$router.replace({name: el.route})"
                   :color="$route.matched.map(v => v.name).includes(el.route) ? 'primary' : undefined"
                   depressed
                   class="rounded-lg text-none body-2 px-3"
            >{{ el.name }}
            </v-btn>
        </v-row>
        <router-view></router-view>
    </template-item>
</template>

<script>
import OutletItemBase from "@/admin/views/outlets/OutletItemBase";
import OutletItemDatabase from "@/admin/views/outlets/OutletItemDatabase";
import OutletItemDeliveryAreas from "@/admin/views/outlets/OutletItemDeliveryAreas";
import OutletItemEquipments from "@/admin/views/outlets/OutletItemEquipments";
import OutletItemNomenclature from "@/admin/views/outlets/OutletItemNomenclature";
import OutletItemLines from "@/admin/views/outlets/OutletItemLines";
import OutletTrackerQueue from "@/admin/views/outlets/OutletTrackerQueue";
import OutletDeliverySettings from '@/admin/views/outlets/OutletDeliverySettings.vue'
import AfterOrderReview from "@/admin/views/outlets/AfterOrderReview.vue";
import OutletPosts from "@/admin/views/outlets/CookStations.vue";

export default {
    name: "Outlet",
    components: {
        OutletPosts,
        AfterOrderReview,
        OutletDeliverySettings,
        OutletTrackerQueue,
        OutletItemBase,
        OutletItemDatabase,
        OutletItemDeliveryAreas,
        OutletItemEquipments,
        OutletItemNomenclature,
        OutletItemLines,
    },

    data: () => ({
        tab: "base",
        routes: [
            {
                name: 'Общая информация',
                route: 'outletBase'
            },
            {
                name: 'Зоны доставки',
                route: 'outletDeliveryAreas'
            },
            {
                name: 'Настройки доставки',
                route: 'outletDeliverySettings'
            },
            {
                name: 'Оборудование',
                route: 'outletEquipments'
            },
            {
                name: 'Остатки',
                route: 'outletNomenclature'
            },
            {
                name: 'База данных',
                route: 'outletDatabase'
            },
            {
                name: 'Линии',
                route: 'outletLines'
            },
            {
                name: 'Посты',
                route: 'outletCookStation'
            },
            {
                name: 'Очередь на трекере',
                route: 'outletQueue'
            },
            {
                name: 'Экраны',
                route: 'displayDeviceList'
            },
            {
                name: 'Информация после оценки заказа',
                route: 'outletAfterOrderReview'
            },
        ]
    }),
    mounted() {
        this.$displayDevice.filters.outlet = this.$outlet.item.id;
    },
    created() {
        this.$equipmentConfig.loadList({
            outlet: this.$outlet.item.id,
        });
        this.$ingredientOutletDetail.loadList({
            outlet: this.$outlet.item.id,
        });
    },
    computed: {
        title() {
            const values = [];
            values.push(this.$outlet.text.itemTitle);
            if (this.$outlet.item) {
                values.push(
                    this.$outlet.item.number + ". " + this.$outlet.item.address
                );
            }
            return values.join(" / ");
        },
    },

    methods: {

    },
};
</script>
