<template>
  <v-col class="pa-3 pb-10">
    <v-row align="center" class="my-0" dense>
      <v-select
        v-model="equipment"
        :item-text="(v) => v.name || '-'"
        :items="$equipment.list"
        class="rounded-lg mr-2"
        clearable
        dense
        hide-details
        item-value="id"
        label="Оборудование"
        outlined
        style="max-width: 250px"
      ></v-select>
      <date-picker
        v-model="startDate"
        :max="maxStartDate"
        :min="minStartDate"
        class="rounded-lg mr-2"
        clearable
        label="Дата начала"
        style="max-width: 220px"
      ></date-picker>
      <date-picker
        v-model="endDate"
        :max="maxEndDate"
        :min="startDate"
        class="rounded-lg mr-2"
        clearable
        label="Дата окончания"
        style="max-width: 220px"
      ></date-picker>
      <v-btn
        :disabled="!isActionAvailable"
        :loading="loading"
        class="rounded-lg"
        color="primary"
        depressed
        height="40"
        @click="generate()"
        >Сформировать
      </v-btn>
    </v-row>
    <v-row align="center" class="my-0" dense>
      <v-checkbox
        v-model="disabledEquipment"
        class=""
        label="Внеплановые блокировки"
      ></v-checkbox>
      <v-checkbox
        v-model="equipmentConfig"
        class="ml-3"
        label="Сервисное обслуживание"
      ></v-checkbox>
    </v-row>
    <div v-if="reportData" style="overflow-x: auto">
      <table class="subtitle-2 lel">
        <tr>
          <th>Точка продаж</th>
          <th v-for="(day, index) in reportData.outlets[0].days" :key="index">
            {{ parseDate(day.date) }}
          </th>
        </tr>
        <tr v-for="outlet in reportData.outlets" :key="outlet.id">
          <td style="min-width: 280px">
            {{ outlet.outlet.number || "БН" }}. {{ outlet.outlet.address }}
          </td>
          <td
            v-for="(day, index) in outlet.days"
            :key="index"
            :class="{ 'cursor-pointer text-center': day.values.length }"
            :style="`background-color:${
              day.values.length ? 'rgba(255,127,127,0.6)' : ''
            }`"
            @click="dayClick(day, outlet)"
          >
            {{ day.values.length || undefined }}
          </td>
        </tr>
      </table>
    </div>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card v-if="currentItem" class="pa-5">
        <h2>История блокировок: {{ parseDate(currentItem.date) }}</h2>
        <div>ТТ {{currentOutlet.number}}. {{ currentOutlet.address }}</div>
        <div
          class="mt-4"
          style="display: flex; flex-direction: column; gap: 10px"
        >
          <template v-for="(item, index) in currentItem.values">
            <div
              v-if="index"
              style="height: 1px; background-color: #ddd; width: 100%"
            ></div>
            <div style="display: flex; gap: 4px">
              <div class="subtitle-1" style="font-weight: 600">
                {{ index + 1 }}.
              </div>
              <div
                class="subtitle-2"
                style="display: flex; flex-direction: column"
              >
                <div>
                  <span style="font-weight: 600">Период: </span
                  >{{ parseTime(item.start_date) }} -
                  {{ item.end_date ? parseTime(item.end_date) : 'По настоящее время' }}
                </div>
                <div>
                  <span style="font-weight: 600">Причина: </span
                  >{{ item.reason ? item.reason.text : "Не указана" }}
                </div>
              </div>
            </div>
          </template>
        </div>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
import moment from "moment/moment";
import { cloneDeep } from "lodash";

export default {
  name: "BlockedEquipmentAnalytics",
  components: {},
  data: () => ({
    equipment: null,
    startDate: null,
    endDate: null,
    loading: false,
    disabledEquipment: false,
    equipmentConfig: false,
    reportData: null,
    currentItem: null,
    currentOutlet: null,
    dialog: false,
  }),
  async mounted() {
    this.loadEquipments();
  },
  methods: {
    loadEquipments() {
      this.$equipment.loadList();
    },
    dayClick(day, outlet) {
      if (!day.values.length) return;
      this.currentItem = day;
      this.currentOutlet = outlet.outlet;
      this.dialog = true;
    },
    parseDate(date) {
      const _date = cloneDeep(date);
      return moment(_date).format("DD.MM.YYYY");
    },
    parseTime(time) {
      return moment.utc(time).local().format("HH:mm");
    },
    generate() {
      const keys = [];
      if (this.disabledEquipment) keys.push("disabled_equipment_history");
      if (this.equipmentConfig) keys.push("equipment_config_service");
      this.loading = true;
      this.$analytics
        .sendGet("locked_equipments", {
          equipment: this.equipment,
          start_date: this.startDate,
          end_date: this.endDate,
          keys: keys.length ? keys.join(",") : undefined,
        })
        .then((res) => {
          this.loading = false;
          this.reportData = res;
        });
    },
  },
  computed: {
    isActionAvailable() {
      return this.equipment && this.startDate && this.endDate;
    },
    today() {
      return new Date().toISOString().substr(0, 10);
    },
    minStartDate() {
      if (this.endDate) {
        return moment(this.endDate).add(-31, "days").format("YYYY-MM-DD");
      } else {
        return undefined;
      }
    },
    maxStartDate() {
      return this.endDate ? this.endDate : this.today;
    },
    maxEndDate() {
      if (this.startDate) {
        const thresholdUpDate = moment(this.startDate).add(31, "days");
        if (thresholdUpDate <= moment(this.today)) {
          return thresholdUpDate.format("YYYY-MM-DD");
        } else {
          return this.today;
        }
      } else {
        return this.today;
      }
    },
  },
};
</script>

<style>
.lel {
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
}
</style>
