<template>
    <v-row class="mt-1 mb-3">
        <v-btn
            @click="$router.replace({ name: 'productProductIngredientGroupItem' })"
            height="40"
            depressed
            class="rounded-lg ml-3"
            :color="$route.name === 'productProductIngredientGroupItem' ? 'primary' : ''"
        >
            <div class="text-none body-2">Группа модификаторов в блюде</div>
        </v-btn>
        <v-btn
            height="40"
            depressed
            class="rounded-lg ml-3"
            @click="$router.replace({ name: 'productProductIngredientGroupOutlets' })"
            :color="$route.name === 'productProductIngredientGroupOutlets' ? 'primary' : ''"
        >
            <div class="text-none body-2">Торговые точки</div>
        </v-btn>
        <v-btn
            height="40"
            depressed
            class="rounded-lg ml-3"
            @click="$router.replace({ name: 'productIngredientGroup', params: {ingredientGroupId: $productIngredientGroup.item.ingredient_group.id} })"
            :color="$route.name === 'productIngredientGroup' ? 'primary' : ''"
        >
            <div class="text-none body-2">Группа модификаторов</div>
        </v-btn>
    </v-row>
</template>

<script>
export default {
    name: "ProductIngredientGroupTabs",
};
</script>
