<template>
    <span>
        <span class="font-weight-bold">{{ current }}</span>
        <v-tooltip bottom v-if="previous !== undefined">
            <template v-slot:activator="{ on, attrs }">
                <span class="font-weight-bold ml-2" v-on="on"
                      :class="{'green--text': percent > 0, 'red--text': percent < 0}"
                      v-if="percent"><span v-if="percent > 0">+</span>{{ percent }}%</span>
                <span v-else class="ml-2" v-on="on">-</span>
            </template>
            <span>{{ previous }}</span>
            </v-tooltip>
    </span>
</template>

<script>

export default {
    name: "Analytics",
    props: {
        previous: Number,
        current: Number
    },
    data: () => ({}),
    computed: {
        percent() {
            if (!this.current) return 0
            return ((this.current - this.previous) / this.current * 100).toFixed()
        }
    }
}
</script>

<style scoped>

</style>