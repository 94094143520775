<template>
    <v-dialog
        :value="value"
        max-width="500"
        @close="$emit('input', false)"
        @click:outside="$emit('input', false)"
    >
        <v-card class="rounded-lg">
            <div class="pa-3 font-weight-medium">Трек по заказу {{ order.daily_number }}</div>
            <v-row no-gutters align="center" justify="center" class="pa-5" v-if="loading">
                <v-progress-circular width="2" v-if="loading" indeterminate color="primary"></v-progress-circular>
            </v-row>
            <v-col class="pa-0" v-else>
                <div v-if="!data.length" class="px-3 pb-3">Нет данных по треку</div>
                <v-col class="pa-0" v-else>
                    <v-col class="pa-0 px-3">
                        <div>{{ parseDate(data[checkValue].created_at) }}</div>
                        <v-slider
                            v-model="checkValue"
                            :max="data.length - 1"
                            min="0"
                        ></v-slider>
                    </v-col>
                    <div id="map" style="height: 500px"/>
                </v-col>
            </v-col>
        </v-card>
    </v-dialog>
</template>

<script>

export default {
    name: "OrderTrackDialog",
    props: {
        value: Boolean,
        order: Object
    },
    components: {},
    data: () => ({
        loading: false,
        data: [],
        checkValue: 0,
        trackLayer: null
    }),
    methods: {
        parseDate(date) {
            return window.moment.localDateTime(date);
        },
        drawTrack(init = false) {
            let geoJson = {
                type: 'FeatureCollection',
                features: [],
            }
            let coords = this.data.map(v => v.coords).slice(0, this.checkValue + 1)
            geoJson.features.push({
                type: 'Feature',
                id: 1,
                geometry: {
                    type: "LineString",
                    coordinates: coords
                },
                properties: {
                    style: {
                        weight: 3,
                        color: 'red',
                        opacity: 1,
                    }
                },
            })
            const homeCoords = [coords[0][1], coords[0][0]]
            const home = this.$L.createPoint(homeCoords, 'red', 'mdi-home')
            const layer = this.$L.polygonLayer(geoJson).addTo(this.$L.map)
            layer.addLayer(home)
            this.$L.drawLayer(this, 'trackLayer', layer, false, init)
        }
    },
    watch: {
        checkValue() {
            this.drawTrack()
        },
        value(val) {
            if (val) {
                this.loading = true
                this.data = []
                if (!this.order.courier) return
                this.$trackHistory.loadList({
                    created_by: this.order.courier.id,
                    start_created_at: this.order.start_delivery_time,
                    end_created_at: this.order.end_delivery_time,
                }).then(res => {
                    this.loading = false
                    this.data = res.reverse()
                    this.checkValue = res.length - 1
                    setTimeout(() => {
                        if (this.data.length) {
                            this.$L.initMap('map', [54.5, 20.5])
                            this.drawTrack(true)
                        }
                    }, 100)
                })
            }
        }
    },
    computed: {},
};
</script>

<style scoped>

</style>
