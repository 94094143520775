<template>
    <v-row no-gutters>
        <v-col cols="3" class="pa-3" style="height: calc(100vh - 65px); z-index: 1; overflow-y: auto">
            <date-picker class="rounded-lg" label="Дата начала"
                         clearable
                         v-model="startDate"></date-picker>
            <date-picker label="Дата окончания" class="rounded-lg mt-3"
                         clearable
                         v-model="endDate"></date-picker>
            <v-autocomplete small-chips multiple return-object clearable hide-details class="rounded-lg mt-3"
                            :items="$outlet.list" item-value="vendor_code" :item-text="v => v.number + '. ' + v.address"
                            v-model="outlet" label="Торговая точка" outlined
                            dense></v-autocomplete>
            <v-checkbox :disabled="!outlet.length" hide-details class="mt-3 pt-0" v-model="showLines"
                        label="Показывать линии"></v-checkbox>
            <v-btn :loading="loading" @click="loadAnalytics" class="rounded-lg mt-3" depressed color="primary"
                   height="40">Обновить
            </v-btn>
            <div class="font-weight-bold my-3">Расстояния ({{ count }})</div>
            <v-col class="pa-0" v-for="(el, index) in distances" :key="index">
                <v-divider class="my-1" v-if="index"></v-divider>
                <v-row no-gutters align="center">
                    <div class="body-2">{{ getDistanceTitle(el.distance) }}</div>
                    <v-spacer></v-spacer>
                    <div class="font-weight-medium">{{ el.count }}</div>
                </v-row>
            </v-col>
        </v-col>
        <v-col cols="9">
            <div id="map" style="height: calc(100vh - 65px); z-index: 1;"/>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'Heatmap',
    data: () => ({
        startDate: null,
        endDate: null,
        outlet: [],
        result: null,
        loading: false,
        layer: null,
        outlets: null,
        linesLayer: null,
        distances: [],
        count: 0,
        showLines: false,
        colors: [
            '#D50000',
            '#673AB7',
            '#E65100',
            '#0091EA',
            '#2E7D32',
            '#FF9800',
            '#FF4081',
            '#5D4037',
            '#311B92'
        ]
    }),
    methods: {
        addOutletToMap() {
            const _outlets = this.outlet.length ? this.outlet : this.$outlet.list
            const layer = this.$outlet.generateMapLayer(_outlets, false)
            this.$L.drawLayer(this, 'outletLayer', layer)
        },
        drawLines() {
            const outletsCoords = {}
            this.$outlet.list.forEach(v => {
                outletsCoords[v.id] = v.coords
            })
            let geoJson = {
                type: 'FeatureCollection',
                features: [],
            }
            const outletColors = {}
            let colorIndex = 0
            this.result.results.forEach((val, index) => {
                const coords = val[0]
                if (!outletColors[val[1]]) {
                    if (colorIndex === this.colors.length) colorIndex = 0
                    outletColors[val[1]] = this.colors[colorIndex]
                    colorIndex += 1
                }
                const currentOutletCoords = outletsCoords[val[1]]
                if (currentOutletCoords) {
                    geoJson.features.push({
                        type: 'Feature',
                        id: index + 1,
                        geometry: {
                            type: "LineString",
                            coordinates: [[currentOutletCoords[1], currentOutletCoords[0]], coords]
                        },
                        properties: {
                            style: {
                                weight: 0.85,
                                color: outletColors[val[1]],
                                opacity: 1,
                            }
                        },

                    })
                }
            })
            const layer = this.$L.polygonLayer(geoJson).addTo(this.$L.map)
            this.$L.drawLayer(this, 'linesLayer', layer)
        },

        getDistanceTitle(distance) {
            if (!distance) return 'Более'
            if (distance < 1000) return `Менее ${distance} м`
            return `Менее ${distance / 1000} км`
        },
        loadAnalytics() {
            this.addOutletToMap()
            this.loading = true
            if (this.layer) {
                this.$L.map.removeLayer(this.layer)
                this.layer = null
            }
            if (this.linesLayer) {
                this.$L.map.removeLayer(this.linesLayer)
                this.linesLayer = null
            }
            let outletParam = undefined
            if (this.outlet.length)
                outletParam = this.outlet.map(v => v.id).join(',')
            this.$order.sendGet('heatmap', {
                start_date: this.startDate,
                end_date: this.endDate,
                outlet: outletParam
            }).then(res => {
                this.result = res
                this.count = res.count
                const results = []
                res.results.forEach(val => {
                    const coords = val[0]
                    results.push([coords[1], coords[0], 15])
                })
                this.distances = res.distances
                this.layer = L.heatLayer(results, {radius: 10}).addTo(this.$L.map)
                if (this.showLines && this.outlet.length) this.drawLines()
                this.loading = false
            })
        },
    },
    mounted() {
        this.$L.initMap('map', [54.5, 20.5])
        this.endDate = window.moment().local().format('YYYY-MM-DD')
        this.startDate = window.moment().subtract(3, 'month').local().format('YYYY-MM-DD')

    }
}
</script>

<style scoped>

</style>