<template>
  <template-item
    :model="$user"
    :show-actions="false"
    :title="'Пользователи / ' + title"
  >
    <template v-slot:afterTitle>
      <v-row
        v-if="$user.item?.id && $auth.user.is_superuser"
        class="mr-3"
        justify="end"
      >
        <v-dialog v-model="blockUserDialog" width="450">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :color="isBlocked ? 'success' : 'error'"
              class="rounded-lg text-none body-2 mr-3"
              depressed
              @click="blockUserDialog = true"
              >{{ isBlocked ? "Разблокировать" : "Заблокировать" }}
            </v-btn>
          </template>
          <ConfirmDialogData
            :confirm-button-color="isBlocked ? 'success' : 'error'"
            :text="`Вы действительно хотите ${
              isBlocked ? 'разблокировать' : 'заблокировать'
            } пользователя?`"
            v-on:closeDialog="blockUserDialog = false"
            v-on:confirm="isBlocked ? unblockUser() : blockUser()"
          >
            <date-picker
              v-if="!isBlocked"
              :value="blockUserUntil"
              class="mt-4"
              clearable
              label="Дата конца блокировки"
              with-time
              @change="blockUserUntil = $event"
            />
          </ConfirmDialogData>
        </v-dialog>

        <v-dialog
          v-if="$auth.hasRight('balance__access')"
          v-model="deleteUserDialog"
          width="450"
        >
          <template v-slot:activator>
            <v-btn
              v-if="$auth.hasRight('balance__access')"
              class="rounded-lg text-none body-2"
              color="error"
              depressed
              outlined
              @click="deleteUserDialog = true"
              >Удалить
            </v-btn>
          </template>
          <ConfirmDialogData
            confirm-button-color="error"
            text="Вы действительно хотите удалить пользователя?"
            v-on:closeDialog="deleteUserDialog = false"
            v-on:confirm="deleteUser()"
          />
        </v-dialog>
      </v-row>
    </template>
    <v-row align="center" no-gutters>
      <v-btn
        :color="$route.name === 'userBase' ? 'primary' : ''"
        class="mr-3 rounded-lg text-none body-2"
        depressed
        @click="$router.replace({ name: 'userBase' })"
        >Общая информация
      </v-btn>
      <v-btn
        v-if="$auth.hasRight('balance__access')"
        :color="$route.name === 'userBalanceOperations' ? 'primary' : ''"
        class="mr-3 rounded-lg text-none body-2"
        depressed
        @click="$router.replace({ name: 'userBalanceOperations' })"
        >Операции
      </v-btn>
      <v-btn
        v-if="$auth.hasRight('orders__access') || $auth.user.is_superuser"
        class="mr-3 rounded-lg text-none body-2"
        color=""
        depressed
        @click="
          $router.push({ name: 'orderList', query: { user: $user.item?.id } })
        "
        >Список заказов
      </v-btn>
<!--      <v-btn-->
<!--          v-if="$auth.user.is_superuser || $auth.user.id === $user.item?.id"-->
<!--        class="mr-3 rounded-lg text-none body-2"-->
<!--        depressed-->
<!--        :color="$route.name === 'userNotificationsSettings' ? 'primary' : ''"-->
<!--        @click="$router.replace({ name: 'userNotificationsSettings' })"-->
<!--        >Настройка уведомлений-->
<!--      </v-btn>-->
    </v-row>
    <router-view></router-view>
  </template-item>
</template>

<script>
import ConfirmationDialog from "@/main/components/ConfirmationDialog.vue";
import ConfirmDialogData from "@/admin/components/ConfirmDialogData.vue";

export default {
  name: "ProductItem",

  components: { ConfirmDialogData, ConfirmationDialog },
  data: () => ({
    blockUserDialog: false,
    deleteUserDialog: false,
    blockUserUntil: null,
  }),
  methods: {
    async blockUser() {
      const user = this.$user.item;
      if (!user) return;
      const method = this.blockUserUntil
        ? "set_disabled_until"
        : "set_is_active";
      const data = {
        disable_until: this.blockUserUntil || undefined,
        is_active: !this.blockUserUntil ? false : undefined,
      };
      await this.$user.sendPutSingle(method, user.id, data);
      this.blockUserDialog = false;
      setTimeout(() => {
        if (this.blockUserUntil) user.disabled_until = this.blockUserUntil;
        else user.is_active = false;
        this.blockUserUntil = null;
      }, 250);
    },
    async deleteUser() {
      await this.$user.sendDeleteSingle("anonymize", this.$user.item.id);
      void this.$router.push({ name: "userList" });
    },
    async unblockUser() {
      const user = this.$user.item;
      if (!user) return;
      const method = user.is_active ? "set_disabled_until" : "set_is_active";
      const data = {
        disable_until: user.is_active ? null : undefined,
        is_active: !user.is_active || undefined,
      };
      await this.$user.sendPutSingle(method, this.$user.item.id, data);
      this.blockUserDialog = false;
      setTimeout(() => {
        if (!user.is_active) user.is_active = true;
        else user.disabled_until = null;
      }, 250);
    },
  },
  computed: {
    title() {
      let value = `${this.$user.item.phone} | ${
        this.$user.item.first_name || "Не указано"
      }`;
      if (this.$auth.hasRight("balance__access")) {
        value += ` | Баланс: ${this.$user.item.balance || 0}`;
      }
      return value;
    },
    isBlocked() {
      const user = this.$user.item;
      if (!user) return false;
      return !user.is_active || user.disabled_until;
    },
  },
};
</script>
