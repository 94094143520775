<template>
    <v-col class="pa-0">
        <v-row no-gutters class="my-3" align="center">
            <div class="text-h6 mr-3">Операции</div>
            <v-divider/>
            <v-btn v-if="$auth.hasRight('balance__edit_information')" :loading="$balanceOperation.loadings.save"
                   @click="dialog = true" depressed color="primary"
                   class="ml-3 rounded-lg text-none body-2">Создать операцию
            </v-btn>
            <v-btn :loading="$balanceOperation.loadings.list" @click="loadList" depressed color="primary"
                   class="ml-3 rounded-lg text-none body-2">Обновить
            </v-btn>
        </v-row>
        <BalanceOperations ref="balanceOperations" in-user></BalanceOperations>
        <v-dialog v-model="dialog" max-width="600">
            <v-card class="pa-5">
                <div class="font-weight-bold">Создание операции</div>
                <v-text-field dense hide-details class="mt-5" outlined label="Сумма" type="number"
                              v-model.number="data.sum"></v-text-field>
                <v-text-field dense hide-details class="mt-3" outlined label="Сообщение пользователю"
                              v-model="data.message"></v-text-field>
                <v-text-field dense hide-details class="mt-3" outlined label="Комментарий к операции"
                              v-model="data.description"></v-text-field>
                <div class="mt-5 primary--text text-body-2"
                     v-if="settings && settings.system_settings.max_amount_for_refiled_balance !== null">
                    Максимальное количество для начисления:
                    {{ settings.system_settings.max_amount_for_refiled_balance }}
                </div>
                <v-btn class="mt-5 rounded-lg" color="primary" depressed
                       :disabled="!data.sum || !data.message || !data.description || !settings || settings.system_settings.max_amount_for_refiled_balance < data.sum"
                       :loading="$balanceOperation.loadings.save" @click="authenticatorDialog = true">Сохранить
                </v-btn>
            </v-card>
        </v-dialog>
        <v-dialog max-width="500" v-model="authenticatorDialog">
            <v-card class="py-7 px-5">
                <v-row align="center" justify="center" class="flex-column">
                    <div class="title font-weight-bold mb-5">Ввод Google Authenticator</div>
                    <AuthenticatorInput style="width: 400px" @complete="save"></AuthenticatorInput>
                    <div class="caption mt-3">Введите 6-значный код из приложения Google Authenticator</div>
                </v-row>
            </v-card>
        </v-dialog>
    </v-col>
</template>

<script>
import Search from "@/components/template/Search";
import BalanceOperations from "@/admin/views/balanceOperations/BalanceOperations.vue";
import AuthenticatorInput from "@/admin/components/AuthenticatorInput.vue";

export default {
    name: "Users",
    components: {
        Search,
        BalanceOperations,
        AuthenticatorInput
    },
    data: () => ({
        timeout: null,
        dialog: false,
        data: {
            sum: 0,
            message: null,
            description: null,
            user: null
        },
        authenticatorDialog: false,
        settings: null
    }),
    methods: {
        loadList() {
            this.$refs.balanceOperations.loadList()
        },
        async save(value) {
            this.data.authenticator = value
            try {
                await this.$balanceOperation.create(this.data)
                await this.$refs.balanceOperations.loadList()
                this.dialog = false
                this.authenticatorDialog = false
                this.$store.commit('setSnackbar', {text: "Операция успешно создана", color: "success"})
            } catch (e) {
                this.$store.commit('setSnackbar', {text: "Ошибка сохранения", color: "error"})
            }
        }

    },
    watch: {
        dialog(v) {
            if (v) {
                this.data.sum = 0
                this.data.message = null
                this.data.description = null
                this.data.user = this.$user.item.id
            }
        }
    },
    mounted() {
        this.$settings.sendGet('current',).then(res => {
            this.settings = res
        })
    }
}
</script>

<style scoped>

</style>
