<template>
    <v-card class="pa-3 mb-3 rounded-lg" elevation="1" @click="$router.push({name: 'outlets'})">
        <v-row align="center" no-gutters v-if="outlet">
            <v-col>
                <v-row align="center" no-gutters justify="space-between">
                    <div class="text-caption">Кафе</div>
                    <div class="caption">Нажмите, чтобы изменить</div>
                </v-row>
                <div class="text-body-2 text-md-subtitle-1 font-weight-medium" v-text="outlet.address"/>
                <div v-if="!nearTime && $store.state.cartItems.length" class="text-body-2 red--text">
                    Невозможно заказть в выбранном кафе
                </div>
            </v-col>
        </v-row>
        <v-col v-else class="pa-0">
            <v-row align="center" justify="center" no-gutters>
                Кафе не выбрано
            </v-row>
            <v-row align="center" justify="center" no-gutters class="caption">
                Нажмите для выбора
            </v-row>
        </v-col>
    </v-card>
</template>

<script>
export default {
    name: "SelectedOutlet",
    props: {
        outlet: Object,
        nearTime: Object
    }
}
</script>
