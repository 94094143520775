<template>
    <template-list :model="$line" :show-create-button="false">
        <template v-slot="{object, to, index}">


            <v-menu offset-y :close-on-content-click="false" @input="!$event ? setColor(object) : void 0">
                <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                        <v-icon :style="`color: ${object.color}`" size="20">mdi-checkbox-blank-circle</v-icon>
                        Линия №{{ object.id }}
                    </div>
                </template>
                <v-card v-if="$auth.hasRight('tracker__edit_information')">
                    <v-color-picker
                        mode.sync="hex"
                        v-model="object.color"
                        dot-size="30"
                    ></v-color-picker>
                </v-card>
            </v-menu>
        </template>
    </template-list>
</template>

<script>

export default {
    name: 'Lines',
    components: {},
    data: () => ({}),
    methods: {
        setColor(v) {
            this.$line.update(v)
        }
    },
    created() {
        this.$outlet.loadList()
    }
}
</script>

<style scoped>

</style>