<template>
    <div>
        <product-group-menu-tile
            v-for="(group, index) in productGroups"
            :key="index"
            :product-group="group"
        />
    </div>
</template>

<script>
import ProductGroupMenuTile from './ProductGroupMenuTile'

export default {
    name: "ProductGroupMenuList",

    components: {
        ProductGroupMenuTile,
    },

    computed: {
        productGroups() {
            if (this.$store.state.selectedPromo) {
                return this.$product.filterByPromo(this.$store.state.selectedPromo)
            } else return this.$product.menu
        },
    },
}
</script>
