<template>
    <v-col class="pa-0" v-intersect="onIntersect" :id="`products-group-${productGroup.id}`">
        <v-row no-gutters>
            <v-col cols="12" class="py-3 px-2">
                <div class="text-h6">{{ productGroup.name }}</div>
            </v-col>
        </v-row>
        <v-row dense v-if="$vuetify.breakpoint.mdAndUp">
            <v-col cols="12" sm="3" md="3" lg="3" v-for="(product, index) in products" :key="index">
                <product-menu-tile :product="product" />
            </v-col>
        </v-row>
        <div v-if="$vuetify.breakpoint.smAndDown">
            <div v-for="(product, index) in products" :key="index">
                <v-divider v-if="index" class="mx-3"/>
                <product-menu-tile class="px-2" :product="product"/>
            </div>
        </div>
    </v-col>
</template>

<script>
    import ProductMenuTile from './ProductMenuTile'

    export default {
        name: "ProductGroupMenuList",

        components: {
            ProductMenuTile,
        },

        props: {
            productGroup: Object,
        },

        computed: {
            products() {
                if (this.$store.state.selectedPromo) {
                    const productIds = this.$store.state.selectedPromo.products.map(e => e.id)
                    return this.productGroup.products.filter(e => {
                        return productIds.indexOf(e.id) > -1
                    })
                }
                return this.productGroup.products
            },
        },
        methods: {
            onIntersect(entries, observer) {
                const id = entries[0].target.id
                const isIntersecting = entries[0].isIntersecting
                this.$eventBus.$emit('onIntersect', {id, isIntersecting})
            }
        }
    }
</script>
