const m = require("air-vue-model")();
m.url = "product_blanks";
m.name = "productBlank";
m.text = {
    itemTitle: "Заготовка",
    listTitle: "Заготовки",
};
m.default = {
    id: null,
    name: "",
};

m.routes = [
    {
        name: "list",
        component: require("@/admin/views/productBlank/ProductBlankList.vue"),
    },
    {
        name: "item",
        component: require("@/admin/views/productBlank/ProductBlankItem.vue"),
        single: true
    }
];

module.exports = m;
