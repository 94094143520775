<template>
    <v-col class="pa-0">
        <v-row no-gutters class="my-3" align="center">
            <div class="text-h6 mr-3">Остатки</div>
            <v-divider/>
        </v-row>
        <v-text-field v-model="search" outlined dense hide-details label="Поиск" style="max-width: 200px"
        ></v-text-field>
        <v-data-table
            no-data-text="Нет данных"
            hide-default-footer
            :headers="headers"
            :items="items"
            :items-per-page="999999"
        >
            <template v-slot:item.disabled="{ item }">
                <v-checkbox class="ma-0 pa-0" hide-details
                            v-model="item.disabled" readonly
                ></v-checkbox>
            </template>
        </v-data-table>
    </v-col>
</template>

<script>
import search from "air-vue-model/search";

export default {
    name: 'OutletItemEquipments',
    data: () => ({
        search: '',
        headers: [
            {text: 'Наименование', value: 'nomenclature.name'},
            {text: 'Количество', value: 'amount'},
            {text: 'Мин. количество для заказа', value: 'min_amount_for_order'},
            {text: 'Мин. количество для оповещения', value: 'min_amount_for_notification'},
            {text: 'Заблокировано', value: 'disabled'},
        ]
    }),
    methods: {},
    computed: {
        items() {
            return search(this.search, this.$outletNomenclature.list, v => v.nomenclature.name)
        },
    },
    mounted() {
        this.$outletNomenclature.list = []
        this.$outletNomenclature.loadList({page_size: 'all', outlet: this.$outlet.item.id})
    }
}
</script>

<style scoped>

</style>