<template>
  <v-layout class="ma-0" column>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      max-width="290px"
      min-width="290px"
      offset-y
      transition="scale-transition"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          :dense="dense"
          :hide-details="hideDetails"
          :label="label"
          :outlined="outlined"
          :rules="rules"
          :style="'max-width: ' + width"
          :value="formatRow"
          class="pa-0 rounded-lg"
          persistent-hint
          readonly
          v-on="!disabled ? on : void 0"
          :disabled="disabled"
          :aria-required="ariaRequired"
        >
          <v-icon
            v-if="prependIcon"
            slot="prepend-inner"
            :size="20"
            class="mt-1"
            >{{ prependIcon }}
          </v-icon>

          <template v-slot:append>
            <slot name="append"></slot>
            <v-btn
              v-if="clearable"
              icon
              small
              style="margin-top: -2px; margin-right: -5px"
              @click="clear"
            >
              <v-icon size="20">mdi-close</v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </template>
      <v-card>
        <v-row no-gutters>
          <v-date-picker
            v-if="selectedType === 'date'"
            v-model="dates"
            :first-day-of-week="1"
            :max="max"
            :min="min"
            :no-title="!time"
            :range="range"
            color="primary"
            locale="ru-RU"
            @change="emitDates"
          ></v-date-picker>
          <template v-else>
            <vue-timepicker
              v-model="internalTime"
              class="elevation-1"
              format="HH:mm"
              hide-clear-button
              hide-disabled-hours
              hour-label="Часы"
              minute-label="Минуты"
              @change="setTime"
            />
            <v-row class="ma-0" justify="end">
              <v-btn
                class="my-1 mr-1"
                color="primary"
                depressed
                @click="emitTime()"
                >Ок
              </v-btn>
            </v-row>
          </template>
          <!--                    <v-time-picker-->
          <!--                        v-else-->
          <!--                        @change="emitTime"-->
          <!--                        v-model="time"-->
          <!--                        format="24hr"-->
          <!--                    ></v-time-picker>-->
        </v-row>
      </v-card>
    </v-menu>
  </v-layout>
</template>

<script>
import datetime from "../../vendor/datetime";
import VueTimepicker from "vue2-timepicker/src";
import "vue2-timepicker/dist/VueTimepicker.css";

export default {
  mixins: [datetime],
  components: { VueTimepicker },
  props: {
    width: {
      type: String,
      default: "100%",
    },
    rules: Array,
    value: [String, Array],
    label: String,
    prependIcon: {
      type: String,
      default: "mdi-calendar",
    },
    hideDetails: {
      type: Boolean,
      default: true,
    },
    clearable: Boolean,
    outlined: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    range: Boolean,
    min: String,
    max: String,
    withTime: Boolean,
    disabled: Boolean,
    ariaRequired: Boolean,
  },
  name: "DatePickerComponent",

  data: () => ({
    menu: false,
    selectedType: "date",
    dates: null,
    time: null,
    internalTime: null,
  }),
  methods: {
    clear() {
      this.internalTime = null;
      if (this.range) {
        this.$emit("input", []);
        this.$emit("change", []);
      } else {
        this.$emit("input", null);
        this.$emit("change", null);
      }
    },
    emitDates(val) {
      if (this.range) {
        if (val[0] > val[1]) this.dates = [val[1], val[0]];
      }
      if (!this.withTime) {
        this.$emit("input", this.dates);
        this.$emit("change", this.dates);
        this.menu = false;
      } else {
        this.selectedType = "time";
      }
    },
    setTime(v) {
      this.time = `${v.displayTime.replace("mm", "00").replace("HH", "00")}:00`;
    },
    emitTime() {
      this.emitDateTime();
      this.menu = false;
    },
    emitDateTime() {
      const time = this.time || "00:00:00";
      if (!this.dates) {
        this.$emit("input", null);
        this.$emit("change", null);
      } else {
        let row = this.dates + " " + time;
        row = moment(row, "YYYY-MM-DD HH:mm:ss")
          .utc()
          .format("YYYY-MM-DD HH:mm:ss");
        this.$emit("input", row);
        this.$emit("change", row);
      }
    },

    formatDate(date) {
      if (!date) return "";
      date = date.split(" ");
      return this.calendarDate(date[0]);
    },
    setDatesAndTimes() {
      if (this.withTime) {
        if (!this.value) {
          this.dates = null;
          this.time = null;
        } else {
          let value = moment
            .utc(this.value, "YYYY-MM-DD HH:mm:ss")
            .local()
            .format("YYYY-MM-DD HH:mm:ss");
          value = value.split(" ");
          this.dates = value[0];
          this.time = value[1];
        }
      } else {
        if (this.range) {
          this.dates =
            !this.value || this.value.length < 2
              ? []
              : [this.value[0], this.value[1]];
        } else this.dates = this.value;
      }
    },
  },
  created() {
    this.setDatesAndTimes();
  },
  computed: {
    formatRow() {
      if (this.withTime) {
        const time = this.time || "";
        return this.formatDate(this.dates) + " " + time;
      } else {
        if (this.range)
          if (this.dates.length !== 0) {
            if (!this.dates[0] && !this.dates[1]) return "Не указан период";
            return `${this.formatDate(this.dates[0])} - ${this.formatDate(
              this.dates[1]
            )}`;
          } else return "Не указан период";
        return this.formatDate(this.dates);
      }
    },
  },
  watch: {
    menu(val) {
      if (!val) {
        if (this.range && this.dates.length === 1) {
          this.dates = [this.dates[0], this.dates[0]];
          this.$emit("input", this.dates);
          this.$emit("change", this.dates);
        }
      } else {
        this.selectedType = "date";
      }
    },
    value() {
      this.setDatesAndTimes();
    },
  },
};
</script>

<style scoped></style>