<template>
    <v-col class="pa-0">
        <v-row no-gutters class="my-3" align="center">
            <div class="text-h6 mr-3">Цены</div>
            <v-divider/>
            <!--            <v-btn :loading="$airIngredient.loadings.action" @click="saveNomenclature" depressed color="primary"-->
            <!--                   class="ml-3 rounded-lg text-none body-2">-->
            <!--                Сохранить-->
            <!--            </v-btn>-->
        </v-row>
        <!--        <v-row no-gutters class="pt-3">-->
        <!--            <v-spacer></v-spacer>-->
        <!--            <v-btn @click="dialog=true" depressed class="text-none rounded-lg body-2 px-5">Добавить</v-btn>-->
        <!--        </v-row>-->
        <v-data-table
            no-data-text="Нет данных"
            hide-default-footer
            :headers="headers"
            :items="$airIngredient.item.prices"
        >
            <template v-slot:item.start_at="{ item }">
                {{ item.start_at ? item.start_at.substring(0, 5) : 'Не указано' }}
            </template>
            <template v-slot:item.end_at="{ item }">
                {{ item.end_at ? item.end_at.substring(0, 5) : 'Не указано' }}
            </template>
        </v-data-table>
        <!--        <v-dialog v-model="dialog" max-width="600">-->
        <!--            <v-card class="pa-3">-->
        <!--                <v-row no-gutters align="center" class="mb-3">-->
        <!--                    <div class="font-weight-bold">Добавить позицию</div>-->
        <!--                    <v-spacer></v-spacer>-->
        <!--                    <v-text-field v-model="search" outlined dense hide-details label="Поиск" class="ml-3"-->
        <!--                                  style="max-width: 200px"-->
        <!--                    ></v-text-field>-->
        <!--                </v-row>-->
        <!--                <div class="body-2" v-if="!availableNomenclature.length">Доступная номенклатура отсутствует</div>-->
        <!--                <v-col class="pa-0">-->
        <!--                    <v-col class="pa-0" v-for="(el, index) in availableNomenclature" :key="index">-->
        <!--                        <v-divider v-if="index"></v-divider>-->
        <!--                        <v-row no-gutters class="py-2 link" @click="addNomenclature(el)">-->
        <!--                            {{ el.name }}-->
        <!--                        </v-row>-->
        <!--                    </v-col>-->
        <!--                </v-col>-->
        <!--            </v-card>-->
        <!--        </v-dialog>-->
    </v-col>
</template>

<script>

export default {
    name: 'IngredientItemPrices',
    data: () => ({
        dialog: false,
        search: '',
        headers: [
            {text: 'Время начала', value: 'start_at'},
            {text: 'Время окончания', value: 'end_at'},
            {text: 'Цена', value: 'price'},

        ]
    }),
    computed: {},
    methods: {
        // saveNomenclature() {
        //     this.$airIngredient.sendPutSingle('set_nomenclature', this.$airIngredient.item.id, this.$ingredientNomenclature.list).then(res => {
        //         this.$ingredientNomenclature.list = res
        //         this.$store.commit('setSnackbar', {color: 'success', text: 'Технологическая карточка сохранена'})
        //     })
        // },
        // addNomenclature(item) {
        //     this.$ingredientNomenclature.list.push({
        //         nomenclature: item,
        //         amount: 0,
        //         removable: false,
        //         control_amount: false,
        //         product: this.$airIngredient.item.id
        //     })
        //     this.dialog = false
        // }
    }
}
</script>

<style scoped>

</style>