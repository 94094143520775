<template>
  <template-item
    v-if="item"
    v-slot="{ object, rules }"
    :model="$ingredientPriceListEdict"
    :show-actions="$auth.hasRight('price_list__access') && editAvailable"
    :get-save-data="getSaveData"
  >
    <v-row class="ma-0">
      <v-col class="pa-0" cols="6">
        <date-picker
          v-model="startsFrom"
          class="rounded-lg"
          :clearable="editAvailable"
          label="Дата применения"
          with-time
          :min="moment().format('YYYY-MM-DD HH:mm:ss')"
          :disabled="!editAvailable"
        ></date-picker>
<!--        <lazy-select-->
<!--          v-model="item.outlets"-->
<!--          :item-text="(val) => val.number + '. ' + val.address"-->
<!--          :lazy="false"-->
<!--          :model="$outlet"-->
<!--          class="mt-3"-->
<!--          clearable-->
<!--          label="Торговые точки"-->
<!--          multiple-->
<!--          :preload="false"-->
<!--          set-to="list"-->
<!--        ></lazy-select>-->
        <v-select
            v-model="item.type"
            :items="$ingredientPriceListEdict.types"
            class="mt-5"
            dense
            hide-details
            item-text="label"
            item-value="value"
            label="Тип"
            outlined
            :return-object="false"
            disabled
        ></v-select>
        <v-row class="ma-0 mt-3 mb-5" align="center">
          <div class="font-weight-bold">Элменты</div>
          <v-divider class="mx-2" />
          <v-menu :close-on-content-click="false" v-if="editAvailable">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" depressed class="rounded-lg text-none body-2"
                >Добавить элемент</v-btn
              >
            </template>
            <v-card style="width: 300px; max-height: 500px; overflow-y: auto">
              <v-text-field
                dense
                hide-details
                outlined
                class="ma-2"
                label="Поиск"
                clearable
                v-model="ingredientSearch"
              ></v-text-field>
              <v-list-item
                v-for="ingredient in ingredients"
                :key="ingredient.id"
                @click="addItem(ingredient)"
              >
                {{ ingredient.vendor_name || ingredient.name }}
              </v-list-item>
            </v-card>
          </v-menu>
        </v-row>

        <template v-for="(ingredient, index) in item.items">
          <v-divider v-if="index"></v-divider>
          <v-row
            class="ma-0 my-2"
            justify="space-between"
            align="center"
            style="flex-wrap: nowrap"
          >
            <div>{{ ingredient.ingredient.vendor_name }}</div>
            <v-col class="pa-0" cols="4">
              <v-row class="ma-0" style="gap: 10px" justify="end">
                <v-text-field
                  style="max-width: 100px"
                  type="number"
                  v-model.number="ingredient.price"
                  outlined
                  hide-details
                  dense
                  label="Цена"
                  :disabled="!editAvailable"
                />
                <v-btn icon @click="deleteItem(index)" v-if="editAvailable"
                  ><v-icon>mdi-close</v-icon></v-btn
                >
              </v-row>
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>
  </template-item>
</template>

<script>
import moment from "moment";

export default {
  name: "IngredientPriceListEdictItem",
  components: {},
  data: () => ({
    ingredientSearch: null,
    startsFrom: null,
  }),
  computed: {
    item() {
      return this.$ingredientPriceListEdict.item;
    },
    ingredients() {
      const selectedIngredientIds = this.item.items.map((v) => v.ingredient.id);
      if (!this.ingredientSearch)
        return this.$ingredient.list.filter(
          (v) => !selectedIngredientIds.includes(v.id)
        );
      const s = this.ingredientSearch?.toLowerCase() || "";
      return this.$ingredient.list.filter(
        (v) =>
          (v.vendor_name?.toLowerCase() || "").includes(s) &&
          !selectedIngredientIds.includes(v.id)
      );
    },
    editAvailable() {
      if (!this.item.starts_from) return true;
      const startsFrom = moment.utc(this.item.starts_from).local();
      return startsFrom.isSameOrAfter(moment());
    },
  },
  mounted() {
    this.$ingredient.loadList();
    this.startsFrom = this.item.starts_from;
  },
  methods: {
    moment,
    addItem(ingredient) {
      this.item.items.push({ ingredient, price: 0 });
    },
    deleteItem(index) {
      this.item.items.splice(index, 1);
    },
    getSaveData(obj) {
      return {
        ...obj,
        starts_from: this.startsFrom,
      };
    },
  },
  watch: {},
};
</script>

<style scoped></style>
