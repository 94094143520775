const productIngredientGroup = require("@/airModels/productIngredientGroup");
const m = require('air-vue-model')()
const airLeaflet = require('air-vue-leaflet')()
const cookingPost = require('./cookStation')
m.url = 'outlets'
m.name = 'outlet'
m.setPagination({
    page_size: 'all'
})
m.text = {
    itemTitle: 'Торговая точка', listTitle: 'Торговые точки'
}

m.generateMapLayer = (outlets, grey = false, clickFunction = null,) => {
    const points = []
    for (const outlet of outlets) {
        const {id, coords, closest_time} = outlet
        points.push({id, coords: [coords[1], coords[0]], closest_time})
    }
    const pointCollection = airLeaflet.pointCollection(points, (val) => {
        return {pt: val.closest_time}
    })
    return L.geoJson(pointCollection, {
        onEachFeature: (feature, layer) => {
            if (clickFunction) {
                layer.on('click', () => clickFunction)
            }
        }, pointToLayer: (feature, latlng) => {
            const pt = feature.properties.pt
            let draw_icon
            if (!pt) {
                draw_icon = new L.icon({
                    iconUrl: grey ? '/images/logo-short-grey.png' : '/images/logo-short.png', iconSize: [20, 28],
                })
            } else {
                const now = moment().local()
                const currentPt = moment.utc(pt.pt).local()
                const diff = currentPt.diff(now, 'minute') + 1
                draw_icon = new L.divIcon({
                    className: 'custom-div-icon', html: `<div class="map-circle green white--text">
                                        <div class="font-weight-medium" style="height: 13px">${diff}</div>
                                   <div>мин</div></div>`, iconSize: [40, 40], iconAnchor: [15, 42]
                })
            }
            return L.marker(latlng, {
                icon: draw_icon
            })
        },
    })
}

m.routes = [{
    name: 'list', component: require('@/admin/views/outlets/Outlets'),
}, {
    name: 'item', component: require('@/admin/views/outlets/Outlet'), single: true,
    redirect: {name: 'outletBase'},
    children: [
        {name: 'base', path: '', component: require('@/admin/views/outlets/OutletItemBase.vue')},
        {name: 'database', path: 'database', component: require('@/admin/views/outlets/OutletItemDatabase.vue')},
        {
            name: 'deliveryAreas',
            path: 'delivery_areas',
            component: require('@/admin/views/outlets/OutletItemDeliveryAreas.vue')
        },
        {
            name: 'equipments',
            path: 'equipments',
            component: require('@/admin/views/outlets/OutletItemEquipments.vue')
        },
        {
            name: 'nomenclature',
            path: 'nomenclature',
            component: require('@/admin/views/outlets/OutletItemNomenclature.vue')
        },
        {
            name: 'lines',
            path: 'lines',
            component: require('@/admin/views/outlets/OutletItemLines.vue')
        },
        {
            name: 'lines',
            path: 'lines',
            component: require('@/admin/views/outlets/OutletItemLines.vue')
        },
        {
            name: 'deliverySettings',
            path: 'delivery_settings',
            component: require('@/admin/views/outlets/OutletDeliverySettings.vue')
        },
        {
            name: 'afterOrderReview',
            path: 'after_order_review',
            component: require('@/admin/views/outlets/AfterOrderReview.vue')
        },
        {
            name: 'queue',
            path: 'queue',
            component: require('@/admin/views/outlets/OutletTrackerQueue.vue')
        },
        {
            name: 'cookStation',
            path: 'cook_stations',
            redirect: {name: 'outletCookStations'},
            component: require('@/admin/views/outlets/CookStations.vue'),
            children: [
                {name: 'cookStations', path: '', component: require('@/admin/views/outlets/CookStationList.vue')},
                {
                    name: 'cookStationDetail',
                    single: true,
                    model: cookingPost,
                    path: 'detail', component: require('@/admin/views/outlets/CookStationDetail.vue')
                },
            ]
        },
        // {name: 'outletPostList', path: '', component: require('@/admin/views/outlets/OutletPostList.vue')},
    ]
},]

module.exports = m