const m = require('air-vue-model')()
m.url = 'checkouts'
m.name = 'checkout'
m.setPagination({
    page_size: 'all'
})

m.default = {

}

m.routes = [
    {name: 'list', component: require('../admin/views/checkout/Checkouts')},
    {name: 'item', component: require('../admin/views/checkout/Checkout'), single: true},
]
module.exports = m