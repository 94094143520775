<template>
    <v-row class="ma-0 fill-height" align="center">
        <v-col cols="10" md="4" offset-md="4" offset="1">
            <v-card class="pa-3">
                <div class="title font-weight-bold">Авторизация</div>
                <v-text-field dense hide-details outlined class="mt-5" label="Логин"
                              v-model="username"></v-text-field>
                <v-text-field @keyup.enter="login()" dense hide-details outlined class="mt-5" label="Пароль"
                              type="password"
                              v-model="password"></v-text-field>
                <v-row align="center" class="pa-3 mt-2">
                    <v-btn :disabled="!username || !password" depressed class="text-none px-7" color="primary"
                           :loading="loading" @click="login()">Войти
                    </v-btn>
                    <v-spacer></v-spacer>
                    <!--                    <v-btn class="text-none" text @click="$router.push({'name': 'register'})">Регистрация</v-btn>-->
                </v-row>
            </v-card>
        </v-col>
        <v-dialog max-width="500" v-model="authenticatorDialog">
            <v-card class="py-7 px-5">
                <v-row align="center" justify="center" class="flex-column">
                    <div class="title font-weight-bold mb-5">Ввод Google Authenticator</div>
                    <AuthenticatorInput style="width: 400px" @complete="login"></AuthenticatorInput>
                    <div class="caption mt-3">Введите 6-значный код из приложения Google Authenticator</div>
                </v-row>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>

import AuthenticatorInput from "@/admin/components/AuthenticatorInput.vue";

export default {
    name: "Login",
    components: {AuthenticatorInput},
    data: () => ({
        username: '',
        password: '',
        loading: false,
        authenticatorDialog: false
    }),
    methods: {
        login(authenticator = undefined) {
            this.loading = true
            this.$auth.login({
                phone: this.username,
                password: this.password,
                authenticator: authenticator
            }).then(() => {
                window.location.href = this.$route.query.path || this.$auth.success
            }).catch(e => {
                if (!this.authenticatorDialog)
                    if (e.data.authenticator || !authenticator) {
                        this.authenticatorDialog = true
                        this.loading = false
                        return
                    }
                this.$store.commit('setSnackbar', {text: "Неверные данные", color: "error"})
            }).finally(() => {
              this.loading = false
            })
        }
    },
}
</script>

<style scoped>

</style>