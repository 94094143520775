<template>
  <template-item
    :show-actions="false"
    :title="title"
    :model="$productIngredientGroupIngredient"
    :show-delete="false"
    v-slot="{ object, rules }"
  >
    <!--      $auth.hasRight('products__edit_information')-->
    <div class="text-h5">{{ object.name }}</div>
    <ProductIngredientGroupIngredientTabs />
    <router-view />
  </template-item>
</template>

<script>
import ProductIngredientGroupIngredientTabs from "@/admin/views/productIngredientGroupIngredient/ProductIngredientGroupIngredientTabs.vue";

export default {
  name: "ProductGroupItem",
  components: { ProductIngredientGroupIngredientTabs },
  computed: {
    title() {
      const values = [];
      if (this.$product.item) {
        if (this.$product.item.group) {
          values.push(this.$product.item.group.vendor_name || "Отсутствует");
        }
        values.push(this.$product.item.vendor_name || "Отсутствует");
      }
      values.push(
        this.$productIngredientGroupIngredient.item.ingredient.vendor_name ||
          "Отсутствует"
      );
      return values.join(" / ");
    },
  },
};
</script>
