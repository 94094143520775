<template>
  <v-col class="pa-3 pb-10">
    <v-row align="center" class="my-0" dense>
      <date-picker
        v-model="startDate"
        class="rounded-lg mr-2"
        clearable
        label="Дата начала"
        style="max-width: 200px"
      ></date-picker>
      <date-picker
        v-model="endDate"
        class="rounded-lg mr-2"
        clearable
        label="Дата окончания"
        style="max-width: 200px"
      ></date-picker>
      <v-text-field
        v-model="search_"
        class="rounded-lg"
        dense
        hide-details
        label="Поиск"
        outlined
        style="max-width: 180px"
      />
      <v-col cols="auto">
        <v-row align="center" class="pa-0 ml-1">
          <v-btn
            :loading="loading"
            class="rounded-lg"
            color="primary"
            depressed
            height="40"
            @click="loadAnalytics()"
            >Обновить
          </v-btn>
          <v-menu v-if="results" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :loading="exportLoading"
                class="rounded-lg ml-3"
                color="primary"
                height="40"
                outlined
                rounded
                v-on="on"
                >Выгрузить
              </v-btn>
            </template>
            <v-card class="pa-3 rounded-lg">
              <v-list>
                <div class="list-item mb-2" @click="exportToExcel()">
                  Обычный
                </div>
                <div class="list-item" @click="exportToExcel(true)">
                  Детальный
                </div>
              </v-list>
            </v-card>
          </v-menu>
          <v-checkbox
            v-model="byProducts"
            class="ml-2"
            label="Недоступно в приложении"
          />
        </v-row>
      </v-col>
      <v-spacer />
      <v-row justify="end" no-gutters>
        <v-btn
          :color="type === 'outlet' ? 'primary' : 'gray'"
          class="rounded-lg text-none"
          depressed
          height="40"
          @click="loadAnalytics('outlet')"
          >Точки
        </v-btn>
        <v-btn
          :color="
            ['nomenclature', 'product'].includes(type) ? 'primary' : 'gray'
          "
          class="rounded-lg ml-3 text-none"
          depressed
          height="40"
          @click="loadAnalytics(byProducts ? 'product' : 'nomenclature')"
          >{{ byProducts ? "Продукты" : "Номенклатура" }}
        </v-btn>
      </v-row>
    </v-row>
    <v-data-table
      v-if="$outlet.list.length && $nomenclature.list.length"
      :headers="currentHeaders"
      :items="currentData"
      class="mt-2"
      disable-pagination
      hide-default-footer
    >
      <template v-slot:item.outletsCount="{ item, value }">
        <div class="cursor-pointer" @click="openDialog(item)">
          {{ value }}
        </div>
      </template>
      <template v-slot:item.nomenclaturesCount="{ item, value }">
        <div class="cursor-pointer" @click="openDialog(item)">
          {{ value }}
        </div>
      </template>
      <template v-slot:item.nomenclaturesTotalUnavailableTime="{value}">
        <div>{{secondsToTime(value)}}</div>
      </template>
      <template v-slot:item.nomenclaturesAvgUnavailableTime="{value}">
        <div>{{secondsToTime(value)}}</div>
      </template>
      <template v-slot:item.totalUnavailableTime="{value}">
        <div>{{secondsToTime(value)}}</div>
      </template>
      <template v-slot:item.avgUnavailableTime="{value}">
        <div>{{secondsToTime(value)}}</div>
      </template>
<!--      nomenclaturesTotalUnavailableTime-->
<!--      nomenclaturesAvgUnavailableTime-->
<!--      totalUnavailableTime-->
<!--      avgUnavailableTime-->
    </v-data-table>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card
        v-if="currentItem"
        class="pa-5"
        max-height="70vh"
        min-height="950px"
        style="overflow: scroll"
      >
        <div style="display: flex; flex-direction: column; gap: 10px">
          <h2>
            <span v-if="type === 'outlet'"
              >{{ currentItem.source.source.number }}.</span
            >
            {{
              currentItem.source.source[type === "outlet" ? "address" : "name"]
            }}
            <span
              v-if="type === 'outlet'"
              class="ml-1 text-caption font-weight-regular"
              >({{ currentItem.source.source.company.name }})</span
            >
          </h2>
          <v-text-field
            v-model="modalSearch"
            class="my-1"
            dense
            hide-details
            label="Поиск"
            outlined
          />
          <div v-for="(item, index) in secondItems" :key="index">
            <div class="font-weight-medium mb-1">
              {{ index + 1 }}.
              {{
                ["nomenclature", "product"].includes(type)
                  ? item.source.address
                  : item.source.name
              }}
              <span
                v-if="['nomenclature', 'product'].includes(type)"
                class="ml-1 text-caption font-weight-regular"
                >({{ item.source.company.name }})</span
              >
            </div>
            <div class="body-2">
              Всего часов:
              <span class="font-weight-medium">
                {{ secondsToTime(item.total_time) }}
              </span>
            </div>
            <div class="body-2">
              В среднем часов:
              <span class="font-weight-medium">{{ secondsToTime(item.avg_time) }}</span>
            </div>
            <div class="body-2">
              Всего дней:
              <span class="font-weight-medium">{{ item.total_days }}</span>
            </div>
            <div class="body-2">
              Дней подряд:
              <span class="font-weight-medium">{{ item.days_in_row }}</span>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-col>
</template>
<script>
import Search from "@/components/template/Search.vue";
import { clone, cloneDeep, debounce } from "lodash";

export default {
  name: "DisabledNomenclatureRatingAnalytics",
  components: { Search },
  data: () => ({
    startDate: window.moment().subtract(1, "week").local().format("YYYY-MM-DD"),
    endDate: null,
    loading: false,
    results: null,
    search_: "",
    search: "",
    setSearchTimeout: null,
    exportLoading: false,
    headers: {},
    dialog: false,
    currentItem: null,
    byProducts: false,
    type: "outlet",
    modalSearch: "",
  }),
  async mounted() {
    await this.$product.loadList({ page_size: "all" });
    this.loadAnalytics();
  },
  methods: {
    _setSearch() {
      this.search = clone(this.search_);
    },
    setSearch() {
      if (this.setSearchTimeout) clearTimeout(this.setSearchTimeout)
      this.setSearchTimeout = setTimeout(() => {
        this._setSearch()
      }, 300)
    },
    getTimeData() {
      return {
        start_date: this.startDate ? this.startDate + " 00:00:00" : "",
        end_date: this.endDate ? this.endDate + " 23:59:59" : "",
      };
    },
    loadAnalytics(type = null) {
      const historyItemsTypes = {
        nomenclature: 1,
        product: 2,
      };
      if (type) {
        this.results = null;
        this.type = type;
      }
      if (this.endDate && this.startDate) {
        if (this.startDate > this.endDate) {
          const tmp = String(this.startDate);
          this.startDate = String(this.endDate);
          this.endDate = tmp;
          this.$store.commit("setSnackbar", {
            text: "Даты исправлены",
            color: "success",
          });
        }
      }
      this.currentItem = null;
      this.loading = true;
      this.$analytics
        .sendPost("disabled_nomenclature_rating", {
          ...this.getTimeData(),
          source: this.type,
          history_item_type: this.byProducts
            ? historyItemsTypes.product
            : historyItemsTypes.nomenclature,
        })
        .then((res) => {
          this.results = res;
          this.loading = false;
        });
    },
    openDialog(item) {
      this.modalSearch = "";
      this.currentItem = item;
      this.dialog = true;
    },
    secondsToTime(v) {
      let hours = Math.floor(v / 3600);
      hours = hours < 10 ? `0${hours}` : hours;
      let minutes = Math.floor((v % 3600) / 60);
      minutes = minutes < 10 ? `0${minutes}` : minutes;
      return `${hours}:${minutes}`;
    },
    exportToExcel(detail = false) {
      this.exportLoading = true;
      const additionalHeaders = [
        {
          text: this.type === "outlet" ? "Номенклатура" : "Точка",
          value: `_source.${this.type === "outlet" ? "name" : "address"}`,
        },
        {
          text: "Всего часов",
          value: "_total_time",
          format: (v) => this.secondsToTime(v)
        },
        {
          text: "В среднем часов",
          value: "_avg_time",
          format: (v) => this.secondsToTime(v)
        },
        {
          text: "Всего дней",
          value: "_total_days",
        },
        {
          text: "Дней подряд",
          value: "_days_in_row",
        },
      ];
      const headers = [...this.currentHeaders, ...additionalHeaders];
      const data = [];
      for (const el of this.currentData) {
        if (detail) {
          for (const secondItem of el.second_items) {
            const item = {};
            for (const header of headers) {
              if (header.value.startsWith("_")) {
                const v = header.value.slice(1);
                item[header.text] = header.format ? header.format(eval(`secondItem.${v}`)) :  eval(`secondItem.${v}`);
              } else {
                item[header.text] = header.format ? header.format(el[header.value]) : el[header.value];
              }
            }
            data.push(item);
          }
        } else {
          const item = {};
          for (const header of this.currentHeaders)
            item[header.text] = el[header.value];
          data.push(item);
        }
      }
      const XLSX = require("xlsx");

      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "data.xlsx");
      this.exportLoading = false;
    },
  },
  computed: {
    headersByNomenclature() {
      return [
      {
        text: "Номенклатура",
        align: "start",
        sortable: true,
        value: "nomenclatureName",
      },
      {
        text: "Общее недоступное время",
        align: "start",
        sortable: true,
        value: "totalUnavailableTime",
        format: (v) => this.secondsToTime(v)
      },
      {
        text: "Среднее недоступное время",
        align: "start",
        sortable: true,
        value: "avgUnavailableTime",
        format: (v) => this.secondsToTime(v)
      },
      {
        text: "Количество точек на которых была недоступна",
        align: "start",
        sortable: true,
        value: "outletsCount",
      },
    ]},
    headersByOutlets() {
      return [
        {
          text: "Номер точки",
          align: "start",
          sortable: true,
          value: "outletNumber",
        },
        {
          text: "Адрес точки",
          align: "start",
          sortable: true,
          value: "outletAddress",
        },
        {
          text: "Компания",
          align: "start",
          sortable: true,
          value: "outletCompanyName",
        },
        {
          text: "Дней с неполным меню",
          align: "start",
          sortable: true,
          value: "daysWithPartialMenu",
        },
        {
          text: "Дней с неполным меню подряд",
          align: "start",
          sortable: true,
          value: "daysWithPartialMenuInRow",
        },
        {
          text: "Общее время недоступности номенклатуры",
          align: "start",
          sortable: true,
          value: "nomenclaturesTotalUnavailableTime",
          format: (v) => this.secondsToTime(v)
        },
        {
          text: "Среднее время недоступности одной номенклатуры",
          align: "start",
          sortable: true,
          value: "nomenclaturesAvgUnavailableTime",
          format: (v) => this.secondsToTime(v)
        },
        {
          text: "Количество заблокированной номенклатуры",
          align: "start",
          sortable: true,
          value: "nomenclaturesCount",
        },
      ]
    },
    outletsByIds() {
      let data = {};
      for (const item of this.$outlet.list) {
        data[item.id] = item;
      }
      return data;
    },
    nomenclaturesByIds() {
      let data = {};
      for (const item of this.$nomenclature.list) {
        data[item.id] = item;
      }
      return data;
    },
    productsByIds() {
      let data = {};
      for (const item of cloneDeep(this.$product.list)) {
        item.name = item.vendor_name || item.name;
        data[item.id] = item;
      }
      return data;
    },
    currentData() {
      let data = cloneDeep(this.results);
      if (
        !data ||
        !Object.keys(this.outletsByIds).length ||
        !Object.keys(this.nomenclaturesByIds).length ||
        !Object.keys(this.productsByIds).length
      )
        return [];
      for (const item of data.sort(
        (a, b) => b.second_items.length - a.second_items.length
      )) {
        const itemsByIdsByType = {
          outlet: this.outletsByIds,
          nomenclature: this.nomenclaturesByIds,
          product: this.productsByIds,
        };
        item.source.source = itemsByIdsByType[this.type][item.source.source];
        // item.source.avg_time = this.secondsToTime(item.source.avg_time);
        // item.source.total_time = this.secondsToTime(item.source.total_time);
        //
        if (this.type === "outlet") {
          item.outletNumber = item.source.source.number;
          item.outletAddress = item.source.source.address;
          item.outletCompanyName = item.source.source.company.name;
          item.daysWithPartialMenu = item.source.total_days;
          item.daysWithPartialMenuInRow = item.source.days_in_row;
          item.nomenclaturesTotalUnavailableTime = item.source.total_time;
          item.nomenclaturesAvgUnavailableTime = item.source.avg_time;
          item.nomenclaturesCount = item.second_items.length;
        } else {
          item.nomenclatureName = item.source.source.name;
          item.totalUnavailableTime = item.source.total_time;
          item.avgUnavailableTime = item.source.avg_time;
          item.outletsCount = item.second_items.length;
        }
        //
        for (const secondItem of item.second_items) {
          const itemsByIdsByTypeSecond = {
            outlet: this.byProducts
              ? this.productsByIds
              : this.nomenclaturesByIds,
            nomenclature: this.outletsByIds,
            product: this.outletsByIds,
          };
          secondItem.source =
            itemsByIdsByTypeSecond[this.type][secondItem.source];
          // secondItem.avg_time = this.secondsToTime(secondItem.avg_time);
          // secondItem.total_time = this.secondsToTime(secondItem.total_time);
        }
      }
      if (this.search) {
        const f = (values, ...keys) => {
          const items = [];
          for (const v of values) {
            for (const key of keys) {
              const val = eval(`v.source.source.${key}`);
              if (
                (val || "").toLowerCase().includes(this.search.toLowerCase())
              ) {
                items.push(v);
                break;
              }
            }
          }
          return items;
        };
        if (this.type === "outlet") data = f(data, "address", "company.name");
        else data = data = f(data, "name");
      }
      return data;
    },
    currentHeaders() {
      return ["nomenclature", "product"].includes(this.type)
        ? this.headersByNomenclature
        : this.headersByOutlets;
    },
    secondItems() {
      const sourceKeyByType = {
        outlet: "name",
        nomenclature: "address",
        product: "address",
      };
      if (!this.currentItem) return [];
      const search = this.modalSearch.toLowerCase();
      if (!search) return this.currentItem.second_items;
      return this.currentItem.second_items.filter((v) => {
        const value = v.source[sourceKeyByType[this.type]];
        return value.toLowerCase().includes(search);
      });
    },
  },
  watch: {
    byProducts(v) {
      if (v && this.type === "nomenclature") {
        this.type = "product";
      } else if (!v && this.type === "product") {
        this.type = "nomenclature";
      }
      this.loadAnalytics(this.type);
    },
    search_() {
      this.setSearch()
    }
  },
};
</script>
<style scoped>
#parent {
  position: absolute;
  width: 100%;
  //height: calc(100vh - 64px); overflow: hidden;
}

table {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  overflow: hidden;
  /* Use this to create a "dead" area color if table is too wide for cells */
}

thead {
  /*
  Grow thead automatically to fit content, don't shrink it
  proportionately to the body.
  */
  flex: 1 0 auto;
  display: block;
  /* x-scrolling will be managed via JS */
  overflow-x: hidden;
  /*
  Keep header columns aligned with useless scrollbar.
  For IE11, use "dead area" color to hide scrollbar functions
  */
  overflow-y: scroll;
  scrollbar-base-color: #ccc;
  scrollbar-face-color: #ccc;
  scrollbar-highlight-color: #ccc;
  scrollbar-track-color: #ccc;
  scrollbar-arrow-color: #ccc;
  scrollbar-shadow-color: #ccc;
  scrollbar-dark-shadow-color: #ccc;
}

thead::-webkit-scrollbar {
  display: block;
  background-color: #ccc;
}

thead::-webkit-scrollbar-track {
  background-color: #ccc;
}

/* Scroll the actual tbody (second child on all browsers) */
tbody {
  display: block;
  overflow: scroll;
}

/* IE11 adds an extra tbody, have to hide it */
tbody:nth-child(3) {
  display: none;
}

/* The one caveat, a hard-set width is required. */
td,
th {
  width: 10em;
  min-width: 10em;
  padding: 0.3em;
  border: 1px solid #ddd;
  background-color: white;
}

th {
  background-color: #f7f7f7;
}

td:first-child,
th:first-child {
  position: sticky;
  position: -webkit-sticky;
  left: 0;
}

.list-item {
  padding: 5px 5px;
  border-radius: 7px;
  cursor: pointer;
}

.list-item:hover {
  background: #e3e3e3;
}
</style>
