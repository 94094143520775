const m = require('air-vue-model')()
m.url = ''
m.name = 'userRolePushSetting'

m.default = {
    notification_type: null,
    is_required: false,
}

m.routes = []
module.exports = m