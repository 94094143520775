<template>
    <template-item
        :show-actions="$auth.hasRight('reason__edit_information')"
        :model="$lineProductGroup" v-slot="{object, rules}" v-if="item">
        <v-row no-gutters align="center">
            <v-menu offset-y :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on" class="mt-3 mr-3">
                        <v-icon :style="`color: ${item.color}`" size="30">mdi-checkbox-blank-circle</v-icon>
                    </div>
                </template>
                <v-card v-if="$auth.hasRight('tracker__edit_information')">
                    <v-color-picker
                        mode.sync="hex"
                        v-model="item.color"
                        dot-size="30"
                    ></v-color-picker>
                </v-card>
            </v-menu>
            <v-text-field class="mt-3" v-model="item.name" outlined dense hide-details
                          label="Наименование"></v-text-field>
        </v-row>
        <v-col class="pa-0">
            <v-row no-gutters align="center" class="my-5">
                <div class="font-weight-medium">Товары</div>
                <v-spacer></v-spacer>
                <v-btn @click="dialog = true" v-if="$auth.hasRight('tracker__edit_information')" depressed
                       color="primary" class="text-none">Изменить
                </v-btn>
            </v-row>
            <v-col class="pa-0" v-for="(product, index) in item.products" :key="index">
                <v-row no-gutters>
                    <v-img max-width="40" height="40" aspect-ratio="1" contain
                           :src="product.image || '/images/not-found.png'"/>
                    <div class="text-body-2 ml-4" v-text="product.name"/>
                </v-row>
            </v-col>
        </v-col>
        <v-dialog v-model="dialog" max-width="700">
            <v-card class="pa-5">
                <v-row no-gutters class="mb-3">
                    <v-btn depressed color="primary" @click="accept">Сохранить</v-btn>
                    <v-spacer></v-spacer>
                    <search :rounded="false" always v-model="search"></search>
                </v-row>
                <v-col class="pa-0">
                    <v-col class="pa-0" v-for="(group, groupIndex) in $productGroup.list"
                           v-if="filterItems(group.products.filter(val => val.active)).length" :key="groupIndex">
                        <div class="font-weight-bold">{{ group.name }}</div>
                        <v-col class="pa-0"
                               v-for="(product, index) in filterItems(group.products.filter(val => val.active))"
                               :key="index">
                            <v-row no-gutters align="center" class="black--text" @click="setProduct(product)">
                                <v-checkbox dense class="pa-0 ma-0" hide-details
                                            :input-value="selectedItemIds.indexOf(product.id) > -1"></v-checkbox>
                                <v-img max-width="40" height="40" aspect-ratio="1" contain
                                       :src="product.image || '/images/not-found.png'"/>
                                <div class="text-body-2 ml-4 link" v-text="product.name"/>
                            </v-row>
                        </v-col>
                    </v-col>
                </v-col>
            </v-card>
        </v-dialog>
    </template-item>
</template>

<script>

import search from "air-vue-model/search";
import Search from "@/components/template/Search";

export default {
    name: 'LineProductGroup',
    components: {
        Search
    },
    data: () => ({
        dialog: false,
        search: null,
        selectedItems: [],
    }),
    methods: {
        accept() {
            this.item.products = this.selectedItems
            this.dialog = false
        },
        filterItems(items) {
            return search(this.search, items,)
        },
        setProduct(value) {
            const index = this.selectedItemIds.indexOf(value.id)
            console.log(index)
            if (index === -1) this.selectedItems.push(value)
            else this.selectedItems.splice(index, 1)
        }
    },
    computed: {
        item() {
            return this.$lineProductGroup.item
        },
        selectedItemIds() {
            return this.selectedItems.map(v => v.id)
        }
    },
    created() {
        console.log(this.$productGroup)
        if (!this.$productGroup.list.length) this.$productGroup.loadList()
    },
    watch: {
        dialog(val) {
            if (val) this.selectedItems = this.$productGroup.copy(this.item.products)
        }
    }

}
</script>

<style scoped>

</style>