<template>
    <div>
        <v-row no-gutters align="center" style="flex-wrap: nowrap">
            <v-btn :x-small="small" v-if="outlined" icon color="primary" outlined @click="decrement">
                <v-icon :x-small="small">mdi-minus</v-icon>
            </v-btn>
            <v-btn elevation="1" v-else :x-small="small" icon class="primary" @click="decrement">
                <v-icon :x-small="small" color="white">mdi-minus</v-icon>
            </v-btn>
            <div class="text-body-2 mx-3" v-text="amount"/>
            <v-btn v-if="outlined" :x-small="small" icon color="primary" outlined @click="increment">
                <v-icon :x-small="small">mdi-plus</v-icon>
            </v-btn>
            <v-btn elevation="1" v-else :x-small="small" icon class="primary" @click="increment">
                <v-icon :x-small="small" color="white">mdi-plus</v-icon>
            </v-btn>
        </v-row>
    </div>
</template>

<script>
    export default {
        name: "AmountChanger",

        props: {
            amount: Number,
            outlined: {
                type: Boolean,
                default: false,
            },
            small: {
                type: Boolean,
                default: false,
            },
        },

        methods: {
            increment() {
                this.$emit('increment')
            },
            decrement() {
                this.$emit('decrement')
            },
        }
    }
</script>
