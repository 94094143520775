<template>
    <apexchart :width="width || '380'" type="donut" :options="options" :series="values"></apexchart>
</template>

<script>
export default {
    name: 'Donat',
    props: {
        values: Array,
        labels: Array,
        width: {
            type: String,
            required: false
        }
    },
    data: () => ({}),
    methods: {},
    computed: {
        options() {
            return {
                labels: this.labels,
            }
        }
    }
}
</script>

<style scoped>

</style>