<template>
    <template-item
        :model="$cookStation"
        :back-route="{name: 'outletCookStations'}"
        name-to="outletCookStationDetail"
        v-slot="{ object }"
    >

        <v-row no-gutters align="center" style="gap: 8px">
            <v-text-field
                v-model="object.name"
                dense
                hide-details
                label="Наименование"
                outlined
            ></v-text-field>
        </v-row>
        <outlet-item-lines v-if="object.id" :cook-station="object.id"></outlet-item-lines>
    </template-item>
</template>

<script>

import Search from "@/components/template/Search.vue";
import OutletQueueData from "@/admin/views/outlets/OutletQueueData.vue";
import OutletItemLines from "@/admin/views/outlets/OutletItemLines.vue";

export default {
    name: "OutletPostList",
    components: {OutletItemLines, OutletQueueData, Search},
    mounted() {
        if (!this.$cookStation.item.outlet) {
            this.$cookStation.item.outlet = this.$outlet.item.id
        }
    }
};
</script>

<style scoped></style>
