<template>
    <v-card
        tile class="pa-3 ma-2 rounded-lg elevation-1"
        :to="{ name: 'orderItem', params: { orderId: order.id } }"
    >
        <v-row no-gutters align="center" class="body-2" justify="space-between">
            <div v-text="calendarDateTime(order.preferred_time)"/>
            <div class="font-weight-medium">{{ order.sum }} ₽</div>
        </v-row>
        <v-row no-gutters align="center" class="mt-1" justify="space-between">
            <div class="text-caption text--secondary" v-text="order.outlet.address"/>
            <div class="text-caption text--secondary" v-text="$order.parseStatus(order.status)"/>
        </v-row>
        <v-row align="center" class="mb-1 mt-2" no-gutters>
            <div class="body-2">Товары</div>
            <v-divider class="ml-2"></v-divider>
        </v-row>
        <v-row
            v-for="(orderItem, index) in order.items" :key="index"
            class="pb-1" no-gutters align="center" justify="space-between"
        >
            <div class="text-caption">{{ orderItem.product.name }}</div>
            <div class="text-caption mr-1">
                {{ orderItem.amount }} x {{ orderItem.price }} ₽
            </div>
        </v-row>
    </v-card>
</template>

<script>
import datetime from "@/vendor/datetime";

export default {
    name: "OrderListItem",
    mixins: [datetime],
    props: {
        order: Object
    }
}
</script>

<style scoped>

</style>