export default {
    data: () => ({
        rules: {
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(value) || 'Некорректный e-mail'
            },
            required: value => !!value || 'Поле обязательно для заполнения',
            match: p => v => (v || '') === p || 'Пароли должны совпадать',
            length: p => v => v.length >= p || `Минимальная длина ${p}`,
            // phone: v => v[4] === '9' || 'Некорректный номер телефона',
            // phoneLength: val => val.length === 17 || `Длина должна составлять 11 символа`,
        },
    }),
}