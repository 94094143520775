<template>
    <v-col class="pa-0">
        <div class="text-h6 ma-3">Мои заказы</div>
        <div v-if="ordersLoading" class="d-flex flex-column align-center justify-center fill-height">
            <v-progress-circular
                indeterminate
                color="primary"
                :size="50"
            />
        </div>
        <div
            v-else-if="orders.length === 0"
            class="d-flex flex-column fill-height align-center justify-center py-10 px-3"
        >
            <v-icon :size="50" color="grey">mdi-book-open-blank-variant</v-icon>
            <div class="text-h6 font-weight-regular text--secondary my-2">
                Здесь пока пусто
            </div>
            <v-btn
                rounded
                depressed
                color="primary"
                @click="$router.push({ name: 'products' })"
            >
                <div class="text-none">Сделайте Ваш первый заказ</div>
            </v-btn>
        </div>
        <order-list-item :order="order" v-for="(order, index) in orders" :key="index"/>
        <div class="my-3">
            <v-pagination
                v-if="orders.length > 0"
                class="mt-3"
                v-model="$order.pagination.page"
                :length="$order.pagination.last_page"
                @input="loadOrders"
            />
        </div>
    </v-col>
</template>

<script>
import OrderListItem from "@/main/components/order/OrderListItem";

export default {
    name: "OrderList",
    components: {
        OrderListItem
    },
    data: () => ({
        ordersLoading: false,
    }),
    methods: {
        async loadOrders() {
            this.ordersLoading = true
            await this.$order.loadList({
                page: this.$order.pagination.page,
                page_size: 10,
            })
            this.ordersLoading = false
        },
    },
    computed: {
        orders() {
            return this.$order.list
        },
    },
    mounted() {
        this.loadOrders();
    },
}
</script>

<style scoped>

</style>