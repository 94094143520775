<template>
    <v-container>
        <v-row no-gutters justify="center" class="py-10">
            <v-img max-width="60%" src="/images/logo-long.png"/>
        </v-row>
        <div class="d-flex align-center justify-center pt-10 fill-height">
            <div>
                <v-row no-gutters justify="center" class="py-5">
                    <v-icon color="primary" :size="100">mdi-credit-card-check-outline</v-icon>
                </v-row>
                <v-row no-gutters justify="center" class="py-5">
                    <div class="text-h4 text-center font-weight-regular">
                        Ваш заказ <br> успешно оплачен
                    </div>
                </v-row>
                <v-row no-gutters align="center" justify="center">
                    <v-btn depressed x-large class="rounded-lg"
                           color="primary"
                           :to="{name: 'orderItem', params: {orderId: $route.query.currentOrder}}"
                           v-if="$route.query.currentOrder">
                        Вернуться к заказу
                    </v-btn>
                </v-row>
            </div>
        </div>
    </v-container>
</template>

<script>
export default {
    name: "PaymentSuccessful",

    created() {
        this.$store.state.loading = false

    }
}
</script>
