<template>
    <v-col class="pa-0">
        <v-row no-gutters class="my-3" align="center">
            <div class="text-h6 mr-3">Общая информация</div>
            <v-divider/>
            <v-btn :loading="$company.loadings.action" depressed color="primary"
                   class="ml-3 rounded-lg text-none body-2"
                   v-if="$auth.hasRight('companies__edit_information')"
                   @click="savePaymentInfo">Сохранить
            </v-btn>
        </v-row>
        <v-text-field class="mt-5" label="ИНН" outlined hide-details dense v-model="item.inn"></v-text-field>
      <v-text-field class="mt-5" label="Юр. адрес" outlined hide-details dense v-model="item.legal_address"></v-text-field>

        <v-row class="mt-5" no-gutters align="center">
            <v-col col="6" class="pr-3">
                <v-text-field label="E-mail" outlined hide-details dense
                              v-model="item.email"></v-text-field>
            </v-col>
            <v-col col="6">
                <v-text-field label="Домен" outlined hide-details dense
                              v-model="item.domain"></v-text-field>
            </v-col>
        </v-row>
        <v-row class="mt-5" no-gutters align="center">
            <v-col col="6" class="pr-3">
                <v-select label="Система налогооблажения" v-model="item.sno" :items="sno"
                          item-text="name" outlined dense hide-details
                          item-value="value"></v-select>
            </v-col>
            <v-col col="6">
                <v-select label="НДС" v-model="item.vat" :items="vat"
                          item-text="name" outlined dense hide-details
                          item-value="value"></v-select>
            </v-col>
        </v-row>
        <v-textarea height="220" class="mt-5" label="Платежная информация компании" outlined hide-details dense
                    v-model="item.payment_info"></v-textarea>
    </v-col>
</template>

<script>

export default {
    name: 'CompanyItemBase',
    components: {},

    data: () => ({
        sno: [
            {value: 'osn', name: 'Общая СН'},
            {value: 'usn_income', name: 'Упрощенная СН (доходы)'},
            {value: 'usn_income_outcome', name: 'Упрощенная СН (доходы минус расходы)'},
            {value: 'envd', name: 'Единый налог на вмененный доход'},
            {value: 'esn', name: 'Единый сельскохозяйственный налог'},
            {value: 'patent', name: 'патентная СН'},
        ],
        vat: [
            {value: 'none', name: 'Без НДС'},
            {value: 'vat0', name: 'НДС 0'},
            {value: 'vat10', name: 'НДС 10'},
            {value: 'vat110', name: 'НДС 110'},
            {value: 'vat20', name: 'НДС 20'},
            {value: 'vat120', name: 'НДС 120'},
        ]
    }),
    computed: {
        item() {
            return this.$company.item
        }
    },
    methods: {
        saveItem() {
            this.$company.update().then(value => {
                this.$store.commit('setSnackbar', {color: 'success', text: 'Компания сохранена'})
            }).catch(err => {
                this.$store.commit('setSnackbar', {color: 'fail', text: 'Не удалось сохранить'})
            })
        },
        savePaymentInfo() {
            this.$company.sendPostSingle('set_payment_info', this.$company.item.id, {
                domain: this.$company.item.domain,
                payment_info: this.$company.item.payment_info,
                production_payments: this.$company.item.production_payments,
                inn: this.$company.item.inn,
                email: this.$company.item.email,
                sno: this.$company.item.sno,
                vat: this.$company.item.vat,
                legal_address: this.$company.item.legal_address,
            }).then(res => {
                this.$store.commit('setSnackbar', {color: 'success', text: 'Сохранено'})
            }).catch(() => {
                this.$store.commit('setSnackbar', {color: 'error', text: 'Не удалось сохранить'})
            })
        },
    },
    watch: {}
}
</script>

<style scoped>

</style>