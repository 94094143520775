const m = require('air-vue-model')()
m.url = 'balance_operations'
m.name = 'balanceOperation'
m.text = {
    itemTitle: 'Операции по балансу',
    listTitle: 'Операции по балансу'
}
m.filters = {
    search: '',
    start_date: null,
    end_date: null,
    sum_type: null
}
m.routes = [
    {
        name: 'list',
        component: require('@/admin/views/balanceOperations/BalanceOperations.vue'),
    },
]
module.exports = m