<template>
  <div style="width: auto">
    <template-list
      :auto-load-list="false"
      :model="$equipment"
      :show-create-button="false"
    >
      <template
        v-if="$auth.hasRight('reason__edit_information')"
        v-slot:actions
      >
        <v-btn
          class="rounded-lg"
          color="primary"
          depressed
          height="40"
          @click="openModal()"
        >
          <div class="text-none body-2 px-2">Создать</div>
        </v-btn>
      </template>
      <template v-slot="{ object, index }">
        <v-col class="pa-0 link" @click="openModal(object)">
          <v-row no-gutters>
            <div class="ml-2 font-weight-medium text-truncate">
              {{ object.name }}
            </div>
          </v-row>
        </v-col>
      </template>
    </template-list>
    <v-dialog v-model="dialog" max-width="450">
      <v-card
        v-if="dialogItem"
        class="pa-5 rounded-lg"
        style="overflow-x: auto"
      >
        <div class="font-weight-bold mb-5">
          {{
            dialogItem.id ? "Обновление оборудования" : "Создание оборудования"
          }}
        </div>
        <v-text-field v-model="dialogItem.name" label="Текст" readonly />
        <v-select
          v-model="dialogItem.type"
          :items="$equipment.types"
          item-text="label"
          item-value="value"
          label="Тип"
        />

        <v-row class="px-2 mt-0">
          <v-btn
            :disabled="!dialogItem.type"
            :loading="loading"
            color="primary"
            @click="saveDialogItem()"
            >Сохранить
          </v-btn>
          <v-spacer />
<!--          <v-btn-->
<!--            v-if="dialogItem.id"-->
<!--            :loading="loading"-->
<!--            color="error"-->
<!--            outlined-->
<!--            @click="deleteDialogItem()"-->
<!--            >Удалить-->
<!--          </v-btn>-->
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { cloneDeep } from "lodash";

export default {
  name: "EquipmentList.vue",
  data: () => ({
    dialog: false,
    dialogItem: null,
    loading: false,
  }),
  methods: {
    loadList() {
      this.$equipment.loadList();
    },
    openModal(_item) {
      let item = cloneDeep(_item);
      if (!item) item = cloneDeep(this.$equipment.default);
      this.dialogItem = item;
      this.dialog = true;
    },
    saveDialogItem() {
      const create = !this.dialogItem.id;
      const item = this.dialogItem;
      this.loading = true;
      if (create) {
        this.$equipment
          .create(item)
          .then((v) => {
            this.$equipment.list.push(v);
            this.dialog = false;
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.$equipment
          .update(item)
          .then((v) => {
            this.dialog = false;
            const index = this.$equipment.list.findIndex(
              (el) => el.id === v.id
            );
            if (index > -1) this.$equipment.list[index] = v;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    deleteDialogItem() {
      this.loading = true;
      this.$equipment
        .delete(this.dialogItem.id)
        .then(() => {
          this.dialog = false;
          const index = this.$equipment.list.findIndex(
            (v) => v.id === this.dialogItem.id
          );
          if (index > -1) this.$equipment.list.splice(index, 1);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.loadList();
  },
  watch: {
    dialog() {
      if (!this.dialog) this.dialogItem = null;
    },
  },
};
</script>

<style scoped></style>
