<template>
  <!--  :show-create-button="$auth.hasRight('roles__edit_information')"-->
  <template-list :model="$promocodeCondition" :auto-load-list="false" :show-create-button="$auth.hasRight('promocode_condition__edit')">
    <template v-slot:subactions>
      <v-row no-gutters style="gap: 10px">
        <v-btn
          depressed
          :color="
            selectedType === $promocodeCondition.typeEnum.DEFAULT
              ? 'primary'
              : ''
          "
          @click="selectedType = $promocodeCondition.typeEnum.DEFAULT"
        >
          <div class="text-none">Обычные</div>
        </v-btn>
        <v-btn
          depressed
          :color="
            selectedType === $promocodeCondition.typeEnum.TEXT ? 'primary' : ''
          "
          @click="selectedType = $promocodeCondition.typeEnum.TEXT"
        >
          <div class="text-none">Текстовые</div>
        </v-btn>
      </v-row>
    </template>
    <template v-slot="{ object, to, index }">
      <v-col class="pa-0 body-2 pointer">
        <router-link :to="to" style="width: 100%; height: 100%">
          <v-row no-gutters align="start link">
            <v-col class="">
              <div>
                Наименование: <b>{{ object.name }}</b>
              </div>
              <div style="margin-top: 2px">
                Статус:
                <b
                  :style="{
                    color: $promocodeCondition.statusColor[object.status],
                  }"
                >
                  {{ $promocodeCondition.statusName[object.status] }}</b
                >
              </div>
              <div style="margin-top: 2px">
                {{ getConditionPerionAsText(object) }}
              </div>
            </v-col>
          </v-row>
        </router-link>
      </v-col>
    </template>
  </template-list>
</template>

<script>
import { parseDate } from "@/services/parseDate";

export default {
  name: "PromocodeConditionsList",
  components: {},
  data: () => ({
    selectedType: 1,
  }),
  methods: {
    getConditionPerionAsText(item) {
      if (!item.start_date && !item.end_date)
        return "Период действия не ограничен";
      let res = "";
      if (item.start_date) res = `с ${parseDate(item.start_date)} `;
      if (item.end_date) res += `по ${parseDate(item.end_date)}`;
      return res;
    },
    loadList() {
      this.$promocodeCondition.filters.type = this.selectedType;
      this.$promocodeCondition.loadList({ type: this.selectedType });
    },
  },
  created() {
    const selectedType = this.$route.query.type
    if (selectedType) this.selectedType = Number(selectedType)
    this.loadList();
  },
  watch: {
    'selectedType'() {
      if (Number(this.$route.query.type) !== this.selectedType)
      this.$router.replace({name: this.$route.name, query: {type: this.selectedType}})
      this.loadList()
    }
  }
};
</script>
