<template>
    <v-col class="pa-0 px-3">
        <div class="text-h4 font-weight-bold pt-3 pb-4">Подключение к вебсокету</div>
        <v-row no-gutters>
            <v-col cols="6">
                <v-row no-gutters align="center">
                    <v-text-field class="rounded-lg" style="max-width: 300px" v-model="outlet" label="ID торговой точки"
                                  outlined
                                  hide-details
                                  dense></v-text-field>
                    <v-btn height="40" depressed color="primary" class="ml-3 rounded-lg text-none body-2"
                           @click="connect" :disabled="!outlet" v-if="!socket">
                        Подключить
                    </v-btn>
                    <v-btn height="40" depressed color="primary" class="ml-3 rounded-lg text-none body-2" @click="close"
                           v-else>Отключить
                    </v-btn>
                    <v-btn height="40" outlined color="primary" class="ml-3 rounded-lg text-none body-2"
                           @click="messages = []">Очистить
                    </v-btn>
                </v-row>
                <div class="mt-2 mb-1 font-weight-bold">Сообщения:</div>
                <v-col class="pa-0 mb-1" v-for="(el, index) in messages" :key="index">
                    <div class="body-2">{{ el.title }}</div>
                    <div class="caption">{{ el.value }}</div>
                </v-col>
            </v-col>
        </v-row>
    </v-col>
</template>

<script>
export default {
    name: 'Websocket',
    data: () => ({
        messages: [],
        outlet: null,
        socket: null,
    }),
    methods: {
        connect() {
            const token = localStorage.getItem('access')
            const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws'
            console.log(window.location)
            this.socket = new WebSocket(`${protocol}://${window.location.host}/ws/orders/${this.outlet}/?token=${token}`);
            this.socket.onopen = e => {
                this.messages.push({
                    title: `Подключение установлено к торговой точке ${this.outlet}`,
                    value: e.currentTarget.url
                })

                this.socket.send(JSON.stringify({'message': 'Я подключился'}))
            }
            this.socket.onclose = e => {
                this.messages.push({
                    title: `Подключение закрыто`,
                    value: e.currentTarget.url
                })
                this.socket = null
            }

            this.socket.onmessage = e => {
                console.log(e)
                this.messages.push({
                    title: 'Получено сообщение',
                    value: JSON.parse(e.data)
                })
            }
        },
        close() {
            this.socket.close()
        },
        sendKek() {
            axios.post('http://127.0.0.1:8000/api/v1/orders/demo/').then(res => {

            })
        }
    },
}
</script>

<style scoped>

</style>