<template>
  <div>
    <v-row
      :key="crutch"
      class="pa-0 mx-1 mt-5 rounded mb-2 font-weight-bold"
      style="background: #f5f5f5"
    >
      <v-col align-self="center" class="py-0" cols="4"
        >Уведомления для роли
      </v-col>
      <v-col class="py-0">
        <v-btn class="pa-0 text-none" text>Обязательное</v-btn>
      </v-col>
    </v-row>
    <v-row
      v-for="(notification, index) in object.push_settings"
      :key="`${notification.id}${notification.name}`"
      class="pa-0"
    >
      <v-col align-self="center" class="pl-6 py-0" cols="4"
        >{{ notification.name }}
      </v-col>
      <v-col align-self="center" class="pa-0 ml-8">
        <v-checkbox
          v-model="notification.is_required"
          class="pa-0 ma-0"
          hide-details
        />
      </v-col>
      <v-col align-self="center" class="pa-0 mr-8">
        <v-row class="ma-0" justify="end">
          <v-icon @click="object.push_settings.splice(index, 1)"
            >mdi-delete
          </v-icon>
        </v-row>
      </v-col>
      <v-col class="pa-0 my-3 px-3" cols="12">
        <v-divider class="mx-2" />
      </v-col>
    </v-row>
    <div v-if="!object.push_settings?.length" class="mt-8 ml-4 body-1">
      Уведомления не заданы
    </div>
    <v-btn
      class="mt-10 ml-2"
      color="primary"
      outlined
      @click="addNotificationDialog = true"
      >Добавить уведомление
    </v-btn>
    <v-dialog v-model="addNotificationDialog" max-width="600px">
      <v-list>
        <div class="body-1 mb-3 mt-3 font-weight-bold px-4">
          Выберите уведомления
        </div>
        <div
          v-if="!availableNotifications.length"
          class="body-1 mb-3 mt-5 px-4"
        >
          Все доступные уведомления уже выбраны
        </div>
        <template v-for="(el, index) in availableNotifications">
          <v-divider v-if="index" class="mx-3"></v-divider>
          <v-list-item :key="el.id" @click="pushItem(el)">
            {{ el.name }}
          </v-list-item>
        </template>
      </v-list>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "UserRoleItemPushes",
  props: { object: Object },
  data: () => {
    return {
      values: [],
      crutch: 0,
      addNotificationDialog: false,
    };
  },
  methods: {
    pushItem(item) {
      const el = {
        notification_type: item.id,
        name: item.name,
        is_required: false,
      };
      this.$props.object.push_settings.push(el);
    },
    setNames() {
      this.$nextTick(() => {
        const items = this.$props.object.push_settings;
        if (!items) return;
        const flatItems = this.values.flatMap((v) => v.children);
        for (const el of items) {
          const found = flatItems.find((v) => v.id === el.notification_type);
          if (found) el.name = found.name;
        }
        this.crutch++;
      });
    },
  },
  mounted() {
    this.$eventBus.$on("roleSaved", () =>
      setTimeout(() => this.setNames(), 50)
    );
    this.$notification.sendGetSingle("notification_type_groups").then((res) => {
      this.values = res;
      this.setNames();
    });
  },
  computed: {
    availableNotifications() {
      // if (!this.$props.object.push_settings?.length) return [];
      const currentTypes = this.$props.object.push_settings.map(
        (v) => v.notification_type
      );
      const res = [];
      for (const item of this.values) {
        const items = item.children;
        for (const el of items) {
          const type = el.id;
          if (!currentTypes.includes(type)) res.push(el);
        }
      }
      return res;
    },
    // itemNotifications() {
    //   const data = []
    //   if (!this.$props.object.push_settings?.length) return data
    //   const settings = this.$props.object.push_settings
    //   for (const el of settings) {
    //     if ()
    //     const found = this.values.find(v => v.children.some(child => child.id === el.notification_type))
    //     if (found) {
    //     }
    //   }
    // }
  },
};
</script>

<style scoped></style>
