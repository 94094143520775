<template>
    <template-list name-to="outletCookStationDetail" :model="$cookStation">
        <template v-slot="{object, to, index}">
            <v-col class="pa-0">
                <router-link :to="to">
                    <v-col class="pa-0">
                        <v-row no-gutters align="center" class="link">
                            <div class=" font-weight-bold mr-1">{{ object.name || 'Отсутствует' }}.</div>
                        </v-row>
                        <v-row no-gutters align="center">
                            <div class="caption grey--text text--darken-2 mr-3" v-if="object.company">
                                {{ object.company.name }}
                            </div>
                        </v-row>
                    </v-col>
                </router-link>
            </v-col>
        </template>
    </template-list>
</template>

<script>

import Search from "@/components/template/Search.vue";
import OutletQueueData from "@/admin/views/outlets/OutletQueueData.vue";

export default {
    name: "OutletPostList",
    components: {OutletQueueData, Search},
    methods: {},
    created() {
        this.$cookStation.filters.outlet = this.$outlet.item.id
    }
};
</script>

<style scoped></style>
