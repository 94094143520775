<template>
  <v-col class="pa-0" style="max-height: 300px; overflow-y: auto">
    <v-row
      no-gutters
      align="center"
      v-for="(el, index) in results"
      :key="index"
      style="flex-wrap: nowrap"
    >
      <v-rating
        v-model="el.rating"
        color="yellow darken-3"
        background-color="grey lighten-1"
        empty-icon="$ratingFull"
        half-increments
        hover
        size="20"
      ></v-rating>
      <div
        style="width: 30px"
        class="font-weight-bold yellow--text text--darken-3 mx-3 text-center"
      >
        {{ el.rating }}
      </div>
      <div
        class="body-2"
        style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap"
      >
        {{ el.number }}. {{ el.address }}
      </div>
      <div class="ml-1 caption grey--text">- {{ el.count }}</div>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: "OutletRatings",
  data: () => ({
    results: [],
  }),
  methods: {},
  created() {
    this.$eventBus.$on("loadedOutletsDate", (v) => (this.results = v));
  },
};
</script>

<style scoped></style>
