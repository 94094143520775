<template>
    <template-item
        v-slot="{ object, rules }"
        :model="$productBlank"
        :show-actions="$auth.hasRight('products__edit_information')"
        object-key-for-title="name"
    >
        <v-col>
            <v-text-field
                v-model="object.name"
                class="mb-3"
                dense
                hide-details
                label="Наименование"
                outlined
            />
            <v-text-field
                v-model.number="object.shelf_life"
                class="mb-3"
                dense
                hide-details
                label="Срок хранения (часы)"
                outlined
                type="number"
            />
        </v-col>
    </template-item>
</template>

<script>

export default {
    name: "ProductBlankItem",


    data: () => {
        return {};
    },

    computed: {},

    methods: {},

    created() {
    },
};
</script>
