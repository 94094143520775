const m = require("air-vue-model")();
const airLeaflet = require("air-vue-leaflet")();
m.url = "track_history";
m.name = "trackHistory";
m.setPagination({
  page_size: "all",
});

m.filters = {
  created_by: null,
  start_date: null,
  end_date: null,
};

// m.text = {
//     listTitle: 'Смены', itemTitle: 'Смена',
// }

// m.routes = [
//     {name: 'list', component: require('../admin/views/shift/Shifts')},
//     {name: 'item', component: require('../admin/views/shift/Shift'), single: true},
// ]

m.getPolyLine = (tracks) => {
  const points = [];
  for (const track of tracks) {
    const coords = track.coords;
    points.push([coords[1], coords[0]]);
  }
  const polyLine = L.polyline(points, {
    color: "blue",
    opacity: 0.4,
    weight: 4,
  });
  return L.layerGroup([polyLine]);
};

module.exports = m;
