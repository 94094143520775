<template>
    <template-item :model="$checkout" v-slot="{object, rules}">
        <v-text-field outlined dense hide-details class="mb-3 mt-5" label="Наименование" v-model="object.name"
                      :rules="[rules.required]"></v-text-field>
        <v-text-field outlined dense hide-details class="mb-3" label="Логин" v-model="object.login"
                      :rules="[rules.required]"></v-text-field>
        <v-text-field outlined dense hide-details class="mb-3" label="Пароль" v-model="object.password"
                      :rules="object.id ? [] : [rules.required]"></v-text-field>
        <v-text-field outlined dense hide-details label="Группа" v-model="object.group_code"
                      :rules="[rules.required]"></v-text-field>
        <v-select outlined dense hide-details class="mt-3" item-text="name" item-value="id" v-model="object.company"
                  :items="$company.list"
                  label="Компания"></v-select>
        <v-row no-gutters align="center" v-if="object.id" class="mt-6 body-2">
            <v-icon size="25" :color="object.validated ? 'green' : 'red'">mdi-circle</v-icon>
            <v-btn color="primary" depressed :disabled="!object.id || object.validated" :loading="loading"
                   @click="validate" class="ml-4 rounded-lg">
                Проверить данные кассы
            </v-btn>
            <v-spacer></v-spacer>
<!--            <div class="mr-2">Токен обновлен:</div>-->
            <!--                <v-data-text hide-defaults :date-time="object.token_received_at"></v-data-text>-->
        </v-row>
    </template-item>
</template>

<script>
export default {
    name: 'Checkout',
    data: () => ({
        loading: false
    }),
    methods: {
        validate() {
            this.loading = true
            this.$checkout.sendPostSingle('validate', this.$checkout.item.id).then(res => {
                this.$checkout.item.validated = true
                this.$checkout.item.token_received_at = res.token_received_at
                this.$store.commit('setSnackbar', {
                    color: 'green',
                    text: `Сохранено`
                })
                this.loading = false
            }).catch(() => {
                this.loading = false
                this.$store.commit('setSnackbar', {
                    color: 'red',
                    text: `Ошибка`
                })
            })
        }
    }
}
</script>

<style scoped>

</style>