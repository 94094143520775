<template>
    <div v-if="order" style="position: relative;">
        <v-app-bar v-if="$vuetify.breakpoint.smAndDown" color="white" elevation="1">
            <v-row no-gutters align="center" justify="space-between">
                <v-btn icon @click="$router.back()">
                    <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <div class="text-body-2">
                    Заказ №{{ order.id }}
                </div>
                <v-btn icon>
                    <v-icon>mdi-qrcode</v-icon>
                </v-btn>
            </v-row>
        </v-app-bar>
        <v-container class="pa-2">
            <v-card class="pa-2 elevation-1 rounded-lg">
                <v-row no-gutters align="center" justify="space-between">
                    <div class="text-body-2" v-text="calendarDateTime(order.preferred_time)"/>
                    <div class="text-subtitle-1 font-weight-bold">{{ order.sum }} ₽</div>
                </v-row>
                <div class="text-caption text--secondary" v-text="$order.parseStatus()"/>
            </v-card>

            <v-row no-gutters class="py-2" align="center" justify="space-between">
                <div class="text-subtitle-1 text-md-h6 ml-2">Список товаров</div>
                <v-btn v-if="$vuetify.breakpoint.mdAndUp" depressed color="primary" width="200" class="rounded-lg"
                       @click="renewOrder">
                    <div class="text-none text-body-2">Повторить заказ</div>
                </v-btn>
            </v-row>

            <v-card class="elevation-2 rounded-lg">
                <div class="mb-2" v-for="(orderItem, index) in order.items" :key="index">
                    <v-divider v-if="index"/>
                    <order-product class="px-2" :order-item="orderItem"></order-product>
                    <div class="pb-1"/>
                </div>
            </v-card>

            <div class="text-subtitle-1 my-2 ml-2">Отзыв</div>

            <v-card v-if="order.review" class="pa-2 elevation-1 rounded-lg">
                <v-rating
                    empty-icon="mdi-star-outline" full-icon="mdi-star" background-color="primary"
                    hover length="5" size="20" readonly v-model="order.review.rating"
                />
                <div class="text-body-2" style="white-space: pre-line" v-text="order.review.comment"/>
            </v-card>

            <v-card v-else class="pa-2 elevation-1 rounded-lg">
                <v-row no-gutters class="mb-2" align="center" justify="space-between">
                    <v-rating
                        empty-icon="mdi-star-outline" full-icon="mdi-star" dense
                        background-color="primary" hover length="5" size="24" v-model="rating"
                    />
                    <v-btn depressed color="primary" :loading="loading" @click="setReview">
                        <div class="text-none text-body-2">Оставить отзыв</div>
                    </v-btn>
                </v-row>
                <v-textarea
                    color="primary" dense hide-details outlined v-model="comment"
                    label="Ваше мнение о заказе (необязательно)" no-resize
                />
            </v-card>

            <div class="text-subtitle-1 mt-2 ml-2">Кафе</div>
            <div class="text-body-2 mb-2 ml-2" v-text="order.outlet.address"/>
            <v-card flat class="rounded-lg">
                <div id="map" style="height: 200px; z-index: 2;"/>
            </v-card>

            <div style="height: 50px"/>
        </v-container>
        <div v-if="$vuetify.breakpoint.smAndDown" class="pa-2" style="position: fixed; bottom: 56px; width: 100%; z-index: 10;">
            <v-btn depressed block color="primary" height="40" class="rounded-lg" @click="renewOrder">
                <div class="text-none text-body-2">Повторить заказ</div>
            </v-btn>
        </div>
    </div>
</template>

<script>
import datetime from "../../../vendor/datetime";
import OrderProduct from "@/main/components/order/OrderProduct";

export default {
    name: "Order",

    mixins: [datetime],
    components: {
        OrderProduct
    },

    data: () => ({
        rating: 5,
        comment: null,
        loading: false,
    }),

    computed: {
        order() {
            return this.$order.item
        },
    },

    methods: {
        async loadOrder() {
            this.$store.commit('setLoading', true)
            const {orderId} = this.$route.params
            try {
                await this.$order.loadItem(orderId)
                this.initMap()
                this.$store.commit('setLoading', false)
            } catch (e) {
                if (e.response.status === 404) await this.$router.replace({name: '404'})
            }
        },

        async setReview() {
            this.loading = true
            await this.$order.setReview(this.rating, this.comment)
            this.loading = false
            this.rating = 5
            this.comment = null
        },

        async renewOrder() {
            this.$store.state.selectedOutletId = this.order.outlet.id
            this.$store.state.cartItems = []

            const cart_items = []
            for (const orderItem of this.order.items) {
                cart_items.push({
                    product: orderItem.product,
                    ingredients: orderItem.ingredients.map(e => e.ingredient),
                    amount: orderItem.amount,
                })
            }
            const response = await axios.post('/products/get_extended_cart/', {
                cart_items
            })
            const {data} = response
            this.$store.state.cartItems = data
            await this.$router.push({name: 'cart'})
        },

        initMap() {
            this.$L.initMap('map', [54.5, 20.5])
            const points = []
            const {id, coords} = this.order.outlet
            points.push({id, coords: [coords[1], coords[0]]})
            const pointCollection = this.$L.pointCollection(points)
            this.$L.pointLayer(pointCollection, null, 'red', null,
                '/images/logo-short.png', {iconSize: [20, 28]}).addTo(this.$L.map)
        }
    },

    mounted() {
        this.loadOrder()
    }
}
</script>
