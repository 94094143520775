<template>
  <div style="width: auto">
    <template-list
        :auto-load-list="false"
        :model="$shift"
        :show-create-button="false"
    >
      <template
          v-if="$auth.hasRight('reason__edit_information')"
          v-slot:actions
      >
        <v-btn
            class="rounded-lg"
            color="primary"
            depressed
            height="40"
            @click="openModal()"
        >
          <div class="text-none body-2 px-2">Начать смену</div>
        </v-btn>
      </template>
      <!--      $auth.hasRight('')-->
      <!--      ? openModal(object)-->
      <!--      : void 0-->
      <template v-slot="{ object, index }">
        <v-col
            class="pa-0 link"
            @click="
            openModal(object)
          "
        >
          <v-row no-gutters>
            <div class="ml-2 font-weight-medium text-truncate">
              {{ object.user?.first_name }} - Курьер {{ object.end_date ? '| Завершена' : '' }}
            </div>
          </v-row>
        </v-col>
      </template>
    </template-list>
    <v-dialog v-model="dialog" max-width="450">
      <v-card
          v-if="dialogItem"
          class="pa-5 rounded-lg"
          style="overflow-x: auto"
      >
        <div class="font-weight-bold mb-5">
          Смена
        </div>
        <v-select
            v-model="dialogItem.user"
            :items="workers"
            :item-text="(v) => v.first_name"
            item-value="id"
            label="Работник"
            :disabled="dialogItem.id"
        />
        <v-select
            v-model="dialogItem.outlet"
            :items="$outlet.list"
            :item-text="(v) => `${v.id}. ${v.address}`"
            item-value="id"
            label="Работник"
            :disabled="dialogItem.id"
        />
        <v-row class="px-2 mt-0">
          <v-btn
              :disabled="!dialogItem.outlet || !dialogItem.user"
              :loading="loading"
              color="primary"
              @click="saveDialogItem()"
          >{{ dialogItem.id ? 'Завершить смену' : 'Сохранить' }}
          </v-btn>
          <v-spacer/>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {cloneDeep} from 'lodash'

export default {
  name: 'Reasons',
  components: {},
  data: () => ({
    dialog: false,
    dialogItem: null,
    loading: false,
    workers: []
  }),
  methods: {
    loadList() {
      this.$shift.loadList()
      this.$user.loadList({user_type: 4, page_size: 'all'}).then(v => {
        this.workers = v
      })
    },
    openModal(_item = {}) {
      if (_item.end_date) return
      let item = cloneDeep(_item)
      this.dialogItem = item
      this.dialog = true
    },
    saveDialogItem() {
      this.loading = true
      if (this.dialogItem.id) {
        this.$shift.sendPostSingle('end', this.dialogItem.id).then(() => {
          this.dialog = false
        }).finally(() => {
          this.loading = false
        })

      } else {
        this.$shift.sendPostSingle('start', undefined, {
          user: this.dialogItem.user,
          outlet: this.dialogItem.outlet
        }).then(v => {
          this.$shift.list.push(v)
          this.dialog = false
        })
            .finally(() => {
              this.loading = false
            })
      }
    },
    deleteDialogItem() {
      this.loading = true
      this.$reason
          .delete(this.dialogItem.id)
          .then(() => {
            this.dialog = false
            const index = this.$reason.list.findIndex(
                (v) => v.id === this.dialogItem.id
            )
            if (index > -1) this.$reason.list.splice(index, 1)
          })
          .finally(() => {
            this.loading = false
          })
    }
  },
  created() {
    this.loadList()
  },
  watch: {
    dialog() {
      if (!this.dialog) this.dialogItem = null
      console.log(this.dialogItem)
    }
  }
}
</script>

<style scoped></style>
