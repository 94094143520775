const m = require("air-vue-model")();
m.url = "ingredients";
m.name = "ingredient";

m.routes = [
  {
    name: "item",
    component: require("@/admin/views/ingredients/IngredientItem"),
    redirect: {
      name: "ingredientBase",
    },
    single: true,
    children: [
      {
        name: "base",
        path: "base",
        component: require("@/admin/views/ingredients/IngredientItemBase.vue"),
      },
      {
        name: "prices",
        path: "prices",
        component: require("@/admin/views/ingredients/IngredientItemPrices.vue"),
      },
      {
        name: "nutritional",
        path: "nutritional",
        component: require("@/admin/views/ingredients/IngredientItemNutritional.vue"),
      },
      {
        name: "nomenclature",
        path: "nomenclature",
        component: require("@/admin/views/ingredients/IngredientItemNomenclature.vue"),
      },
    ],
  },
];

module.exports = m;
