<template>
    <template-list :model="$checkout">
        <template v-slot="{object, to, index}">
            <router-link :to="to" style="width: 100%">
                <v-row no-gutters align="center">
                    <v-col class="pa-0">
                        <div class="font-weight-bold link mr-2">{{ object.name }}</div>
                        <div class="caption grey--text text--darken-2" v-if="object.company.name">{{
                                object.company.name
                            }}
                        </div>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-icon size="20" :color="object.validated ? 'green' : 'red'">mdi-circle</v-icon>
                </v-row>
            </router-link>
        </template>
    </template-list>
</template>

<script>

export default {
    name: 'Checkouts',
    components: {},
    data: () => ({}),
    methods: {},
    created() {

    }
}
</script>

<style scoped>

</style>