<template>
  <div>
    <v-row
      class="pa-0 mx-1 mt-5 rounded mb-2 font-weight-bold"
      style="background: #f5f5f5"
    >
      <v-col align-self="center" class="py-0" cols="4"
        >Список уведомлений
      </v-col>
      <v-col class="py-0">
        <v-btn class="pa-0 text-none" text @click="toggle('firebase')"
          >Push
        </v-btn>
      </v-col>
    </v-row>
    <v-col
      v-for="(group, index) in permissions"
      :key="group.id"
      :class="{ 'mt-10': index }"
      class="pa-0"
    >
      <v-col class="mb-3" cols="4">
        <span class="font-weight-bold">{{ group.name }}</span>
      </v-col>
      <v-row v-for="item in group.children" :key="item.id" class="px-3 py-1">
        <v-col align-self="center" class="px-3 py-0" cols="4"
          >{{ item.name }}
        </v-col>
        <v-col align-self="center" class="pa-0 ml-8">
          <v-checkbox
            v-model="item.permissions.firebase"
            :disabled="!item.can_be_disabled"
            class="pa-0 ma-0"
            hide-details
          />
        </v-col>
        <v-col class="pa-0 my-3" cols="12">
          <v-divider class="mx-2" />
        </v-col>
      </v-row>
    </v-col>
    <v-btn class="mt-10 ml-2" color="primary" outlined @click="save()"
      >Сохранить</v-btn
    >
  </div>
</template>
<script>
export default {
  name: "UserNotificationsSettings",
  data: () => ({
    permissions: [],
    appName: "service_app",
    allowedPermissionsKeys: ["firebase"],
  }),
  mounted() {
    this.$userNotification
      .sendGetSingle("permissions", undefined, {
        app_name: this.appName,
        user: this.$route.params.userId
      })
      .then((v) => {
        this.permissions = v.results;
      });
  },
  methods: {
    toggle(type) {
      const items = this.permissions.flatMap((v) => v.children);
      if (items.some((v) => !v.permissions[type])) {
        items.forEach((v) => (v.permissions[type] = true));
      } else {
        items.forEach((v) => (v.permissions[type] = false));
      }
    },
    save() {
      const dataToSend = [];
      for (const item of this.permissions) {
        for (const permission of item.children) {
          for (const permissionKey of this.allowedPermissionsKeys) {
            dataToSend.push({
              contact_type: permissionKey,
              is_enabled: permission.permissions[permissionKey],
              notification_type: permission.id,
            });
          }
        }
      }
      this.$userNotification.sendPostSingle("set_permissions", undefined, {
        app_name: this.appName,
        user: this.$route.params.userId,
        data: dataToSend,
      });
    },
  },
};
</script>
