<template>
    <v-col class="pa-0">
        <apexchart type="line" height="350" :options="chartOptions" :series="series"></apexchart>
    </v-col>
</template>

<script>
export default {
    name: 'Performance',
    props: {
        data: Object
    },
    data: () => ({
        chartOptions: {
            chart: {
                height: 350,
                type: 'line',
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth',
                width: 2,
                dashArray: [0, 0, 5, 5]
            },
            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'],
                    opacity: 0.5
                },
            },
            xaxis: {
                tickAmount: 10,
                labels: {
                    format: 'dd/MM',
                },
                type: 'datetime'
            },
            // yaxis: {
            //     labels: {
            //         formatter: val => val.toFixed(0)
            //     },
            // }
        },
    }),
    methods: {},
    computed: {
        series() {
            const current = this.data.current
            const previous = this.data.previous
            const values = [
                {
                    name: 'Новые пользователи',
                    data: current.map(val => {
                        return {x: val.date, y: val.joined_users}
                    })
                },
                {
                    name: 'Созданные заказы',
                    data: current.map(val => {
                        return {x: val.date, y: val.created_orders}
                    })
                },
            ]
            if (previous.length) {
                values.push({
                    name: 'Новые пользователи (пред)',
                    data: previous.map((val, index) => {
                        return {x: current[index].date, y: val.joined_users}
                    }),
                    dashArray: "5,5"
                })
                values.push({
                    name: 'Созданные заказы (пред)',
                    data: previous.map((val, index) => {
                        return {x: current[index].date, y: val.created_orders}
                    }),
                    dashArray: "5,5"
                })
            }
            return values
        }
    },
}
</script>

<style scoped>

</style>