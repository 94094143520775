<template>
    <v-col class="pa-0" v-if="item">
        <v-row no-gutters class="my-3" align="center">
            <div class="text-h6 mr-3">Документы</div>
            <v-divider/>
            <!--            <v-btn :loading="$company.loadings.save" depressed color="primary" class="ml-3 rounded-lg text-none body-2"-->
            <!--                   @click="saveItem">Сохранить-->
            <!--            </v-btn>-->
        </v-row>
        <v-row no-gutters align="center" class="mt-3">
            <v-file-input v-model="privacyPolicy" dense hide-details class="rounded-lg" outlined
                          label="Политика конфиденциальности"
                          :disabled="!$auth.hasRight('companies__edit_information')"
                          truncate-length="15"
            ></v-file-input>
            <v-btn :loading="privacyPolicyLoading" @click="setPrivacyPolicy" color="primary"
                   class="text-none body-2 rounded-lg ml-2"
                   height="40"
                   v-if="$auth.hasRight('companies__edit_information')"
                   :disabled="!privacyPolicy">Сохранить файл
            </v-btn>
        </v-row>
        <v-col class="pa-0 mt-2">
            <a v-if="item.privacy_policy" target="_blank" :href="item.privacy_policy"
               class="body-2 font-weight-medium">Ссылка
                на политику
                конфиденциальности</a>
            <div v-else class="body-2 font-weight-medium">Документ отсутствует</div>
        </v-col>
        <v-row no-gutters align="center" class="mt-5">
            <v-file-input
                :disabled="!$auth.hasRight('companies__edit_information')"
                v-model="termsOfUse" dense hide-details class="rounded-lg" outlined
                          label="Пользовательское соглашение"
                          truncate-length="15"
            ></v-file-input>
            <v-btn :loading="termsOfUseLoading" @click="setTermsOfUse" color="primary"
                   class="text-none body-2 rounded-lg ml-2"
                   height="40"
                   v-if="$auth.hasRight('companies__edit_information')"
                   :disabled="!termsOfUse">Сохранить файл
            </v-btn>
        </v-row>
        <v-col class="pa-0 mt-2">
            <a v-if="item.terms_of_use" target="_blank" :href="item.terms_of_use"
               class="body-2 font-weight-medium">Ссылка на
                пользовательское соглашение</a>
            <div v-else class="body-2 font-weight-medium">Документ отсутствует</div>
        </v-col>
    </v-col>
</template>

<script>

export default {
    name: 'CompanyItemDocuments',
    components: {},

    data: () => ({
        privacyPolicy: null,
        privacyPolicyLoading: false,
        termsOfUse: null,
        termsOfUseLoading: null,
    }),
    computed: {
        item() {
            return this.$company.item
        }
    },
    methods: {
        setPrivacyPolicy() {
            this.privacyPolicyLoading = true
            const formData = new FormData();
            formData.append('file', this.privacyPolicy)
            axios.put(`/companies/${this.$company.item.id}/set_privacy_policy/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(res => {
                this.privacyPolicy = null
                this.$store.commit('setSnackbar', {color: 'success', text: 'Файл сохранен'})
                this.privacyPolicyLoading = false
                this.$company.item = res.data
            }).catch(() => {
                this.$store.commit('setSnackbar', {color: 'red', text: 'Не удалось сохранить'})
                this.privacyPolicyLoading = false
                this.privacyPolicy = null
            })
        },
        setTermsOfUse() {
            this.termsOfUseLoading = true
            const formData = new FormData();
            formData.append('file', this.termsOfUse)
            axios.put(`/companies/${this.$company.item.id}/set_terms_of_use/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(res => {
                this.termsOfUse = null
                this.$store.commit('setSnackbar', {color: 'success', text: 'Файл сохранен'})
                this.termsOfUseLoading = false
                this.$company.item = res.data
            }).catch(() => {
                this.$store.commit('setSnackbar', {color: 'red', text: 'Не удалось сохранить'})
                this.privacyPolicyLoading = false
                this.termsOfUse = null
            })
        }
    },
    watch: {}
}
</script>

<style scoped>

</style>