import Vue from "vue";

export function initDefaultComponents(vue) {
    vue.component('template-list', require('../components/template/TemplateList').default)
    vue.component('template-list-element', require('../components/template/TemplateListElement').default)
    vue.component('template-list-header', require('../components/template/TemplateListHeader').default)
    vue.component('template-list-actions', require('../components/template/TemplateListActions').default)
    vue.component('template-item', require('../components/template/TemplateItem').default)
    vue.component('template-item-actions', require('../components/template/TemplateItemActions').default)
    vue.component('lazy-select', require('../components/helpers/LazySelect').default)
    Vue.component('date-picker', require('../components/helpers/DatePicker').default)
    Vue.component('multi-select', require('../components/helpers/Multiselect').default)
}

// uncomment if you want use leaflet and install this packages: leaflet, leaflet.markercluster
// export function initLeaflet() {
//     import "leaflet/dist/leaflet.css"
//     const L = require("leaflet")
//     const market_cluster = require("leaflet.markercluster")
// }

export function initModels(vue, store, models) {
    for (let model in models) {
        if (models.hasOwnProperty(model)) {
            vue.set(store.state.items, model, models[model])
            vue.prototype[`$${model}`] = models[model]
        }
    }
}
