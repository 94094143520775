<template>
  <v-row class="px-2 ma-0">
    <v-col
      cols="4"
      v-for="(order, index) in orders"
      :key="order.id"
      style="cursor: pointer"
      class="pa-2"
      @click="$emit('selectOrder', order)"
    >
<!--      @click="selectOrderOnMap(order)"-->
      <v-col
        class="pa-2"
        :class="{ active: selectedOrders.map((v) => v.id).includes(order.id) }"
        style="border: 1px solid #dedede; border-radius: 8px; height: 100%"
      >
        <v-row justify="space-between" class="ma-0" align="center">
          <div class="font-weight-bold daily-number">
            {{ order.daily_number }}
          </div>
          <div class="ma-0" style="display: flex">
            <div class="body-2 mr-2">до {{ deliveryBefore(order) }}</div>
            <v-icon size="20px" @click.stop="openOrder(order)"
              >mdi-information-outline</v-icon
            >
          </div>
        </v-row>
        <div class="body-2 mt-1">
          {{ order.delivery_address?.address }}
        </div>
        <div class="body-2 mt-1">
          Курьер:
          <span
            :class="{
              'font-weight-bold': !order.courier,
            }"
          >
            {{ order.courier ? order.courier.first_name : "Курьер не установлен" }}</span
          >
        </div>
        <v-row class="ma-0 mt-1" align="center">
          <v-row class="ma-0" style="gap: 4px">
            <div class="body-2">₽</div>
            <div class="body-2">{{ order.sum }}</div>
          </v-row>
          <v-tooltip bottom v-if="order.user_status === $order.userStatusEnum.PACKAGED">
            <template v-slot:activator="{ on, attrs }">
              <div
                  v-on="on"
                  class="order-chip mr-2"
                  :class="{
                      [order.packagedWaitingTime > 5
                        ? 'order-chip-red'
                        : 'order-chip-gray']: true,
                    }"
                  style="display: flex; align-items: center; gap: 5px"
                  v-if="order.packaged_at"
              >
                <v-icon size="15px" style="transform: rotate(90deg)"
                >mdi-waves</v-icon
                >
                <div>{{ order.packagedWaitingTime }} мин</div>
              </div>
            </template>
            Остывает на тепловой полке
          </v-tooltip>
          <div
            :style="{ ...$order.userStatusColors[order.user_status] }"
            style="border-radius: 6px; line-height: 100%"
            class="pa-1"
          >
            {{ $order.getUserStatusName(order) }}
          </div>
        </v-row>
      </v-col>
    </v-col>
    <OrderViewDialog :value="orderModal" @input="orderModal = $event" />
  </v-row>
</template>

<script>
import moment from "moment";
import OrderViewDialog from "@/admin/views/equipmentLockedPeriod/OrderViewDialog.vue";

export default {
  name: "DeliveryOrders",
  components: { OrderViewDialog },
  props: {
    orders: Array,
    selectedOrders: Array,
  },
  emits: ['selectOrder'],
  data: () => {
    return {
      filters: [],
      orderModal: false,
    };
  },
  mounted() {},
  methods: {
    getLocalMomentValue(v, isUtc = true) {
      let res = null;
      if (isUtc) res = moment.utc(v).local();
      else res = moment(v);
      return res;
    },
    momentValueToFormat(v, format = "DD.MM.YYYY HH:mm:ss") {
      return v.format(format);
    },
    parseDate(v, format = "DD.MM.YYYY HH:mm:ss") {
      return this.momentValueToFormat(this.getLocalMomentValue(v), format);
    },
    deliveryBefore(order) {
      return this.momentValueToFormat(
        this.getLocalMomentValue(order.preferred_time).add(
          order.delivery_minutes,
          "m"
        ),
        "HH:mm"
      );
    },
    openOrder(order) {
      this.$order.item = order;
      this.orderModal = true;
    },
    selectOrderOnMap(order) {
      this.$eventBus.$emit("selectOrderOnMap", order);
    },
  },
};
</script>

<style scoped>
.order-chip {
  border-radius: 6px;
  padding: 3px 4px;
  line-height: 100%;
  font-size: 12px;
  font-weight: 500;
}

.order-chip-gray {
  background: #dedede;
  color: #4f4f4f;
}

.order-chip-red {
  background: red;
  color: white;
}

.v-icon {
  color: inherit;
}

.active .daily-number {
  color: #fab400 !important;
}

.active {
  transition: all .2s ease-in-out;
  border-color: #fab400 !important;
  box-shadow: 0px 0px 5px #fab4004d;
}
</style>
