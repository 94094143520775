var render = function render(){var _vm=this,_c=_vm._self._c;return _c('template-list',{attrs:{"model":_vm.$user,"show-create-button":_vm.$auth.hasRight('users__creating'),"hide-items":"","auto-load-list":false},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('search',{staticClass:"mr-3",attrs:{"rounded":false,"always":""},model:{value:(_vm.$user.filters.search),callback:function ($$v) {_vm.$set(_vm.$user.filters, "search", $$v)},expression:"$user.filters.search"}}),_c('UsersFilter',{on:{"filter":function($event){return _vm.loadList($event)}}}),_c('v-btn',{staticClass:"rounded-lg text-none body-2 mr-3",attrs:{"loading":_vm.$user.loadings.list,"color":"primary","depressed":"","height":"40"},on:{"click":_vm.loadList}},[_vm._v("Обновить ")])]},proxy:true},{key:"items",fn:function(){return [_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.$user.list,"items-per-page":-1,"disable-sort":"","hide-default-footer":""},on:{"click:row":(_, evt) =>
          _vm.$router.push({ name: 'userItem', params: { userId: evt.item.id } })},scopedSlots:_vm._u([_vm._l((_vm.headers),function(header){return {key:_vm.getHeaderSlotName(header.name),fn:function(){return [_c('CHover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-row',{staticClass:"ma-0",staticStyle:{"cursor":"pointer","flex-wrap":"nowrap"},on:{"click":function($event){return _vm.setSortingByField(header.name)}}},[_vm._v(" "+_vm._s(header.text)+" "),_c('v-icon',{staticClass:"ml-1",style:({
                opacity: _vm.sortingData.some((v) => v.includes(header.name))
                  ? 1
                  : hover
                  ? 0.5
                  : 0,
                transform: _vm.getTransformValue(header.name),
              }),attrs:{"size":"16px"}},[_vm._v("mdi-arrow-up ")])],1)]}}],null,true)})]},proxy:true}}),_vm._l((_vm.headers),function(header){return {key:_vm.getItemSlotName(header.name),fn:function({ value, item }){return [(['outlets', 'user_roles'].includes(header.name))?[_c('v-tooltip',{attrs:{"disabled":value.length < 2,"bottom":"","transition":"none"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"row text-truncate",staticStyle:{"flex-wrap":"nowrap","align-items":"center"}},'div',attrs,false),on),[_vm._v(" "+_vm._s(value.length ? value[0][header.name === "outlets" ? "address" : "name"] : null)+" "),(value.length > 1)?_c('div',{staticClass:"primary ml-2 text-caption white--text",staticStyle:{"border-radius":"10px","display":"flex","justify-content":"center","align-items":"center","padding":"4px 5px","line-height":"100%"}},[_vm._v(" +"+_vm._s(value.length - 1)+" ")]):_vm._e()])]}}],null,true)},_vm._l((value
                .slice(1)
                .map(
                  (v) => v[header.name === 'outlets' ? 'address' : 'name']
                )),function(v){return _c('div',{staticClass:"caption"},[_vm._v(" "+_vm._s(v)+" ")])}),0)]:_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(_vm.getValue(header, value, item))+" ")])]}}})],null,true)})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }