<template>
  <div>
  <v-text-field
      v-model="object.name"
      class="mt-4 mb-6"
      dense
      hide-details
      label="Наименование"
      outlined
  />
  <v-row align="center" class="mb-3" no-gutters>
    <div class="body-2 link" @click="selectAll">Выбрать все</div>
    <div class="body-2 link ml-2" @click="disableAll">Снять все</div>
  </v-row>
  <v-col v-for="el in values" :key="el.title" class="pa-0">
    <div class="mb-4 font-weight-bold link" @click="selectByGroup(el)">
      {{ el.title }}
    </div>
    <v-row
        v-for="(v, index) in el.rights"
        :key="index"
        align="center"
        class="mb-3"
        no-gutters
    >
      <v-checkbox
          v-model="object.rights"
          :label="v.title"
          :value="v.right"
          class="ma-0 pa-0"
          dense
          hide-details
      ></v-checkbox>
    </v-row>
    <template v-if="el.key === 'users'">
      <lazy-select
          v-model="object.available_user_roles"
          :items="$userRole.list"
          :lazy="false"
          :model="$userRole"
          class="mb-3"
          dense
          hide-details
          item-text="name"
          item-value="id"
          label="Доступные роли"
          multiple
          outlined
          preload
          set-to="list"
      ></lazy-select>
      <v-combobox
          v-model="object.available_user_types"
          :items="userTypes"
          color="primary"
          dense
          outlined
          class="mb-3"
          hide-details
          item-text="label"
          item-value="value"
          label="Доступные типы"
          multiple
      >
      </v-combobox>
    </template>
  </v-col>
  </div>
</template>
<script>
export default {
  name: 'UserRoleItemRules',
  props: {object: Object, values: Array},
  data: () => {
    return {
      userTypes: [
        {
          label: "Покупатель",
          value: 1,
        },
        {
          label: "Владелец",
          value: 2,
        },
        {
          label: "Работник",
          value: 3,
        },
        {
          label: "Курьер",
          value: 4,
        },
      ],
    }
  },
  methods: {
    selectAll() {
      this.values.forEach((v) => this.selectByGroup(v, true));
    },
    disableAll() {
      this.values.forEach((v) => this.selectByGroup(v, false));
    },
    selectByGroup(group, shouldBeSelected = null) {
      if (!this.$userRole.item) return;
      const currentRights = this.$userRole.item.rights;
      const _shouldBeSelected =
          shouldBeSelected === null
              ? group.rights.every((e) => currentRights.includes(e.right))
              : !shouldBeSelected;

      if (_shouldBeSelected) {
        group.rights.forEach((v) => {
          const index = currentRights.indexOf(v.right);
          if (index > -1) currentRights.splice(index, 1);
        });
      } else {
        group.rights.forEach((e) => {
          const index = currentRights.indexOf(e.right);
          if (index === -1) currentRights.push(e.right);
        });
      }
    },
  },
}
</script>

<style scoped>

</style>