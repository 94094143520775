<template>
    <v-col class="pa-3 pb-10">
        <v-row dense align="center" class="my-0">
            <v-col cols="12" md="3">
                <date-picker class="rounded-lg" label="Дата начала"
                             clearable
                             v-model="startDate"></date-picker>
            </v-col>
            <v-col cols="12" md="3">
                <date-picker label="Дата окончания" class="rounded-lg"
                             clearable
                             v-model="endDate"></date-picker>
            </v-col>
            <v-col cols="12" md="3">
                <v-btn :loading="loading" @click="loadAnalytics" class="rounded-lg" depressed color="primary"
                       height="40">Обновить
                </v-btn>
                <v-btn :loading="exportLoading" class="rounded-lg ml-3" outlined height="40" rounded color="primary" v-if="result" @click="exportToExcel">Выгрузить
                </v-btn>
            </v-col>
        </v-row>
        <v-col class="pa-0 mt-5" v-if="result">
            <div class="font-weight-medium mb-1">Общая информация</div>
            <div class="body-2">Всего заказов: <span class="font-weight-medium">{{ result.total.total_count }}</span>
            </div>
            <div class="body-2">Выданные заказы: <span class="font-weight-medium">{{
                    result.total.released_count
                }}</span></div>
            <div class="body-2">Утилизированные заказы: <span class="font-weight-medium">{{
                    result.total.declined_count
                }}</span>
            </div>
            <div class="body-2">Процент утилизации: <span class="font-weight-medium">{{
                    result.total.declined_percent
                }}%</span>
            </div>
        </v-col>
        <v-divider class="mt-5"></v-divider>
        <v-col class="pa-0 mt-3" v-if="result">
            <v-col class="pa-0 py-2" v-for="(el, index) in result.result" :key="index">
                <div class="font-weight-medium mb-1">
                    <span class="mr-2" v-if="el.outlet.vendor_code">{{ el.outlet.number }}</span>
                    <span>{{ el.outlet.address }}</span>
                    <span class="ml-2 text-caption font-weight-regular">({{ el.outlet.company.name }})</span>
                </div>
                <div class="body-2">Всего заказов: <span class="font-weight-medium">{{ el.total_count }}</span></div>
                <div class="body-2">Выданные заказы: <span class="font-weight-medium">{{ el.released_count }}</span>
                </div>
                <div class="body-2">Утилизированные заказы: <span class="font-weight-medium">{{
                        el.declined_count
                    }}</span></div>
                <div class="body-2">Процент утилизации: <span class="font-weight-medium">{{
                        el.declined_percent
                    }}%</span></div>
            </v-col>
        </v-col>
    </v-col>
</template>

<script>

export default {
    name: "Analytics",
    components: {},
    data: () => ({
        result: null,
        loading: false,
        startDate: null,
        endDate: null,
        exportLoading: false
    }),
    methods: {
        loadAnalytics() {
            this.loading = true
            this.$analytics.sendPost('decline_orders', {
                start_date: this.startDate,
                end_date: this.endDate
            }).then(res => {
                this.result = res
                this.loading = false
            })
        },
        exportToExcel() {
            this.exportLoading = true
            const data = []
            for (const el of this.result.result) {
                data.push({
                    'Номер': el.outlet.number,
                    'Адрес': el.outlet.address,
                    'Компания': el.outlet.company.name,
                    'Всего заказов': el.total_count,
                    'Выданные заказы': el.released_count,
                    'Утилизированные заказы': el.declined_count,
                    'Процент утилизации': el.declined_percent
                })
            }
            const XLSX = require("xlsx");

            const worksheet = XLSX.utils.json_to_sheet(data);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
            XLSX.writeFile(workbook, 'data.xlsx');
            this.exportLoading = false
        }
    },
    watch: {},
    created() {
        this.endDate = window.moment().local().format('YYYY-MM-DD')
        this.startDate = window.moment().subtract(1, 'month').local().format('YYYY-MM-DD')
        this.loadAnalytics()
    }
}
</script>

<style scoped>

</style>
