<template>
  <template-item
    :show-actions="$auth.hasRight('displays__edit_information')"
    :model="$displayDevice"
    v-slot="{ object, rules }"
    title-prepend="Настройки экранов / Экраны /"
    object-key-for-title="name"
  >
    <v-text-field
      class="rounded-lg"
      v-model="object.name"
      label="Наименование"
      outlined
      hide-details
      dense
    ></v-text-field>
    <v-select
      :items="$outlet.list"
      class="mt-3 rounded-lg"
      outlined
      dense
      hide-details
      label="Торговая точка"
      item-text="address"
      item-value="id"
      :item-disabled="false"
      v-model="object.outlet"
    ></v-select>
    <v-select
      hide-details
      dense
      outlined
      class="mt-3 rounded-lg"
      label="Очередь"
      v-model="object.line"
      item-value="uuid"
      item-text="name"
      :items="$displayLine.list"
    ></v-select>

    <v-checkbox
      v-model="object.active"
      hide-details
      class="ma-0 pa-0 mt-4"
      label="Активно"
    ></v-checkbox>
  </template-item>
</template>

<script>
export default {
  name: "DisplayDevice",
  data: () => ({}),
  methods: {},
  created() {
    this.$outlet.loadList();
    this.$displayLine.loadList();
  },
};
</script>

<style scoped></style>
