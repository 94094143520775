<template>
  <v-col class="pa-3 pb-10">
    <!--        <pre>{{ results }}</pre>-->

    <v-row align="center" class="my-0" dense>
      <v-col cols="12" md="2">
        <date-picker
          v-model="startDate"
          class="rounded-lg"
          clearable
          label="Дата начала"
        ></date-picker>
      </v-col>
      <v-col cols="12" md="2">
        <date-picker
          v-model="endDate"
          class="rounded-lg"
          clearable
          label="Дата окончания"
        ></date-picker>
      </v-col>
      <v-col cols="12" md="3">
        <search
          v-model="search"
          :rounded="false"
          always
          max-width="100%"
        ></search>
      </v-col>
      <v-col cols="12" md="5">
        <v-row class="pa-0">
          <v-btn
            :loading="loading"
            class="rounded-lg ml-2"
            color="primary"
            depressed
            height="40"
            @click="loadAnalytics"
            >Обновить
          </v-btn>
          <v-btn
            :loading="loading"
            class="rounded-lg ml-4"
            color="primary"
            depressed
            height="40"
            outlined
            @click="exportToExcel"
            >Выгрузить
          </v-btn>
          <v-spacer />
          <v-btn
            :loading="loading"
            class="rounded-lg"
            color="primary"
            depressed
            height="40"
            @click="chartsEnabled = !chartsEnabled"
          >
            {{ chartsEnabled ? "Скрыть графики" : "Отобразить графики" }}
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="list"
      :items-per-page="999"
      hide-default-footer
    >
      <template v-slot:item.outlet="{ item }">
        <div>{{ item.outlet.number }}. {{ item.outlet.address }}</div>
      </template>
      <template v-if="chartsEnabled" v-slot:item.chart="{ item }">
        <v-row justify="end">
          <apexchart
            :options="chartOptions"
            :series="getSeries(item.items)"
            height="60"
            type="line"
            width="400"
          ></apexchart>
        </v-row>
      </template>
    </v-data-table>
  </v-col>
</template>

<script>
import moment from "moment";
import OrderStatus from "@/admin/views/orders/OrderStatus.vue";
import OrderBalanceView from "@/admin/views/orders/OrderBalanceView.vue";
import Search from "@/components/template/Search.vue";
import search from "air-vue-model/search";

export default {
  name: "AnalyticsTrackerActivities",
  components: { Search, OrderBalanceView, OrderStatus },
  data: () => ({
    results: [],
    loading: false,
    startDate: null,
    search: null,
    endDate: null,
    exportLoading: false,
    chartOptions: {
      chart: {
        toolbar: {
          show: false,
        },
        type: "line",
        height: 350,
      },
      stroke: {
        curve: "stepline",
        width: 1,
      },
      dataLabels: {
        enabled: false,
      },
      title: {
        text: undefined,
      },
      xaxis: {
        type: "datetime",
        labels: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
      markers: {
        hover: {
          sizeOffset: 4,
        },
      },
      grid: {
        show: false,
      },
    },
    chartsEnabled: false,
  }),
  methods: {
    getSeries(values) {
      const series = [];
      const data = [];
      for (const item of values) {
        data.push({
          x: moment.utc(item.date).local().add(2, "h"),
          y: item.result ? 1 : 0,
        });
      }
      series.push({ data });
      return series;
    },
    loadAnalytics() {
      this.chartsEnabled = false;
      this.loading = true;
      this.$analytics
        .sendPost("tracker_activity", {
          start_date: this.startDate,
          end_date: this.endDate,
        })
        .then((res) => {
          this.results = res.results;
          this.loading = false;
        });
    },
    exportToExcel() {
      this.exportLoading = true;
      const data = [];
      for (const el of this.results) {
        const item = {};
        for (const header of this.headers.filter((v) => v.value !== "chart")) {
          if (header.value === "outlet") {
            item[header.text] = `${el.outlet.number}. ${el.outlet.address}`;
          } else item[header.text] = el[header.value];
        }
        data.push(item);
      }
      const XLSX = require("xlsx");
      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "data.xlsx");
      this.exportLoading = false;
    },
  },
  computed: {
    list() {
      return search(
        this.search,
        this.results,
        (v) => v.outlet.vendor_code + "." + v.outlet.address
      );
    },
    headers() {
      const headers = [
        {
          text: "Торговая точка",
          align: "left",
          sortable: false,
          value: "outlet",
        },
        {
          text: "Устройство",
          align: "center",
          sortable: false,
          value: "device",
        },
        {
          text: "Потери",
          align: "center",
          sortable: true,
          value: "loss_count",
        },
        {
          text: "Онлайн (%)",
          align: "center",
          sortable: true,
          value: "online_percent",
        },
      ];
      if (this.chartsEnabled)
        headers.push({
          text: "График",
          align: "right",
          sortable: false,
          value: "chart",
        });
      return headers;
    },
  },
  watch: {},
  created() {
    this.endDate = window.moment().local().format("YYYY-MM-DD");
    this.startDate = window
      .moment()
      .subtract(1, "month")
      .local()
      .format("YYYY-MM-DD");
    this.loadAnalytics();
  },
};
</script>

<style scoped></style>
