<template>
  <div v-if="$auth.hasRight('balance__access')">
    <div
      v-if="object.refiled_balance || object.withdraw_balance"
      style="border-radius: 5px; display: flex"
      :style="{
        border: fullInfo ? '' : '1px solid #fab400',
        background: fullInfo ? '#F6F6F6' : '',
      }"
      class="px-1"
    >
      <div
        v-if="object.refiled_balance"
        class="font-weight-medium"
        :class="{'success--text': !withoutColors, 'grey--text text--darken-1': withoutColors}"
        style="display: flex; align-items: center"
      >
        <div
          v-if="fullInfo && !withoutColors"
          style="
            background: currentColor;
            width: 14px;
            height: 14px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
          "
          class="mr-1"
        >
          <span class="white--text body-2" style="transform: translate(0.5px, -0.5px)">+</span>
        </div>
        <template v-else>+</template>
        {{ object.refiled_balance }} {{fullInfo ? 'бонусов' : ''}}
      </div>
      <span
        class="mx-1 primary--text"
        v-if="object.refiled_balance && object.withdraw_balance"
        >|</span
      >
      <div
        v-if="object.withdraw_balance"
        class="font-weight-medium"
        :class="{'red--text': !withoutColors, 'grey--text text--darken-1': withoutColors}"
        style="display: flex; align-items: center"
      >
        <div
            v-if="fullInfo && !withoutColors"
            style="
            background: currentColor;
            width: 14px;
            height: 14px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
          "
            class="mr-1"
        >
          <span class="white--text body-2">—</span>
        </div>
        <template v-else>-</template>
        {{ object.withdraw_balance}} {{fullInfo ? 'бонусов' : ''}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderBalanceView.vue",
  props: {
    object: Object,
    fullInfo: Boolean,
    withoutColors: Boolean,
  },
  data: () => ({}),
  methods: {},
};
</script>

<style scoped></style>
