var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{staticClass:"px-4 mb-6 my-0",attrs:{"align":"center"}},[_c('div',{staticClass:"text-h6 mr-3"},[_vm._v("Основная информация")]),_c('v-divider'),(_vm.$auth.hasRight('products__edit_information'))?_c('v-btn',{staticClass:"rounded-lg ml-3",attrs:{"loading":_vm.saveLoading,"height":"40","depressed":"","color":"primary"},on:{"click":function($event){return _vm.save()}}},[_c('div',{staticClass:"text-none body-2 px-2"},[_vm._v("Сохранить")])]):_vm._e()],1),_c('div',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.$productIngredientGroupIngredient.item.name))]),_c('v-row',{staticClass:"my-3",attrs:{"no-gutters":"","align":"center"}},[_c('v-text-field',{attrs:{"type":"number","outlined":"","hide-details":"","dense":"","label":"Сортировка"},on:{"change":function($event){_vm.$productIngredientGroupIngredient.item.sorting = _vm.getSorting(
          _vm.$productIngredientGroupIngredient.item.sorting
        )}},model:{value:(_vm.$productIngredientGroupIngredient.item.sorting),callback:function ($$v) {_vm.$set(_vm.$productIngredientGroupIngredient.item, "sorting", $$v)},expression:"$productIngredientGroupIngredient.item.sorting"}}),_c('AcceptToOtherProducts',{staticClass:"ml-3",attrs:{"set-data":{
        product_ingredient_group_ingredients: {
          by_ingredient: _vm.$productIngredientGroupIngredient.item.ingredient.id,
          sorting: _vm.$productIngredientGroupIngredient.item.sorting,
        },
      },"filters":{
        ingredient: _vm.$productIngredientGroupIngredient.item.ingredient.id,
      }},on:{"approved":_vm.onSetToOther}})],1),_c('v-text-field',{staticClass:"my-3",attrs:{"outlined":"","hide-details":"","dense":"","label":"Промо текст"},on:{"input":function($event){_vm.$productIngredientGroupIngredient.item.promo_text = !$event
        ? null
        : $event}},model:{value:(_vm.$productIngredientGroupIngredient.item.promo_text),callback:function ($$v) {_vm.$set(_vm.$productIngredientGroupIngredient.item, "promo_text", $$v)},expression:"$productIngredientGroupIngredient.item.promo_text"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }