<template>
    <template-list :model="$company" :show-create-button="false">
        <template v-slot="{object, to, index}">
            <router-link :to="to">
                <v-col class="pa-0 black--text">
                    <v-row no-gutters align="center">
                        <div class="font-weight-bold link mr-2">{{ object.name }}</div>
<!--                        <span class="green&#45;&#45;text caption" v-if="!object.production_payments">Тестовая оплата</span>-->
                    </v-row>
                    <v-row no-gutters align="center" class="caption">
                        <span class="mr-2">Артикул: {{ object.vendor_code }}</span>
                        <span>Домен: {{ object.domain || 'Не указано' }}</span>
                    </v-row>
                </v-col>
            </router-link>
        </template>
    </template-list>
</template>

<script>

export default {
    name: 'Companies',
    components: {},
    data: () => ({}),
    methods: {},
    created() {

    }
}
</script>

<style scoped>

</style>