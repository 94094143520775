<template>
    <v-col class="pa-0">
        <v-row no-gutters class="my-3" align="center">
            <div class="text-h6 mr-3">Линии</div>
            <v-divider/>
        </v-row>
        <v-data-table
            no-data-text="Нет данных"
            hide-default-footer
            @click:row="editItem($event)"
            :headers="headers"
            :items="items"
            :items-per-page="999999"
        >
            <template v-slot:item.color="{ item }">
                <v-icon :style="`color: ${item.color}`" size="20">mdi-checkbox-blank-circle</v-icon>
            </template>
        </v-data-table>
        <v-dialog v-model="dialog" max-width="600">
            <v-card class="pa-5" v-if="currentItem">
                <v-row no-gutters align="center" class="mb-5">
                    <div class="font-weight-bold">Редактирование</div>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" outlined @click="saveItem">Сохранить</v-btn>
                </v-row>
                <v-row no-gutters align="center">
                    <v-icon class="mr-3" :style="`color: ${currentItem.color}`" size="30">mdi-checkbox-blank-circle
                    </v-icon>
                    <v-text-field readonly v-model="currentItem.name" dense hide-details
                                  outlined label="Наименование">
                    </v-text-field>
                </v-row>
                <v-col class="pa-0">
                    <div class="font-weight-bold my-3">Группы блюд на линиях</div>
                    <v-col class="pa-0 mb-4" v-for="(el, index) in $lineProductGroup.list" :key="index">
                        <v-row no-gutters align="center">
                            <v-checkbox :value="el.id" class="ma-0 pa-0" hide-details
                                        v-model="currentItem.line_product_groups"></v-checkbox>
                            <v-icon :style="`color: ${el.color}`" size="20">mdi-checkbox-blank-circle</v-icon>
                            <div>{{ el.name }}</div>
                        </v-row>
                    </v-col>
                </v-col>
            </v-card>
        </v-dialog>
    </v-col>
</template>

<script>

export default {
    name: 'OutletItemLines',
    props: {
        cookStation: Number
    },
    data: () => ({
        dialog: false,
        search: '',
        currentItem: null,
        headers: [
            {text: 'Цвет', value: 'color'},
            {text: 'Линия', value: 'line'},
            {text: 'Наименование', value: 'name'},
        ]
    }),
    methods: {
        editItem(val) {
            this.currentItem = this.$outletLine.copy(val)
            this.currentItem.cook_station = this.cookStation
            this.dialog = true
        },
        saveItem() {
            const {promise} = this.$outletLine.updateOrCreate(this.currentItem)
            promise.then(res => {
                this.$store.commit('setSnackbar', {color: 'success', text: 'Линия сохранена'})
                this.$outletLine.sendGet('current', {
                    outlet: this.$outlet.item.id,
                    cook_station: this.cookStation
                }).then(res => {
                    this.$outletLine.list = res
                })
                this.dialog = false
            })
        }
    },
    computed: {
        items() {
            return this.$outletLine.list
        },
    },
    mounted() {
        this.$outletLine.list = []
        this.$outletLine.sendGet('current', {
            outlet: this.$outlet.item.id,
            cook_station: this.cookStation
        }).then(res => {
            this.$outletLine.list = res
        })
        if (!this.$lineProductGroup.list.length)
            this.$lineProductGroup.loadList({page_size: 'all'})
    }
}
</script>

<style scoped>

</style>