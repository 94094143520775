<template>
  <v-dialog :value="value" @input="closeModal()" max-width="500px">
    <v-card class="pa-3">
      <v-autocomplete
        v-model="selectedUser"
        :loading="$user.loadings.list"
        :items="$user.list"
        :search-input.sync="searchUser"
        cache-items
        class="mx-4"
        flat
        hide-no-data
        hide-details
        label="Пользователь"
        item-text="phone"
        item-value="id"
        return-object
      ></v-autocomplete>
      <v-text-field
        v-if="$promocodeCondition.item?.for_earn?.required_progress"
        class="mx-4 mt-2"
        flat
        label="Прогресс"
        v-model.number="progress"
        type="number"
        hide-details
      />

      <v-btn
        :disabled="!selectedUser"
        color="primary"
        class="mt-4 mx-4"
        @click="create()"
      >
        <div class="text-none">Начислить</div>
      </v-btn>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from "air-vue-model/rules";

export default {
  name: "CreateTextCodesModal",
  computed: {
    rules() {
      return rules;
    },
  },
  components: {},
  data: () => {
    return {
      searchUser: "",
      selectedUser: null,
      timeout: null,
      progress: 1,
    };
  },
  props: {
    value: Boolean,
  },
  emits: ["input"],
  mounted() {},
  methods: {
    clearData() {
      this.selectedUser = null;
      this.searchUser = "";
      this.progress = 1
    },
    closeModal() {
      this.$emit("input", false);
    },
    create() {
      const data = {
        user_id: this.selectedUser.id,
        promocode_condition: this.$promocodeCondition.item.id,
      };
      if (this.$promocodeCondition.item?.for_earn?.required_progress)
        data["progress"] = this.progress;
      this.$userPromocode.create(data).then(() => {
        this.$store.commit("setSnackbar", "Промокод создан");
        this.$userPromocode.loadList();
        this.closeModal();
        this.clearData();
      });
    },
  },
  watch: {
    searchUser(v) {
      this.$user.filters.phone = v;
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.$user.loadList();
      }, 300);
    },
  },
};
</script>

<style scoped></style>
