var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',{staticClass:"pa-0"},[_c('v-app-bar',{attrs:{"dense":"","flat":"","app":"","color":"white","elevation":"0","height":_vm.$vuetify.breakpoint.smAndDown ? 50 : 60}},[_c('router-link',{attrs:{"to":{ name: 'products' }}},[_c('v-img',{attrs:{"max-width":_vm.$vuetify.breakpoint.smAndDown ? 120 : 160,"contain":"","src":"/images/logo-long.png"}})],1),_c('v-spacer'),_c('v-card',{attrs:{"flat":"","to":{ name: 'outlets' }}},[_c('v-row',{attrs:{"no-gutters":"","justify":"end"}},[_c('div',{staticClass:"text-right",class:{
            'text-small': _vm.$vuetify.breakpoint.smAndDown,
            'text-caption': _vm.$vuetify.breakpoint.mdAndUp,
          }},[_vm._v(" "+_vm._s(_vm.outlet ? _vm.outlet.address : "Кафе не выбрано")+" ")])]),(_vm.outlet)?_c('v-row',{staticStyle:{"margin-top":"-4px"},attrs:{"no-gutters":"","justify":"end"}},[_c('div',{staticClass:"text--secondary text-right",class:{
            'text-small': _vm.$vuetify.breakpoint.smAndDown,
            'text-caption': _vm.$vuetify.breakpoint.mdAndUp,
          }},[_vm._v(" Примерно время готовности "+_vm._s(_vm.closestDuration)+" мин ")])]):_vm._e(),_c('v-row',{staticStyle:{"margin-top":"-4px"},attrs:{"no-gutters":"","justify":"end"}},[_c('div',{staticClass:"primary--text",class:{
            'text-small': _vm.$vuetify.breakpoint.smAndDown,
            'text-caption': _vm.$vuetify.breakpoint.mdAndUp,
          }},[_vm._v(" "+_vm._s(_vm.outlet ? "Изменить кафе" : "Выбрать кафе")+" ")])])],1)],1),_c('promo-list'),_c('product-groups'),_c('v-container',{staticClass:"pa-0"},[_c('product-group-menu-list')],1),(_vm.$store.state.selectedPromo)?_c('div',{staticStyle:{"position":"fixed","right":"10px","top":"68px","z-index":"100"}},[_c('v-chip',{attrs:{"color":"primary","close":""},on:{"click:close":function($event){_vm.$store.state.selectedPromo = null}}},[_vm._v(" Выбрана акция "+_vm._s(_vm.$store.state.selectedPromo.name)+" ")])],1):_vm._e(),(!_vm.$store.state.loading)?_c('v-footer',{staticClass:"mt-3 pa-0",attrs:{"color":"grey lighten-4","height":"80"}},[_c('v-col',{staticClass:"pa-0 fill-height"},[_c('v-divider',{staticClass:"grey lighten-3"}),_c('v-row',{staticClass:"grey--text text--darken-2 fill-height px-3",attrs:{"no-gutters":"","justify":"space-between","align":"center"}},[_c('div',{staticClass:"font-weight-medium"},[_vm._v("© Ростерхит 2021")]),_c('router-link',{staticClass:"grey--text text--darken-2",attrs:{"to":{ name: 'paymentInfo' }}},[_c('div',{staticClass:"body-2"},[_vm._v("Условия предзаказа товара")])])],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }