<template>
  <div class="ma-0 px-3" style="height: calc(100vh - 64px)">
    <div v-if="shifts?.length === 0" class="body-2">Нет активных курьеров</div>
    <v-row
      class="ma-0"
      style="
        height: 100%;
        flex-direction: column;
        justify-content: space-between;
        flex-wrap: nowrap;
      "
    >
      <div>
        <v-col
          :style="
            selectedShift && selectedOrders.length
              ? 'position: sticky; top: 0; background: white; z-index: 1'
              : ''
          "
          cols="12"
          class="pa-0"
          v-for="[status, currentShifts] in Object.entries(byStatus)"
          :key="status"
        >
          <div
            v-if="currentShifts.length"
            class="headline font-weight-bold"
            :class="{ 'mt-10': Object.keys(byStatus)[0] !== String(status) }"
          >
            {{ $shift.statusName[status] }}
          </div>
          <v-col cols="12" class="ma-0 pa-0 mt-2" style="gap: 10px">
            <v-row
              v-for="(shift, index) in currentShifts"
              :key="`${shift.id}${shift.status}`"
              class="ma-0"
              style="
                display: flex;
                align-items: center;
                position: relative;
                cursor: pointer;
              "
              :class="{ active: selectedShift?.id === shift.id }"
              @click="$emit('selectCourier', shift)"
            >
              <v-col v-if="index" cols="12" class="pa-0">
                <v-divider />
              </v-col>
              <v-row class="ma-0 my-2" align="center">
                <div
                  style="
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    border: 1px solid #dedede;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                  class="mr-3"
                >
                  <v-icon size="60px">mdi-account-circle</v-icon>
                </div>
                <v-col class="pa-0">
                  <div class="font-weight-medium courier-name">
                    {{ shift.user.first_name || "Без имени" }}
                  </div>
                  <div class="caption">
                    Назначено заказов: {{ courierOrdersCount(shift.user) }}
                  </div>
                  <div class="caption mt-n1">
                    {{ getTime(shift) }}
                  </div>
                </v-col>
                <v-icon
                  v-if="selectedShift?.id === shift.id"
                  size="22px"
                  class="mr-2"
                  @click.stop="$emit('selectCourier', shift)"
                  >mdi-close
                </v-icon>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      size="22px"
                      class="mr-2"
                      :color="
                        filterOrdersByShift?.id === shift.id ? 'primary' : ''
                      "
                      v-on="on"
                      @click.stop="$emit('filterOrdersByShift', shift)"
                      >mdi-list-box-outline
                    </v-icon>
                  </template>
                  Список заказов курьера
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      size="22px"
                      v-on="on"
                      @click.stop="openShiftAnalytics(shift)"
                      >mdi-information-outline
                    </v-icon>
                  </template>
                  Информация о смене
                </v-tooltip>
              </v-row>
            </v-row>
          </v-col>
          <v-divider
            class="mt-4"
            v-if="selectedShift && selectedOrders.length"
          />
        </v-col>
        <v-col class="pa-0 mt-4" v-if="selectedOrders.length && selectedShift">
          <div>
            <v-col
              class="pa-0"
              v-for="(order, orderIndex) in selectedOrders"
              :key="orderIndex"
              :class="{ 'mt-8': orderIndex }"
            >
              <v-row class="ma-0" justify="space-between">
                <v-row class="ma-0" align="center">
                  <div class="title mr-2">{{ order.daily_number }}</div>
                  <v-tooltip
                    bottom
                    v-if="order.user_status === $order.userStatusEnum.PACKAGED"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        v-on="on"
                        class="order-chip mr-2"
                        :class="{
                          [order.packagedWaitingTime > 5
                            ? 'order-chip-red'
                            : 'order-chip-gray']: true,
                        }"
                        style="display: flex; align-items: center; gap: 5px"
                        v-if="order.packaged_at"
                      >
                        <v-icon size="15px" style="transform: rotate(90deg)"
                          >mdi-waves
                        </v-icon>
                        <div>{{ order.packagedWaitingTime }} мин</div>
                      </div>
                    </template>
                    Остывает на тепловой полке
                  </v-tooltip>
                  <div
                    :style="{ ...$order.userStatusColors[order.user_status] }"
                    style="border-radius: 6px; line-height: 100%"
                    class="pa-1"
                  >
                    {{ $order.getUserStatusName(order) }}
                  </div>
                </v-row>

                <v-icon size="20px" @click="$emit('selectOrder', order)"
                  >mdi-close
                </v-icon>
              </v-row>
              <v-row class="mt-4 ma-0" style="flex-wrap: nowrap" align="start">
                <v-icon class="mr-1">mdi-map-marker-outline</v-icon>
                <div class="body-2">{{ order.delivery_address.address }}</div>
              </v-row>
              <v-row class="mt-4 ma-0" style="flex-wrap: nowrap" align="start">
                <v-icon class="mr-1">mdi-cash</v-icon>
                <div class="body-2">{{ order.sum }} ₽</div>
              </v-row>
              <v-col
                v-for="(orderItem, index) in order.items"
                :key="index"
                class="pa-0 mt-4"
              >
                <v-row align="center" class="my-1" no-gutters>
                  <div class="body-2 font-weight-medium">
                    <template v-if="orderItem.product.name">
                      {{ orderItem.product.name }}
                    </template>
                    <template v-else-if="orderItem.product.vendor_name"
                      >{{ orderItem.product.vendor_name }}
                    </template>
                    <span class="grey--text text--darken-1"
                      >(x{{ orderItem.amount }})</span
                    >
                  </div>
                  <v-spacer></v-spacer>
                  <div class="body-2 font-weight-bold mr-2">
                    {{ orderItem.price }} ₽
                  </div>
                </v-row>
                <v-col class="pa-0">
                  <div
                    v-if="orderItem.disabled_nomenclature?.length"
                    class="c-column"
                  >
                    <div
                      class="c-row c-items-center red--text mb-1 mt-1 body-2"
                      v-for="(
                        disabledNomenclature, disabledNomenclatureIndex
                      ) in orderItem.disabled_nomenclature"
                      :key="disabledNomenclatureIndex"
                    >
                      <v-icon class="mr-1" color="red" size="20"
                        >mdi-information-outline
                      </v-icon>
                      Убрать {{ disabledNomenclature.name }}
                    </div>
                  </div>
                  <div
                    v-for="(el, ingIndex) in orderItem.ingredients"
                    :key="ingIndex"
                    class="caption py-1"
                  >
                    <v-row align="center" class="pl-1" no-gutters>
                      - <span v-if="el.ingredient.name" class="body-2">{{
                        el.ingredient.name
                      }}</span>
                      <span v-else>{{
                        (el.ingredient.vendor_name || "Отсутствует").replace(
                          "-",
                          ""
                        )
                      }}</span>
                      <span class="grey--text text--darken-1"
                        > (x{{ orderItem.amount }})</span
                      >
                    </v-row>
                  </div>
                </v-col>
              </v-col>
            </v-col>
          </div>
        </v-col>
      </div>
      <v-row
        v-if="selectedOrders.length || selectedShift"
        class="ma-0 mt-2 px-2 py-2"
        style="
          width: 100%;
          position: sticky;
          bottom: 0px;
          background: white;
          flex: unset;
        "
      >
        <v-col cols="12" class="pa-0">
          <v-btn width="100%" depressed @click="$emit('clear')">
            <div class="text-none body-2">Сбросить</div>
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          class="pa-0 mt-2"
          v-if="selectedShift && selectedOrders.length"
        >
          <v-btn
            depressed
            color="primary"
            width="100%"
            @click="$emit('setOrdersToShift')"
            :disabled="
              selectedOrders.some(
                (v) =>
                  ![
                    $order.userStatusEnum.PACKAGED,
                    $order.userStatusEnum.IN_DELIVERY,
                  ].includes(v.user_status)
              )
            "
          >
            <div class="text-none body-2">Назначать заказы</div>
          </v-btn>
        </v-col>
      </v-row>
    </v-row>
    <ShiftAnalyticsModal
      :model-value="shiftAnalyticsModal"
      @update:modelValue="shiftAnalyticsModal = $event"
      :data="shiftAnalyticsResult"
      :shift="shiftAnalyticsShift"
    />
    <OrderViewDialog :value="orderDialog" @input="orderDialog = false" />
  </div>
</template>

<script setup>
import Vue, { computed, onMounted, ref } from "vue";
import moment from "moment/moment";
import ShiftAnalyticsModal from "@/admin/views/deliveryAdmin/ShiftAnalyticsModal.vue";
import OrderViewDialog from "@/admin/views/equipmentLockedPeriod/OrderViewDialog.vue";

const app = Vue.prototype;

const props = defineProps({
  shifts: Array,
  totalOrders: Array,
  orders: Array,
  selectedOrders: Array,
  selectedShift: Object,
  filterOrdersByShift: Object,
  shiftToTakeNextOrder: Object,
});

defineEmits([
  "selectCourier",
  "setOrdersToShift",
  "filterOrdersByShift",
  "selectOrder",
  "clear",
]);

const shiftAnalyticsLoading = ref(false);
const shiftAnalyticsResult = ref(null);
const shiftAnalyticsModal = ref(false);
const shiftAnalyticsShift = ref(null);
const orderDialog = ref(false);

onMounted(() => {
  app.$eventBus.$on("openCourierModal", (item) => {
    openShiftAnalytics(item);
  });
});

const byStatus = computed(() => {
  const data = {
    [app.$shift.statusEnum._TAKES_ORDER]: [], // берет заказ
    [app.$shift.statusEnum.WAITING]: [],
    [app.$shift.statusEnum.WORKING]: [],
    [app.$shift.statusEnum.BREAK]: [],
    [app.$shift.statusEnum.GOING_BACK]: [],
  };
  const onlyTakesOrder = props.selectedOrders && props.selectedShift;
  if (props.selectedShift) {
    data[app.$shift.statusEnum._TAKES_ORDER].push(props.selectedShift);
  }
  for (const shift of props.shifts) {
    if (props.selectedShift?.id === shift.id) continue;
    if (onlyTakesOrder && shift.id === props.selectedShift?.id)
      data[app.$shift.statusEnum._TAKES_ORDER].push(shift);
    else {
      const dataKey = Object.keys(data).find((v) => Number(v) === shift.status);
      if (!dataKey) {
        console.error(
          `shift status ${shift.status} not found in prepared data`
        );
        continue;
      }
      if (props.shiftToTakeNextOrder?.id === shift.id && !props.selectedShift) {
        data[app.$shift.statusEnum._TAKES_ORDER].push(shift);
      } else data[dataKey].push(shift);
    }
  }
  const waiting = data[app.$shift.statusEnum.WAITING];
  data[app.$shift.statusEnum.WAITING] = waiting.sort((a, b) => courierOrdersCount(a.user) < courierOrdersCount(b.user) ? -1 : 1);
  return onlyTakesOrder
    ? { [app.$shift.statusEnum._TAKES_ORDER]: data[0] }
    : data;
});

const courierOrdersCount = (user) => {
  return props.totalOrders.filter((v) => v.courier?.id === user.id).length;
};

const getTime = (item) => {
  const now = moment();
  if (item.status === app.$shift.statusEnum.GOING_BACK) {
    if (!item.estimated_going_back_time) return "";
    const val = moment.utc(item.estimated_going_back_time).local();
    return val.diff(now, "minutes") + " мин";
  } else {
    const val = moment.utc(item.status_updated_at).local();
    return now.diff(val, "minutes") + " мин";
  }
};
const openShiftAnalytics = (shift) => {
  shiftAnalyticsLoading.value = true;
  app.$analytics
    .sendPostSingle("courier_report_by_shift", undefined, {
      shift: shift.id,
    })
    .then((res) => {
      shiftAnalyticsShift.value = shift;
      shiftAnalyticsResult.value = res.result;
      shiftAnalyticsModal.value = true;
    })
    .catch(() => void 0)
    .finally(() => {
      shiftAnalyticsLoading.value = false;
    });
};
</script>

<style scoped>
.active .courier-name {
  color: #fab400 !important;
}

.order-chip {
  border-radius: 6px;
  padding: 3px 4px;
  line-height: 100%;
  font-size: 12px;
  font-weight: 500;
}

.order-chip-gray {
  background: #dedede;
  color: #4f4f4f;
}

.order-chip-red {
  background: red;
  color: white;
}

.order-chip .v-icon {
  color: inherit;
}
</style>
