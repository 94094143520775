// read bootstrap.js
export default {
    methods: {
        calendarDateTime: date => window.moment.utc(date, 'YYYY-MM-DD HH:mm:ss').local().calendar(),
        calendarDate: date => window
            .moment
            .utc(date, 'YYYY-MM-DD HH:mm:ss')
            .local()
            .calendar()
            .split(',')[0]
            .replace(` ${moment().year()} г.`, ''),
        calendarTime: date => window.moment.utc(date, 'YYYY-MM-DD HH:mm:ss').local().format('HH:mm'),
        parseDateTime: date => window.moment.utc(date, 'YYYY-MM-DD HH:mm:ss').local(),
        formatWorkDay(workDay) {
            switch (workDay) {
                case 1:
                    return 'пн'
                case 2:
                    return 'вт'
                case 3:
                    return 'ср'
                case 4:
                    return 'чт'
                case 5:
                    return 'пт'
                case 6:
                    return 'сб'
                case 7:
                    return 'вс'
            }
        },
    }
}
