<template>
    <v-row align="center">
        <v-col>
            <v-row no-gutters align="center" justify="center">
                <div class="font-weight-bold mb-5">ANDROID - {{ androidData.total }}</div>
            </v-row>
            <v-row no-gutters align="center" justify="center">
                <donat :values="androidData.values" :labels="androidData.labels"></donat>
            </v-row>
        </v-col>
        <v-col>
            <v-row no-gutters align="center" justify="center">
                <div class="font-weight-bold mb-5">IOS - {{ iosData.total }}</div>
            </v-row>
            <v-row no-gutters align="center" justify="center">
                <donat :values="iosData.values" :labels="iosData.labels"></donat>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import Donat from "@/admin/views/analyitcs/Donat";

export default {
    name: 'DeviceVersions',
    props: {
        results: Object
    },
    components: {
        Donat
    },
    data: () => ({
        // results: null,
        androidData: {
            values: [],
            labels: [],
            total: 0
        },
        iosData: {
            values: [],
            labels: [],
            total: 0
        }
    }),
    methods: {},
    watch: {
        results() {
            const systems = ['android', 'ios']
            systems.forEach(system => {
                this[`${system}Data`].values = []
                this[`${system}Data`].labels = []
                this[`${system}Data`].total = this.results.total[system]
                this.results.versions[system].forEach(val => {
                    this[`${system}Data`].values.push(val.count)
                    this[`${system}Data`].labels.push(val.app_version)
                })
            })
        }
    },
}
</script>

<style scoped>

</style>