const m = require('air-vue-model')()
m.url = 'discounts'
m.name = 'discountSystem'
m.currentSystem = null


m.routes = [{
    name: 'item', component: require('@/admin/views/discountSystems/DiscountSystems.vue'),
}]

module.exports = m