<template>
  <v-dialog :value="value" @input="closeModal()" max-width="400px">
    <v-card class="pa-4">
      <v-row justify="space-between" class="ma-0 mb-5">
        <div class="text-body-1 font-weight-bold">Настройте приоритет</div>
      </v-row>
      <draggable v-model="currentShifts">
        <transition-group>
          <div
            v-for="shift in currentShifts"
            :key="shift.id"
            style="cursor: move"
          >
            <v-row class="ma-0 my-2" align="center">
              <div
                style="
                  width: 50px;
                  height: 50px;
                  border-radius: 50%;
                  border: 1px solid #dedede;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
                class="mr-3"
              >
                <v-icon size="60px">mdi-account-circle</v-icon>
              </div>
              <v-col class="pa-0">
                <div class="font-weight-medium courier-name">
                  {{ shift.user.first_name || "Без имени" }}
                </div>
              </v-col>
              <v-icon>mdi-drag</v-icon>
            </v-row>
          </div>
        </transition-group>
      </draggable>
      <v-btn
        depressed
        small
        color="primary"
        class="mt-3"
        @click="updatePriority()"
      >
        <div class="text-none">Применить</div>
      </v-btn>
    </v-card>
  </v-dialog>
</template>

<script setup>
import draggable from "vuedraggable";
import { ref, watch } from "vue";
import { cloneDeep } from "lodash";

const props = defineProps({
  value: Boolean,
  shifts: Array,
});

const emit = defineEmits(["input", "updatePriority"]);

const currentShifts = ref([]);

const closeModal = () => {
  emit("input", false);
};

const updatePriority = () => {
  emit("updatePriority", currentShifts.value);
};

watch(
  () => props.value,
  () => {
    if (props.value) {
      currentShifts.value = cloneDeep(props.shifts);
      // currentShifts.value.forEach(v => v.priority = v.priority || 0)
      // currentShifts.value = currentShifts.value.sort((a,b) => a.priority < b.priority ? 1 : 1)
    }
  }
);
</script>

<style scoped></style>
