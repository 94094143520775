<template>
  <template-item
    :show-actions="false"
    :title="title"
    :model="$productIngredientGroup"
    :show-delete="false"
    v-slot="{ object, rules }"
    :after-save="afterSave"
    ref="templateItemRef"
  >
    <ProductIngredientGroupTabs />
    <router-view />
  </template-item>
</template>

<script>
import ProductIngredientGroupTabs from "@/admin/views/productIngredientGroupIngredient/ProductIngredientGroupTabs.vue";

export default {
  name: "ProductGroupItem",
  components: { ProductIngredientGroupTabs },
  mounted() {
    this.$eventBus.$on("saveProductIngredientGroupItem", () => {
      try {
        this.$refs.templateItemRef.save()
      } catch {
        void 0
      } finally {
        this.$eventBus.$emit("productIngredientGroupItemSaveFinal")
      }
    });
  },
  computed: {
    title() {
      const values = [];
      if (this.$product.item) {
        if (this.$product.item.group) {
          values.push(this.$product.item.group.vendor_name || "Отсутствует");
        }
        values.push(this.$product.item.vendor_name || "Отсутствует");
      }
      if (this.$productIngredientGroup.item?.ingredient_group)
      values.push(
          this.$productIngredientGroup.item.ingredient_group.vendor_name ||
          "Отсутствует"
      );
      return values.join(" / ");
    },
  },
  methods: {
    afterSave() {
      this.$eventBus.$emit("productIngredientGroupItemSaved")
    }
  }
};
</script>
