<template>
  <div class="container mt-6">
    <div class="t-text t-text_md" field="text">
      <strong style="color: rgb(0, 0, 0)"
        >Политика обработки персональных данных</strong
      ><br /><strong style="color: rgb(0, 0, 0)"
        >Редакция № 1 от 01.07.2023</strong
      ><br /><span style="color: rgb(0, 0, 0)"
        >Это Политика конфиденциальности сети стрит-фуд кафе «Ростерхит» (далее
        – Политика).</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >Когда в Политике используются слова «мы», «нас» и прочие, говорится о
        сети стрит-фуд кафе «Ростерхит».</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >В Политике мы обращаемся к вам как пользователям нашего мобильного
        приложения «Ростерхит» и/или его веб-версии </span
      ><a :href="host" style="color: rgb(0, 0, 0)"
        ><u>{{ host }}</u></a
      ><span style="color: rgb(0, 0, 0)">
        (далее при совместном упоминании именуются – сервис).</span
      ><br /><br /><strong style="color: rgb(0, 0, 0)"
        >О чем эта Политика?</strong
      ><br /><span style="color: rgb(0, 0, 0)"
        >В Политике изложена информация о том, как мы обрабатываем ваши
        персональные данные и обеспечиваем их безопасность и
        конфиденциальность.</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >Из Политики вы узнаете, какие персональные данные мы получаем и как их
        используем.</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >Что такое персональные данные? </span
      ><br /><span style="color: rgb(0, 0, 0)"
        >Персональные данные – любая информация, относящаяся к прямо или
        косвенно определенному или определяемому физическому лицу (субъекту
        персональных данных) (п. 1 ст. 3 ФЗ от 27.07.2006 № 152-ФЗ «О
        персональных данных»).</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >Иными словами, персональные данные – это любая информация о человеке
        (субъекте персональных данных), по которым его можно определить. </span
      ><br /><span style="color: rgb(0, 0, 0)"
        >Мы обрабатываем только те персональные данные, которые перечислены в
        Политике, и которые характеризуют вас как пользователя сервиса. </span
      ><br /><span style="color: rgb(0, 0, 0)"
        >Мы не можем проверить и поэтому доверяем Вам в том, что вы:</span
      ><br />
      <ul>
        <li style="color: rgb(0, 0, 0)">
          <span style="color: rgb(0, 0, 0)"
            >обладаете полной дееспособностью;</span
          >
        </li>
        <li style="color: rgb(0, 0, 0)">
          <span style="color: rgb(0, 0, 0)"
            >предоставили собственные достоверные персональные данные.</span
          >
        </li>
      </ul>
      <span style="color: rgb(0, 0, 0)"
        >Вы можете дать согласие на обработку персональных данных проставив
        отметку в чекбоксе «Согласен на обработку персональных данных», что
        означает полное согласие со всеми положениями Политики.</span
      ><br /><br /><strong style="color: rgb(0, 0, 0)"
        >Какие права есть у вас?</strong
      ><span style="color: rgb(0, 0, 0)"> </span><br /><span
        style="color: rgb(0, 0, 0)"
        >В любой момент, когда у нас есть ваши персональные данные, вы можете
        воспользоваться следующими правами:</span
      ><br /><u style="color: rgb(0, 0, 0)">Право на информацию</u><br /><span
        style="color: rgb(0, 0, 0)"
        >Вы можете попросить нас предоставить сведения об обработке своих
        персональных данных.</span
      ><br /><u style="color: rgb(0, 0, 0)">Право на доступ</u><br /><span
        style="color: rgb(0, 0, 0)"
        >У вас есть право запросить копию персональных данных, которые у нас
        есть.</span
      ><br /><u style="color: rgb(0, 0, 0)">Право на исправление</u><br /><span
        style="color: rgb(0, 0, 0)"
        >Вы можете попросить нас исправить неточные или неполные персональные
        данные.</span
      ><br /><u style="color: rgb(0, 0, 0)">Право на отзыв</u><br /><span
        style="color: rgb(0, 0, 0)"
        >В любой момент вы можете отозвать свое согласие на обработку
        данных.</span
      ><br /><u style="color: rgb(0, 0, 0)">Право на забвение</u><br /><span
        style="color: rgb(0, 0, 0)"
        >Вы можете запросить удаление данных, которые у нас есть относительно
        вас, за исключением случаев, когда мы обязаны хранить эти данные по
        закону.</span
      ><br /><br /><strong style="color: rgb(0, 0, 0)"
        >Как мы обрабатываем персональные данные?</strong
      ><span style="color: rgb(0, 0, 0)"> </span><br /><span
        style="color: rgb(0, 0, 0)"
        >Мы обрабатываем персональные данные следующими способами:</span
      ><br /><em style="color: rgb(0, 0, 0)">Автоматизировано</em><br /><span
        style="color: rgb(0, 0, 0)"
        >Это обработка данных техникой без участия человека. Например, когда вы
        вводите данные в нашем приложении и данные автоматически записываются в
        нашу базу данных. </span
      ><br /><em style="color: rgb(0, 0, 0)"
        >Без использования средств автоматизации</em
      ><span style="color: rgb(0, 0, 0)"> </span><br /><span
        style="color: rgb(0, 0, 0)"
        >Это обработка данных человеком. Например, когда вы передаете бумажный
        документ со своими данными почтой или лично. </span
      ><br /><em style="color: rgb(0, 0, 0)">Смешанно</em
      ><span style="color: rgb(0, 0, 0)"> </span><br /><span
        style="color: rgb(0, 0, 0)"
        >Это обработка данных человеком с применением техники. Например, когда
        вы передаете данные сотруднику, и он вносит их в базу данных.</span
      ><br /><br /><span style="color: rgb(0, 0, 0)">Мы будем:</span><br />
      <ul>
        <li style="color: rgb(0, 0, 0)">
          <span style="color: rgb(0, 0, 0)">Собирать и записывать данные.</span>
        </li>
        <li style="color: rgb(0, 0, 0)">
          <span style="color: rgb(0, 0, 0)"
            >Систематизировать, накапливать и хранить данные в своих
            информационных базах, находящихся в России.</span
          >
        </li>
        <li style="color: rgb(0, 0, 0)">
          <span style="color: rgb(0, 0, 0)"
            >Использовать данные в соответствии с целями обработки.</span
          >
        </li>
        <li style="color: rgb(0, 0, 0)">
          <span style="color: rgb(0, 0, 0)"
            >Уточнять (обновлять/изменять) данные в случае их изменения,
            неполноты или неточности.</span
          >
        </li>
        <li style="color: rgb(0, 0, 0)">
          <span style="color: rgb(0, 0, 0)"
            >&nbsp;Передавать данные лицам, объединенных в рамках
            франчайзинговой сети под товарным знаком «РОСТЕРХИТ СТРИТ ФУД КАФЕ»,
            в целях оказания услуг пользователю сервиса и исполнения договоров
            розничной купли-продажи товаров.</span
          >
        </li>
        <li style="color: rgb(0, 0, 0)">
          <span style="color: rgb(0, 0, 0)"
            >Блокировать данные, если обнаружим, что какие-то данные не подлежат
            обработке.</span
          >
        </li>
        <li style="color: rgb(0, 0, 0)">
          <span style="color: rgb(0, 0, 0)"
            >Уничтожать данные, которые перестали быть необходимыми.</span
          >
        </li>
        <li style="color: rgb(0, 0, 0)">
          <span style="color: rgb(0, 0, 0)">Мы не будем:</span>
        </li>
        <li style="color: rgb(0, 0, 0)">
          <span style="color: rgb(0, 0, 0)"
            >Передавать ваши персональные данные третьим лицам без вашего
            согласия, за исключением случаев, когда такая обязанность
            установлена для нас законом.</span
          >
        </li>
        <li style="color: rgb(0, 0, 0)">
          <span style="color: rgb(0, 0, 0)"
            >Публиковать и распространять данные неограниченному кругу
            лиц.</span
          >
        </li>
        <li style="color: rgb(0, 0, 0)">
          <span style="color: rgb(0, 0, 0)"
            >Передавать данные за границу России.</span
          >
        </li>
      </ul>
      <br /><strong style="color: rgb(0, 0, 0)"
        >В каких целях мы обрабатываем ваши персональные данные?</strong
      ><span style="color: rgb(0, 0, 0)"> </span><br /><em
        style="color: rgb(0, 0, 0)"
        >Цель обработки</em
      ><br /><span style="color: rgb(0, 0, 0)">Обеспечение работы сервиса</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >Обработка действий пользователя сервиса, связанных с регистрацией и
        авторизацией на сервисе (управление аккаунтом)</span
      ><br /><br /><span style="color: rgb(0, 0, 0)">Персональные данные</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;IP-адрес</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;данные о
        местоположении</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;cookie-файлы</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;имя</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;фамилия</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;отчество</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;пол</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;дата рождения
        (дд.мм.гггг)</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;возраст</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;номер телефона</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;электронная
        почта</span
      ><br /><br /><em style="color: rgb(0, 0, 0)">Цель обработки</em
      ><br /><span style="color: rgb(0, 0, 0)"
        >Исполнение обязательств по договору розничной купли-продаже </span
      ><br /><span style="color: rgb(0, 0, 0)"
        >Персонализация цен, скидок, бонусов и предложений</span
      ><br /><br /><span style="color: rgb(0, 0, 0)">Персональные данные</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;имя</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;фамилия</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;отчество </span
      ><br /><span style="color: rgb(0, 0, 0)"
        >·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;пол</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;дата рождения
        (дд.мм.гггг)</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;возраст</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;номер телефон</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;электронная почта </span
      ><br /><span style="color: rgb(0, 0, 0)"
        >·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;маскированный номер
        банковской карты</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;данные чека об
        оплате</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;данные об оказанных и
        оказываемых пользователю услугах</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;история заказов,
        обращений пользователя сервиса посредством использования сервиса</span
      ><br /><br /><em style="color: rgb(0, 0, 0)">Цель обработки</em
      ><br /><span style="color: rgb(0, 0, 0)"
        >Оповещение об изменении пользовательского соглашения относительно
        условий доставки, порядка оказания услуг, меню, перечня проводимых нами
        акций, скидок и иных маркетинговых мероприятий</span
      ><br /><br /><span style="color: rgb(0, 0, 0)">Персональные данные</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;имя</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;фамилия</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;отчество</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;пол</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;дата рождения
        (дд.мм.гггг)</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;возраст</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;номер телефона</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;электронная
        почта</span
      ><br /><br /><strong style="color: rgb(0, 0, 0)"
        >В течение какого срока мы обрабатываем ваши персональные
        данные?</strong
      ><span style="color: rgb(0, 0, 0)"> </span><br /><span
        style="color: rgb(0, 0, 0)"
        >Мы обрабатываем ваши персональные данные с момента их получения и до
        достижения цели или отзыва согласия на обработку. Вы можете в любой
        момент прекратить обработку персональных данных, направив нам заявление
        в свободной форме об отзыве согласия на обработку данных по электронной
        почте на адрес </span
      ><a href="mailto:support@rosterhit.ru" style="color: rgb(0, 0, 0)"
        ><u>support@rosterhit.ru</u></a
      ><u style="color: rgb(0, 0, 0)">.</u><br /><span
        style="color: rgb(0, 0, 0)"
        >Каким образом мы уничтожаем ваши персональные данные? </span
      ><br /><span style="color: rgb(0, 0, 0)"
        >При достижении цели обработки персональных данных или в случае отзыва
        согласия на обработку мы удаляем персональные данные из нашей базы
        данных.</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >Как мы обеспечиваем безопасность персональных данных?</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >Мы защищаем персональные данные, которые у нас хранятся, от
        разглашения, полной или частичной утраты, а также несанкционированного
        доступа со стороны третьих лиц.</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >Для этого мы используем все необходимые правовые, технические и
        организационные меры по обеспечению безопасности и конфиденциальности, и
        постоянно обновляем их с учетом последних законодательных изменений и
        технических разработок.</span
      ><br /><br /><span style="color: rgb(0, 0, 0)"
        >Если происходит утечка персональных данных, то мы:</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;в течение 24 часов
        уведомляем об этом Роскомнадзор;</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;в течение 72 часов
        проводим собственное расследование и уведомляем Роскомнадзор о его
        результатах.</span
      ><br /><br /><strong style="color: rgb(0, 0, 0)"
        >Как с нами связаться?</strong
      ><span style="color: rgb(0, 0, 0)"> </span><br /><span
        style="color: rgb(0, 0, 0)"
        >По любым вопросам в отношении обработки персональных данных вы можете
        обратиться к нам по электронной почте </span
      ><a href="mailto:support@rosterhit.ru" style="color: rgb(0, 0, 0)"
        ><u>support@rosterhit.ru</u></a
      ><u style="color: rgb(0, 0, 0)">.</u><br /><span
        style="color: rgb(0, 0, 0)"
        >При обращении укажите свое имя и контакты для обратной связи.</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >Мы ответим на ваше обращение не позднее 10 рабочих дней с момента его
        получения.</span
      ><br /><br /><span style="color: rgb(0, 0, 0)"
        >Реквизиты сети стрит-фуд кафе «Ростерхит»:</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >Индивидуальный предприниматель </span
      ><br /><span style="color: rgb(0, 0, 0)">Хитров Валерий Николаевич</span
      ><br /><span style="color: rgb(0, 0, 0)">ИНН 390803367851</span
      ><br /><span style="color: rgb(0, 0, 0)">ОГРНИП 318392600017669</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >Адрес: г. Калининград, ул. Коломенская, д. 19</span
      ><br /><span style="color: rgb(0, 0, 0)">E-mail: </span
      ><a href="mailto:support@rosterhit.ru" style="color: rgb(0, 0, 0)"
        ><u>support@rosterhit.ru</u></a
      ><br /><span style="color: rgb(0, 0, 0)"
        >Политика размещена по адресу: </span
      ><a :href="currentHref" style="color: rgb(0, 0, 0)"
        ><u>{{ currentHref }}</u></a
      ><br /><br /><br />
    </div>
  </div>
</template>

<script>
export default {
  name: "UserAgreement",
  data: () => ({
    host: null,
    paymentInfo: null,
    currentHref: null,
  }),
  created() {
    this.currentHref = window.location.href;
    this.host = "127.0.0.1:8000".includes(window.location.host)
      ? "https://app.rosterhit.ru"
      : `${window.location.protocol} ${window.location.host}`;
    this.$company
      .sendGet("get_payment_info", {
        domain: this.host,
      })
      .then((res) => {
        this.paymentInfo = res;
      })
      .catch(() => {
        this.$company
          .sendGet("get_payment_info", {
            domain: "app.rosterhit.ru",
          })
          .then((res) => {
            this.paymentInfo = res;
          });
      });
  },
};
</script>

<style scoped></style>
