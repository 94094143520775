const m = require('air-vue-model')()
m.url = 'order_review_tags'
m.name = 'orderReviewTag'

m.text = {
    listTitle: 'Категории отзывов'
}

m.default = {
    title: '',
    color: '#fab400',
    group: null
}

m.filters = {
}



m.routes = [
    {
        name: 'list',
        component: require('@/admin/views/orderReviewTags/OrderReviewTags.vue'),
    },
]

module.exports = m