<template>
  <!--  :show-actions="$auth.hasRight('nomenclature__edit_information')"-->
  <template-item
    v-slot="{ object, rules }"
    :model="$promocodeCondition"
    object-key-for-title="name"
    title-prepend="Условие промокода / "
    :show-actions="$route.name === 'promocodeConditionItem' && $auth.hasRight('promocode_condition__edit')"
    :before-save="beforeSave"
    :after-save="afterSave"
    :show-delete="isDeleteShowed($promocodeCondition.item)"
  >

    <v-row no-gutters align="center" class="mb-5 mx-0" v-if="object.id && $auth.hasRight('user_promocode__access')">
      <v-btn
        @click="$router.replace({ name: 'promocodeConditionItem' })"
        :color="$route.name === 'promocodeConditionItem' ? 'primary' : ''"
        depressed
        class="mr-3 rounded-lg text-none body-2"
        >Общая информация
      </v-btn>
      <v-btn
        @click="$router.replace({ name: 'promocodeConditionPromocodesList' })"
        :color="
          $route.name === 'promocodeConditionPromocodesList' ? 'primary' : ''
        "
        depressed
        class="mr-3 rounded-lg text-none body-2"
        >Список промокодов
      </v-btn>
<!--      <v-spacer />-->
<!--      <v-btn-->
<!--          v-if="$route.name === 'promocodeConditionItem' && object.id"-->
<!--          @click="copyToNewPromocode(object)"-->
<!--          color="primary"-->
<!--          depressed-->
<!--          class="mr-3 rounded-lg text-none body-2"-->
<!--      >Скопировать в новый промокод-->
<!--      </v-btn>-->
    </v-row>
    <router-view />
  </template-item>
</template>

<script>
import {cloneDeep} from "lodash";

export default {
  name: "PromocodeConditionPage",
  data: () => ({
    afterSave: (_) => void _,
    beforeSave: (_) => void _,
  }),
  methods: {
    isDeleteShowed(item) {
      if (!item || !item?.id || this.$route.name !== "promocodeConditionItem")
        return false;
      return (
        item.id && item.status === this.$promocodeCondition.statusEnum.DRAFT
      );
    },
    copyToNewPromocode(item) {
      if (!item?.id) return
      this.$router.push({name: 'promocodeConditionItem', params: {promocodeConditionId: 'new'}}).then(() => {
          this.$promocodeCondition.item = cloneDeep({...item, id: undefined})
      })
    }
  },
  mounted() {
    this.$eventBus.$on("promocodeConditionItemInited", (data) => {
      const refItem = data;
      this.afterSave = refItem.afterSave;
      this.beforeSave = () => {
        refItem.beforeItemSave();
      };
    });
  },
};
</script>

<style scoped></style>
