<template>
    <v-row class="px-3 py-3">
        <v-col cols="4">
            <v-col class="pa-0" v-for="(group, groupIndex) in $productGroup.list" :key="groupIndex">
                <v-row no-gutters align="center">
                    <div class="mr-2">{{ group.id }}</div>
                    <div class="text-h5 link font-weight-bold mr-3">{{ group.name }}</div>
                    <div class="mr-1 text-caption text--secondary">Количество блюд: {{ group.products.length }}</div>
                </v-row>
                <v-row v-for="(product, index) in group.products" :key="index" no-gutters
                       @click="loadProduct(product.id)">
                    <div class="mr-2">{{ product.id }}</div>
                    <div class="text-body-1 link" v-text="product.name"/>
                </v-row>
            </v-col>
        </v-col>
        <v-col cols="8">
            <v-col v-if="currentData">
                <v-row no-gutters align="center" class="link" @click="showData = !showData">
                    <v-icon class="mr-2" size="20">{{ `mdi-arrow-${showData ? "up" : "down"}-drop-circle-outline` }}
                    </v-icon>
                    <div class="font-weight-medium">Общая информация</div>
                </v-row>
                <v-col class="pa-0" v-if="showData">
                    <lazy-select @change="ingredient = null" clearable class="mt-3" clearable :items="groups"
                                 v-model="ingredientGroup"
                                 label="Группа"></lazy-select>
                    <lazy-select clearable class="mt-3" clearable :items="ingredients" v-model="ingredient"
                                 label="Ингредиент"></lazy-select>
                    <json-viewer
                        :key="currentKey"
                        :expand-depth="ingredient ? 100 : 2"
                        copyable
                        :value="currentDataResult"
                    ></json-viewer>
                </v-col>
            </v-col>
            <v-col v-if="equipmentsData.length">
                <v-row no-gutters align="center" class="link" @click="showEquipments = !showEquipments">
                    <v-icon class="mr-2" size="20">
                        {{ `mdi-arrow-${showEquipments ? "up" : "down"}-drop-circle-outline` }}
                    </v-icon>
                    <div class="font-weight-medium">Информация о готовке</div>
                </v-row>
                <v-col class="pa-0" v-if="showEquipments">
                    <v-checkbox v-model="hideEquipments" class="pa-0 ma-0 mt-2" hide-details
                                label="Скрывать без этапов готовки"></v-checkbox>
                    <lazy-select clearable class="mt-3" clearable :items="equipmentsDataItems"
                                 v-model="currentEquipment"
                                 label="Ингредиенты"></lazy-select>
                    <json-viewer
                        :key="equipmentsKey"
                        :expand-depth="currentEquipment ? 100 : 2"
                        copyable
                        :value="currentEquipmentsResult"
                    ></json-viewer>
                </v-col>
            </v-col>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "DatabaseData",
    components: {},
    data: () => ({
        showData: true,
        showEquipments: true,

        currentData: null,
        ingredientGroup: null,
        ingredient: null,
        currentKey: '',

        equipmentsData: [],
        currentEquipment: null,
        equipmentsKey: '',
        hideEquipments: true

    }),
    methods: {
        loadProduct(id) {
            this.clearData()
            this.$product.loadItem(id).then(res => {
                this.currentData = res
            })
            this.$product.sendGetSingle('equipments', id).then(res => {
                this.equipmentsData = res
            })
        },
        clearData() {
            this.currentData = null
            this.equipmentsData = []
            this.currentEquipment = null
            this.hideEquipments = true
            this.ingredientGroup = null
            this.ingredient = null
        }
    },
    computed: {
        groups() {
            if (!this.currentData)
                return []
            return this.currentData.product_ingredient_groups.map(val => {
                return {
                    id: val.id,
                    name: val.ingredient_group.name
                }
            })
        },
        groupElement() {
            if (!this.currentData || !this.ingredientGroup)
                return null
            return this.currentData.product_ingredient_groups.find(val => val.id === this.ingredientGroup.id)
        },
        ingredients() {
            if (!this.groupElement)
                return []
            return this.groupElement.ingredients.map(val => {
                return {
                    id: val.id,
                    name: val.ingredient.name
                }
            })
        },
        ingredientElement() {
            if (!this.currentData || !this.ingredient || !this.groupElement)
                return null
            return this.groupElement.ingredients.find(val => val.id === this.ingredient.id)
        },
        currentEquipmentsDataList() {
            let list = this.equipmentsData
            if (this.hideEquipments)
                list = list.filter(val => val.ingredient.equipments.length)
            return list
        },
        equipmentsDataItems() {
            return this.currentEquipmentsDataList.map(val => {
                return {
                    id: val.id,
                    name: val.ingredient.name
                }
            })
        },
        currentEquipmentsResult() {
            this.equipmentsKey = Math.random().toString(36).substring(7)
            if (this.currentEquipment) {
                return this.currentEquipmentsDataList.filter(val => this.currentEquipment.id === val.id)
            }
            return this.currentEquipmentsDataList
        },
        currentDataResult() {
            this.currentKey = Math.random().toString(36).substring(7)
            if (this.ingredientElement) {
                return this.ingredientElement
            }
            if (this.groupElement)
                return this.groupElement
            return this.currentData
        }
    },
    created() {
        this.$productGroup.loadList()
    }
}
</script>

<style scoped>

</style>
