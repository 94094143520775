const m = require('air-vue-model')()
const airLeaflet = require('air-vue-leaflet')()
m.url = 'orders'
m.name = 'order'
m.text = {
    itemTitle: 'Заказ',
    listTitle: 'Заказы'
}
m.routes = []

m.filters = {
    start_date: null,
    end_date: null,
    outlet: null,
    search: null,
    user: null,
    offline_with_user: null,
    source: null
}

m.sum = (order = null) => {
    order = order || m.item
    let sum = 0
    order.items.forEach(val => {
        sum += val.amount * val.price
    })
    return sum
}
m.creationLoading = false

m.parseStatus = (status = null) => {
    if (status == null) status = m.item?.status
    switch (status) {
        case 0:
            return 'Отклонен'
        case 1:
            return 'Создан'
        case 2:
            return 'Оплачен'
        case 3:
            return 'Принят в работу'
        case 4:
            return 'Готовится'
        case 5:
            return 'Завершен'
    }
}

m.pickupTypeEnum = {
    PICKUP: 1,
    CAFE: 2,
    DELIVERY: 3,
}

m.pickupTypeName = {
    [m.pickupTypeEnum.PICKUP]: 'Самовывоз',
    [m.pickupTypeEnum.CAFE]: 'В кафе',
    [m.pickupTypeEnum.DELIVERY]: 'Доставка',
}

m.pickupTypes = Object.keys(m.pickupTypeName).map(_key => {
    const key = Number(_key)
    return {
        label: m.pickupTypeName[key],
        value: key
    }
})

m.userStatusEnum = {
    DECLINED: 0,
    CREATED: 1,
    ACCEPTED: 2,
    IN_PROCESS: 3,
    READY: 4,
    IN_DELIVERY: 5,
    RELEASED: 6,
    PACKAGED: 7,
}

m.sourceEnum = {
    APP: 1,
    CHECKOUT: 2,
    KIOSK: 3,
    YANDEX_EDA: 4,
}

m.sourceName = {
    [m.sourceEnum.APP]: 'Приложение',
    [m.sourceEnum.CHECKOUT]: 'Касса',
    [m.sourceEnum.KIOSK]: 'Киоск',
    [m.sourceEnum.YANDEX_EDA]: 'Яндекс еда',
}

m.sources = Object.keys(m.sourceEnum).map(key => {
    return {
        label: m.sourceName[Number(m.sourceEnum[key])],
        value: Number(m.sourceEnum[key])
    }
})


m.userStatusName = {
    [-1]: 'Не удалось определить',
    [m.userStatusEnum.DECLINED]: 'Отклонен',
    [m.userStatusEnum.CREATED]: 'Принят',
    [m.userStatusEnum.READY]: 'Готов',
    [m.userStatusEnum.ACCEPTED]: 'Принят',
    [m.userStatusEnum.IN_PROCESS]: 'Готовим',
    [m.userStatusEnum.RELEASED]: 'Выдан',
    [m.userStatusEnum.IN_DELIVERY]: 'Доставляется',
    [m.userStatusEnum.PACKAGED]: 'Собран',
}

m.userDeliveryStatusName = {
    [-1]: 'Не удалось определить',
    [m.userStatusEnum.DECLINED]: 'Отклонен',
    [m.userStatusEnum.CREATED]: 'Принят',
    [m.userStatusEnum.READY]: 'Собираем',
    [m.userStatusEnum.ACCEPTED]: 'Принят',
    [m.userStatusEnum.IN_PROCESS]: 'Готовим',
    [m.userStatusEnum.RELEASED]: 'Выдан',
    [m.userStatusEnum.IN_DELIVERY]: 'Доставляется',
    [m.userStatusEnum.PACKAGED]: 'Собран',
}

m.getUserStatusName = (order) => {
    if (order.pickup_type === m.pickupTypeEnum.DELIVERY) return m.userDeliveryStatusName[order.user_status]
    return m.userStatusName[order.user_status]
}

m.userStatusColors = {
    [-1]: {
        backgroundColor: '#EC222214',
        color: '#444444'
    },
    [m.userStatusEnum.DECLINED]: {
        backgroundColor: '#EC222214',
        color: '#EC2222'
    },
    [m.userStatusEnum.CREATED]: {
        backgroundColor: '#006AFF14',
        color: '#006AFF'
    },
    [m.userStatusEnum.READY]: {
        backgroundColor: '#F59A2814',
        color: '#F59A28'
    },
    [m.userStatusEnum.ACCEPTED]: {
        backgroundColor: '#006AFF14',
        color: '#006AFF'
    },
    [m.userStatusEnum.IN_DELIVERY]: {
        backgroundColor: '#006AFF14',
        color: '#006AFF'
    },
    [m.userStatusEnum.IN_PROCESS]: {
        backgroundColor: '#F59A2814',
        color: '#F59A28'
    },
    [m.userStatusEnum.RELEASED]: {
        backgroundColor: '#409B3F14',
        color: '#377E36'
    },
    [m.userStatusEnum.PACKAGED]: {
        backgroundColor: '#409B3F14',
        color: '#377E36'
    },
}

m.getOrderFiscalizationStatus = (order) => {
    if (order.fiscalization_status === 'done') return {color: 'green', text: 'Фискализирован'}
    if (order.fiscalization_status === 'wait') return {color: 'blue', text: 'Ожидает'}
    if (order.fiscalization_status === 'created') return {color: 'primary', text: 'Создан'}
    if (order.fiscalization_status === 'fail') return {color: 'red', text: 'Ошибка'}
    return {'color': 'grey lighten-3', text: 'Отсутствует'}
}

m.computeFirstAvailableTime = (cartItems, outletId) => {
    return m.sendPost('compute_first_available_time', {
        cart_items: cartItems,
        outlet: outletId
    })
}

m.ordersByOutlets = (outletId) => {
    return m.sendGetSingle('queue', outletId)
}


m.routes = [
    {
        name: 'list',
        component: require('@/admin/views/orders/Orders'),
    },
]

m.generateMapLayer = (orders, grey = false, clickFunction = null,) => {
    const points = []
    for (const order of orders) {
        const {coords} = order.delivery_address
        points.push({id: order.id, coords: [coords[0], coords[1]]})
    }
    const pointCollection = airLeaflet.pointCollection(points)
    return L.geoJson(pointCollection, {
        onEachFeature: (feature, layer) => {
            if (clickFunction) {
                layer.on('click', () => clickFunction(feature, layer))
            }
        }, pointToLayer: (feature, latlng) => {
            const draw_icon = new L.icon({
                    iconUrl: grey ? '/images/logo-short-grey.png' : '/images/logo-short.png', iconSize: [20, 28],
                })
            return L.marker(latlng, {
                icon: draw_icon
            })
        },
    })
}

module.exports = m