<template>
    <div class="outline-wrapper rounded-lg" style="position: relative; height: 38px">
        <div class="text-small white px-1" style="position: absolute; top: -8px; left: 10px" v-text="title"/>
        <div class="px-3 py-1" style="height: 100%; width: 100%">
            <slot/>
        </div>
    </div>
</template>

<script>
export default {
    name: "OutlineWrapper",

    props: {
        title: String,
    },
}
</script>

<style scoped>
.outline-wrapper {
    border: 1px #FAB400 solid;
}
</style>