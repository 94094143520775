// if you want use this mixin you should be install package compressorjs

import Compressor from 'compressorjs'

export default {
    methods: {
        getImageFromInput(elementId, multiple = false) {
            return new Promise((resolve, reject) => {
                const files = document.getElementById(elementId).files
                if (multiple) {
                    files.forEach(file => {
                        if (!this.checkFile(file)) {
                            reject()
                        }
                    })
                    const promises = []
                    files.forEach(file => {
                        if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
                            this.$store.commit('setSnackbar', {
                                text: 'Неверный формат файла',
                                color: 'error'
                            })
                            reject()
                        }
                        const promise = new Promise(resolve => {
                            const reader = new FileReader()
                            reader.readAsDataURL(file)
                            reader.onload = () => {
                                resolve(reader.result)
                            }
                        })
                        promises.push(promise)
                    })
                    Promise.all(promises).then(values => {
                        resolve(values)
                    }).catch(() => {
                        reject()
                    })
                } else {
                    const file = files[0]
                    if (!this.checkFile(file)) reject()
                    const reader = new FileReader()
                    reader.readAsDataURL(file)
                    reader.onload = () => {
                        resolve(reader.result)
                    }
                    // resolve(file)
                    // this.compressImage(file).then(value => {
                    //     resolve(value)
                    // }).catch(() => {
                    //     reject()
                    // })
                }
            })
        },
        checkFile(file) {
            if (!file) {
                this.$store.commit('setSnackbar', { text: "Не удалось загрузить изображение", color: "error" })
                return false
            }
            let extension = file.name.split('.').pop().toLowerCase()
            let extensions = ['jpg', 'jpeg', 'png']
            if (!extensions.includes(extension)) {
                this.$store.commit('setSnackbar', {
                    text: "Только изображения в формате JPEG и PNG",
                    color: "error"
                })
                return false
            }
            return true
        },
        attachImage(elementId) {
            document.getElementById(elementId).value = null
            document.getElementById(elementId).click()
        },
        compressImage(file) {
            return new Promise((resolve, reject) => {
                new Compressor(file, {
                    checkOrientation: true,
                    convertSize: 10000,
                    quality: 0.8,
                    success: result => {
                        const reader = new FileReader()
                        reader.readAsDataURL(result)
                        reader.onload = () => {
                            resolve(reader.result)
                        }
                        // resolve(result)
                        // let reader = new FileReader()
                        // reader.readAsDataURL(result)
                        // reader.onload = () => {
                        //     resolve(reader.result)
                        // }
                    },
                    error: () => {
                        this.$store.commit('setSnackbar', {
                            text: "Произошла ошибка при сжатии изображения",
                            color: "error"
                        })
                        reject()
                    },
                })
            })
        },
        imageUploaded(elementId, multiple = false) {
            return new Promise((resolve, reject) => {
                this.getImageFromInput(elementId, multiple)
                    .then(val => {
                        document.getElementById(elementId).value = null
                        resolve(val)
                    }).catch(error => {
                        reject(error)
                    })
            })
        }
    }
}
