import BaseModel from "../vendor/baseModel";
import OutletView from "../main/views/Outlet"

export class Outlet extends BaseModel {

    dialog = false

    constructor() {
        super({
            url: 'outlets',
            name: 'outlet',
            page_size: 'all',
            routes: [
                {
                    name: 'item',
                    component: OutletView,
                    single: true
                },
            ],
        });
    }

    loadList(params) {
        return new Promise((resolve, reject) => {
            axios.post(`/${this.url}/get_list_by_location_and_cart/`, params).then(response => {
                const {data} = response
                this.list = data
                resolve(data)
            }).catch(() => {
                reject()
            })
        })
    }

    openDialog(id) {
        this.item = this.list.find(e => e.id === id)
        this.dialog = true
    }
}
