<template>
    <v-col class="pa-0 fill-height background-color" style="position: relative" v-if="currentTime">
        <v-divider vertical class="primary"
                   style="position: absolute; left: 50%; height: calc(100% - 30px); top: 15px; min-height: 1px"></v-divider>
        <v-app-bar app class="pa-0 ma-0" color="primary" height="60" elevation="0">
            <v-col class="pa-0">
                <v-row no-gutters align="center" class="pa-5 mt-0">
                    <v-col cols="6">
                        <v-row no-gutters align="center" justify="center" style="color: rgb(38, 41, 42)"
                               class="display-1 font-weight-medium">
                            Готовятся
                        </v-row>
                    </v-col>
                    <v-col cols="6">
                        <v-row no-gutters align="center" justify="center" style="color: rgb(38, 41, 42)"
                               class="display-1 font-weight-medium">
                            Готовы
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-app-bar>
        <v-row no-gutters class="fill-height flex-nowrap">
            <v-col cols="6 pr-3">
                <OutletQueueColumn :items="incomingOrders"></OutletQueueColumn>
            </v-col>
            <v-col cols="6">
                <OutletQueueColumn :items="readyOrders"></OutletQueueColumn>
            </v-col>
        </v-row>
        <v-footer app color="black" class="pa-3" v-if="showHello || service">
            <v-col class="pa-3 caption white--text">
                <div class="caption mb-1" v-if="$outlet.item">{{ $outlet.item.address }}</div>
                <div v-for="(el, index) in messages" :key="index">
                    {{ el }}
                </div>
            </v-col>
            <v-row v-if="showHello" no-gutters align="center" justify="center"
                   class="white--text title font-weight-bold">
                Добро пожаловать!
            </v-row>
        </v-footer>
    </v-col>
</template>

<script>
import OutletQueueColumn from "@/outlet_queue/OutletQueueColumn";

export default {
    name: 'OutletQueue',
    components: {
        OutletQueueColumn
    },

    data: () => ({
        socket: null,
        interval: null,
        outlet: null,
        orders: [],
        currentTime: null,
        service: false,
        messages: [],
        showHello: false,
        globallySocket: null
    }),
    methods: {
        parseOrders(orders) {
            orders.forEach(val => {
                let current_order = this.orders.find(el => el.id === val.id)
                if (current_order) {
                    current_order.start_cooking_time = val.start_cooking_time
                    current_order.user_status = val.user_status
                    current_order.daily_number = val.daily_number
                } else
                    this.orders.push(val)
            })
        },
        loadOrders() {
            this.$order.ordersByOutlets(this.outlet).then(res => {
                this.messages.splice(0, 0, `load orders: ${res.length}`)
                this.orders = []
                this.parseOrders(res)
            })
        },
        connectWebsocket() {
            if (!this.outlet)
                return
            this.$outlet.loadItem(this.outlet)
            const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws'
            this.socket = new WebSocket(`${protocol}://${window.location.host}/ws/outlet_queue/${this.outlet}/`);
            this.socket.onopen = e => {
                console.log('connected')
                this.messages.splice(0, 0, `connected`)
                this.loadOrders()
            }
            this.socket.onclose = e => {
                console.log('disconnected')
                this.messages.splice(0, 0, `disconnected`)
                this.socket = null
                setTimeout(() => {
                    this.connectWebsocket()
                }, 5000)
            }

            this.socket.onmessage = e => {
                const data = JSON.parse(e.data)
                this.messages.splice(0, 0, `get message: ${e.data}`)
                this.parseOrders([data.data])
            }
        },
        connectGloballyWebsocket() {
            const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws'
            this.globallySocket = new WebSocket(`${protocol}://${window.location.host}/ws/outlet_queue/`);
            this.globallySocket.onopen = e => {
                console.log('globally websocket connected')
                this.messages.splice(0, 0, `globally websocket connected`)
            }
            this.globallySocket.onclose = e => {
                console.log('globally websocket disconnected')
                this.messages.splice(0, 0, `globally websocket disconnected`)
                this.globallySocket = null
                setTimeout(() => {
                    this.connectGloballyWebsocket()
                }, 5000)
            }

            this.globallySocket.onmessage = e => {
                const data = JSON.parse(e.data)
                if (data.type === 'page.reload') {
                    window.location.reload()
                }
            }
        }

    },
    computed: {
        currentOrders() {
            const values = []
            const outlet = this.$outlet.item
            if (!outlet) return values
            for (const value of this.orders) {
                if (outlet.worker_interface !== 'tracker') {
                    values.push(value)
                    continue
                }
                if (value.show_in_tracker) {
                    values.push(value)
                }
            }
            return values
        },
        incomingOrders() {
            return this.currentOrders.filter(val => {
                if (val.user_status === 3 || !val.start_cooking_time)
                    return true
                if (val.user_status === 2) {
                    let orderTime = window.moment.utc(val.start_cooking_time)
                    return this.currentTime >= orderTime || orderTime.diff(this.currentTime, 'minutes') <= 10
                }

            })
        },
        readyOrders() {
            return this.currentOrders.filter(val => val.user_status === 4)
        }
    },
    created() {
        this.currentTime = window.moment.utc()
        this.outlet = this.$route.query.outlet
        this.service = !!this.$route.query.service
        this.showHello = !!this.$route.query.show_hello
        this.loadOrders()
        this.connectWebsocket()
        this.connectGloballyWebsocket()

        setInterval(() => {
            this.loadOrders()
        }, 1000 * 60 * 3)

        setInterval(() => {
            this.orders = this.orders.filter(val => {
                return [2, 3, 4].indexOf(val.user_status) > -1
            })
            this.currentTime = window.moment.utc()
            this.messages = this.messages.slice(0, 5)
        }, 3000)
    }
}
</script>

<style>
.v-toolbar__content {
    padding: 0 !important;
}

.background-color {
    background: rgb(38, 41, 42);
}

</style>