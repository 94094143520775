<template>
    <div class="pa-2 white" v-if="promos.length > 0">
        <swiper class="swiper" :options="swiperOption">
            <swiper-slide v-for="(promo, index) in promos" :key="index">
                <v-img @click="openPromo(index)" :height="height" :src="promo.image" class="rounded-lg cursor-pointer"/>
            </swiper-slide>
        </swiper>
        <v-overlay v-if="dialog && selected && $vuetify.breakpoint.mdAndUp" z-index="100" opacity=".5">
            <div v-if="selected" style="width: 100vw">
                <v-row>
                    <v-col cols="2">
                        <div class="d-flex justify-start align-center fill-height pl-5">
                            <v-btn x-large icon @click="prev">
                                <v-icon :size="60">mdi-chevron-left</v-icon>
                            </v-btn>
                        </div>
                    </v-col>

                    <v-col cols="8">
                        <v-img :src="selected.image" contain height="70vh">
                            <div class="d-flex fill-height align-end justify-center pb-4">
                                <v-btn rounded depressed color="primary" @click="goToPromo">
                                    <div class="text-none text-body-2">Перейти к акции</div>
                                </v-btn>
                            </div>
                        </v-img>
                    </v-col>

                    <v-col cols="2">
                        <div class="d-flex justify-end align-center fill-height pr-5">
                            <v-btn x-large icon @click="next">
                                <v-icon :size="60">mdi-chevron-right</v-icon>
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>

                <v-btn large icon fixed top right @click="dialog = false">
                    <v-icon large>mdi-close</v-icon>
                </v-btn>
            </div>
        </v-overlay>
        <v-dialog v-if="selected && $vuetify.breakpoint.smAndDown" v-model="dialog" fullscreen>
            <v-card tile>
                <v-img :src="selected.image" height="100vh">
                    <div class="d-flex align-center fill-height">
                        <v-row no-gutters justify="space-between">
                            <v-icon :size="50" @click="prev">
                                mdi-chevron-left
                            </v-icon>
                            <v-icon :size="50" @click="next">
                                mdi-chevron-right
                            </v-icon>
                        </v-row>
                        <v-row no-gutters style="width: 100%; position: fixed; bottom: 20px;" justify="center">
                            <v-btn rounded depressed color="primary" @click="goToPromo">
                                <div class="text-none text-body-2">Перейти к акции</div>
                            </v-btn>
                        </v-row>

                        <v-btn class="primary" icon fixed top right @click="dialog = false">
                            <v-icon color="white">mdi-close</v-icon>
                        </v-btn>
                    </div>
                </v-img>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {Swiper, SwiperSlide} from "vue-awesome-swiper"
import "swiper/swiper-bundle.css"

export default {
    name: "PromoList",

    components: {
        Swiper,
        SwiperSlide,
    },

    data: () => ({
        dialog: false,
        selectedIndex: 0,
    }),

    computed: {
        promos() {
            return this.$promo.list
        },

        selected() {
            if (this.promos.length === 0) return null
            return this.$promo.list[this.selectedIndex]
        },

        swiperOption() {
            return {
                slidesPerView: this.$vuetify.breakpoint.mdAndUp ? 5 : 3,
                spaceBetween: this.$vuetify.breakpoint.mdAndUp ? 30 : 15,
            }
        },

        height() {
            return this.$vuetify.breakpoint.mdAndUp ? 350 : 200
        }
    },

    methods: {
        loadPromos() {
            this.$promo.loadList()
        },

        openPromo(index) {
            this.selectedIndex = index
            this.dialog = true
        },

        next() {
            if (this.selectedIndex + 1 === this.promos.length) {
                this.selectedIndex = 0
            } else this.selectedIndex++
        },

        prev() {
            if (this.selectedIndex === 0) {
                this.selectedIndex = this.promos.length - 1
            } else this.selectedIndex--
        },

        goToPromo() {
            this.$store.state.selectedPromo = this.selected
            this.dialog = false
        },
    },

    created() {
        this.loadPromos()
    },

    mounted() {
        document.onkeydown = (e) => {
            if (!this.dialog) return
            if (e.key === 'Escape') this.dialog = false
            if (e.key === 'ArrowLeft') this.prev()
            if (e.key === 'ArrowRight') this.next()
        }
    }
};
</script>
