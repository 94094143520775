<template>
  <div class="container mt-6">
    <div class="t-text t-text_md" field="text">
      <strong>Оферта для Покупателей</strong><br /><strong
        style="color: rgb(0, 0, 0)"
        >Редакция № 1 от 01.07.2023</strong
      ><br /><span style="color: rgb(0, 0, 0)"
        >Этот документ устанавливает правила для Покупателей.</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >Он обязателен для вас и для нас с момента, как вы оплатите товар.</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >Прочтите оферту перед тем, как оплатить товар. Если у вас возникнут
        вопросы, напишите нам на </span
      ><a href="mailto:feedback@rosterhit.ru" style="color: rgb(0, 0, 0)"
        ><u>feedback@rosterhit.ru</u></a
      ><br /><br /><strong style="color: rgb(0, 0, 0)">Термины</strong
      ><br /><span style="color: rgb(0, 0, 0)"
        >Продавец -&nbsp;сеть стрит-фуд кафе «Ростерхит»[1]</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >Оферта -&nbsp;предложение Продавца заключить Договор на изложенных в
        данном документе условиях</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >Договор -&nbsp;соглашение, заключенное между Продавцом и
        Покупателем</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >Сервис -&nbsp;сайт Продавца по адресу </span
      ><a :href="host" style="color: rgb(0, 0, 0)"
        ><u>{{host}}</u></a
      ><u style="color: rgb(0, 0, 0)"> </u
      ><span style="color: rgb(0, 0, 0)"
        >и программное приложение «Ростерхит»</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >Покупатель -&nbsp;лицо, авторизованное в Сервисе и приобретающее
        Товар</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >Товар -&nbsp;продукция, изготовляемая сетью стрит-фуд кафе
        «Ростерхит»</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >Заказ -&nbsp;оформленный Покупателем запрос на приобретение Товара в
        Сервисе</span
      ><br /><br /><strong style="color: rgb(0, 0, 0)"
        >1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Акцепт
        Оферты</strong
      ><br /><span style="color: rgb(0, 0, 0)"
        >1.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Лицо, желающее
        приобрести Товар, вводит номер своего телефона для регистрации или
        авторизации в Сервисе.</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >1.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;После регистрации
        или авторизации лицо выбирает в Сервисе Товар.</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >1.3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Оплачивая Товар,
        лицо:</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >1.3.1.&nbsp;&nbsp;&nbsp;&nbsp;подтверждает, что ознакомилось и
        безусловно принимает (акцептует) Оферту;</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >1.3.2.&nbsp;&nbsp;&nbsp;&nbsp;дает согласие на обработку персональных
        данных в целях исполнения Договора.</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >1.4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;С момента оплаты
        Товара, Договор считается заключенным.</span
      ><br /><br /><strong style="color: rgb(0, 0, 0)"
        >2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Товар и
        порядок размещения Заказа</strong
      ><br /><span style="color: rgb(0, 0, 0)"
        >2.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Наименование, цена,
        количество Товара, а также прочие необходимые условия определяются
        Покупателем при оформлении Заказа в Сервисе.</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >2.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Сопровождающие
        Товар фотографии являются простыми иллюстрациями к нему и могут
        отличаться от фактического внешнего вида Товара.</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >2.3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Сведения о составе
        Товара указываются Продавцом в Сервисе. Состав Товара может быть изменен
        Покупателем на следующих условиях:</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >2.3.1.&nbsp;&nbsp;&nbsp;&nbsp;Покупатель вправе убрать базовые
        ингредиенты из состава без изменения цены Товара;</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >2.3.2.&nbsp;&nbsp;&nbsp;&nbsp;Покупатель вправе добавить дополнительные
        ингредиенты в состав, при этом цена Товара изменится.</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >2.4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;После размещения
        Заказа Покупателю предоставляется информация о деталях Заказа,
        включающая дату и примерное время выдачи Товара. Время выдачи Товара
        может быть изменено Продавцом при наличии объективных причин.</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >2.5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Отмена или
        изменение Покупателем размещенного Заказа не допускается.</span
      ><br /><br /><strong style="color: rgb(0, 0, 0)"
        >3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Уведомления
        Покупателя</strong
      ><br /><span style="color: rgb(0, 0, 0)"
        >3.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Продавец сообщает
        Покупателю информацию о статусе размещенного Заказа путем направления
        push-уведомлений следующего содержания:</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >3.1.1.&nbsp;&nbsp;&nbsp;&nbsp;Ваш заказ начали готовить;</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >3.1.2.&nbsp;&nbsp;&nbsp;&nbsp;Ваш заказ готов;</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >3.1.3.&nbsp;&nbsp;&nbsp;&nbsp;Ваш заказ будет утилизирован. Вы не
        забрали свой заказ в течение 30 минут;</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >3.1.4.&nbsp;&nbsp;&nbsp;&nbsp;Не забудьте оставить отзыв о
        заказе.</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >3.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Покупатель может
        отключить push-уведомления в настройках своего телефона. В таком случае
        Покупатель обязан самостоятельно отслеживать статус размещенного
        Заказа.</span
      ><br /><br /><strong style="color: rgb(0, 0, 0)"
        >4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Оплата и
        передача Товара</strong
      ><br /><span style="color: rgb(0, 0, 0)"
        >4.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Цена Товара
        определяется Продавцом в Сервисе. </span
      ><br /><span style="color: rgb(0, 0, 0)"
        >4.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Оплата Товара
        производится Покупателем банковской картой. Продавец может установить
        бонусную систему оплаты Товара.</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >4.3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Обязанность по
        оплате считается выполненной с момента зачисления полной стоимости
        Товара на расчетный счет Продавца. Кассовый чек выдается Покупателю
        нарочно или направляется на электронную почту Покупателя, если она
        внесена Покупателем в Сервис. </span
      ><br /><span style="color: rgb(0, 0, 0)"
        >4.4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Право собственности
        на Товар, риск случайной гибели или повреждения Товара переходит к
        Покупателю с момента фактической передачи Товара Покупателю. Передача
        Товара осуществляется по адресу Продавца, выбранному в Сервисе. </span
      ><br /><span style="color: rgb(0, 0, 0)"
        >4.5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Приготовленный
        Товар хранится не более 30 минут с указанного в Сервисе времени выдачи.
        Невостребованный Товар утилизируется. Стоимость утилизированного Товара
        не возвращается.</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >4.6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;При размещении
        Заказа в последние 15 минут работы Продавца Товар должен быть
        востребован по назначенному времени в соответствии с графиком работы
        Продавца. График работы Продавца указан в Сервисе. Невостребованный
        Товар утилизируется. Стоимость утилизированного Товара не
        возвращается.</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >4.7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;При невозможности
        передать Покупателю приобретенный Товар, Продавец предлагает Покупателю
        выбор другого Товара или возврат денежных средств.</span
      ><br /><br /><strong style="color: rgb(0, 0, 0)"
        >5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Возврат
        Товара и денежных средств</strong
      ><br /><span style="color: rgb(0, 0, 0)"
        >5.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Покупатель не может
        возвратить или обменять Товар надлежащего качества. Денежные средства за
        Товар надлежащего качества не возвращаются. </span
      ><br /><span style="color: rgb(0, 0, 0)"
        >5.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Покупатель,
        приобретший Товар ненадлежащего качества, вправе потребовать:</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >5.2.1.&nbsp;&nbsp;&nbsp;&nbsp;замены Товаром надлежащего
        качества;</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >5.2.2.&nbsp;&nbsp;&nbsp;&nbsp;соразмерного уменьшения покупной
        цены;</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >5.2.3.&nbsp;&nbsp;&nbsp;&nbsp;возврата уплаченной за Товар суммы. </span
      ><br /><span style="color: rgb(0, 0, 0)"
        >5.3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Покупатель должен
        направить требование о возврате денежных средств на электронную почту
        Продавца </span
      ><u style="color: rgb(0, 0, 0)">feedback@rosterhit.ru</u><br /><span
        style="color: rgb(0, 0, 0)"
        >5.4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;В требовании должны
        быть указаны банковские реквизиты Покупателя для получения возврата
        денежных средств.</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >5.5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Продавец возвращает
        денежные средства в течение 10 рабочих дней с момента получения
        требования. Срок зачисления денежных средств на банковский счет будет
        зависеть от условий банка, в котором открыт счет.</span
      ><br /><br /><strong style="color: rgb(0, 0, 0)"
        >6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Коммуникации</strong
      ><br /><span style="color: rgb(0, 0, 0)"
        >6.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Все взаимодействие
        между Продавцом и Покупателем происходит через электронную почту.</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >6.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Электронная почта
        Продавца указана в Договоре.</span
      ><br /><br /><strong style="color: rgb(0, 0, 0)"
        >7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ответственность</strong
      ><br /><span style="color: rgb(0, 0, 0)"
        >7.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ответственность
        Продавца и Покупателя определяется в соответствии с законодательством
        Российской Федерации.</span
      ><br /><br /><strong style="color: rgb(0, 0, 0)"
        >8.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Урегулирование
        споров</strong
      ><br /><span style="color: rgb(0, 0, 0)"
        >8.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;В случае
        возникновения спора стороны разрешают его в претензионном порядке. Срок
        ответа на претензию — 30 рабочих дней с момента ее получения.</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >8.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Претензия должна
        быть направлена по электронной почте. Если претензия направлена в
        нерабочее время, она считается полученной на следующий рабочий
        день.</span
      ><br /><br /><strong style="color: rgb(0, 0, 0)">Реквизиты</strong
      ><br />
      <div v-html="paymentInfo.payment_info.replaceAll('\n','<br>')"></div>
      <span style="color: rgb(0, 0, 0)"> e-mail </span
      ><a href="mailto:feedback@rosterhit.ru" style="color: rgb(0, 0, 0)"
        ><u>feedback@rosterhit.ru</u></a
      ><br /><br /><span style="color: rgb(0, 0, 0)">
        [1] Юридическое лицо или индивидуальный предприниматель, входящее (-ий)
        во франчайзинговую сеть, объединенную под товарным знаком «РОСТЕРХИТ
        СТРИТ ФУД КАФЕ», непосредственно осуществляющее (-ий) реализацию товаров
        и услуг для Пользователя</span
      ><br /><br />
    </div>
  </div>
</template>

<script>
export default {
  name: "UserAgreement",
  data: () => ({
    host: null,
    paymentInfo: null,
    currentHref: null,
  }),
  created() {
    this.currentHref = window.location.href;
    this.host = "127.0.0.1:8000".includes(window.location.host)
      ? "https://app.rosterhit.ru"
      : `${window.location.host}`;
    this.$company
      .sendGet("get_payment_info", {
        domain: this.host,
      })
      .then((res) => {
        this.paymentInfo = res;
      })
      .catch(() => {
        this.$company
          .sendGet("get_payment_info", {
            domain: "app.rosterhit.ru",
          })
          .then((res) => {
            this.paymentInfo = res;
          });
      });
  },
};
</script>

<style scoped></style>
