<template>
  <v-col cols="6" md="3">
    <v-card class="rounded-lg pa-3 elevation-1" height="100%">
      <div v-if="$store.state.showAllInfo" class="mb-1 caption"></div>
      <div>
        <div
          @click="disabled ? void 0 : toggle()"
          class="cursor-pointer fill-height d-flex flex-column justify-space-between"
        >
          <v-row no-gutters align="start" justify="space-between" style="position: relative">
            <div style="position:absolute;">
            <v-icon :size="20" :color="isExcluded ? 'red' : 'primary'">
              {{
                ingredientIconName
              }}
            </v-icon>
            </div>
            <v-img
              v-if="withImage && image"
              height="40"
              width="40"
              :src="image"
              aspect-ratio="1"
              contain
            />
            <div v-else style="height: 40px"></div>
          </v-row>
          <div class="d-flex align-center justify-center mt-2">
            <div class="text-body-2 noselect text-center">
              {{ ingredient.name }}
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </v-col>
</template>

<script>
export default {
  name: "IngredientTile",

  props: {
    productIngredient: Object,
    productIngredientGroup: Object,
    selectedIngredientIds: Array,
    excludedIngredientIds: Array,
    withImage: Boolean,
    disabled: Boolean
  },

  data: () => ({
    isChanging: false,
  }),

  computed: {
    ingredient() {
      return this.productIngredient.ingredient;
    },
    image() {
      return this.ingredient.image || "/media/not-found.png";
    },

    isSelected() {
      return this.selectedIngredientIds.includes(
        this.productIngredient.ingredient.id
      );
    },
    isExcluded() {
      return this.excludedIngredientIds.includes(
          this.productIngredient.ingredient.id
      );
    },
    ingredientIconName() {
      if (this.isSelected) return 'mdi-check-circle-outline'
      else if (this.isExcluded) return 'mdi-close-circle-outline'
      return 'mdi-checkbox-blank-circle-outline'
    }
  },

  methods: {
    toggle() {
      const ingredientId =this.productIngredient.ingredient.id
      if (this.isSelected) {
        const index = this.selectedIngredientIds.findIndex(
          (v) => v === ingredientId
        );
        if (index > -1) this.selectedIngredientIds.splice(index, 1);
        this.excludedIngredientIds.push(this.productIngredient.ingredient.id)
      } else if (this.isExcluded) {
        const index = this.excludedIngredientIds.findIndex(
            (v) => v === ingredientId
        );
        if (index > -1) this.excludedIngredientIds.splice(index, 1);
      } else {
        this.selectedIngredientIds.push(ingredientId)
      }
    },

  },
  watch: {},
};
</script>
