<template>
  <template-list
    title="Смены"
    :model="$user"
    :show-create-button="false"
    hide-items
    :extend-filter="{ user_type: 4, add_shift_data: true }"
  >

    <template v-slot:items>
      <v-data-table
        :headers="headers"
        :items="$user.list"
        :items-per-page="-1"
        disable-sort
        hide-default-footer
        @click:row="(_, evt) => openShiftAnalytics(evt.item)"
      >
        <template v-slot:item.active_shift="{ item }">
          <v-chip
            small
            :color="
              item.shift_data && item.shift_data.has_active_shift
                ? 'primary'
                : ''
            "
          >
            {{
              item.shift_data && item.shift_data.has_active_shift ? "Да" : "Нет"
            }}
          </v-chip>
        </template>
        <template v-slot:item.shift_start="{ item }">
          {{
            item.shift_data && item.shift_data.has_active_shift
              ? parseDate(item.shift_data.shifts[0].start_date)
              : "-"
          }}
        </template>
        <!--                <template v-slot:item.shift_start="{ item }">-->
        <!--                    {{-->
        <!--                        item.shift_data && item.shift_data.has_active_shift ? parseDate(item.shift_data.shifts[0].start_date) : '-'-->
        <!--                    }}-->
        <!--                </template>-->
        <template v-slot:item.total_shifts_time="{ item }">
          {{
            item.shift_data && item.shift_data.has_active_shift
              ? secondsToTime(item.shift_data.total_shifts_time)
              : "-"
          }}
        </template>
        <template v-slot:item.delivered_orders="{ item }">
          {{
            item.shift_data && item.shift_data.has_active_shift
              ? item.shift_data.delivered_orders
              : "-"
          }}
        </template>
        <template v-slot:item.total_reward="{ item }">
          {{
            item.shift_data && item.shift_data.has_active_shift
              ? beautifyNumber(item.shift_data.total_reward)
              : "-"
          }}
        </template>
      </v-data-table>
      <ShiftAnalyticsModal
        :model-value="dialog"
        @update:modelValue="dialog = $event"
        :data="result"
        :shift="shift"
      />
    </template>
  </template-list>
</template>

<script>
import { beautifyNumber, secondsToTime } from "@/services/numberFunctions";
import ShiftAnalyticsModal from "@/admin/views/deliveryAdmin/ShiftAnalyticsModal.vue";

export default {
  name: "CourierList",
  components: { ShiftAnalyticsModal },
  data: () => ({
    dialog: false,
    shift: null,
    result: null,
    headers: [
      { text: "Имя", value: "first_name" },
      {
        text: "На смене",
        value: "active_shift",
      },
      {
        text: "Дата начала смены",
        value: "shift_start",
      },
      {
        text: "Длительность смены",
        value: "total_shifts_time",
      },
      {
        text: "Кол-во доставленных заказов",
        value: "delivered_orders",
      },
      {
        text: "Доход курьера",
        value: "total_reward",
      },
    ],
  }),
  methods: {
    beautifyNumber,
    secondsToTime,
    parseDate(date) {
      return window.moment.localDateTime(date);
    },
    async openShiftAnalytics(data) {
      if (!data.shift_data || !data.shift_data.has_active_shift) return;
      const shift = await this.$shift.loadItem(data.shift_data.shifts[0].id);
      this.$analytics
        .sendPostSingle("courier_report_by_shift", undefined, {
          shift: shift.id,
        })
        .then((res) => {
          this.shift = shift;
          this.result = res.result;
          this.dialog = true;
        })
        .catch(() => void 0)
        .finally(() => {});
    },
  },
  created() {
  },
};
</script>

<style scoped></style>
