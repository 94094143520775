<template>
  <div class="container mt-6">
    <div class="t-text t-text_md" field="text">
      <strong style="color: rgb(0, 0, 0)"
        >Пользовательское соглашение по использованию сервиса сети стрит-фуд
        кафе «Ростерхит»</strong
      ><br /><strong style="color: rgb(0, 0, 0)"
        >Редакция №1 от 01.07.2023</strong
      ><br /><span style="color: rgb(0, 0, 0)"
        >Это Пользовательское соглашение по использованию мобильного приложения
        и его веб-версии </span
      ><a :href="host" style="color: rgb(0, 0, 0)"
        ><u>{{host}}</u></a
      ><span style="color: rgb(0, 0, 0)">
        сети стрит-фуд кафе «Ростерхит» (далее – Соглашение).</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >В Соглашении мы обращаемся к вам как пользователям нашего мобильного
        приложения и его веб-версии </span
      ><a :href="host" style="color: rgb(0, 0, 0)"
        ><u>{{host}}</u></a
      ><span style="color: rgb(0, 0, 0)"> (далее – Сервис).</span><br /><span
        style="color: rgb(0, 0, 0)"
        >Внимательно прочитайте Соглашение, прежде чем начать пользоваться
        сервисом. Вы обязаны соблюдать условия настоящего Соглашения, заходя на
        сервис и используя его функции. В случае, если Вы не согласны с
        условиями Соглашения, Вы не можете пользоваться сервисом. Начало
        использования сервиса означает надлежащее заключение настоящего
        Соглашения и Ваше полное согласие со всеми его условиями.</span
      ><br /><span style="color: rgb(0, 0, 0)">Термины и определения:</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >Компания – сеть стрит-фуд кафе «Ростерхит» [1].</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >Пользователь – лицо, присоединившееся к соглашению для использования
        сервиса. </span
      ><br /><span style="color: rgb(0, 0, 0)"
        >Мобильное приложение – это программное обеспечение, разработанное
        компанией, посредством которого Пользователь может получить доступ к
        предложениям о продаже товаров и оказании услуг Компанией.</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >Веб-версия мобильного приложения – это программное обеспечение,
        запускаемое в веб-браузере, разработанное компанией, посредством
        которого Пользователь может получить доступ к предложениям о продаже
        товаров и оказании услуг Компанией. </span
      ><br /><span style="color: rgb(0, 0, 0)"
        >Сервис – мобильное приложение и/или веб-версия мобильного
        приложения.</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >Исполнитель – юридическое лицо или индивидуальный предприниматель,
        входящее (-ий) во франчайзинговую сеть, объединенную под товарным знаком
        «РОСТЕРХИТ СТРИТ ФУД КАФЕ», непосредственно осуществляющее (-ий)
        реализацию товаров и услуг для Пользователя.</span
      ><br /><br /><span style="color: rgb(0, 0, 0)"
        >[1] Индивидуальный предприниматель Хитров Валерий Николаевич (ИНН
        390803367851, ОГРНИП 318392600017669), адрес: г. Калининград, ул.
        Коломенская, д. 19 </span
      ><br /><br /><br /><strong style="color: rgb(0, 0, 0)"
        >Предмет Соглашения</strong
      ><br /><span style="color: rgb(0, 0, 0)"
        >Предметом Соглашения являются порядок и правила использования сервиса,
        включая элементы дизайна, текст, графические изображения, иллюстрации,
        фотографии, видео, средства индивидуализации и иные охраняемые законом
        материалы.</span
      ><br /><br /><strong style="color: rgb(0, 0, 0)">Общие положения</strong
      ><span style="color: rgb(0, 0, 0)"> </span><br /><span
        style="color: rgb(0, 0, 0)"
        >Компания предлагает Пользователю использовать свой сервис и его функции
        на условиях, изложенных в Соглашении. </span
      ><br /><span style="color: rgb(0, 0, 0)"
        >Начиная использовать сервис или его отдельные функции, либо пройдя
        процедуру регистрации, Пользователь считается принявшим условия
        Соглашения в полном объеме без всяких оговорок и исключений. В случае
        несогласия Пользователя с условиями Соглашения Пользователь не вправе
        использовать сервис и его функции.</span
      ><br /><br /><strong style="color: rgb(0, 0, 0)"
        >Условия использования сервиса</strong
      ><br /><span style="color: rgb(0, 0, 0)"
        >Пользователь обязуется использовать сервис только в законных целях и
        способами, не нарушающими законодательство Российской Федерации, нормы
        международного права и права третьих лиц. </span
      ><br /><span style="color: rgb(0, 0, 0)"
        >Пользователь самостоятельно несет ответственность перед третьими лицами
        за свои действия, связанные с использованием сервиса и его функций, в
        том числе, если такие действия приведут к нарушению прав и законных
        интересов третьих лиц, а также за соблюдение законодательства Российской
        Федерации при использовании сервиса и его функций.</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >Компания не несет ответственность за вред, причиненный технике
        Пользователя в случае, если это произошло в результате перехода по
        гипертекстуальным ссылкам, размещенным на сервисе.</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >Компания вправе произвести приостановление оказания услуг пользования
        сервисом либо отказать Пользователю в возможности использования
        отдельных функций сервиса в случае нарушения последним положений
        Соглашения.</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >Для того чтобы воспользоваться всеми функциями сервисов Пользователю
        необходимо предоставить достоверную и полную информацию о себе по всем
        разделам, предлагаемым в форме регистрации, и отметить чекбокс «Согласен
        на обработку персональных данных» в соответствии с условиями Политики
        обработки персональных данных, размещенной и доступной по адресу </span
      ><a :href="host" style="color: rgb(0, 0, 0)"
        ><u>{{host}}</u></a
      ><u style="color: rgb(0, 0, 0)">.</u><br /><br /><span
        style="color: rgb(0, 0, 0)"
        >В рамках сервиса Пользователю доступны разнообразные услуги и товары, в
        том числе, но не ограничиваясь, посредством формирования и направления
        заявок Пользователей. Если в сервисе не определено иное, они
        предоставляются в следующем порядке:</span
      ><br />
      <ul>
        <li style="color: rgb(0, 0, 0)">
          <span style="color: rgb(0, 0, 0)"
            >незарегистрированный Пользователь должен зарегистрироваться, а
            зарегистрированный Пользователь должен авторизоваться в
            сервисе;</span
          >
        </li>
        <li style="color: rgb(0, 0, 0)">
          <span style="color: rgb(0, 0, 0)"
            >пользователю необходимо выбрать в сервисе необходимые товары и
            услуги и предоставить для этого запрашиваемую информацию;</span
          >
        </li>
        <li style="color: rgb(0, 0, 0)">
          <span style="color: rgb(0, 0, 0)"
            >после выполнения Пользователем указанных действий сервис формирует
            заявку;</span
          >
        </li>
        <li style="color: rgb(0, 0, 0)">
          <span style="color: rgb(0, 0, 0)"
            >пользователь проверяет правильность информации, содержащейся в
            сформированной заявке, и даёт распоряжение на её отправку.</span
          >
        </li>
      </ul>
      <strong style="color: rgb(0, 0, 0)">Пользователь вправе:</strong><br />
      <ul>
        <li style="color: rgb(0, 0, 0)">
          <span style="color: rgb(0, 0, 0)"
            >пользоваться услугами, доступными в сервисе;</span
          >
        </li>
        <li style="color: rgb(0, 0, 0)">
          <span style="color: rgb(0, 0, 0)"
            >в любой момент удалить свой аккаунт с сервиса, воспользовавшись
            соответствующей функцией в своём профиле.</span
          >
        </li>
      </ul>
      <p style="color: rgb(0, 0, 0)">
        <strong style="color: rgb(0, 0, 0)">Пользователь обязан:</strong>
      </p>
      <ul>
        <li style="color: rgb(0, 0, 0)">
          <span style="color: rgb(0, 0, 0)"
            >соблюдать условия настоящего Соглашения;</span
          >
        </li>
        <li style="color: rgb(0, 0, 0)">
          <span style="color: rgb(0, 0, 0)"
            >использовать сервис в соответствии с условиями Соглашения.</span
          >
        </li>
      </ul>
      <span style="color: rgb(0, 0, 0)"
        >Пользователь несет ответственность перед Компанией за соблюдение
        условий Соглашения.</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >Пользователь несет полную личную ответственность за содержание и
        условия заявок, сформированных им с помощью сервиса.</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >Компания не несет ответственности за задержки, сбои, неверную или
        несвоевременную доставку запросов Пользователей и другие технические
        проблемы, лежащие вне пределов зоны ответственности Компании, вызванные,
        в том числе (включая, но не ограничиваясь) сбоями в телекоммуникационных
        сетях, действиями вредоносных программ, нарушениями функционирования
        операторов, предоставляющих услуги доступа в Интернет.</span
      ><br /><br /><strong style="color: rgb(0, 0, 0)">Регистрация</strong
      ><span style="color: rgb(0, 0, 0)"> </span><br /><span
        style="color: rgb(0, 0, 0)"
        >Регистрация Пользователя в сервисе осуществляется путем заполнения
        соответствующей регистрационной формы.</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >Сбор персональных данных, а также иной информации, которая не относится
        к персональным данным, осуществляется в сервисе при регистрации и/или
        авторизации путем заполнения Пользователем регистрационной формы.</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >Для исполнения соглашения Компания обрабатывает персональные данные
        Пользователя в соответствии с условиями Политики обработки персональных
        данных, размещенной и доступной по адресу </span
      ><a :href="host" style="color: rgb(0, 0, 0)"
        ><u>{{host}}</u></a
      ><u style="color: rgb(0, 0, 0)">.</u><br /><span
        style="color: rgb(0, 0, 0)"
        >Пользователь обязуется предоставить в регистрационной форме
        достоверную, полную и точную информацию о себе и поддерживать эту
        информацию в актуальном состоянии.</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >В случае предоставления Пользователем недостоверной информации Компания
        имеет право приостановить либо отменить регистрацию и/или отказать
        Пользователю в использовании сервиса.</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >С момента регистрации Пользователь приобретает статус
        зарегистрированного Пользователя и имеет возможность пользоваться полным
        функционалом сервиса. Незарегистрированный Пользователь имеет
        возможность просматривать информацию, размещённую в сервисе в свободном
        доступе.</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >Пользователь соглашается с тем, что действия, совершенные в сервисе
        после авторизации, признаются действиями Пользователя и все
        распоряжения, которые отдаются после авторизации, считаются отданными
        Пользователем лично.</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >В случае передачи логина/пароля третьему лицу всю ответственность несет
        непосредственно сам Пользователь. За ущерб, причиненный в результате
        несанкционированного доступа к персональным данным Пользователя,
        Компания ответственности не несет.</span
      ><br /><br /><strong style="color: rgb(0, 0, 0)"
        >Полномочия Компании</strong
      ><br /><span style="color: rgb(0, 0, 0)"
        >Компания оставляет за собой право без какого-либо специального
        уведомления вносить изменения в Соглашение, в связи с чем Пользователь
        обязуется самостоятельно контролировать наличие изменений в Соглашении.
        Новая редакция Соглашения вступает в силу с момента ее размещения, если
        иное не предусмотрено новой редакцией Соглашения.</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >Компания оставляет за собой право изменять сервис, в том числе изменять
        или добавлять в его структуру разделы, менять дизайн и совершать прочие
        действия, направленные на повышение функциональности сервиса.</span
      ><br /><br /><strong style="color: rgb(0, 0, 0)"
        >Исключительные права на содержание сервисов и контент</strong
      ><br /><span style="color: rgb(0, 0, 0)"
        >Содержание сервиса, в том числе элементы дизайна, текст, графические
        изображения, иллюстрации, фотографии, видео, средства индивидуализации и
        иные охраняемые законом материалы (далее – содержание сервиса) являются
        объектами исключительных прав Компании.</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >Никакие элементы содержания сервиса не могут быть использованы иным
        образом без предварительного разрешения Компании, являющейся
        правообладателем указанных объектов исключительного права. Под
        использованием подразумеваются, в том числе: воспроизведение,
        копирование, переработка, распространение на любой основе, и т.д.
        Исключение составляют случаи, прямо предусмотренные законодательством
        Российской Федерации или условиями использования сервиса.</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >Использование Пользователем элементов содержания сервиса для личного
        некоммерческого использования, допускается при условии сохранения всех
        знаков охраны авторского права, смежных прав, товарных знаков, других
        уведомлений об авторстве, сохранения имени (или псевдонима)
        автора/наименования правообладателя в неизменном виде, сохранении
        соответствующего объекта в неизменном виде. Исключение составляют
        случаи, прямо предусмотренные законодательством Российской Федерации или
        пользовательскими соглашениями сервиса.</span
      ><br /><br /><strong style="color: rgb(0, 0, 0)">Прочие условия</strong
      ><br /><span style="color: rgb(0, 0, 0)"
        >Все возможные споры, вытекающие из Соглашения или связанные с ним,
        подлежат разрешению в соответствии с действующим законодательством
        Российской Федерации по месту нахождения Компании.</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >Если Пользователь считает, что содержимое сервиса нарушает его личные
        неимущественные и/или исключительные права, соответствующее уведомление
        должно быть отправлено на адрес электронной почты </span
      ><a href="mailto:support@rosterhit.ru" style="color: rgb(0, 0, 0)"
        ><u>support@rosterhit.ru</u></a
      ><span style="color: rgb(0, 0, 0)">.</span><br /><br /><span
        style="color: rgb(0, 0, 0)"
        >Уведомление о нарушении личных неимущественных и/или исключительных
        прав должно содержать:</span
      ><br />
      <ul>
        <li style="color: rgb(0, 0, 0)">
          <span style="color: rgb(0, 0, 0)"
            >указание на материалы содержимого сервиса, предположительно
            нарушающие права Пользователя;</span
          >
        </li>
        <li style="color: rgb(0, 0, 0)">
          <span style="color: rgb(0, 0, 0)">
            сведения об объекте, личном неимущественном и/или исключительном
            праве, которое предположительно нарушается сервисом с приложением
            документов, подтверждающих права Пользователя на такой объект.</span
          >
        </li>
        <li style="color: rgb(0, 0, 0)">
          <span style="color: rgb(0, 0, 0)"
            >контактную информацию о Пользователе, включая:</span
          >
        </li>
      </ul>
      <span style="color: rgb(0, 0, 0)"
        >- для физического лица - фамилию, имя, отчество, паспортные данные
        (серия и номер, кем выдан, дата выдачи), адрес, номер телефона, адрес
        электронной почты;</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >- для юридического лица - наименование, место нахождения, адрес, номер
        телефона, адрес электронной почты.</span
      ><br />
      <ul>
        <li style="color: rgb(0, 0, 0)">
          <span style="color: rgb(0, 0, 0)">подпись Пользователя.</span>
        </li>
      </ul>
      <span style="color: rgb(0, 0, 0)"
        >Уведомления, не соответствующие указанным требованиям, не
        рассматриваются Компанией.</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >Действующая редакция Соглашения размещена в мобильном приложении
        «Ростерхит» и доступна в сети Интернет по адресу </span
      ><a :href="currentHref" style="color: rgb(0, 0, 0)"
        ><u>{{currentHref}}</u></a
      ><span style="color: rgb(0, 0, 0)">.</span><br /><span
        style="color: rgb(0, 0, 0)"
        >Реквизиты сети стрит-фуд кафе «Ростерхит»:</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >Индивидуальный предприниматель </span
      ><br /><span style="color: rgb(0, 0, 0)">Хитров Валерий Николаевич</span
      ><br /><span style="color: rgb(0, 0, 0)">ИНН 390803367851</span
      ><br /><span style="color: rgb(0, 0, 0)">ОГРНИП 318392600017669</span
      ><br /><span style="color: rgb(0, 0, 0)"
        >Адрес: г. Калининград, ул. Коломенская, д. 19</span
      ><br /><span style="color: rgb(0, 0, 0)">E-mail: </span
      ><a href="mailto:support@rosterhit.ru" style="color: rgb(0, 0, 0)"
        ><u>support@rosterhit.ru</u></a
      ><br /><br /><br />
    </div>
  </div>
</template>

<script>
export default {
  name: "UserAgreement",
  data: () => ({
    host: null,
    paymentInfo: null,
    currentHref: null
  }),
  created() {
    this.currentHref = window.location.href
    this.host = "127.0.0.1:8000".includes(window.location.host)
        ? "app.rosterhit.ru"
        : window.location.host;
    this.$company
        .sendGet("get_payment_info", {
          domain: this.host,
        })
        .then((res) => {
          this.paymentInfo = res;
        })
        .catch(() => {
          this.$company
              .sendGet("get_payment_info", {
                domain: "app.rosterhit.ru",
              })
              .then((res) => {
                this.paymentInfo = res;
              });
        });
  }
};
</script>

<style scoped></style>
