<template>
  <div style="width: auto">
    <template-list
      :auto-load-list="false"
      :model="$answerTemplate"
      :show-create-button="false"
    >
      <template
        v-if="$auth.hasRight('answer_template__edit_information')"
        v-slot:actions
      >
        <v-btn
          class="rounded-lg"
          color="primary"
          depressed
          height="40"
          @click="openModal()"
        >
          <div class="text-none body-2 px-2">Создать</div>
        </v-btn>
      </template>
      <template v-slot="{ object, index }">
        <v-col
          class="pa-0 link"
          @click="
            $auth.hasRight('answer_template__edit_information')
              ? openModal(object)
              : void 0
          "
        >
          <v-row no-gutters>
            <div class="ml-2 font-weight-medium text-truncate">
              {{ object.text }}
            </div>
          </v-row>
        </v-col>
      </template>
    </template-list>
    <v-dialog v-model="dialog" max-width="450">
      <v-card
        v-if="dialogItem"
        class="pa-5 rounded-lg"
        style="overflow-x: auto"
      >
        <div class="font-weight-bold mb-5">
          {{ dialogItem.id ? "Обновление шаблонного ответа" : "Создание шаблонного ответа" }}
        </div>
        <v-textarea v-model="dialogItem.text" label="Текст" />
        <v-row class="px-2 mt-0">
          <v-btn
            :disabled="!dialogItem.text"
            :loading="loading"
            color="primary"
            @click="saveDialogItem()"
            >Сохранить
          </v-btn>
          <v-spacer />
          <v-btn
            v-if="dialogItem.id"
            :loading="loading"
            color="error"
            outlined
            @click="deleteDialogItem()"
            >Удалить
          </v-btn>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";

export default {
  name: "Reasons",
  components: {},
  data: () => ({
    dialog: false,
    dialogItem: null,
    loading: false,
  }),
  methods: {
    loadList() {
      this.$answerTemplate.loadList();
    },
    openModal(_item) {
      let item = cloneDeep(_item);
      if (!item) item = cloneDeep(this.$answerTemplate.default);
      this.dialogItem = item;
      this.dialog = true;
    },
    saveDialogItem() {
      const create = !this.dialogItem.id;
      const item = this.dialogItem;
      this.loading = true;
      if (create) {
        this.$answerTemplate
          .create(item)
          .then((v) => {
              this.$answerTemplate.list.push(v);
            this.dialog = false;
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.$answerTemplate
          .update(item)
          .then((v) => {
            this.dialog = false;
            const index = this.$answerTemplate.list.findIndex((el) => el.id === v.id);
            if (index > -1) this.$answerTemplate.list[index] = v;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    deleteDialogItem() {
      this.loading = true;
      this.$answerTemplate
        .delete(this.dialogItem.id)
        .then(() => {
          this.dialog = false;
          const index = this.$answerTemplate.list.findIndex(
            (v) => v.id === this.dialogItem.id
          );
          if (index > -1) this.$answerTemplate.list.splice(index, 1);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.loadList();
  },
  watch: {
    dialog() {
      if (!this.dialog) this.dialogItem = null;
    },
  },
};
</script>

<style scoped></style>