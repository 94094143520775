<template>
    <v-row no-gutters align="center" justify="center" @click="focus">
        <div
            class="rounded-lg grey lighten-3 pa-1 mx-1"
            v-for="i in Array(length).keys()" :key="i"
        >
            <input
                :disabled="disabled"
                v-model="values[i]" @input="onInput" @keydown="onKeyDown"
                :id="`pass-char-${i}`" class="input--textfield"
                :type="$vuetify.breakpoint.smAndDown ? 'number' : 'text'"
                maxlength="1" :style="{height: size, width: size}"
            >
        </div>
    </v-row>
</template>

<script>
export default {
    name: "PasswordInput",

    props: {
        length: Number,
        autofocus: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            default: '30px',
        },
      disabled: Boolean
    },

    data: () => ({
        values: [],
    }),

    computed: {
        password() {
            return this.values.join('')
        },
    },

    methods: {
        onInput(e) {
            const inputType = e.inputType
            const value = e.data

            if (inputType === 'insertText') {
                if (!this.charIsAvailable(value)) {
                    this.values.pop()
                    return
                }
                const length = this.values.length
                if (length < this.length) document.getElementById(`pass-char-${length}`).focus()
                this.$emit('input', this.password)
                if (this.values.length === this.length) this.complete()
            }
        },

        onKeyDown(e) {
            const length = this.values.length
            const key = e.key

            if (key === 'Backspace') {
                if (length - 1 > -1) document.getElementById(`pass-char-${length - 1}`).focus()
                this.values.pop()
            }
        },

        focus() {
            const i = this.values.length
            document.getElementById(`pass-char-${i}`).focus()
        },

        charIsAvailable(val) {
            let availableChars = '0123456789';
            // if (!this.digitsOnly) availableChars += 'abcdefghijklmnopqrstuvwxyz!@#$%^&*()_-+='
            return availableChars.indexOf(val.toLowerCase()) > -1
        },

        complete() {
            this.$emit('complete', this.password)
        },
    },

    mounted() {
        if (this.autofocus) this.focus()
    },
}
</script>

<style lang="scss">
.input--textfield {
    border: none;
    color: transparent;
    display: inline-block;
    font-size: 1.3em;
    text-shadow: 0 0 0 black;
    width: 3em;
    text-align: center;

    &:focus {
        outline: none;
    }
}
</style>