var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"px-2 ma-0"},[_vm._l((_vm.orders),function(order,index){return _c('v-col',{key:order.id,staticClass:"pa-2",staticStyle:{"cursor":"pointer"},attrs:{"cols":"4"},on:{"click":function($event){return _vm.$emit('selectOrder', order)}}},[_c('v-col',{staticClass:"pa-2",class:{ active: _vm.selectedOrders.map((v) => v.id).includes(order.id) },staticStyle:{"border":"1px solid #dedede","border-radius":"8px","height":"100%"}},[_c('v-row',{staticClass:"ma-0",attrs:{"justify":"space-between","align":"center"}},[_c('div',{staticClass:"font-weight-bold daily-number"},[_vm._v(" "+_vm._s(order.daily_number)+" ")]),_c('div',{staticClass:"ma-0",staticStyle:{"display":"flex"}},[_c('div',{staticClass:"body-2 mr-2"},[_vm._v("до "+_vm._s(_vm.deliveryBefore(order)))]),_c('v-icon',{attrs:{"size":"20px"},on:{"click":function($event){$event.stopPropagation();return _vm.openOrder(order)}}},[_vm._v("mdi-information-outline")])],1)]),_c('div',{staticClass:"body-2 mt-1"},[_vm._v(" "+_vm._s(order.delivery_address?.address)+" ")]),_c('div',{staticClass:"body-2 mt-1"},[_vm._v(" Курьер: "),_c('span',{class:{
              'font-weight-bold': !order.courier,
            }},[_vm._v(" "+_vm._s(order.courier ? order.courier.first_name : "Курьер не установлен"))])]),_c('v-row',{staticClass:"ma-0 mt-1",attrs:{"align":"center"}},[_c('v-row',{staticClass:"ma-0",staticStyle:{"gap":"4px"}},[_c('div',{staticClass:"body-2"},[_vm._v("₽")]),_c('div',{staticClass:"body-2"},[_vm._v(_vm._s(order.sum))])]),(order.user_status === _vm.$order.userStatusEnum.PACKAGED)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(order.packaged_at)?_c('div',_vm._g({staticClass:"order-chip mr-2",class:{
                      [order.packagedWaitingTime > 5
                        ? 'order-chip-red'
                        : 'order-chip-gray']: true,
                    },staticStyle:{"display":"flex","align-items":"center","gap":"5px"}},on),[_c('v-icon',{staticStyle:{"transform":"rotate(90deg)"},attrs:{"size":"15px"}},[_vm._v("mdi-waves")]),_c('div',[_vm._v(_vm._s(order.packagedWaitingTime)+" мин")])],1):_vm._e()]}}],null,true)},[_vm._v(" Остывает на тепловой полке ")]):_vm._e(),_c('div',{staticClass:"pa-1",staticStyle:{"border-radius":"6px","line-height":"100%"},style:({ ..._vm.$order.userStatusColors[order.user_status] })},[_vm._v(" "+_vm._s(_vm.$order.getUserStatusName(order))+" ")])],1)],1)],1)}),_c('OrderViewDialog',{attrs:{"value":_vm.orderModal},on:{"input":function($event){_vm.orderModal = $event}}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }