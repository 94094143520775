const m = require('air-vue-model')()
m.url = 'settings'
m.name = 'settings'
m.text = {
    listTitle: 'Настройки'
}

m.routes = [
    {
        name: 'item',
        component: require('@/admin/views/settings/SettingsPage.vue'),
        redirect: {
            name: 'settingsBase'
        },
        children: [
            {name: 'base', path: 'base', component: require('@/admin/views/settings/SettingsBase.vue')},
            {name: 'documents', path: 'documents', component: require('@/admin/views/settings/SettingsDocuments.vue')},
            {
                name: 'loyaltySystem',
                path: 'loyalty_system',
                component: require('@/admin/views/settings/SettingsLoyaltySystem.vue')
            },
            {name: 'delivery', path: 'delivery', component: require('@/admin/views/settings/SettingsDelivery.vue')},
            {name: 'terminal', path: 'terminal', component: require('@/admin/views/settings/SettingsTerminal.vue')},
            {name: 'serviceApp', path: 'service_app', component: require('@/admin/views/settings/ServiceApp.vue')},
        ]
    },
]
module.exports = m