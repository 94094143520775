const m = require('air-vue-model')()
m.url = 'display_contents'
m.name = 'displayContent'

m.text = {
    listTitle: 'Контент',
    itemTitle: 'Контент',
}

m.default = {
    type: 1,
    name: null
}

m.setPagination({
    page_size: 100
})

m.types = [
    {value: 1, title: 'Изображение'},
    {value: 2, title: 'Видео'},
    {value: 3, title: 'Ссылка'},
]

m.pk = 'uuid'

m.routes = [
    {
        name: 'list',
        component: require('@/admin/views/display/contents/DisplayContents'),
    },
    {
        name: 'item',
        component: require('@/admin/views/display/contents/DisplayContent'),
        single: true
    },
]

module.exports = m