<template>
    <v-col class="pa-0">
        <apexchart height="350" ref="chart" :options="chartOptions"
                   :series="series"></apexchart>
    </v-col>
</template>

<script>
export default {
    name: 'PerformanceByOutlets',
    props: {
        data: Array,
        previous: Array
    },
    data: () => ({
        series: [],
        chartOptions: {
            labels: [],
            chart: {
                height: 350,
                type: 'bar',
            },
            dataLabels: {
                enabled: false
            },
        },
    }),
    methods: {},
    computed: {},
    watch: {
        data() {
            this.chartOptions.labels = this.data.map(val => val.number + '. ' + val.address)
            this.$refs.chart.updateOptions(this.chartOptions)
            const series = [{
                name: 'Заказы',
                data: this.data.map(val => val.count)
            }]
            if (this.previous?.length) {
                series.push({
                    name: 'Заказы (пред)',
                    data: this.previous.map(val => val.count)
                })
            }
            this.series = series
        }
    }
}
</script>

<style>

</style>