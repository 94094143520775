<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    :width="$vuetify.breakpoint.mdAndUp ? 400 : 'auto'"
  >
    <v-card class="pa-3" height="455px" width="400">
      <div
        v-if="loading"
        class="d-flex align-center justify-center fill-height"
      >
        <v-progress-circular color="primary" indeterminate />
      </div>
      <v-col v-else>
        <v-row class="mb-3" justify="center" no-gutters>
          <v-btn
            :color="asSoonAsPossible ? 'primary' : ''"
            class="text-none mr-3 text-body-2"
            depressed
            @click="asSoonAsPossible = true"
            >Как можно скорее
          </v-btn>
          <v-btn
            :color="!asSoonAsPossible ? 'primary' : ''"
            class="text-none ml-3 text-body-2"
            depressed
            @click="asSoonAsPossible = false"
            >Выбрать время
          </v-btn>
        </v-row>
        <v-row justify="center" no-gutters>
          <vue-timepicker
            v-if="!asSoonAsPossible"
            v-model="selected"
            :hour-range="hours"
            :minute-range="minutes"
            class="elevation-1"
            format="HH:mm"
            hide-clear-button
            hide-disabled-hours
            hour-label="Часы"
            minute-label="Минуты"
          />
          <div
            class="text-center column align-center justify-center"
            v-else
            style="height: 295px"
          >
            <template v-if="firstAvailableTimeMinutes > 0">
              Приготовим примерно через
              <div class="font-weight-bold" style="color: #ecab03">
                {{ firstAvailableTimeMinutes }} мин
              </div>
            </template>
            <v-progress-circular
              v-else
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
        </v-row>
        <v-row class="pt-4" justify="center" no-gutters>
          <v-btn
            :disabled="!selected || !selected.HH || !selected.mm"
            class="elevation-1"
            color="primary"
            height="50px"
            width="100%"
            @click="selectTime"
            >Подтвердить
          </v-btn>
        </v-row>
      </v-col>
    </v-card>
  </v-dialog>
</template>

<script>
import VueTimepicker from "vue2-timepicker/src";
import "vue2-timepicker/dist/VueTimepicker.css";
import { mapState } from "vuex";
import datetime from "../../vendor/datetime";

export default {
  name: "TimePicker",

  mixins: [datetime],

  components: { VueTimepicker },
  props: {
    value: Object,
    firstAvailableTimeMinutes: Number,
  },

  data: () => ({
    dialog: false,
    loading: false,
    timeSets: [],
    selected: {
      HH: "16",
      mm: "12",
    },
    hourRange: [],
    minutesRange: [],
    currentTimes: [],
    asSoonAsPossible: false,
  }),

  computed: {
    ...mapState({
      cartItems: (state) => state.cartItems,
      outlet: (state) => state.selectedOutletId,
    }),

    lockedHours() {
      const locked = [];
      for (let h = 0; h < 24; h++) {}
      return locked;
    },
    hours() {
      return this.currentTimes.map((val) => val.hour);
    },
    minutes() {
      if (!this.selected) return [];
      const hour = parseInt(this.selected.HH);
      const el = this.currentTimes.find((val) => val.hour === hour);
      if (el) return el.times.map((val) => val.minute);
    },

  },

  methods: {
    selectTime() {
      if (!this.asSoonAsPossible) {
        const currentHour = this.currentTimes.find(
          (val) => val.hour === parseInt(this.selected.HH)
        );
        if (!currentHour) {
          this.$store.commit("setSnackbar", {
            color: "red",
            text: "Не удалось выбрать указанное время",
          });
          return;
        }
        const currentValue = currentHour.times.find(
          (val) => val.minute === parseInt(this.selected.mm)
        );
        if (!currentValue) {
          this.$store.commit("setSnackbar", {
            color: "red",
            text: "Не удалось выбрать указанное время",
          });
          return;
        }
        this.$emit("input", currentValue.time);
      } else {
        this.$emit("asSoonAsPossible");
      }
      this.dialog = false;
    },
    async loadTimeSets() {
      const currentHour = parseInt(moment().local().hour());
      this.loading = true;
      this.dialog = true;
      const cart_items = this.$cart.getCartItemIds(this.$store.state.cartItems);

      const response = await axios
        .post("/orders/compute_available_times/", {
          cart_items,
          outlet: this.outlet,
        })
        .catch(() => {
          this.$store.commit("setSnackbar", {
            color: "red",
            text: "Не удалось выбрать время",
          });
        });
      if (!response) {
        this.dialog = false;
        return;
      }

      const times = [];
      response.data.available_times.forEach((e) => {
        const pt = this.parseDateTime(e.pt).local();
        const hour = pt.hour();
        const minute = pt.minute();
        const val = times.find((val) => val.hour === pt.hours());
        if (hour >= currentHour) {
          if (val) {
            val.times.push({ time: e, minute: minute });
          } else {
            times.push({
              hour: hour,
              times: [{ time: e, minute: minute }],
            });
          }
        }
      });
      this.currentTimes = times;
      this.loading = false;
    },
  },
  watch: {
    dialog(val) {
      if (val && this.value) {
        const currentValue = this.parseDateTime(this.value.pt).local();
        const hour = currentValue.hour();
        const minute = currentValue.minute();
        this.selected = {
          HH: (hour < 10 ? "0" : "") + hour,
          mm: (minute < 10 ? "0" : "") + minute,
        };
      }
    },
    asSoonAsPossible(val) {
      if (val) {
        this.$emit("updateNearTime");
      }
    },
  },

  created() {
    this.$eventBus.$off("showTimePicker");
    this.$eventBus.$on("showTimePicker", this.loadTimeSets);
  },
};
</script>

<style>
.dropdown {
  display: block !important;
  height: 250px !important;
  width: 100% !important;
  margin-top: 10px !important;
  position: inherit !important;
  -webkit-box-shadow: 0px 10px 23px -7px rgba(34, 60, 80, 0.1) !important;
  -moz-box-shadow: 0px 10px 23px -7px rgba(34, 60, 80, 0.1) !important;
  box-shadow: 0px 10px 23px -7px rgba(34, 60, 80, 0.1) !important;
  border-radius: 10px;
}

.select-list {
  height: 250px !important;
  width: 100% !important;
}

.display-time {
  width: 100% !important;
}

.vue__time-picker {
  width: 100% !important;
  position: inherit !important;
  top: unset !important;
  left: unset !important;
}

.vue__time-picker .dropdown ul li:not([disabled]).active,
.vue__time-picker .dropdown ul li:not([disabled]).active:focus,
.vue__time-picker .dropdown ul li:not([disabled]).active:hover {
  background: orange !important;
}

.column {
  display: flex;
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}
</style>