<template>
    <v-row class="my-1 mb-3">
        <v-btn
            @click="$router.replace({ name: 'productProductIngredientItem' })"
            height="40"
            depressed
            class="rounded-lg ml-3"
            :color="$route.name === 'productProductIngredientItem' ? 'primary' : ''"
        >
            <div class="text-none body-2">Модификатор в блюде</div>
        </v-btn>
        <v-btn
            height="40"
            depressed
            class="rounded-lg ml-3"
            @click="$router.replace({ name: 'productProductIngredientOutlets' })"
            :color="$route.name === 'productProductIngredientOutlets' ? 'primary' : ''"
        >
            <div class="text-none body-2">Торговые точки</div>
        </v-btn>

        <v-btn
            height="40"
            depressed
            class="rounded-lg ml-3"
            @click="$router.replace({ name: 'productIngredientBase', params: {ingredientId: $productIngredientGroupIngredient.item.ingredient.id} })"
            :color="$route.matched.map(v => v.name).includes('productIngredient') ? 'primary' : ''"
        >
            <div class="text-none body-2">Модификатор</div>
        </v-btn>
    </v-row>
</template>

<script>
export default {
    name: "ProductIngredientGroupTabs",
};
</script>
