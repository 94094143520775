<template>
    <v-col class="pa-0">
        <v-row no-gutters class="my-3" align="center">
            <div class="text-h6 mr-3">Настройки сообщения после оценки заказа</div>
            <v-divider/>
            <v-btn
                @click="createItem()"
                depressed
                color="primary"
                class="ml-3 rounded-lg text-none body-2"
            >Создать
            </v-btn>
        </v-row>

        <v-col no-gutters class="mb-5 mt-5 pa-0" cols="6">
            <v-row class="ma-0">
                <v-row class="ma-0 font-weight-bold mb-2 px-2">
                    <v-col class="pa-0" cols="5">Наименование</v-col>
                    <v-col class="pa-0" cols="2">Активно</v-col>
                    <v-col class="pa-0" cols="3">Сортировка</v-col>
                    <v-col class="pa-0" cols="2">Картинка</v-col>
                </v-row>
                <template v-for="(el, index) in $afterOrderReview.list">
                    <v-col class="pa-0" cols="12">
                        <v-divider style="width: 100%" v-if="index"/>
                        <v-list-item
                            @click="openItem(el)"
                            style="display: flex"
                            class="px-2"
                        >
                            <v-col class="pa-0" cols="5"
                            >{{ el.title || `Без названия ${el.id}` }}
                            </v-col>
                            <v-col class="pa-0" cols="2">{{
                                    el.active ? "Да" : "Нет"
                                }}
                            </v-col>
                            <v-col class="pa-0" cols="3">{{ el.sorting }}</v-col>
                            <v-col class="pa-0" cols="2">{{ el.image ? "Да" : "Нет" }}</v-col>
                        </v-list-item>
                    </v-col>
                </template>
            </v-row>
        </v-col>
        <v-pagination
            v-if="$afterOrderReview.pagination.last_page > 1"
            class="mt-3"
            v-model="$afterOrderReview.pagination.page"
            :length="$afterOrderReview.pagination.last_page"
            @input="loadList"
        />
        <v-dialog v-model="modal" width="500px" v-if="itemToOpen">
            <v-card class="pa-5 rounded-lg">
                <div class="font-weight-bold mb-5">
                    {{ itemToOpen.id ? "Обновлние" : "Создание" }}
                </div>
                <v-text-field
                    dense
                    hide-details
                    outlined
                    label="Заголовок"
                    v-model="itemToOpen.title"
                ></v-text-field>
                <v-textarea
                    dense
                    hide-details
                    outlined
                    label="Текст"
                    v-model="itemToOpen.text"
                    class="mt-3"
                ></v-textarea>
                <v-text-field
                    dense
                    hide-details
                    outlined
                    label="Сортировка"
                    v-model="itemToOpen.sorting"
                    type="number"
                    class="mt-3"
                ></v-text-field>
                <v-checkbox hide-details v-model="itemToOpen.active" label="Активно"/>
                <v-col class="pa-0 mt-4">
                    <v-row class="ma-0" justify="space-between">
                        <div class="body-2">Кнопки</div>
                        <v-btn x-small @click="addButton">Добавить</v-btn>
                    </v-row>
                    <v-row
                        class="ma-0 mt-2"
                        style="flex-wrap: nowrap"
                        v-for="(button, index) in itemToOpen.buttons_data"
                        :key="index"
                    >
                        <v-text-field
                            dense
                            hide-details
                            outlined
                            label="Текст"
                            class="mt-3 mr-1"
                            v-model="button.title"
                        ></v-text-field>
                        <v-text-field
                            dense
                            hide-details
                            outlined
                            label="Ссылка"
                            class="mt-3 ml-1 mr-1"
                            v-model="button.value"
                        ></v-text-field>
                        <v-menu bottom offset-y :close-on-content-click="false">
                            <template v-slot:activator="{ on }">
                                <div
                                    style="
                    width: 40px;
                    height: 40px;
                    border: 1px solid #dedede;
                    cursor: pointer;
                    border-radius: 4px;
                  "
                                    :style="{ background: button.color || '#fab400' }"
                                    v-on="on"
                                    class="ml-1 mr-1 mt-3"
                                ></div>
                            </template>
                            <v-color-picker
                                dot-size="13"
                                hide-inputs
                                hide-mode-switch
                                mode="hexa"
                                swatches-max-height="200"
                                :value="button.color"
                                @input="setColor($event, button)"
                            ></v-color-picker>
                        </v-menu>

                        <v-btn class="ml-1 mt-5" icon x-small @click="deleteButton(index)"
                        >
                            <v-icon>mdi-delete</v-icon>
                        </v-btn
                        >
                    </v-row>
                </v-col>
                <ImageUploader
                    :image="imageAsUrl || itemToOpen.image"
                    aspect-ratio="0.5"
                    class="mt-5"
                    height="200"
                    title="Изображение"
                    width="460px"
                    @input="uploadImage($event, 'image')"
                ></ImageUploader>
                <v-row class="ma-0" justify="space-between">
                    <v-btn
                        @click="save()"
                        class="mt-5"
                        :disabled="
              itemToOpen.buttons_data.some((v) => !v.value || !v.title) ||
              !itemToOpen.buttons_data?.length
            "
                        color="primary"
                        :loading="loading || imageLoading"
                    >Сохранить
                    </v-btn>
                    <v-btn
                        v-if="itemToOpen.id"
                        @click="deleteItem()"
                        class="mt-5"
                        color="error"
                        depressed
                        outlined
                        :loading="loading || imageLoading"
                    >Удалить
                    </v-btn>
                </v-row>
            </v-card>
        </v-dialog>
    </v-col>
</template>
<script>
import ImageUploader from "@/admin/components/ImageUploader.vue";
import {cloneDeep} from "lodash";

export default {
    name: "AfterOrderReview",
    components: {ImageUploader},
    data: () => ({
        itemToOpen: null,
        modal: false,
        imageToSetAfterCreate: null,
        imageAsUrl: null,
        imageLoading: false,
        loading: false,
    }),
    emits: ["update"],
    mounted() {
        this.loadList();
    },
    computed: {
        item() {
            return this.$outlet.item
        }
    },
    methods: {
        setColor(e, item) {
            item.color = typeof e === "object" ? e.hexa : e;
        },
        deleteItem() {
            if (this.itemToOpen.id) {
                this.$afterOrderReview.delete(this.itemToOpen.id).then(() => {
                    this.modal = false;
                    const index = this.$afterOrderReview.list.findIndex(
                        (v) => v.id === this.itemToOpen.id
                    );
                    if (index > -1) this.$afterOrderReview.list.splice(index, 1);
                    this.itemToOpen = null;
                });
            }
        },
        addButton() {
            if (!this.itemToOpen) return;
            this.itemToOpen.buttons_data.push({
                title: "",
                color: "#FAB400",
                type: "external_link",
                value: "",
            });
        },
        deleteButton(index) {
            if (!this.itemToOpen) return;
            this.itemToOpen.buttons_data.splice(index, 1);
        },
        loadList() {
            this.$afterOrderReview.filters.outlet = this.item.id;
            this.$afterOrderReview.loadList();
        },
        openItem(el) {
            this.itemToOpen = el;
            this.modal = true;
            this.imageToSetAfterCreate = null;
            this.imageAsUrl = null;
        },
        createItem() {
            this.itemToOpen = cloneDeep(this.$afterOrderReview.default);
            this.itemToOpen.outlet = this.item.id;
            this.imageToSetAfterCreate = null;
            this.imageAsUrl = null;
            this.modal = true;
        },
        async save() {
            this.loading = true;
            for (const key of Object.keys(this.itemToOpen)) {
                if (this.itemToOpen[key] === "") this.itemToOpen[key] = null;
            }
            const action = this.itemToOpen.id ? "update" : "create";
            const func =
                action === "update"
                    ? this.$afterOrderReview.update
                    : this.$afterOrderReview.create;

            this.itemToOpen = await func({...this.itemToOpen, image: undefined});
            if (this.imageToSetAfterCreate)
                await this.uploadImage(this.imageToSetAfterCreate);
            if (action === "create")
                this.$afterOrderReview.list.push(this.itemToOpen);
            this.modal = false;
            this.loading = false;
        },
        async uploadImage(file) {
            if (!this.itemToOpen.id) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                this.imageToSetAfterCreate = file;
                reader.onload = () => {
                    this.imageAsUrl = reader.result;
                };
            } else {
                this.imageLoading = true;
                const formData = new FormData();
                formData.append("image", file);
                const res = await this.$afterOrderReview.sendPutSingle(
                    "set_image",
                    this.itemToOpen.id,
                    formData
                );
                this.itemToOpen.image = res.image;
                this.imageLoading = false;
                this.$store.commit("setSnackbar", {
                    color: "success",
                    text: "Изображение сохранено",
                });
            }
        },
    },
};
</script>

<style scoped></style>
