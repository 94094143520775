<template>
    <v-col class="pa-0">
        <template-list :model="$websocketEvent" :show-create-button="false">
            <template v-slot="{object, to, index}">
                <v-col class="pa-0 pointer" @click="openDialog(object)">
                    <v-row no-gutters align="center">
                        <v-icon size="18" class="mr-1" :color="object.active ? 'green' : 'grey'">mdi-circle</v-icon>
                        <div class="font-weight-bold">{{ object.uuid }}</div>
                        <v-spacer></v-spacer>
                        <div class="caption mr-1">Дата создания</div>
                        <div>{{ parseDate(object.created_at) || 'Отсутствует' }}</div>
                    </v-row>
                    <v-row no-gutters align="center" class="mt-0">
                        <div>{{ object.type }}</div>
                        <v-spacer></v-spacer>
                        <div class="caption mr-1">Последняя отправка</div>
                        <div>{{ parseDate(object.last_sent_at) || 'Отсутствует' }}</div>
                    </v-row>
                    <v-row no-gutters align="center" class="mt-0">
                        <div>{{ object.consumer_name }}</div>
                        <v-spacer></v-spacer>
                        <div class="caption mr-1">Дата принятия</div>
                        <div>{{ parseDate(object.accepted_at) || 'Отсутствует' }}</div>
                    </v-row>
                    <v-row no-gutters align="center" class="mt-0">
                        <div class="font-weight-bold" v-if="object.accepted_by">Принята {{ object.accepted_by.phone }}
                            | {{ object.accepted_by.first_name }}
                        </div>
                        <v-spacer></v-spacer>
                        <div class="caption mr-1">Попыток</div>
                        <div class="mr-1">{{ object.tries }}/{{ object.max_tries }}</div>
                        <div class="caption mr-1">Задержка</div>
                        <div>{{ object.delay }}с</div>
                    </v-row>
                </v-col>
            </template>
        </template-list>
        <v-dialog v-model="dialog" max-width="1000">
            <v-card class="pa-3" v-if="value">
                <pre>{{ value }}</pre>
            </v-card>
        </v-dialog>
    </v-col>
</template>

<script>
export default {
    name: "WebsocketEventList",
    data: () => ({
        value: null,
        dialog: false
    }),
    methods: {
        parseDate(date) {
            return window.moment.localDateTime(date)
        },
        openDialog(value) {
            this.value = value
            this.dialog = true
        }
    }
}
</script>

<style scoped>

</style>
