import Vue from "vue";
import VueRouter from 'vue-router'
import Home from '../views/Home'
import NotFound from "../../components/template/NotFound";
import DatabaseData from "../views/DatabaseData";
import Websocket from "@/admin/views/Websocket";
import DisplayHome from "@/admin/views/display/DisplayHome";
import Analytics from "@/admin/views/analyitcs/Analytics";
import AnalyticsHome from "@/admin/views/analyitcs/AnalyticsHome.vue";
import AnalyticsDeclined from "@/admin/views/analyitcs/AnalyticsDeclined.vue";
import Heatmap from "@/admin/views/analyitcs/Heatmap";
import BlockedProductsTable from "@/admin/views/blockedProductsTable/BlockedProductsTable";
import AnalyticsOverdue from "@/admin/views/analyitcs/AnalyticsOverdue.vue";
import AnalyticsAsSoonAsPossible from "@/admin/views/analyitcs/AnalyticsAsSoonAsPossible.vue";
import AnalyticsTrackerActivities from "@/admin/views/analyitcs/AnalyticsTrackerActivities.vue";
import DisabledNomenclatureRatingAnalytics
    from '@/admin/views/disabledNomenclatureAnalytics/DisabledNomenclatureRatingAnalytics.vue'
import OutletNomenclatureAmountAnalytics
    from '@/admin/views/outletNomenclatureAmountAnalytics/OutletNomenclatureAmountAnalytics.vue'
import DeliveryAdmin from '@/admin/views/deliveryAdmin/DeliveryAdmin.vue'
import CouriersShiftsAnalytics from "@/admin/views/couriersShiftsAnalytics/CouriersShiftsAnalytics.vue";
import GuestComebackAnalytics from "@/admin/views/guestComebackAnalytics/GuestComebackAnalytics.vue";
import OrderReviewTagsAnalaytics from "@/admin/views/analyitcs/OrderReviewTagsAnalaytics.vue";
import BlockedEquipmentAnalytics from "@/admin/views/analyitcs/BlockedEquipmentAnalytics.vue";
import PromocodeConditionsList from "@/admin/views/promocodes/PromocodeConditionsList.vue";
import PromocodeConditionPage from "@/admin/views/promocodes/PromocodeConditionPage.vue";
import PromocodeConditionItem from "@/admin/views/promocodes/PromocodeConditionItem.vue";
import UserPromocodeList from "@/admin/views/promocodes/UserPromocodeList.vue";

const router = require('air-vue-model/router')
console.log(...Vue.prototype.$settings.getRoutes(),)

const routes = [
    {
        path: '/admin',
        name: 'admin',
        component: Home,
        meta: {
            auth: true,
        },
        children: [
            ...Vue.prototype.$user.getRoutes(),
            ...Vue.prototype.$productGroup.getRoutes(),
            ...Vue.prototype.$product.getRoutes(),
            ...Vue.prototype.$airIngredient.getRoutes(),
            ...Vue.prototype.$outlet.getRoutes(),
            ...Vue.prototype.$promo.getRoutes(),
            ...Vue.prototype.$productIngredientGroup.getRoutes(),
            ...Vue.prototype.$productIngredientGroupIngredient.getRoutes(),
            ...Vue.prototype.$outletServiceLog.getRoutes(),
            ...Vue.prototype.$order.getRoutes(),
            ...Vue.prototype.$equipmentLockedPeriod.getRoutes(),
            ...Vue.prototype.$deviceActivity.getRoutes(),
            ...Vue.prototype.$company.getRoutes(),
            ...Vue.prototype.$displayDevice.getRoutes(),
            ...Vue.prototype.$displayLine.getRoutes(),
            ...Vue.prototype.$displayDeviceGroup.getRoutes(),
            ...Vue.prototype.$displayContent.getRoutes(),
            ...Vue.prototype.$websocketEvent.getRoutes(),
            ...Vue.prototype.$checkout.getRoutes(),
            ...Vue.prototype.$nomenclature.getRoutes(),
            ...Vue.prototype.$line.getRoutes(),
            ...Vue.prototype.$lineProductGroup.getRoutes(),
            ...Vue.prototype.$ingredientGroup.getRoutes(),
            ...Vue.prototype.$userRole.getRoutes(),
            ...Vue.prototype.$balanceOperation.getRoutes(),
            ...Vue.prototype.$discountSystem.getRoutes(),
            ...Vue.prototype.$settings.getRoutes(),
            ...Vue.prototype.$reason.getRoutes(),
            ...Vue.prototype.$equipment.getRoutes(),
            ...Vue.prototype.$answerTemplate.getRoutes(),
            ...Vue.prototype.$orderReview.getRoutes(),
            ...Vue.prototype.$shift.getRoutes(),
            ...Vue.prototype.$orderReviewTag.getRoutes(),
            ...Vue.prototype.$orderReviewTagGroup.getRoutes(),
            ...Vue.prototype.$ingredientPriceListEdict.getRoutes(),
            ...Vue.prototype.$productBlank.getRoutes(),
            ...Vue.prototype.$promocodeLog.getRoutes(),

            {
                path: 'delivery_admin',
                name: 'deliveryAdmin',
                component: DeliveryAdmin
            },
            {
                path: 'db_data',
                name: 'databaseData',
                component: DatabaseData
            },
            {
                path: 'display_home',
                name: 'displayHome',
                component: DisplayHome
            },
            {
                path: 'websocket',
                name: 'websocket',
                component: Websocket
            },
            {
                path: 'analytics',
                name: 'analytics',
                component: AnalyticsHome,
                children: [
                    {
                        path: 'base',
                        name: 'analyticsBase',
                        component: Analytics
                    },
                    {
                        path: 'declined',
                        name: 'analyticsDeclined',
                        component: AnalyticsDeclined
                    },
                    {
                        path: 'overdue',
                        name: 'analyticsOverdue',
                        component: AnalyticsOverdue
                    },
                    {
                        path: 'as_soon_as_possible',
                        name: 'asSoonAsPossible',
                        component: AnalyticsAsSoonAsPossible
                    },
                    {
                        path: 'tracker_activities',
                        name: 'trackerActivities',
                        component: AnalyticsTrackerActivities
                    },
                    {
                        path: 'disabled_nomenclature_rating',
                        name: 'disabledNomenclatureAnalytics',
                        component: DisabledNomenclatureRatingAnalytics
                    },
                    {
                        path: 'outlet_nomenclature_amount',
                        name: 'outletNomenclatureAmountAnalytics',
                        component: OutletNomenclatureAmountAnalytics
                    },
                    {
                        path: 'couriers_shifts_analytics',
                        name: 'couriersShiftsAnalytics',
                        component: CouriersShiftsAnalytics
                    },
                    {
                        path: 'guest_comeback_analytics',
                        name: 'guestComebackAnalytics',
                        component: GuestComebackAnalytics
                    },
                    {
                        path: 'order_review_tags_analytics',
                        name: 'orderReviewTagsAnalytics',
                        component: OrderReviewTagsAnalaytics
                    },
                    {
                        path: 'blocked_equipment_analytics',
                        name: 'blockedEquipmentAnalytics',
                        component: BlockedEquipmentAnalytics
                    },
                ]
            },
            {
                path: 'heatmap',
                name: 'heatmap',
                component: Heatmap
            },
            {
                path: 'blocked_products_table',
                name: 'blockedProductsTable',
                component: BlockedProductsTable
            },
            {
                name: "",
                path: "promocode_conditions/:promocodeConditionId",
                component: PromocodeConditionPage,
                children: [
                    {
                        path: "",
                        name: "promocodeConditionItem",
                        component: PromocodeConditionItem,
                    },
                    {
                        path: "user_promocodes",
                        name: "promocodeConditionPromocodesList",
                        component: UserPromocodeList,
                    },
                ],
                meta: {
                    param: "promocodeConditionId",
                    single: true,
                    model: Vue.prototype.$promocodeCondition,
                },
            },
            {
                name: "promocodeConditionList",
                path: "promocode_conditions",
                component: PromocodeConditionsList,
            },
        ],
    },
    // {
    //     path: '/404',
    //     name: '404',
    //     component: NotFound,
    // },
    // {
    //     path: '*',
    //     redirect: '/404'
    // }
]


export default router(Vue, VueRouter, routes)

