<template>
  <template-list
    :model="$ingredientGroup"
    :items="list"
    title="Модификаторы"
    :show-create-button="false"
  >
    <template v-slot:actions>
      <search :rounded="false" always v-model="search"></search>
    </template>
    <template v-slot="{ object, to }">
      <v-col>
        <v-row no-gutters align="center">
          <router-link :to="to">
            <v-hover v-slot="{ hover }">
              <v-row
                no-gutters
                align="center"
                :class="hover ? 'link' : undefined"
                class="text-h6 font-weight-bold link"
              >
                <div class="mr-2">
                  {{ object.vendor_name || "Отсутствует" }}
                </div>
                <div class="text-caption mr-2" v-if="object.name">
                  ({{ object.name }})
                </div>
                <div class="mr-2 text-caption">
                  Количество модификаторов: {{ object.ingredients.length }}
                </div>
              </v-row>
            </v-hover>
          </router-link>
        </v-row>
        <v-col
          class="pa-0"
          v-for="(ingredient, index) in filterItems(object.ingredients)"
          :key="index"
          :class="{'mt-2': index}"
        >
          <router-link
            :to="{
              name: 'ingredientItem',
              params: { ingredientId: ingredient.id },
            }"
          >
            <v-hover v-slot="{ hover }">
              <v-row
                no-gutters
                align="center"
                :class="
                  ingredient.show_in_app || hover ? 'link' : 'secondary--text'
                "
              >
                <v-img
                  max-width="40"
                  height="40"
                  aspect-ratio="1"
                  contain
                  :src="ingredient.image || '/static/not-found.png'"
                />
                <div
                  class="text-body-2 ml-4"
                  v-text="ingredient.vendor_name || 'Отсутствует'"
                />
                <div class="text-caption ml-2 mr-2" v-if="ingredient.name">
                  ({{ ingredient.name }})
                </div>
              </v-row>
            </v-hover>
          </router-link>
        </v-col>
      </v-col>
    </template>
  </template-list>
</template>

<script>
import search from "air-vue-model/search";
import Search from "@/components/template/Search";
import DiscountSystemInformation from "@/admin/views/discountSystems/DiscountSystemInformation.vue";

export default {
  name: "ProductGroupList",
  components: {
    DiscountSystemInformation,
    Search,
  },
  data: () => ({
    search: null,
  }),
  methods: {
    filterItems(items) {
      return search(this.search, items, (val) => val.vendor_name);
    },
  },
  computed: {
    list() {
      if (!this.search) return this.$ingredientGroup.list;
      return this.$ingredientGroup.list.filter((v) => {
        return this.filterItems(v.ingredients).length > 0;
      });
    },
  },
  created() {},
};
</script>
