import { render, staticRenderFns } from "./Outlets.vue?vue&type=template&id=5bd68684&scoped=true"
import script from "./Outlets.vue?vue&type=script&lang=js"
export * from "./Outlets.vue?vue&type=script&lang=js"
import style0 from "./Outlets.vue?vue&type=style&index=0&id=5bd68684&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.8_css-loader@6.11.0_webpack@5.95.0__lodash@4.17.21_v_oq5h3cvaysvvpkaou2uvyhvu2e/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bd68684",
  null
  
)

export default component.exports