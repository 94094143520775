<template>
  <v-col class="pa-0">
    <v-row no-gutters class="my-3" align="center">
      <div class="text-h6 mr-3">Модификаторы</div>
      <v-divider />
    </v-row>
    <div
      v-for="(ingredientGroup, index) in item.product_ingredient_groups"
      :key="ingredientGroup.id"
    >
      <v-divider v-if="index" class="mb-1" />
      <ingredient-group-card
        @ingredientGroup="
          openRoute({
            name: 'productProductIngredientGroup',
            params: { productIngredientGroupId: $event.id },
          })
        "
        @ingredient="
          openRoute({
            name: 'productProductIngredient',
            params: { productIngredientGroupIngredientId: $event.id },
          })
        "
        @resort="item.product_ingredient_groups = item.product_ingredient_groups.sort((a,b) => (a.sorting - b.sorting))"
        @click.stop="$store.commit('setProductDrawer', true)"
        :ingredient-group="ingredientGroup"
      />
    </div>
    <v-navigation-drawer
      disable-route-watcher
      :value="$store.getters.productDrawer"
      @input="$store.commit('setProductDrawer', $event)"
      overlay-opacity="0"
      style="z-index: 1000"
      width="80%"
      app
      right
      temporary
    >
      <router-view></router-view>
    </v-navigation-drawer>
  </v-col>
</template>

<script>
import IngredientGroupCard from "@/admin/components/IngredientGroupCard";

export default {
  name: "ProductItemIngredients",
  props: {},
  computed: {
    item() {
      return this.$product.item;
    },
  },
  components: {
    IngredientGroupCard,
  },
  data: () => ({
    drawer: false,
  }),
  methods: {
    async openRoute(config) {
      await this.$router.push(config);
      this.$store.commit("setProductDrawer", true);
    },
  },
  watch: {
    "$store.getters.productDrawer"(val) {
      if (!val) {
        this.$router.replace({ name: "productIngredients" });
        this.$product.loadItem(this.$product.item.id);
      }
    },
    $route(val) {
      if (val.name === "productIngredients")
        this.$store.commit("setProductDrawer", false);
    },
  },
  created() {
    if (this.$route.name !== "productIngredients" && !this.drawer) {
      this.$store.commit("setProductDrawer", true);
    }
  },
};
</script>

<style scoped></style>
