<template>
  <template-item
    v-slot="{ object, rules }"
    :model="$displayDeviceGroup"
    :show-actions="$auth.hasRight('displays__edit_information')"
    object-key-for-title="name"
    title-prepend="Настройки экранов / Группы устройств /"
  >
    <v-text-field
      v-model="object.name"
      class="rounded-lg"
      dense
      hide-details
      label="Наименование"
      outlined
    ></v-text-field>
    <v-select
      v-model="object.line"
      :items="$displayLine.list"
      class="mt-3 rounded-lg"
      dense
      hide-details
      item-text="name"
      item-value="uuid"
      label="Очередь"
      outlined
    ></v-select>
  </template-item>
</template>

<script>
export default {
  name: "DisplayDeviceGroup",
  data: () => ({}),
  methods: {},
  created() {
    this.$displayLine.loadList();
  },
};
</script>

<style scoped></style>
