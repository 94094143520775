<template>
    <v-text-field
        style="border-radius: 10px"
        :maxlength="6"
        v-model="value" @input="checkValue" outlined hide-details></v-text-field>
</template>

<script>
export default {
    name: 'AuthenticatorInput',
    data: () => ({
        value: null,
    }),
    methods: {
        checkValue(val) {
            if (val.length === 6) {
                val = parseInt(val)
                if (!Number.isNaN(val))
                    this.$emit('complete', val)
            }
        }
    }
}
</script>

<style scoped>

</style>