<template>
  <v-row class="ma-0 my-1" style="gap: 10px">
    <v-btn :color="$route.name === 'orderReviewTagList' ? 'primary' : ''" :to="{name: 'orderReviewTagList'}">
      <div class="text-none">Категории</div>
    </v-btn>
    <v-btn :color="$route.name === 'orderReviewTagGroupList' ? 'primary' : ''" :to="{name: 'orderReviewTagGroupList'}">
      <div class="text-none">Группы категорий</div>
    </v-btn>
  </v-row>
</template>

<script>
export default {
  name: "Tabs",
};
</script>

<style scoped></style>
