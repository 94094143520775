<template>
  <div style="position: relative">
    <div
      style="
        width: calc(100% - 1px);
        height: calc(100vh - 130px);
        z-index: 0;
        position: relative;
        right: -1px;
      "
      id="map"
    ></div>
    <v-card
      style="
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 1;
        max-width: 300px;
        min-width: 200px;
      "
      class="pa-2"
      v-if="selectedMapShift"
    >
      <v-icon
        style="position: absolute; top: 10px; right: 10px"
        @click="
          () => {
            selectedMapShift = null;
            drawOrdersOnMap();
            selectedMapShiftTracks = [];
            drawSelectedMapShiftTracks();
          }
        "
        >mdi-close
      </v-icon>
      <div class="headline pr-7">
        {{ selectedMapShift.user.first_name || "Без имени" }}
      </div>
      <div class="body-1 mt-2">
        <span class="font-weight-bold grey--text text--darken-3">Статус: </span>
        {{
          selectedMapShift.end_date
            ? "Не на смене"
            : $shift.statusName[selectedMapShift.status]
        }}
        - {{ getTime(selectedMapShift) }}
      </div>
      <div
        class="body-1"
        v-if="selectedMapShift.status === $shift.statusEnum.GOING_BACK"
      >
        <span class="font-weight-bold grey--text text--darken-3"
          >Вернется через:
        </span>
        {{ getTime(selectedMapShift, true) }}
      </div>
      <div class="body-1">
        <span class="font-weight-bold grey--text text--darken-3"
          >Назначено заказов:
        </span>
        {{ selectedMapShiftOrders.length }}
      </div>
      <v-btn class="mt-3" color="primary" style="width: 100%" @click="openCourierModal">
        <div class="text-none">Посмотеть подрбнее</div>
      </v-btn>
    </v-card>
  </div>
</template>
<script>
import moment from "moment/moment";

export default {
  name: "DeliveryMap",
  props: {
    orders: Array,
    shifts: Array,
    shiftsKey: Number,
  },
  emits: ["deliveryOrdersInitScroll"],
  data: () => ({
    drawnItems: null,
    selectedMapShift: null,
    selectedMapShiftTracks: [],
    selectedMapOrder: null,
    orderLayers: [],
    courierLayers: [],
    layers: {},
    selectedMapShiftTracksLayers: [],
    currentOrderTooltip: {
      id: 0,
      value: [],
    },
  }),
  methods: {
    openCourierModal() {
      if (!this.selectedMapShift) return
      // this.$eventBus.$emit('setIndex', 0)
      this.$eventBus.$emit('openCourierModal', this.selectedMapShift)
    },
    getTime(item, checkGoingBack) {
      const now = moment();
      if (checkGoingBack) {
        if (item.status === this.$shift.statusEnum.GOING_BACK) {
          if (!item.estimated_going_back_time) return "";
          const val = moment.utc(item.estimated_going_back_time).local();
          return val.diff(now, "minutes") + " мин";
        } else return "";
      } else {
        const val = moment.utc(item.status_updated_at).local();
        return now.diff(val, "minutes") + " мин";
      }
    },
    setOrdersOnMap() {
      const ordersToShow = this.selectedMapShift
        ? this.orders.filter(
            (v) => !v.courier || v.courier?.id === this.selectedMapShift.user.id
          )
        : this.orders;
      const layers = [];
      for (const item of ordersToShow) {
        const layer = this.$order.generateMapLayer(
          [item],
          this.selectedMapShift &&
            item.courier?.id !== this.selectedMapShift.id,
          this.onMapOrderPointClick
        );
        layer.bindTooltip(
          `
            <div>Заказ ${item.daily_number}</div>
            <div>Курьер: ${
              item.courier
                ? item.courier.first_name || "Без имени"
                : "Не установлен"
            }</div>
        `,
          {
            direction: "top",
          }
        );
        this.$L.drawLayer({ layer }, "layer", layer, false);
        layers.push(layer);
      }
      return layers;
    },
    initMap() {
      this.$L.initMap("map", [54.5223, 20.7039]);
    },
    drawOrdersOnMap() {
      this.orderLayers.forEach((v) => {
        this.$L.map.removeLayer(v);
      });
      this.orderLayers = [];
      this.drawnItems = new L.FeatureGroup();
      this.$L.map.addLayer(this.drawnItems);
      const layers = this.setOrdersOnMap();
      this.orderLayers.push(...layers);
    },
    drawCouriersOnMap() {
      this.courierLayers.forEach((v) => {
        this.$L.map.removeLayer(v);
      });
      this.courierLayers = [];
      if (!this.drawnItems) this.drawnItems = new L.FeatureGroup();
      this.$L.map.addLayer(this.drawnItems);
      const layers = this.setCouriersOnMap();
      this.courierLayers.push(...layers);
    },
    setCouriersOnMap() {
      const shifts = [];
      for (const el of this.shifts) {
        if (el.last_position) shifts.push(el);
      }
      const layers = [];

      for (const shift of shifts) {
        const l = this.$shift.generateMapLayer([shift], this.onMapCourierClick);
        const res = { shift };
        const data = `
        <div>${shift.user.first_name || "Без имени"}</div>
        <div>ТТ ${shift.outlet.id} - ${shift.outlet.address}</div>
        `;
        l.bindTooltip(data, {
          direction: "top",
        });
        this.$L.drawLayer(res, "shift", l, false);
        layers.push(l);
      }
      return layers;
    },
    drawSelectedMapShiftTracks() {
      const layer = this.$trackHistory.getPolyLine(this.selectedMapShiftTracks);
      this.$L.drawLayer(this.layers, "track", layer);
    },
    addTrack(track) {
      if (track.created_by === this.selectedMapShift?.user.id) {
        this.selectedMapShiftTracks.push(track);
        this.drawSelectedMapShiftTracks();
      }
    },
    onMapCourierClick(feature, layer) {
      const coords = feature.geometry.coordinates;
      this.$L.map.flyTo(L.latLng(coords[1], coords[0]), 14);

      const shift = this.shifts.find((v) => v.id === feature.id);
      if (!shift) return;
      this.selectedMapShift = shift;
      this.drawOrdersOnMap();
      this.loadSelectedShiftTrack()
    },
    loadSelectedShiftTrack() {
      if (!this.selectedMapShift) return
      this.$trackHistory
          .loadList({
            created_by: this.selectedMapShift.user.id,
            start_created_at: this.selectedMapShift.status_updated_at,
          })
          .then((v) => {
            this.selectedMapShiftTracks = v;
            this.drawSelectedMapShiftTracks();
          });
    },
    onMapOrderPointClick(feature, layer, scroll = true) {
      this.orderLayers.forEach((v) => {
        v.getLayers().forEach((el) => {
          el.closeTooltip();
        });
      });
      const order = this.orderById[feature.id];
      if (!order) return;

      this.$order.item = order;
      if (scroll) this.$emit("deliveryOrdersInitScroll");
    },
    redrawAll() {
      this.drawOrdersOnMap();
      this.drawCouriersOnMap();
    },
  },
  mounted() {
    this.initMap();
    this.redrawAll();
  },
  computed: {
    selectedMapShiftOrders() {
      if (!this.selectedMapShift) return [];
      return this.orders.filter(
        (v) => v.courier?.id === this.selectedMapShift.user.id
      );
    },
    orderById() {
      const data = {};
      for (const el of this.orders) data[el.id] = el;
      return data;
    },
  },
  watch: {
    orders() {
      this.drawOrdersOnMap();
    },
    shifts() {
      this.drawCouriersOnMap();
    },
    shiftsKey() {
      this.drawCouriersOnMap();
    },
  },
};
</script>

<style scoped></style>
