const { cloneDeep } = require("lodash");
const m = require("air-vue-model")();
m.url = "promocode_logs";
m.name = "promocodeLog";
m.text = {
  itemTitle: "Лог промокода",
  listTitle: "Логи промокодов",
};

// m.pagination.page_size = 'all'

m.routes = [
  {
    name: "list",
    component: require("@/admin/views/promocodeLog/PromocodeLogList"),
  },
];

m.filters = {};

m.typeEnum = {
  CONDITION_CREATED: 1,
  CONDITION_ACTIVATED: 2,
  CONDITION_DEACTIVATED: 3,
  USER_EARN_PROMOCODE: 4,
  USER_SPENT_PROMOCODE: 5,
  ASSIGN_PROMOCODE_BY_ADMIN: 6,
};

m.typeName = {
  [m.typeEnum.CONDITION_CREATED]: "Создание условия",
  [m.typeEnum.CONDITION_ACTIVATED]: "Условие активировано",
  [m.typeEnum.CONDITION_DEACTIVATED]: "Условие деактивировано",
  [m.typeEnum.USER_EARN_PROMOCODE]: "Пользователь получил промокод",
  [m.typeEnum.USER_SPENT_PROMOCODE]: "Пользователь потратил промокод",
  [m.typeEnum.ASSIGN_PROMOCODE_BY_ADMIN]: "Администратор начислил промокод",
};

m.typesList = Object.keys(m.typeName).map((key) => {
  const _key = Number(key);
  return {
    label: m.typeName[_key],
    value: _key,
  };
});

module.exports = m;
