<template>
  <template-item
    :show-actions="$auth.hasRight('displays__edit_information')"
    :model="$displayContent"
    v-slot="{ object, rules }"
    title-prepend="Настройки экранов / Контент /"
    object-key-for-title="name"
    :after-save="afterSave"
  >
    <v-text-field
      class="rounded-lg"
      v-model="object.name"
      label="Наименование"
      outlined
      hide-details
      dense
    ></v-text-field>
    <v-select
      outlined
      hide-details
      dense
      class="mt-3 rounded-lg"
      label="Тип контента"
      v-model="object.type"
      :items="$displayContent.types"
      item-text="title"
      item-value="value"
    ></v-select>
    <v-text-field
      v-if="object.type === 3"
      class="rounded-lg mt-3"
      v-model="object.url"
      label="Ссылка на контент"
      outlined
      hide-details
      dense
    ></v-text-field>
    <v-img
      class="my-2"
      max-width="300"
      :src="object.file"
      v-if="object.file && object.type === 1"
    ></v-img>

    <v-col class="pa-0" v-if="object.type !== 3">
      <v-divider class="my-3"></v-divider>
      <div class="font-weight-bold">Изменить файл</div>
      <v-row no-gutters align="center" class="mt-3">
        <v-file-input
          v-model="file"
          dense
          hide-details
          class="rounded-lg"
          outlined
          label="Файл"
          truncate-length="15"
          :disabled="!$auth.hasRight('displays__edit_information')"
        ></v-file-input>
<!--        <v-btn-->
<!--          :loading="fileLoading"-->
<!--          @click="setFile"-->
<!--          color="primary"-->
<!--          class="text-none body-2 rounded-lg ml-2"-->
<!--          height="40"-->
<!--          v-if="$auth.hasRight('displays__edit_information')"-->
<!--          :disabled="!file"-->
<!--          >Сохранить файл-->
<!--        </v-btn>-->
      </v-row>
    </v-col>
  </template-item>
</template>

<script>
export default {
  name: "DisplayContent",
  data: () => ({
    file: null,
    fileLoading: false,
  }),
  methods: {
    setFile() {
      this.fileLoading = true;
      const formData = new FormData();
      formData.append("file", this.file);
      axios
        .put(
          `/display_contents/${this.$displayContent.item.uuid}/set_file/`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          this.file = null;
          this.$displayContent.item.file = res.data.file;
          this.$store.commit("setSnackbar", {
            color: "success",
            text: "Файл сохранен",
          });
          this.fileLoading = false;
        })
        .catch(() => {
          this.$store.commit("setSnackbar", {
            color: "red",
            text: "Не удалось сохранить",
          });
          this.fileLoading = false;
          this.file = null;
        });
    },
    afterSave(_) {
      if (this.file) this.setFile()
    }
  },
};
</script>

<style scoped></style>
