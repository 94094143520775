<template>
    <v-col class="pa-0">
        <v-row no-gutters class="my-3" align="center">
            <div class="text-h6 mr-3">Общая информация</div>
            <v-divider/>
            <v-btn
                v-if="$auth.hasRight('products__edit_information')"
                :loading="$product.loadings.save"
                depressed
                color="primary"
                class="ml-3 rounded-lg text-none body-2"
                @click="saveItem"
            >Сохранить
            </v-btn>
        </v-row>
        <v-row no-gutters align="start">
            <ImageUploader
                width="215"
                height="215"
                title="Основное"
                class="mr-5"
                @input="uploadImage($event, 'image')"
                :image="item.image"
            ></ImageUploader>
            <ImageUploader
                width="215"
                height="215"
                title="В карточке"
                class="mr-5"
                @input="uploadImage($event, 'full_image')"
                :image="item.full_image"
            ></ImageUploader>
            <ImageUploader
                width="215"
                height="215"
                title="Яндекс еда"
                @input="uploadImage($event, 'yandex_eda_image')"
                :image="item.yandex_eda_image"
            ></ImageUploader>
            <div class="ml-5">
                <v-row align="center" no-gutters>
                    <div class="mr-2">
                        Наименование из учетной системы:
                        <span class="font-weight-bold">{{
                                item.vendor_name || "Отсутствует"
                            }}</span>
                    </div>
                    <discount-system-information
                        size="30"
                        class="ml-3"
                        :product="item"
                    ></discount-system-information>
                </v-row>
                <v-text-field
                    v-model="item.name"
                    outlined
                    hide-details
                    dense
                    label="Клиентское наименование"
                    class="mt-4"
                />
                <v-text-field
                    v-model="item.tracker_name"
                    @input="item.tracker_name = $event || null"
                    outlined
                    hide-details
                    dense
                    label="Наименование для трекера"
                    class="mt-4"
                />
                <v-text-field
                    v-model="item.promo_position"
                    @input="item.promo_position = $event || null"
                    outlined
                    hide-details
                    dense
                    label="Порядковый номер в промо блоке"
                    class="mt-4"
                />
                <v-checkbox
                    class="pa-0 ma-0 py-4"
                    hide-details
                    label="Отображать в приложении"
                    v-model="item.show_in_app"
                ></v-checkbox>
                <v-checkbox
                    class="pa-0 ma-0 pb-4"
                    hide-details
                    label="Комбо"
                    v-model="item.combo"
                ></v-checkbox>
                <v-checkbox
                    class="pa-0 ma-0 pb-4"
                    hide-details
                    v-model="item.dont_forget"
                    label="Напоминать при выдаче"
                ></v-checkbox>
                <v-checkbox
                    class="pa-0 ma-0 pb-4"
                    hide-details
                    label="Использовать как позицию со стоимостью доставки"
                    v-model="item.use_as_delivery"
                />

                <v-menu offset-y :close-on-content-click="false" v-model="tagMenu">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            depressed
                            width="130"
                            small
                            :color="item.tag ? item.tag.color : ''"
                            v-bind="attrs"
                            v-on="on"
                            :class="item.tag ? 'white--text' : ''"
                        >
                            {{ item.tag ? item.tag.name : "Не указано" }}
                        </v-btn>
                    </template>
                    <v-card class="pa-3" min-width="250">
                        <div class="link mb-2" @click="changeEdit(null)">
                            {{ newTag ? "вернуться" : "добавить новый" }}
                        </div>
                        <div
                            class="caption text-secondary"
                            v-if="!$productTag.list.length && !newTag"
                        >
                            Теги отсутсвуют
                        </div>
                        <v-col class="pa-0" v-if="!newTag">
                            <v-row
                                no-gutters
                                align="center"
                                class="mt-3 flex-nowrap"
                                v-for="(el, index) in $productTag.list"
                                :key="index"
                            >
                                <v-simple-checkbox
                                    :value="el.hit"
                                    readonly
                                    class="mr-2"
                                ></v-simple-checkbox>
                                <v-btn
                                    @click="setTag(el)"
                                    class="white--text"
                                    depressed
                                    :color="el.color"
                                    small
                                    style="flex-grow: 1"
                                >
                                    {{ el.name }}
                                </v-btn>
                                <v-btn icon small class="ml-2" @click="changeEdit(el)">
                                    <v-icon size="20">mdi-pencil</v-icon>
                                </v-btn>
                            </v-row>
                            <v-btn
                                depressed
                                block
                                class="mt-3"
                                v-if="item.tag"
                                @click="setTag(null)"
                                small
                                v-bind="attrs"
                                v-on="on"
                            >
                                Снять тег
                            </v-btn>
                        </v-col>
                        <v-col class="pa-0" v-else>
                            <v-col class="pa-0" v-if="$productTag.item">
                                <v-text-field
                                    outlined
                                    dense
                                    hide-details
                                    label="Наименование"
                                    v-model="$productTag.item.name"
                                ></v-text-field>
                                <v-checkbox
                                    v-model="$productTag.item.hit"
                                    label="Использовать как хит"
                                    hide-details
                                    class="ma-0 pa-0 mt-2"
                                ></v-checkbox>
                                <v-color-picker
                                    mode.sync="hex"
                                    v-model="$productTag.item.color"
                                    class="mt-3"
                                    dot-size="30"
                                ></v-color-picker>
                                <v-row no-gutters align="center" justify="space-between">
                                    <v-btn
                                        @click="saveTag"
                                        :loading="$productTag.loadings.save"
                                        :disabled="!$productTag.item.name"
                                        class="mt-3"
                                        color="primary"
                                        depressed
                                    >
                                        Сохранить
                                    </v-btn>
                                    <v-btn
                                        @click="deleteTag($productTag.item.id)"
                                        text
                                        v-if="$productTag.item.id"
                                        :loading="$productTag.loadings.delete"
                                        class="mt-3"
                                        color="error"
                                        depressed
                                    >
                                        Удалить
                                    </v-btn>
                                </v-row>
                            </v-col>
                        </v-col>
                    </v-card>
                </v-menu>
            </div>
        </v-row>
        <v-checkbox
            label="Отображать описание в приложении"
            v-model="item.show_description"
            class="pa-0 ma-0"
            hide-details
        ></v-checkbox>
        <v-textarea
            v-model="item.description"
            outlined
            hide-details
            dense
            no-resize
            :rows="5"
            auto-grow
            label="Описание"
            class="my-3"
        />

        <v-text-field
            v-model="item.sorting"
            type="number"
            outlined
            hide-details
            dense
            label="Сортировка"
            class="mb-3"
        />
        <v-row align="center" class="mt-4" no-gutters>
            <v-text-field
                v-model="item.shelf_life"
                @input="item.shelf_life = $event || null"
                outlined
                hide-details
                dense
                type="number"
                label="Срок годности (часы)"

            />
            <AcceptToOtherProducts
                class="ml-3"
                :set-data="{
                products: {
                    shelf_life: item.shelf_life,
                }}"
                :filters="{}"></AcceptToOtherProducts>
        </v-row>
        <v-checkbox
            dense
            hide-details
            label="Отображать как акционное"
            v-model="item.is_promo"
        />
    </v-col>
</template>

<script>
import imageUpload from "@/vendor/imageUpload";
import ImageUploader from "@/admin/components/ImageUploader";
import DiscountSystemInformation from "@/admin/views/discountSystems/DiscountSystemInformation.vue";
import AcceptToOtherProducts from "@/admin/components/AcceptToOtherProducts.vue";

export default {
    name: "ProductItemBase",
    components: {
        AcceptToOtherProducts,
        ImageUploader,
        DiscountSystemInformation,
    },
    mixins: [imageUpload],

    data: () => ({
        newTag: false,
        tagMenu: false,
    }),
    computed: {
        item() {
            return this.$product.item;
        },
    },
    methods: {
        async uploadImage(file, field) {
            const formData = new FormData();
            formData.append("image", file);
            formData.append("type", field);
            const res = await this.$product.sendPutSingle(
                "upload_image",
                this.$product.item.id,
                formData
            );
            this.$product.item[field] = res.url;
            this.$store.commit("setSnackbar", {
                color: "success",
                text: "Изображение сохранено",
            });
        },
        changeEdit(item = null) {
            if (this.newTag) {
                this.newTag = false;
                return;
            }
            if (item) {
                this.$productTag.item = this.$productTag.copy(item);
            } else {
                this.$productTag.setItemFromDefault();
            }
            this.newTag = true;
        },
        saveTag() {
            const {promise, created} = this.$productTag.updateOrCreate();
            promise.then(() => {
                this.$store.commit("setSnackbar", {
                    color: "success",
                    text: "Сохранено",
                });
                this.newTag = false;
                this.$productTag.loadList();
            });
        },
        setTag(item) {
            this.$product.item.tag = item;
            this.tagMenu = false;
            this.$product.sendPutSingle("set_product_tag", this.$product.item.id, {
                product_tag: item ? item.id : null,
            });
            this.$store.commit("setSnackbar", {
                color: "success",
                text: "Тег сохранен",
            });
        },
        deleteTag(id) {
            this.$productTag.delete(id).then(() => {
                this.$store.commit("setSnackbar", {
                    color: "success",
                    text: "Удалено",
                });
                this.newTag = false;
                this.$productTag.loadList();
            });
        },
        saveItem() {
            this.$product
                .update()
                .then((value) => {
                    this.$store.commit("setSnackbar", {
                        color: "success",
                        text: "Блюдо сохранено",
                    });
                })
                .catch((err) => {
                    this.$store.commit("setSnackbar", {
                        color: "fail",
                        text: "Не удалось сохранить",
                    });
                });
        },
    },
    watch: {},
};
</script>

<style scoped></style>
