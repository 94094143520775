const m = require("air-vue-model")();
m.url = "product_ingredient_group_ingredients";
m.name = "productIngredientGroupIngredient";
m.setPagination({
  page_size: "all",
});
m.text = {
  itemTitle: "Ингредиент",
  listTitle: "Ингредиенты",
};
// m.routes = [
//   {
//     name: "item",
//     component: require("@/admin/views/productIngredientGroupIngredient/ProductIngredientGroupIngredientItem"),
//     single: true,
//   },
// ];

m.routes = [
  {
    name: "",
    component: require("@/admin/views/productIngredientGroupIngredient/ProductIngredientGroupIngredient"),
    children: [
      {
        name: "item",
        component: require("@/admin/views/productIngredientGroupIngredient/ProductIngredientGroupIngredientItem"),
        single: true,
      },
      {
        path: "product_ingredient_group_ingredients/:productIngredientGroupIngredientId/outlets/",
        name: "outlets",
        component: require("@/admin/views/productIngredientGroupIngredient/ProductIngredientGroupIngredientOutletItems"),
        single: true,
      },
    ],
  },
];

module.exports = m;
