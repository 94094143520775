const m = require('air-vue-model')()
m.url = 'product_tags'
m.name = 'productTag'

m.setPagination({
    page_size: 'all'
})


m.default = {
    color: '#4DA955',
    name: '',
    hit: false,
}

module.exports = m