<template>
  <v-col class="pa-3 pb-10">
    <v-row align="center" class="my-0" dense style="gap: 10px 0px">
      <v-row class="ma-0" style="flex-wrap: nowrap; flex: unset">
        <date-picker
          v-model="startDateBefore"
          class="rounded-lg mr-2"
          label="Период до"
          style="max-width: 140px"
          clearable
        ></date-picker>
        <date-picker
          v-model="endDateBefore"
          class="rounded-lg mr-2"
          label="Период до"
          style="max-width: 140px"
          clearable
        ></date-picker>
      </v-row>
      <v-divider vertical class="mr-3 ml-1" />
      <v-row class="ma-0" style="flex-wrap: nowrap; flex: unset">
        <date-picker
          v-model="startDate"
          class="rounded-lg mr-2"
          label="Период поверки"
          style="max-width: 140px"
        ></date-picker>
        <date-picker
          v-model="endDate"
          class="rounded-lg mr-2"
          label="Период поверки"
          style="max-width: 140px"
        ></date-picker>
      </v-row>
      <v-divider vertical class="mr-3 ml-1" />
      <v-row class="ma-0" style="flex-wrap: nowrap; flex: unset">
        <date-picker
          v-model="startDateAfter"
          class="rounded-lg mr-2"
          label="Период после"
          style="max-width: 140px"
        ></date-picker>
        <date-picker
          v-model="endDateAfter"
          class="rounded-lg mr-2"
          label="Период после"
          style="max-width: 140px"
        ></date-picker>
      </v-row>
      <v-divider vertical class="mr-3 ml-1" />
      <v-row class="ma-0" style="flex-wrap: nowrap; flex: unset">
        <v-text-field
          dense
          class="rounded-lg mr-2"
          hide-details
          outlined
          label="Оценка"
          v-model="startRating"
          type="number"
          style="max-width: 70px"
        ></v-text-field>
        <v-text-field
          class="rounded-lg mr-2"
          dense
          hide-details
          outlined
          label="Оценка"
          v-model="endRating"
          style="max-width: 70px"
        ></v-text-field>
      </v-row>
      <v-col cols="auto">
        <v-row align="center" class="pa-0 ml-1">
          <v-btn
            :loading="loading"
            class="rounded-lg"
            color="primary"
            depressed
            height="40"
            @click="loadAnalytics()"
            >Обновить
          </v-btn>
          <!--          <v-btn-->
          <!--            v-if="results"-->
          <!--            @click="exportToExcel"-->
          <!--            :loading="exportLoading"-->
          <!--            class="rounded-lg ml-3"-->
          <!--            color="primary"-->
          <!--            height="40"-->
          <!--            outlined-->
          <!--            rounded-->
          <!--            >Выгрузить-->
          <!--          </v-btn>-->
        </v-row>
      </v-col>
      <v-spacer />
    </v-row>
    <div class="subtitle-1 font-weight-bold mt-4">Сводная аналитика:</div>
    <v-data-table
      style="max-width: 1200px"
      v-if="result"
      :headers="summaryHeaders"
      :items="[result.summary]"
      class="mt-2"
      disable-pagination
      hide-default-footer
    />
    <div class="subtitle-1 font-weight-bold mt-6">Детальная аналитика:</div>
    <v-data-table
      style="max-width: 1200px"
      v-if="result"
      :headers="detailHeaders"
      :items="detailAnalyticsData"
      class="mt-2"
      disable-pagination
      hide-default-footer
    />
    <div class="subtitle-1 font-weight-bold mt-6">Отзывы:</div>
    <v-data-table
      style="max-width: 1200px"
      v-if="result"
      :headers="reviewsHeaders"
      :items="result.reviews"
      class="mt-2 reviews-table"
      disable-pagination
      hide-default-footer
      @click:row="onRowClick"
    />
    <!--    </template>-->
  </v-col>
</template>
<script>
import moment from "moment";

export default {
  name: "CouriersShiftsAnalytics",
  components: {},
  data: () => ({
    startDateBefore: moment()
      .subtract(3, "d")
      .subtract(3, "w")
      .format("YYYY-MM-DD"),
    endDateBefore: moment()
      .subtract(3, "d")
      .subtract(2, "w")
      .format("YYYY-MM-DD"),
    startDate: moment().subtract(2, "d").subtract(2, "w").format("YYYY-MM-DD"),
    endDate: moment().subtract(2, "d").subtract(1, "w").format("YYYY-MM-DD"),
    startDateAfter: moment()
      .subtract(1, "d")
      .subtract(1, "w")
      .format("YYYY-MM-DD"),
    endDateAfter: moment().subtract(1, "d").format("YYYY-MM-DD"),
    startRating: 1,
    endRating: 3,
    loading: false,
    result: null,
    exportLoading: false,
    summaryHeaders: [
      {
        text: "Кол-во гостей",
        align: "start",
        sortable: true,
        value: "users_total",
      },
      {
        text: "Кол-во заказов",
        align: "start",
        sortable: true,
        value: "orders_total",
      },
      {
        text: "Кол-во вернувшихся",
        align: "start",
        sortable: true,
        value: "returned_users",
      },
      {
        text: "% вернувшихся",
        align: "start",
        sortable: true,
        value: "returned_users_percent",
      },
      {
        text: "Кол-во отзывов",
        align: "start",
        sortable: true,
        value: "reviews_total",
      },
    ],
    detailHeaders: [
      {
        text: "",
        align: "start",
        sortable: true,
        value: "name",
      },
      {
        text: "До",
        align: "start",
        sortable: true,
        value: "before",
      },
      {
        text: "После",
        align: "start",
        sortable: true,
        value: "after",
      },
      {
        text: "Разница в %",
        align: "start",
        sortable: true,
        value: "diffInPercent",
      },
    ],
    reviewsHeaders: [
      {
        text: "Гость",
        align: "start",
        sortable: true,
        value: "order.user.phone",
      },
      {
        text: "ТТ",
        align: "start",
        sortable: true,
        value: "order.outlet.id",
      },
      {
        text: "Дата заказа",
        align: "start",
        sortable: true,
        value: "order.createdAt",
      },
      {
        text: "Номер заказа",
        align: "start",
        sortable: true,
        value: "order.daily_number",
      },
      {
        text: "Оценка",
        align: "start",
        sortable: true,
        value: "rating",
      },
      {
        text: "Комментарий",
        align: "start",
        sortable: true,
        value: "comment",
      },
      {
        text: "Статус отзыва",
        align: "start",
        sortable: true,
        value: "statusName",
      },
      {
        text: "Решение",
        align: "start",
        sortable: true,
        value: "decision",
      },
    ],
  }),
  async mounted() {
    const lastDataRaw = sessionStorage.getItem("lastData");
    if (lastDataRaw) {
      const lastData = JSON.parse(lastDataRaw);
      this.result = lastData.result;
      this.startDateBefore = lastData.startDateBefore || null;
      this.endDateBefore = lastData.endDateBefore || null;
      this.startDate = lastData.startDate || null;
      this.endDate = lastData.endDate || null;
      this.startDateAfter = lastData.startDateAfter || null;
      this.endDateAfter = lastData.endDateAfter || null;
    } else this.loadAnalytics();
  },
  methods: {
    onRowClick(item) {
      if (!item.id) return;
      sessionStorage.setItem('lastData', JSON.stringify({
        result: this.result,
        startDateBefore: this.startDateBefore,
        endDateBefore: this.endDateBefore,
        startDate: this.startDate,
        endDate: this.endDate,
        startDateAfter: this.startDateAfter,
        endDateAfter: this.endDateAfter,
      }))
      this.$router.push({
        name: "orderReviewList",
        params: { review_id: item?.id },
      });
    },
    getTimeData() {
      const res = {
        start_date: this.startDate ? this.startDate + " 00:00:00" : "",
        end_date: this.endDate ? this.endDate + " 23:59:59" : "",
      };
      if (res.start_date)
        res.start_date = moment(res.start_date)
          .utc()
          .format("YYYY-MM-DD HH:mm:ss");
      if (res.end_date)
        res.end_date = moment(res.end_date).utc().format("YYYY-MM-DD HH:mm:ss");
      return res;
    },
    loadAnalytics() {
      if (!this.startDateBefore || !this.endDateBefore) {
        this.startDateBefore = null;
        this.endDateBefore = null;
      }
      if (this.startDate > this.endDate) {
        const tmp = String(this.startDate);
        this.startDate = String(this.endDate);
        this.endDate = tmp;
      }
      this.loading = true;
      this.$analytics
        .sendPost("returned_users_report", {
          start_date_before: this.startDateBefore || undefined,
          end_date_before: this.endDateBefore || undefined,
          start_date: this.startDate || undefined,
          end_date: this.endDate || undefined,
          start_date_after: this.startDateAfter || undefined,
          end_date_after: this.endDateAfter || undefined,
          rating_from: this.startRating,
          rating_to: this.endRating,
        })
        .then((res) => {
          this.result = res.result;
          const users_total = this.result.summary.users_total;
          const returned_users = this.result.summary.returned_users;
          this.result.summary.returned_users_percent = (
            (returned_users / (users_total || 1)) *
            100
          ).toFixed(2);
          for (const review of this.result.reviews || []) {
            review.rating += 1;
            review.order.createdAt = moment
              .utc(review.order.createdAt)
              .local()
              .format("DD.MM.YYYY HH:mm:ss");
            review.statusName = this.$orderReview.statuses.find(
              (v) => v.value === review.status
            ).label;
          }
        }).catch(() => {
        this.$store.commit("setSnackbar", {
          color: "red",
          text: "Ошибка",
        });
      }).finally(() => {

        this.loading = false;
      })
    },
    secondsToTime(v) {
      const secs = Number(Number(v).toFixed(0));
      let hours = Math.floor(secs / 3600);
      hours = hours < 10 ? `0${hours}` : hours;
      let minutes = Math.floor((secs % 3600) / 60);
      minutes = minutes < 10 ? `0${minutes}` : minutes;
      let seconds = secs - hours * 3600 - minutes * 60;
      seconds = seconds < 10 ? `0${seconds}` : seconds;
      return `${hours}:${minutes}:${seconds}`;
    },
  },
  computed: {
    detailAnalyticsData() {
      // return []
      const analytics = this.result.detailed;
      if (!analytics) return [];
      const res = [];
      const mapping = {
        orders_count: "Кол-во чеков",
        orders_sum: "Сумма заказов",
        orders_by_user: "Чеков на гостя",
        average_sum: "Средний чек",
      };
      for (const [k, v] of Object.entries(mapping)) {
        const before = analytics.before[k];
        const after = analytics.after[k];
        const diffInPercent =
          !before && !after
            ? 0
            : ((after / (before || 1)) * 100 - 100).toFixed(2);
        res.push({
          name: v,
          before,
          after,
          diffInPercent,
        });
      }
      return res;
    },
  },
};
</script>
<style scoped>
#parent {
  position: absolute;
  width: 100%;
}

table {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  overflow: hidden;
}

thead {
  /*
  Grow thead automatically to fit content, don't shrink it
  proportionately to the body.
  */
  flex: 1 0 auto;
  display: block;
  /* x-scrolling will be managed via JS */
  overflow-x: hidden;
  /*
  Keep header columns aligned with useless scrollbar.
  For IE11, use "dead area" color to hide scrollbar functions
  */
  overflow-y: scroll;
  scrollbar-base-color: #ccc;
  scrollbar-face-color: #ccc;
  scrollbar-highlight-color: #ccc;
  scrollbar-track-color: #ccc;
  scrollbar-arrow-color: #ccc;
  scrollbar-shadow-color: #ccc;
  scrollbar-dark-shadow-color: #ccc;
}

thead::-webkit-scrollbar {
  display: block;
  background-color: #ccc;
}

thead::-webkit-scrollbar-track {
  background-color: #ccc;
}

/* Scroll the actual tbody (second child on all browsers) */
tbody {
  display: block;
  overflow: scroll;
}

/* IE11 adds an extra tbody, have to hide it */
tbody:nth-child(3) {
  display: none;
}

/* The one caveat, a hard-set width is required. */
td,
th {
  width: 10em;
  min-width: 10em;
  padding: 0.3em;
  border: 1px solid #ddd;
  background-color: white;
}

th {
  background-color: #f7f7f7;
}

td:first-child,
th:first-child {
  position: sticky;
  position: -webkit-sticky;
  left: 0;
}

.list-item {
  padding: 5px 5px;
  border-radius: 7px;
  cursor: pointer;
}

.list-item:hover {
  background: #e3e3e3;
}

.reviews-table :deep(thead th:nth-child(6)) {
  width: 300px;
}
</style>
