<template>
  <v-card width="100%" class="mb-3" flat>
    <v-row no-gutters class="mb-2" align="center">
      <v-btn icon small class="mr-2" @click="show = !show">
        <v-icon size="20">{{
          show ? "mdi-chevron-up" : "mdi-chevron-down"
        }}</v-icon>
      </v-btn>

      <v-col class="pa-0">
        <!--                <router-link-->
        <!--                    :to="{name: 'ingredientGroupItem', params: {ingredientGroupId: ingredientGroup.ingredient_group.id}}">-->
        <v-hover v-slot="{ hover }">
          <div
            @click="$emit('ingredientGroup', ingredientGroup)"
            :class="
              ingredientGroup.show_in_app || hover ? 'link' : 'secondary--text'
            "
            class="text-subtitle-1 mr-2 link font-weight-medium"
          >
            <span>{{
              ingredientGroup.ingredient_group.vendor_name || "Отсутствует"
            }}</span>
            <span
              class="text-caption ml-2"
              v-if="ingredientGroup.ingredient_group.name"
              >({{ ingredientGroup.ingredient_group.name }})</span
            >
          </div>
        </v-hover>
        <!--                </router-link>-->
        <v-row no-gutters>
          <!--                    <router-link-->
          <!--                        :to="{name: 'productIngredientGroupItem', params: {productIngredientGroupId: ingredientGroup.id}}">-->
          <!--                        <div class="mr-3 caption link">Отображение в блюде</div>-->
          <!--                    </router-link>-->
          <div class="text-caption">
            Кол-во от {{ ingredientGroup.min_amount }} до
            {{ ingredientGroup.max_amount }}
          </div>
          <v-menu
            :close-on-content-click="false"
            max-width="300"
            min-width="300"
            offset-y
            @input="onMenuToggle"
          >
            <template v-slot:activator="{ on, attrs }">
              <div class="text-caption ml-2" v-on="on" style="cursor: pointer">
                Сортировка: {{
                      ingredientGroup.sorting === null ? ingredientGroup.ingredient_group.sorting : ingredientGroup.sorting
                  }}
              </div>
            </template>
            <v-card class="pa-2">
              <v-row class="ma-0" style="gap: 10px">
                <v-text-field
                  type="number"
                  outlined
                  dense
                  hide-details
                  v-model.number="ingredientGroup.sorting"
                  @change="ingredientGroup.sorting = getSorting(ingredientGroup.sorting)"
                  label="Сортировка"
                ></v-text-field>
                <v-btn icon style="margin-top: 1px" @click="saveSorting"
                  ><v-icon>mdi-check</v-icon></v-btn
                >
              </v-row>
            </v-card>
          </v-menu>
          <div class="text-caption red--text font-weight-medium ml-2">
            {{ ingredientGroup.hidden ? "Скрытая" : "" }}
          </div>
        </v-row>
      </v-col>
      <v-spacer></v-spacer>
      <div class="caption mr-3">Положение:</div>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            depressed
            width="130"
            small
            :color="
              $productIngredientGroup.getPositionByValue(
                ingredientGroup.position
              )
                ? 'primary'
                : ''
            "
            v-bind="attrs"
            v-on="on"
          >
            {{
              $productIngredientGroup.getPositionNameByValue(
                ingredientGroup.position
              )
            }}
          </v-btn>
        </template>
        <v-list v-if="$auth.hasRight('products__edit_information')">
          <v-list-item
            @click="setPosition(ingredientGroup, el.value)"
            v-for="(el, index) in $productIngredientGroup.positions"
            :key="index"
          >
            <v-list-item-title>{{ el.name }} </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="
              $productIngredientGroup.getPositionByValue(
                ingredientGroup.position
              )
            "
            @click="setPosition(ingredientGroup, null)"
          >
            <v-list-item-title class="caption red--text text-center"
              >Очистить</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
    </v-row>
    <v-col class="pa-0" v-if="show">
      <v-col
        class="pa-0"
        v-for="(ingredientInGroup, index) in ingredientGroup.ingredients"
        :key="ingredientInGroup.id"
      >
        <v-divider v-if="index" class="my-3"></v-divider>
        <v-row no-gutters align="center">
          <ImageUploader
            height="40"
            width="40"
            @input="uploadImage($event, ingredientInGroup.ingredient)"
            :image="ingredientInGroup.ingredient.image"
          ></ImageUploader>
          <v-col>
            <v-row no-gutters align="center">
              <!--                            <router-link :to="{name: 'ingredientBase', params: {-->
              <!--                                ingredientId: ingredientInGroup.ingredient.id-->
              <!--                            }}">-->
              <v-hover v-slot="{ hover }">
                <div
                  @click="$emit('ingredient', ingredientInGroup)"
                  :class="
                    ingredientInGroup.ingredient.show_in_app || hover
                      ? 'link'
                      : 'secondary--text'
                  "
                  class="mx-3 link body-2 font-weight-medium"
                >
                  <span>{{
                    ingredientInGroup.ingredient.vendor_name || "Отсутствует"
                  }}</span>
                  <span
                    class="text-caption mx-2"
                    v-if="ingredientInGroup.ingredient.name"
                    >({{ ingredientInGroup.ingredient.name }})</span
                  >
                  <discount-system-information
                    :pigi="ingredientInGroup"
                    size="24"
                    class="ml-3"
                  ></discount-system-information>
                </div>
              </v-hover>
              <!--                            </router-link>-->
            </v-row>
            <v-row no-gutters align="center">
              <!--                            <router-link :to="{name: 'productIngredientGroupIngredientItem', params: {-->
              <!--                                                            productIngredientGroupIngredientId: ingredientInGroup.id}}">-->
              <!--                                <div class="ml-3 caption link">Отображение в блюде</div>-->
              <!--                            </router-link>-->
              <div class="text-caption ml-3">
                Кол-во от {{ ingredientInGroup.min_amount }}
                до
                {{ ingredientInGroup.max_amount }}
              </div>
              <div class="text-caption ml-3">
                По-умолчанию
                {{ ingredientInGroup.default_amount }}
              </div>
              <v-menu
                  :close-on-content-click="false"
                  max-width="300"
                  min-width="300"
                  offset-y
                  @input="onPigiMenuToggle(index, $event)"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div class="text-caption ml-3" v-on="on" style="cursor: pointer">
                    Сортировка
                    {{ ingredientInGroup.sorting === null ? ingredientInGroup.ingredient.sorting : ingredientInGroup.sorting}}
                  </div>
                </template>
                <v-card class="pa-2">
                  <v-row class="ma-0" style="gap: 10px">
                    <v-text-field
                        type="number"
                        outlined
                        dense
                        hide-details
                        @change="ingredientInGroup.sorting = getSorting(ingredientInGroup.sorting)"
                        v-model.number="ingredientInGroup.sorting"
                        label="Сортировка"
                    ></v-text-field>
                    <v-btn icon style="margin-top: 1px" @click="savePigiSorting(index)"
                    ><v-icon>mdi-check</v-icon></v-btn
                    >
                  </v-row>
                </v-card>
              </v-menu>

              <div
                class="caption green--text ml-3"
                v-if="ingredientInGroup.promo_text"
              >
                {{ ingredientInGroup.promo_text }}
              </div>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-col>
  </v-card>
</template>

<script>
import imageUpload from "../../vendor/imageUpload";
import ImageUploader from "@/admin/components/ImageUploader";
import DiscountSystemInformation from "@/admin/views/discountSystems/DiscountSystemInformation.vue";
import { clone } from "lodash";
import {getSorting} from "@/services/getSorting";

export default {
  name: "IngredientGroupCard",

  props: {
    ingredientGroup: Object,
  },
  data: () => ({
    show: false,
    initialSorting: false,
    initialPigiSortingByIndex: {},
  }),
  components: {
    DiscountSystemInformation,
    ImageUploader,
  },

  mixins: [imageUpload],

  methods: {
      getSorting,
    onPigiMenuToggle(index, v){
      if (v) this.initialPigiSortingByIndex[index] = clone(this.ingredientGroup.ingredients[index].sorting);
      else if (this.ingredientGroup && this.initialPigiSortingByIndex[index] !== undefined)
        this.ingredientGroup.ingredients[index].sorting = clone(this.initialPigiSortingByIndex[index]);
    },
    onMenuToggle(v) {
      if (v) this.initialSorting = clone(this.ingredientGroup?.sorting);
      else if (this.ingredientGroup)
        this.ingredientGroup.sorting = clone(this.initialSorting);
    },
    saveSorting() {
      this.$productIngredientGroup.update({
        id: this.ingredientGroup.id,
        sorting: this.ingredientGroup.sorting,
      });
      this.initialSorting = clone(this.ingredientGroup.sorting)
      this.$emit('resort')
    },
    savePigiSorting(index){
      const item = this.ingredientGroup.ingredients[index]
      this.$productIngredientGroupIngredient.update({
        id: item.id,
        sorting: item.sorting,
      }).then(() => {
        this.initialPigiSortingByIndex = {}
        this.ingredientGroup.ingredients = this.ingredientGroup.ingredients.sort((a,b) => a.sorting - b.sorting)
      });
    },
    setPosition(ingredientGroup, position) {
      ingredientGroup.position = position;
      this.$productIngredientGroup.update({
        id: ingredientGroup.id,
        position,
      });
    },
    async uploadImage(file, ingredient) {
      const formData = new FormData();
      formData.append("image", file);
      const res = await this.$airIngredient.sendPutSingle(
        "upload_image",
        ingredient.id,
        formData
      );
      ingredient.image = res.url;
      this.$store.commit("setSnackbar", {
        color: "success",
        text: "Изображение сохранено",
      });
    },
  },
};
</script>
