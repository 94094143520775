<template>
    <template-list
        :show-create-button="$auth.hasRight('displays__edit_information')"
        :model="$displayContent" title-prepend="Настройки экранов / ">
        <template v-slot:actions>
        </template>
        <template v-slot="{object, to, index}">
            <v-col class="pa-0 body-2 black--text">
                <router-link :to="{name: 'displayContentItem', params: {displayContentUuid: object.uuid}}">
                    <div class="font-weight-bold link">{{ object.name }}</div>
                    <div class="caption black--text">
                        {{ $displayContent.types.find(val => val.value === object.type).title }}
                    </div>
                </router-link>
            </v-col>
        </template>
    </template-list>
</template>

<script>
export default {
    name: 'DisplayContents',
    data: () => ({}),
    methods: {
        parseDate(date) {
            return window.moment.localDateTime(date)
        },
    },
    created() {

    }
}
</script>

<style scoped>

</style>