<template>
  <template-list
    :auto-load-list="false"
    :model="$promocodeLog"
    :show-create-button="false"
    :hide-header="inUser"
  >
    <template v-slot:actions>
      <v-btn
        :loading="$order.loadings.list"
        color="primary"
        height="40"
        depressed
        class="rounded-lg text-none body-2"
        @click="loadList"
        >Обновить
      </v-btn>
    </template>
    <template v-slot:subactions>
      <v-row dense align="center" class="my-0">
        <v-col>
          <date-picker
            class="rounded-lg"
            label="Дата начала"
            with-time
            clearable
            v-model="$promocodeLog.filters.start_created_at"
          ></date-picker>
        </v-col>
        <v-col>
          <date-picker
            label="Дата окончания"
            with-time
            class="rounded-lg"
            clearable
            v-model="$promocodeLog.filters.end_created_at"
          ></date-picker>
        </v-col>
        <v-col>
          <v-text-field
            class="rounded-lg"
            outlined
            label="Номер телефна"
            dense
            hide-details
            v-model="$promocodeLog.filters.user_phone"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-select
            clearable
            hide-details
            class="rounded-lg"
            :items="$promocodeLog.typesList"
            item-value="value"
            item-text="label"
            v-model="$promocodeLog.filters.type"
            label="Тип"
            outlined
            dense
          ></v-select>
        </v-col>
        <!--        <v-col>-->
        <!--          <v-text-field class="rounded-lg" dense hide-details outlined label="Макс. сумма"-->
        <!--                        v-model="$promocodeLog.filters.max_sum"></v-text-field>-->
        <!--        </v-col>-->
        <!--        <v-col>-->
        <!--          <v-select clearable hide-details class="rounded-lg"-->
        <!--                    :items="[-->
        <!--                                  {name: 'Автоматическое', id: 'auto'},-->
        <!--                                  {name: 'Ручное', id: 'manual'},-->
        <!--                              ]" item-value="id"-->
        <!--                    item-text="name"-->
        <!--                    v-model="$promocodeLog.filters.type" label="Тип" outlined-->
        <!--                    dense></v-select>-->
        <!--        </v-col>-->
        <!--        <v-col>-->

        <!--        <v-col>-->
        <!--          <v-text-field clearable class="rounded-lg" v-model="$promocodeLog.filters.search" dense-->
        <!--                        hide-details-->
        <!--                        outlined-->
        <!--                        label="Поиск"></v-text-field>-->
        <!--        </v-col>-->
      </v-row>
      <order-view-dialog v-model="dialog"></order-view-dialog>
    </template>
    <template v-slot="{ object, to, index }">
      <v-col class="pa-0">
        <v-row no-gutters align="center" class="font-weight-bold">
          <div>
            Пользователь: {{ object.user_data?.first_name || "Не указано" }}
            {{ object.user_data?.phone }}
          </div>
          <v-spacer></v-spacer>
          <div>Тип: {{ $promocodeLog.typeName[object.type] }}</div>
        </v-row>
        <v-row no-gutters align="center">
          <div>
            Промокод:
            <span class="font-weight-bold">{{
              object.condition.name || "Отсутствует"
            }}</span>
          </div>
          <v-spacer></v-spacer>
          <div>{{ parseDate(object.created_at) }}</div>
        </v-row>
        <v-row no-gutters align="center">
          <div
            :class="{ 'text-primary': object.order_id }"
            style="cursor: pointer"
            @click="openOrder(object.order_id)"
          >
            Заказ:
            <span class="font-weight-bold">{{
              object.order_data?.daily_number || "Отсутствует"
            }}</span>
          </div>
        </v-row>
        <!--        <v-row no-gutters align="center">-->
        <!--          <div>Сообщение пользователю: {{ object.message || 'Не указано' }}</div>-->
        <!--        </v-row>-->
        <!--        <v-row no-gutters align="center">-->
        <!--          <div>Комментарий: {{ object.description || 'Не указано' }}</div>-->
        <!--        </v-row>-->
        <!--        <v-row no-gutters align="center">-->
        <!--          <div v-if="object.created_by">Сформировал: {{ object.created_by.first_name || 'Не указано' }} {{-->
        <!--              object.created_by.phone-->
        <!--            }}-->
        <!--          </div>-->
        <!--        </v-row>-->
      </v-col>
    </template>
  </template-list>
</template>

<script>
import { parseDate } from "@/services/parseDate";

export default {
  name: "PromocodeLogList",
  components: {},
  data: () => ({
    dialog: false,
  }),
  props: {},
  methods: {
    parseDate,
    loadList() {
      this.$promocodeLog.loadList();
    },
    openOrder(id) {
      if (!id) return;
      this.$order.loadItem(id).then(() => {
        this.dialog = true;
      });
    },
  },
  created() {
    this.loadList();
  },
};
</script>

<style scoped></style>
