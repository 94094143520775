const m = require('air-vue-model')()
m.url = 'equipment_locked_periods'
m.name = 'equipmentLockedPeriod'

m.text = {
    listTitle: 'Блокировки оборудования'
}

m.filters = {
    start_date: null,
    end_date: null,
    outlet: null
}

m.routes = [
    {
        name: 'list',
        component: require('@/admin/views/equipmentLockedPeriod/EquipmentLockedPeriods'),
    },
    {
        name: 'gant',
        component: require('@/admin/views/gant/GantView'),
        path: 'gant'
    },
]

module.exports = m