<template>
    <div class="product-group-tile cursor-pointer pa-2 mx-1"
         :class="{'selected-product-group-tile': isSelected}"
         @click="selectProductGroup"
    >
        <div class="text-body-2 noselect">{{ productGroup.name }}</div>
    </div>
</template>

<script>
    export default {
        name: "ProductGroupTile",

        props: {
            productGroup: Object,
        },

        computed: {
            isSelected() {
                return this.$store.state.selectedProductGroupId === this.productGroup.id
            },
        },

        methods: {
            selectProductGroup() {
                if (this.$store.state.selectedProductGroupId === this.productGroup.id) {
                    this.$store.state.selectedProductGroupId = null
                } else {
                    this.$store.state.selectedProductGroupId = this.productGroup.id
                }
            },
        },
    }
</script>

<style scoped>
    .product-group-tile {
        border: #FAB400 1px solid;
        border-radius: 5px;
    }

    .selected-product-group-tile {
        background-color: #FAB400;
        color: white;
    }
</style>