<template>
    <v-col class="pa-0">
        <v-divider class="my-3"></v-divider>
        <div class="text-h5 font-weight-medium mb-3">Платежные сервисы</div>
        <v-row no-gutters align="center">
            <v-text-field v-model="applePay" hide-details outlined dense label="Apple Pay"></v-text-field>
            <v-text-field class="ml-3" v-model="googlePay" hide-details outlined dense
                          label="Google Pay"></v-text-field>
            <v-btn height="40" class="ml-3" depressed color="primary" @click="save">Сохранить</v-btn>
        </v-row>
    </v-col>
</template>

<script>
export default {
    name: 'CompanyPaymentServices',
    props: {
        company: Object
    },
    data: () => ({
        applePay: null,
        googlePay: null,
    }),
    methods: {
        save() {
            this.$company.sendPostSingle('set_payment_services', this.company.id, {
                apple_pay: this.applePay || null,
                google_pay: this.googlePay || null,
            }).then(res => {
                this.$store.commit('setSnackbar', {color: 'success', text: 'Данные сохранены'})
            })
        }
    },
    created() {
        const paymentServices = this.company.payment_services
        this.applePay = paymentServices ? paymentServices.apple_pay : null
        this.googlePay = paymentServices ? paymentServices.google_pay : null

    }
}
</script>

<style scoped>

</style>