<template>
  <template-list
    :model="$displayDevice"
    :show-create-button="$auth.hasRight('displays__edit_information')"
    title="Настройки экранов / Экраны"
  >
    <template v-slot:actions>
      <v-select
        v-model="$displayDevice.filters.outlet"
        :items="outletItems"
        class="rounded-lg mr-2"
        clearable
        dense
        hide-details
        :item-text="v => v.number + '. ' + v.address"
        item-value="id"
        label="Торговая точка"
        outlined
        style="max-width: 200px;"
      ></v-select>
      <v-btn
        class="mr-2 rounded-lg"
        color="primary"
        depressed
        height="40"
        @click="$displayDevice.loadList()"
      >
        <div class="text-none body-2 px-2">Обновить</div>
      </v-btn>
    </template>
    <template v-slot="{ object, to, index }">
      <router-link
        :to="{
          name: 'displayDeviceItem',
          params: { displayDeviceUuid: object.uuid },
        }"
        style="width: 100%"
      >
        <v-col class="pa-0 body-2 black--text">
          <v-row align="center" class="font-weight-bold" no-gutters>
            <div class="link">{{ object.name }}</div>
            <div
              :class="object.active ? 'green--text' : 'red--text'"
              class="caption ml-2"
            >
              {{ object.active ? "Активно" : "Не активно" }}
            </div>
            <v-spacer></v-spacer>
            <div>
              {{ object.line ? object.line.name : "Очередь отсутствует" }}
            </div>
          </v-row>
          <v-row align="center" class="caption" no-gutters>
            <div>
              {{
                object.outlet
                  ? object.outlet.number + '. ' + object.outlet.address
                  : "Торговая точка не привязана"
              }}
            </div>
            <v-spacer></v-spacer>
            <div>{{ parseDate(object.last_online_at) }}</div>
          </v-row>
        </v-col>
      </router-link>
    </template>
  </template-list>
</template>

<script>
import {cloneDeep} from "lodash";

export default {
  name: "DisplayDevices",
  data: () => ({}),
  methods: {
    parseDate(date) {
      return window.moment.localDateTime(date);
    },
  },
  created() {},
  computed: {
    outletItems() {
      const res = cloneDeep(this.$outlet.list)
      res.forEach(v => v.disabled = undefined)
      return res
    }
  }
};
</script>

<style scoped></style>
