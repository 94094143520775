<template>
    <div v-if="limitedProducts.length > 0">
        <div class="text-caption error--text">Недоступные товары:</div>
        <div class="text-body-2" v-for="(product, index) in limitedProducts" :key="index" v-text="product.name"/>
    </div>
</template>

<script>
    export default {
        name: "OutletLimitedProducts",

        props: {
            limitedProducts: Array,
        }
    }
</script>
