<template>
    <template-list :model="$outlet" :show-create-button="false" :items="list">
        <template v-slot:actions>
            <search :rounded="false" always v-model="search"></search>
        </template>
        <template v-slot="{object, to, index}">
            <v-col class="pa-0">
                <router-link :to="to">
                    <v-col class="pa-0">
                        <v-row no-gutters align="center" class="link">
                            <div class=" font-weight-bold mr-1">{{ object.number || 'Отсутствует' }}.</div>
                            <div class="font-weight-bold">{{ object.address || 'Отсутствует адрес' }}</div>
                            <v-chip v-if="object.hidden_for_guests" class="ml-2" x-small>Скрыта
                            </v-chip>
                            <v-chip v-if="object.for_delivery" class="ml-2" x-small>На доставку
                            </v-chip>
                            <OutletQueueData class="ml-3" :outlet-id="object.id"></OutletQueueData>
                        </v-row>
                        <v-row no-gutters align="center">
                            <div class="caption grey--text text--darken-2 mr-3" v-if="object.company">
                                {{ object.company.name }}
                            </div>
                            <div class="mr-1 text-caption"
                                 :class="{'red--text': !object.active, 'green--text': object.active}">
                                {{ object.active ? 'Активна' : 'Не активна' }}
                            </div>
                        </v-row>
                    </v-col>
                </router-link>
            </v-col>
        </template>
    </template-list>
</template>

<script>
import Search from "@/components/template/Search";
import search from "air-vue-model/search";
import OutletQueueData from "@/admin/views/outlets/OutletQueueData.vue";

export default {
    name: "Outlets",
    components: {
        OutletQueueData,
        Search
    },
    computed: {
        list() {
            return search(this.search, this.$outlet.list, v => v.vendor_code + v.address)
        }
    },
    data: () => ({
        search: null
    })
}
</script>

<style scoped>
.text--success {
    color: #56c224;
}

.text--error {
    color: #bf533f;
}
</style>
