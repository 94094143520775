<template>
    <v-col class="pa-0">
        <template-list :model="$deviceActivity" :show-create-button="false">
            <template v-slot:actions>
                <v-btn color="primary" height="40" class="rounded-lg text-none body-2" depressed
                       @click="$deviceActivity.loadList()">Обновить
                </v-btn>
            </template>
            <template v-slot:subactions>
                <v-row dense align="center" class="my-0">
                    <v-col cols="12" md="3">
                        <v-select clearable hide-details class="rounded-lg"
                                  :items="$deviceActivity.types" item-value="value" item-text="title"
                                  v-model="$deviceActivity.filters.type" label="Тип приложения" outlined
                                  dense></v-select>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-select clearable hide-details class="rounded-lg"
                                  :items="$user.list" item-value="id"
                                  :item-text="val => val.phone + ' ' + val.first_name"
                                  v-model="$deviceActivity.filters.user" label="Пользователь" outlined
                                  dense>
                        </v-select>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field outlined hide-details dense v-model="$deviceActivity.filters.phone" class="rounded-lg"
                                      label="Телефон"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field outlined hide-details dense v-model="$deviceActivity.filters.mac" class="rounded-lg"
                                      label="Устройство"></v-text-field>
                    </v-col>
                </v-row>
            </template>
            <template v-slot="{object, to, index}">
                <v-col class="pa-0 body-2">
                    <v-row no-gutters align="center" class="font-weight-bold">
                        <div>{{ object.type === 1 ? 'Сервисное' : 'Клиентское' }}</div>
                        <v-spacer></v-spacer>
                        <div>Сборка: {{ object.app_build_version }}</div>
                    </v-row>
                    <v-row no-gutters align="center" class="mt-0 caption">
                        <div>+{{ object.user.phone }} - {{ object.user.first_name }}</div>
                        <v-spacer></v-spacer>
                        <div>Версия: {{ object.app_version }}</div>
                    </v-row>
                    <v-row no-gutters align="center" class="mt-0 caption">
                        <div>{{ parseDate(object.updated_at) }}</div>
                        <v-spacer></v-spacer>
                        <div>Устройство: {{ object.mac }}</div>
                    </v-row>
                </v-col>
            </template>
        </template-list>
    </v-col>
</template>

<script>

export default {
    name: 'DeviceActivity',
    components: {},
    data: () => ({}),
    methods: {
        parseDate(date) {
            return window.moment.localDateTime(date)
        },
    },
    created() {
        this.$user.loadList({
            user_type: 3
        })
    }
}
</script>

<style scoped>

</style>