import Vue from 'vue'
import Vuex from 'vuex'
import auth from '../../vendor/auth'
import items from "./items";
import helpers from "../../vendor/helpers";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        drawer: true,
        ready: false,
        outletQueue: [],
        settings: null,
        _productDrawer: false,
        outletQueueLoading: false,
        onlyInApp: true,
        units: [
            {name: 'pc', title: 'Шт'},
            {name: 'kg', title: 'Кг'},
            {name: 'g', title: 'Г'},
            {name: 'ml', title: 'Мл'},
        ],
        userPageSavedFilters: {}
    },
    getters: {productDrawer: (state) => state._productDrawer},
    mutations: {
        setProductDrawer: (state, payload) => {
            state._productDrawer = payload
        }
    },
    actions: {},
    modules: {
        auth, helpers, items
    }
})
