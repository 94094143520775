<template>
    <v-col class="pa-0">
        <template-list :model="$equipmentLockedPeriod" :show-create-button="false">
            <template v-slot:actions>
                <v-btn color="primary" height="40" depressed class="rounded-lg text-none body-2"
                       @click="$equipmentLockedPeriod.loadList()">Обновить
                </v-btn>
            </template>
            <template v-slot:subactions>
                <v-row dense align="center" class="my-0">
                    <v-col cols="12" md="3">
                        <date-picker class="rounded-lg" label="Дата начала"
                                     with-time clearable
                                     v-model="$equipmentLockedPeriod.filters.start_date"></date-picker>
                    </v-col>
                    <v-col cols="12" md="3">
                        <date-picker label="Дата окончания" with-time class="rounded-lg"
                                     clearable
                                     v-model="$equipmentLockedPeriod.filters.end_date"></date-picker>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-select clearable hide-details class="rounded-lg"
                                  :items="$equipment.list" item-value="id" item-text="name"
                                  v-model="$equipmentLockedPeriod.filters.equipment" label="Оборудование" outlined
                                  dense></v-select>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-select clearable hide-details class="rounded-lg"
                                  :items="outletItems" item-value="id" :item-text="v => v.number + '. ' + v.address"
                                  v-model="$equipmentLockedPeriod.filters.outlet" label="Торговая точка" outlined
                                  dense></v-select>
                    </v-col>
                </v-row>
            </template>
            <template v-slot="{object, to, index}">
                <v-col class="pa-0 body-2" @click="openOrder(object.order)">
                    <v-row no-gutters align="center" class="font-weight-bold">
                        <div>Номер заказа: {{ object.order.daily_number || 'Отсутствует' }}</div>
                        <div class="ml-2">id: {{ object.order.id }}</div>
                        <v-spacer></v-spacer>
                        <div>{{ object.equipment.name }} ({{ object.slot_number }})</div>
                        <v-icon size="15" class="ml-2" :color="object.active ? 'green' : 'grey'">
                            mdi-checkbox-blank-circle
                        </v-icon>
                    </v-row>
                    <v-row no-gutters align="center" class="mt-0 caption">
                        <div>Адрес: {{ object.order.outlet.address }}</div>
                        <v-spacer></v-spacer>
                        <div v-if="object.order.user">+{{ object.order.user.phone }}</div>
                    </v-row>
                    <v-row no-gutters align="center" class="mt-0 caption">
                        <div>{{ parseDate(object.start_date) }} - {{ parseDate(object.end_date) }}</div>
                        <v-spacer></v-spacer>
                        <div>{{ parseDate(object.order.created_at) }}</div>
                    </v-row>
                </v-col>
            </template>
        </template-list>
        <order-view-dialog v-model="dialog"></order-view-dialog>
    </v-col>
</template>

<script>
import OrderViewDialog from "@/admin/views/equipmentLockedPeriod/OrderViewDialog";
import {cloneDeep} from "lodash";

export default {
    name: 'EquipmentLockedPeriods',
    components: {
        OrderViewDialog
    },
    data: () => ({
        time: null,
        dialog: false
    }),
    methods: {
        parseDate(date) {
            return window.moment.localDateTime(date)
        },
        openOrder(order) {
            this.$order.loadItem(order.id).then(() => {
                this.dialog = true
            })
        }
    },
    created() {
        this.$outlet.loadList({})
        this.$equipment.loadList({})
    },
  computed: {
      outletItems() {
        const res = cloneDeep(this.$outlet.list)
        res.forEach(v => v.disabled = undefined)
        return res
      }
  }
}
</script>

<style scoped>

</style>