const m = require('air-vue-model')()
m.url = 'user_roles'
m.name = 'userRole'
m.text = {
    itemTitle: 'Роль',
    listTitle: 'Роли'
}

m.default = {
    name: null,
    rights: [],
    push_settings: []
}
m.pagination.page_size = 'all'
m.routes = [
    {
        name: 'item',
        component: require('@/admin/views/userRoles/UserRoleItem.vue'),
        single: true,
    },
    {
        name: 'list',
        component: require('@/admin/views/userRoles/UserRoleList.vue'),
    },
]
module.exports = m