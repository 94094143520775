<template>
  <!--  :show-create-button="$auth.hasRight('roles__edit_information')"-->
  <div>
    <template-list
      :model="$userPromocode"
      :show-create-button="false"
      header-classes="user-promocodes-list-header"
    >
      <template v-slot:actions>
        <!--        v-if="-->
        <!--        $promocodeCondition.item?.type === $promocodeCondition.typeEnum.TEXT-->
        <!--        "-->
        <v-spacer />
        <v-menu
          :close-on-content-click="false"
          :nudge-right="40"
          max-width="290px"
          min-width="290px"
          offset-y
          transition="scale-transition"
        >
          <template v-slot:activator="{ on }">
            <v-btn depressed class="rounded-lg mr-3" color="primary" v-on="on">
              <v-icon>mdi-filter</v-icon>
            </v-btn>
          </template>
          <v-list class="pt-4 px-3">
            <v-row align="center" class="ma-0" justify="space-between">
              <div class="subtitle-2">Фильтрация</div>
              <v-btn text x-small @click="clearFilter()">Сбросить фильтр</v-btn>
            </v-row>

            <v-text-field
              v-model="$userPromocode.filters.user_phone"
              label="Номер телефона"
              v-bind="inputProps"
            />
            <v-text-field
              v-if="
                $promocodeCondition.item.type ===
                $promocodeCondition.typeEnum.TEXT
              "
              v-model="$userPromocode.filters.text"
              label="Текст промокода"
              v-bind="inputProps"
            />
            <div class="mt-3 text--secondary">Дата выпуска</div>
            <v-row class="ma-0">
              <v-col class="pa-0" cols="6">
                <date-picker
                  v-model="$userPromocode.filters.start_created_at"
                  class="mr-1 body-2"
                  label="С"
                  v-bind="{ ...inputProps, class: undefined }"
                />
              </v-col>
              <v-col class="pa-0" cols="6">
                <date-picker
                  v-model="$userPromocode.filters.end_created_at"
                  class="ml-1 body-2"
                  label="По"
                  v-bind="{ ...inputProps, class: undefined }"
                />
              </v-col>
            </v-row>
            <div class="mt-3 text--secondary">Дата активации</div>
            <v-row class="ma-0">
              <v-col class="pa-0" cols="6">
                <date-picker
                  v-model="$userPromocode.filters.start_spent_at"
                  class="mr-1 body-2"
                  label="С"
                  v-bind="{ ...inputProps, class: undefined }"
                />
              </v-col>
              <v-col class="pa-0" cols="6">
                <date-picker
                  v-model="$userPromocode.filters.end_spent_at"
                  class="ml-1 body-2"
                  label="По"
                  v-bind="{ ...inputProps, class: undefined }"
                />
              </v-col>
            </v-row>
            <v-row
              align="center"
              class="mx-0 mb-0 mt-5 pb-2 pt-2"
              style="position: sticky; bottom: 0; background: white"
            >
              <v-btn color="primary" small @click="loadList()">Применить</v-btn>
            </v-row>
          </v-list>
        </v-menu>
        <v-btn
          v-if="
            $auth.hasRight('user_promocode__assign') &&
            !$promocodeCondition.item?.for_earn?.required_progress &&
            $promocodeCondition.item?.status !==
              $promocodeCondition.statusEnum.DRAFT
          "
          depressed
          class="rounded-lg"
          color="primary"
          @click="openNewPromocodesModal()"
        >
          <div class="text-none">Выпустить промокоды</div>
        </v-btn>
      </template>
      <template v-slot="{ object, to, index }">
        <v-col class="pa-0 body-2">
          <v-row
            no-gutters
            align="center"
            class="font-weight-bold"
            v-if="
              $promocodeCondition.item?.type ===
              $promocodeCondition.typeEnum.TEXT
            "
          >
            <div>Текст: {{ object.text }}</div>
            <v-spacer></v-spacer>
          </v-row>
          <v-row no-gutters align="center" class="mt-0 text-body-2">
            <div>
              Пользователь:
              <component
                :is="object.user_data ? 'router-link' : 'div'"
                :to="
                  object.user_data
                    ? { name: 'userItem', params: { userId: object.user_id } }
                    : undefined
                "
              >
                {{ object.user_data?.phone || "Отсуствует" }}
              </component>
            </div>
          </v-row>
          <v-row
            no-gutters
            align="center"
            class="mt-0 text-body-2"
            v-if="object.received_by_order_id"
          >
            <div>
              Получен за заказ:
              <span
                style="cursor: pointer"
                :class="{ 'text-primary': !!object.received_by_order_id }"
                @click="openOrder(object.received_by_order_id)"
              >
                {{
                  object.received_by_order_data?.daily_number || "Без заказа"
                }}
              </span>
            </div>
          </v-row>
          <v-row no-gutters align="center" class="mt-0 text-body-2">
            <div>Когда получен: {{ parseDate(object.created_at) }}</div>
          </v-row>
          <v-row no-gutters align="center" class="mt-0 text-body-2">
            <div>
              Потрачен на заказ:
              <span
                style="cursor: pointer"
                :class="{ 'text-primary': !!object.spent_on_order_id }"
                @click="openOrder(object.spent_on_order_id)"
              >
                {{ object.spent_on_order_data?.daily_number }}
              </span>
            </div>
          </v-row>
          <v-row
            no-gutters
            align="center"
            class="mt-0 text-body-2"
            v-if="object.spent_at"
          >
            <div>Когда потрачен: {{ parseDate(object.spent_at) }}</div>
          </v-row>
          <v-row
            no-gutters
            align="center"
            class="mt-0 text-body-2"
            v-if="object.progress"
          >
            <div>Прогресс: {{ object.progress }}</div>
          </v-row>
          <v-row no-gutters align="center" class="mt-0 text-body-2">
            <div>
              Истекает:
              {{ object.expired_at ? parseDate(object.expired_at) : "Никогда" }}
            </div>
          </v-row>
          <v-row no-gutters align="center" class="mt-0 text-body-2">
            <div>ID: {{ object.id }}</div>
          </v-row>
        </v-col>
      </template>
    </template-list>
    <CreateTextCodesModal v-model="createTextCodesModelValue" />
    <CreateUserPromocodeModal v-model="createUserPromocodesModelValue" />
    <OrderViewDialog :value="orderModal" @input="orderModal = $event" />
  </div>
</template>

<script>
import CreateTextCodesModal from "@/admin/views/promocodes/CreateTextCodesModal.vue";
import { parseDate } from "../../../services/parseDate";
import OrderViewDialog from "@/admin/views/equipmentLockedPeriod/OrderViewDialog.vue";
import CreateUserPromocodeModal from "@/admin/views/promocodes/CreateUserPromocodeModal.vue";
import LazySelect from "@/components/helpers/LazySelect.vue";
import ImageUploader from "@/admin/components/ImageUploader.vue";

export default {
  name: "UserPromocodeList",
  components: {
    ImageUploader,
    LazySelect,
    OrderViewDialog,
    CreateTextCodesModal,
    CreateUserPromocodeModal,
  },
  data: () => {
    return {
      createTextCodesModelValue: false,
      createUserPromocodesModelValue: false,
      orderModal: false,
      inputProps: {
        class: "project-search-input pa-0 rounded-lg mt-3 ",
        dense: true,
        hideDetails: true,
        outlined: true,
        rounded: true,
        style: "transition: 0.3s",
        clearable: true,
      },
    };
  },
  created() {
    const id = this.$route.params.promocodeConditionId;
    if (!id) return;
    this.$userPromocode.filters = { promocode_condition: id };
    this.loadList();
  },
  methods: {
    parseDate,
    openOrder(id) {
      if (!id) return;
      this.$order.loadItem(id).then(() => {
        this.orderModal = true;
      });
    },
    openNewPromocodesModal() {
      const item = this.$promocodeCondition.item;
      if (!item) return;
      if (item.type === this.$promocodeCondition.typeEnum.DEFAULT)
        this.createUserPromocodesModelValue = true;
      else this.createTextCodesModelValue = true;
    },
    clearFilter() {
      const id = this.$route.params.promocodeConditionId;
      if (!id) return;
      this.$userPromocode.filters = {
        promocode_condition: id,
      };
      this.loadList();
    },
    loadList() {
      void this.$userPromocode.loadList();
    },
  },
};
</script>

<style scoped>
.text-primary {
  color: #e5a400;
}

:deep(.user-promocodes-list-header) {
  position: absolute;
  right: 0;
  transform: translateY(-68px);
}
</style>
